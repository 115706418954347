import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { GetModulo } from "../../../services/API";

import "../style.css";
import star from "../../../assets/icons/estrela.png";
import clock from "../../../assets/icons/relogio.png";
import { Box, Typography } from "@mui/material";

function Empreendedorismo() {
   const navigate = useNavigate();

   const [Aulas, setAulas] = useState([]);
   const noImage = 'https://img.freepik.com/premium-vector/no-photo-available-vector-icon-default-image-symbol-picture-coming-soon-web-site-mobile-app_87543-18055.jpg'

   const getGestao = async () => {
      const response = await GetModulo(4);

      if (response.status === 200) {
         var moduloAtivo = response.data.filter((res) => res.FlgAtivo)
         setAulas(moduloAtivo);
      }
   };

   useEffect(() => {
      getGestao();
   }, []);

   return (
      <div className="home-container" style={{ paddingTop: 10 }}>
         <Box sx={{ width: "100%", marginBottom: 1 }}>
            <Box style={{ width: "100%", marginLeft: 30, marginTop: 20 }}>
               <Typography style={{ fontWeight: "bold" }}>Academy</Typography>
            </Box>
         </Box>

         <h3 style={{ marginLeft: 30 }}>Empreendedorismo</h3>

         <div className="home-card-container">
            {Array.isArray(Aulas) &&
               Aulas.map((res) => (
                  <div
                     onClick={() => navigate("/aula")}
                     className="home-card-content"
                     key={res.IdModulo}
                  >
                     <div style={{ position: "relative" }}>
                        <img
                           src={res.CaminhaCapa ? res.CaminhaCapa : noImage}
                           id="home-card-image"
                           alt={res.Titulo}
                        />
                        <FavoriteRoundedIcon
                           htmlColor={res.fav ? "#ff8e00" : "#d3d3d3"}
                           fontSize="medium"
                           id="home-icon-play-fav"
                        />
                        <PlayArrowRoundedIcon
                           htmlColor="#fff"
                           fontSize="medium"
                           id="home-icon-play"
                        />
                     </div>
                     <label>{res.Titulo}</label>
                     <p id="home-card-description">{res.Descricao}</p>
                     <div className="home-card-status">
                        <div id="home-card-footer">
                           <img src={clock} id="home-icon-image" alt="icon" />
                           <text>{res.Tempo} minutos</text>
                        </div>
                        <div id="home-card-footer">
                           <text>{res.star}</text>
                           <img src={star} id="home-icon-image" alt="icon" />
                        </div>
                     </div>
                  </div>
               ))}
         </div>
      </div>
   );
}

export default Empreendedorismo;
