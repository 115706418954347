import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;
