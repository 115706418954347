
import CloseIcon from '@mui/icons-material/Close';
import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
  ${({ theme }) => css`
    position: fixed;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    right: 1rem;
    bottom: 10rem;
    border-radius: 10px;
    z-index: 6;
  `}
`;

export const ContainerModal = styled(Box)`
  ${({ theme }) => css`
    width: 350px;
    height: 300px;

    @media (max-width: 393px) {
        width: 100%;
    }
  `}
`;

export const CloseBtn = styled(CloseIcon)`
    path {
        color: white
    }
    cursor: pointer;

    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 15px;
    margin-right: 10px;
`;
