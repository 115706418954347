export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_FONT_SIZE = '@customization/SET_FONT_SIZE';
export const SET_BACKGROUND_COLOR = '@customization/SET_BACKGROUND_COLOR';
export const SET_MODE = '@customization/SET_MODE';

export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
