import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as Styled from './styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import { CancelOutlined, Checkroom, Inventory2Outlined, LocalShippingOutlined, LocationOn, People, Person, ProductionQuantityLimits, QueryStatsOutlined, ShoppingBagOutlined, ShoppingCart } from '@mui/icons-material';
import { INDICADORES_SET } from '../../../store/IndicadoresReducer/types';
import { POST_DATA } from '../../../services/API';
import IndicadoresListItem from '../../../components/IndicadoresListItem';
import PeriodPicker from '../../../components/PeriodPicker';
import DashboardCard from '../../../components/DashboardCard';
import { useNavigate } from 'react-router';

const ResumoLoja = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);
    const usuarioLogado = useSelector((store) => store.usuarioLogado);
    const [quantidadeProdutos, setQuantidadeProdutos] = useState([]);

    const [indicadores, setIndicadores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async() => {
            if(usuarioLogado !== null){
                await fetchIndicadores(usuarioLogado.IdUsuario);
                const produtosBuscados = await fecthProdutos(usuarioLogado.IdUsuario);
                const prds = produtosBuscados.filter(a => a.DtInclusao >= ordenacaoDashboard.period.startDate && a.DtInclusao <= ordenacaoDashboard.period.endDate );
                
                let quantidade = 0;
                prds.map((produto) => {
                    produto._Etiquetas.map((etiqueta) => {
                        const qtd = etiqueta._EtiquetaComplemento.reduce((soma, item) => { return soma += item?.iQuantidade}, 0);
                        quantidade += qtd;
                    });                    
                });

                setQuantidadeProdutos(quantidade);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarioLogado, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    const fetchIndicadores = async (idUsuario) => {
        setIsLoading(true);
        try {
            const indicadoresEndpoint = `/Usuario/GetDashMinhaLoja?IdUsuario=${idUsuario}&DataInicioAtual=${moment(ordenacaoDashboard?.period?.startDate).format('YYYY-MM-DD')}T00%3A00%3A00&DataFimAtual=${moment(ordenacaoDashboard?.period?.endDate).format('YYYY-MM-DD')}T23%3A59%3A59&DataInicioAntigo=${moment(ordenacaoDashboard?.periodToCompare?.startDate).format('YYYY-MM-DD')}T00%3A00%3A00&DataFimAntigo=${moment(ordenacaoDashboard?.periodToCompare?.endDate).format('YYYY-MM-DD')}T23%3A59%3A59`;            
            const resIndicadores = await POST_DATA(indicadoresEndpoint);

            dispatch({
                type: INDICADORES_SET,
                data: resIndicadores
            });
            setIndicadores(resIndicadores);
            setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
    };

    const fecthProdutos = async (idUsuario) => {
        const endpoint = `Imagem/GetAllImagemByIdUsuario?idUsuario=${idUsuario}&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdImagem`;
        return await POST_DATA(endpoint);
    }

    return (
        <Styled.Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Indicadores / Resumo Loja</Typography>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="body2" sx={{ color: "#707070" }}>
                            {ordenacaoDashboard?.period?.desc === "Hoje" &&
                            `${moment(ordenacaoDashboard?.period?.startDate).format("DD/MM/YYYY")}`}

                            {ordenacaoDashboard?.period?.desc !== "Hoje" &&
                            `${moment(ordenacaoDashboard?.period?.startDate).format("DD/MM/YYYY")} até ${moment(ordenacaoDashboard?.period?.endDate).format("DD/MM/YYYY")}`}
                        </Typography>
                    </Box>

                    <PeriodPicker />
                </Box>
            </Box>
            <Grid
                container
                style={{ 
                    width:'100%',
                    marginTop: 10
                }} 
                direction={'row'} 
                spacing={1}
            >
                <Grid item xs={12} md={3}>
                    <IndicadoresListItem
                        icon={<LocationOn />}
                        indicadorTitulo={"Visitas"}
                        indicadorExibido={"VisitasAtuais"}
                        indicadorComparativo={"VisitasAntigas"}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <IndicadoresListItem
                        icon={<ShoppingCart />}
                        indicadorTitulo={"Vendas"}
                        indicadorExibido={"VendasAtuais"}
                        indicadorComparativo={"VendasAntigas"}
                        link={'/relatorio-vendas-cliente'}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <IndicadoresListItem
                        icon={<Person />}
                        indicadorTitulo={"Clientes"}
                        indicadorExibido={"ClientesAtuais"}
                        indicadorComparativo={"ClientesAntigos"}
                        link={'/relatorio-clientes'}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <IndicadoresListItem
                        icon={<Checkroom />}
                        indicadorTitulo={"Produtos"}
                        indicadorExibido={"ProdutosAtuais"}
                        indicadorComparativo={"ProdutosAntigos"}
                        link={'/relatorio-estoque'}
                        isLoading={isLoading}
                        valor={quantidadeProdutos}
                    />
                </Grid>
            </Grid>
        </Styled.Container>
    );
};

export default ResumoLoja;