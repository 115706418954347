import React, { useEffect, useState } from 'react';

// material-ui
// project imports
import NavGroup from './NavGroup';
import packageJson from '../../../../../package.json';
import { Typography } from '@material-ui/core';
import menuItem from '../../../menu-itens';
import menuLeapLovers from '../../../menu-itens/menuLeapLovers';
import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';
import menuLeapVesti from '../../../menu-itens/menuLeapVesti';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const DivVersao = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  width: ${props => props.drawerOpen ? "260px" : "88px"};
  top: 97%;
  color: #c3c3c3;
  font-size: 16px;
  padding-bottom: 10px;
`;

const MenuList = () => {
  const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';
  const ehLeapVesti = process.env.REACT_APP_LEAP_VESTI === 'true';
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const userLoggedIn = useSelector((state) => state.usuarioLogado);
  const superUser = useSelector((state) => state.superUser);

  const [menusLojaLogada, setMenusLojaLogada] = useState([]);

  const fetchMenuData = async () => {
    try {
      const response = await fetch('https://leapstyle.blob.core.windows.net/crmlogin/menus.json'); // Substitua pelo caminho correto do seu JSON

      if (!response.ok) {
        throw new Error('Erro ao carregar os dados do menu');
      }
      const menus = await response.json();

      const menusByPlano = [{ id: 'initial', type: 'group', children: menuItem.items[0].children.filter(item => item.id === 'dashboard') }];
      const itensMenu = [];


      if (userLoggedIn.PlanoEmpresa?.IdUsuarioEmpresaPlano === 1) //New York
      {
        menus?.menusNewYork.forEach((id) => {
          const item = menuItem.items[1].children.filter(item => item.id === id)[0];

          if (item !== undefined)
            itensMenu.push(item);
        });
      }
      else if (userLoggedIn.PlanoEmpresa?.IdUsuarioEmpresaPlano === 2) //Milão
      {
        menus?.menusMilao.forEach((id) => {
          const item = menuItem.items[1].children.filter(item => item.id === id)[0];

          if (item !== undefined)
            itensMenu.push(item);
        });
      }
      else if (userLoggedIn.PlanoEmpresa?.IdUsuarioEmpresaPlano === 3) //Paris
      {
        menus?.menusParis.forEach((id) => {
          const item = menuItem.items[1].children.filter(item => item.id === id)[0];

          if (item !== undefined)
            itensMenu.push(item);
        });
      }
      else if (userLoggedIn.PlanoEmpresa?.IdUsuarioEmpresaPlano === 4) //Londres
      {
        menus?.menusCompleto.forEach((id) => {
          const item = menuItem.items[1].children.filter(item => item.id === id)[0];

          if (item !== undefined)
            itensMenu.push(item);
        });
      }

      menusByPlano.push({ id: 'pages', type: 'group', children: itensMenu });

      setMenusLojaLogada(menusByPlano);

    } catch (error) {
      console.error('Erro ao carregar os dados do menu:', error);
    }
  };

  useEffect(() => {
    (async () => {
      if(userLoggedIn === null) return;
      
      await fetchMenuData();
    })();
  }, [userLoggedIn]);

  const menu = ehLeapLovers ? menuLeapLovers.items : ehLeapVesti ? menuLeapVesti.items : superUser !== null ? menuItem.items : menusLojaLogada;

  const navItems = menu.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  navItems.push(<div key='rodape'>
    {leftDrawerOpened && <DivVersao drawerOpen={leftDrawerOpened}>Versão: {packageJson.version} </DivVersao>}
    {!leftDrawerOpened && <DivVersao drawerOpen={leftDrawerOpened}>V: {packageJson.version} </DivVersao>}
  </div>);

  return <>{navItems}</>;
};

export default MenuList;
