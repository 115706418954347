import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as Styled from './styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import { CancelOutlined, Checkroom, Inventory2Outlined, LocalShippingOutlined, LocationOn, People, Person, ProductionQuantityLimits, QueryStatsOutlined, ShoppingBagOutlined, ShoppingCart } from '@mui/icons-material';
import { INDICADORES_SET } from '../../../store/IndicadoresReducer/types';
import { POST_DATA } from '../../../services/API';
import IndicadoresListItem from '../../../components/IndicadoresListItem';
import PeriodPicker from '../../../components/PeriodPicker';
import DashboardCard from '../../../components/DashboardCard';
import { useNavigate } from 'react-router';

const EstatisticaLoja = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDarkMode = theme?.palette?.mode !== 'light';
    const matchesXS = useMediaQuery('(max-width:900px)');
    const matchesXL = useMediaQuery('(min-width:1520px)');
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);
    const usuarioLogado = useSelector((store) => store.usuarioLogado);
    const customization = useSelector((store) => store.customization);

    const [indicadores, setIndicadores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async() => {
            if(usuarioLogado !== null){
                await fetchIndicadores(usuarioLogado.IdUsuario);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarioLogado, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    const fetchIndicadores = async (idUsuario) => {
        setIsLoading(true);
        try {
            const indicadoresEndpoint = `/Usuario/GetDashMinhaLoja?IdUsuario=${idUsuario}&DataInicioAtual=${moment(ordenacaoDashboard?.period?.startDate).format('YYYY-MM-DD')}T00%3A00%3A00&DataFimAtual=${moment(ordenacaoDashboard?.period?.endDate).format('YYYY-MM-DD')}T23%3A59%3A59&DataInicioAntigo=${moment(ordenacaoDashboard?.periodToCompare?.startDate).format('YYYY-MM-DD')}T00%3A00%3A00&DataFimAntigo=${moment(ordenacaoDashboard?.periodToCompare?.endDate).format('YYYY-MM-DD')}T23%3A59%3A59`;
            const resIndicadores = await POST_DATA(indicadoresEndpoint);

            dispatch({
                type: INDICADORES_SET,
                data: resIndicadores
            });
            setIndicadores(resIndicadores);
            setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
    };

    return (
        <Styled.Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Indicadores / Estátistica Loja</Typography>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="body2" sx={{ color: "#707070" }}>
                            {ordenacaoDashboard?.period?.desc === "Hoje" &&
                            `${moment(ordenacaoDashboard?.period?.startDate).format("DD/MM/YYYY")}`}

                            {ordenacaoDashboard?.period?.desc !== "Hoje" &&
                            `${moment(ordenacaoDashboard?.period?.startDate).format("DD/MM/YYYY")} até ${moment(ordenacaoDashboard?.period?.endDate).format("DD/MM/YYYY")}`}
                        </Typography>
                    </Box>
                    <PeriodPicker />
                </Box>
            </Box>

            <Grid
                container
                style={{ 
                    width:'100%',
                    marginTop: 10
                }} 
                direction={'row'} 
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Pedidos</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={1}
                        title="Em Análise"
                        icon={<QueryStatsOutlined style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        valueDiff={1200}
                        bgColor="#FFC748"
                        darkBgColor="#c19536"
                        idIndicador={1}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={2}
                        title="Aprovados"
                        icon={<ShoppingBagOutlined style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        valueDiff={100}
                        bgColor="#57B528"
                        darkBgColor="#40841e"
                        idIndicador={2}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={6}
                        title="Recusados"
                        icon={<CancelOutlined style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        valueDiff={100}
                        bgColor="#FF5648"
                        darkBgColor="#bf3e35"
                        idIndicador={6}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={7}
                        title="Cancelados"
                        icon={<CancelOutlined style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        valueDiff={100}
                        bgColor="#343434"
                        darkBgColor="#101010"
                        idIndicador={7}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Logística</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={3}
                        title="Pacote em trânsito"
                        icon={<LocalShippingOutlined style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        valueDiff={100}
                        bgColor="#48abff"
                        darkBgColor="#275a84"
                        idIndicador={3}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={4}
                        title="Pacote entregues"
                        icon={<Inventory2Outlined style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        valueDiff={100}
                        bgColor="#E10279"
                        darkBgColor="#9b0153"
                        idIndicador={4}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    {
                        /** 
                        <DashboardCard
                            category={0}
                            title="Carrinhos recuperados"
                            icon={<ProductionQuantityLimits style={{ color: isDarkMode ? 'black' : 'white' }} />}
                            bgColor="#57B528"
                            darkBgColor="#40841e"
                            idIndicador={5}
                            value={0}
                        />
                        */
                    }
                </Grid>

                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Checkout</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard
                        category={20}
                        title="Carrinhos abandonados"
                        icon={<ProductionQuantityLimits style={{ color: isDarkMode ? 'black' : 'white' }} />}
                        bgColor="#FF5648"
                        darkBgColor="#bf3e35"
                        idIndicador={5}
                        value={indicadores.CarrinhosAbandonadosAtuais}
                    />
                </Grid>
            </Grid>
        </Styled.Container>
    );
};

export default EstatisticaLoja;