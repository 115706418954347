import React from 'react';
import ReactQuill from 'react-quill';
// import ImageResize  from 'quill-image-resize-module';
import 'react-quill/dist/quill.snow.css';
import katex from "katex";
import "katex/dist/katex.min.css";
import CustomToolbar from './CustomToolbar'
window.katex = katex;

// Quill.register('modules/ImageResize',ImageResize);
const Editor = ({ toolbarId = 'customTooolbar', className, style, text, handleChange, showWand = false, onClickCustom = null }) => {
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ]

  const modules = {
    toolbar: {
      container: "#" + toolbarId
    }
  }

  return (
    <>
      <CustomToolbar id={toolbarId} showWand={showWand} onClickCustom={() => onClickCustom()} />
      <ReactQuill
        style={style}
        className={className}
        value={text}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </>
  )
}

Editor.modules = (toolbarId = 'customTooolbar') => ({
  toolbar: {
    container: "#" + toolbarId
  }
})


export default Editor;
