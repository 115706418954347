import { Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { POST_DATA } from '../../../services/API';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from '../../../services/utils/functions';
import { ManageSearch } from '@mui/icons-material';
import EditOutlined from '@mui/icons-material/EditOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagem/types';
import PeriodPicker from '../../../components/PeriodPicker';
import { CONTROLEMENSAGEM_AVISO } from '../../../store/ControleMensagemReducer/types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const columns = [
    { id: "imagem", label: "Foto", minWidth: 100 },
    {
        id: "nome",
        label: "Nome",
        maxWidth: 120,
        align: "left",
    },
    { id: "email", label: "E-mail", minWidth: 90, maxWidth: 100 },
    {
        id: "celular",
        label: "Celular",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "dtInclusao",
        label: "Data Cadastro",
        minWidth: 140,
        align: "left",
        format: (value) => value.toFixed(2),
    },
    {
        id: "flgAtivo",
        label: "Ativo",
        minWidth: 70,
        align: "left",
    },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 100,
        align: "left",
    },
];

const ListagemClientes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [clientes, setClientes] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            if (userLoggedIn !== null) {
                await fetchUsuarios();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    const fetchUsuarios = async () => {
        setLoading(true);

        let endpoint = `UsuarioEmpresa/GetAllUsuarioByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuario}`;
        if (ordenacaoDashboard?.period && ordenacaoDashboard?.periodToCompare) {
            endpoint = `UsuarioEmpresa/GetAllUsuarioByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuario}&dataInicio=${ordenacaoDashboard?.period?.startDate}&dataFim=${ordenacaoDashboard?.period?.endDate}`;
        }

        const result = await POST_DATA(endpoint);
        setRows(result || []);
        setClientes(result);
        setLoading(false);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleAtivaInativa = async (idUsuario, flgAtivo) => {
        if (!flgAtivo) {
            await POST_DATA(`Usuario/DeletarUsuario?IdUsuario=${idUsuario}&Log=`);
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao inativar o usuário',
            });
        }
        else {
            await POST_DATA(`Usuario/AtivarUsuario?IdUsuario=${idUsuario}&Log=`);
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao ativar o usuário',
            });
        }

        await fetchUsuarios();
    }

    const fetchExcel = async () => {
        if (clientes === null || clientes.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Nome; E-mail; Celular; Data de Cadastro; Ativo`;
        clientes?.forEach(cliente => {
            dados += `\n${cliente.Nome}; ${cliente.Email}; ${cliente.Celular}; ${formatDateSimples(cliente.DtInclusao)}; ${(cliente.FlgAtivo ? 'Sim' : 'Não')} `;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Clientes.csv', dados);
        window.open(url, '_blank');
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Clientes</Typography>
                </Box>
            </Box>
            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2}>
                    <TextField
                        label="Pesquisar por e-mail"
                        placeholder='Pesquisar por e-mail'
                        size="small"
                        sx={{ width: querySelector ? "200px" : "250px" }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        onBlur={() => setRows(clientes.filter(a => String(a.Email).includes(search)))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <ManageSearch style={{ cursor: 'pointer' }} onClick={() => setRows(clientes.filter(a => String(a.Email).includes(search)))} />
                            </InputAdornment>,
                            endAdornment: search.length > 0 && (
                                <InputAdornment
                                    style={{ cursor: 'pointer', color: 'black' }}
                                    position="end"
                                    onClick={() => {
                                        setSearch(() => '');
                                        setRows(clientes);
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/edicao-cliente')}
                        style={{ height: 42, marginRight: 5 }}
                    >
                        Novo
                    </Button>
                    <PeriodPicker />
                </Box>
            </Stack>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.imagem}
                                            >
                                                <TableCell key={`img-${row.IdUsuario}`} align='left'>
                                                    {
                                                        row.FotoProfile &&
                                                        <img style={{ borderRadius: "8px", width: "80px", height: "100px" }} src={row.FotoProfile} alt='Usuário' />
                                                    }
                                                    {
                                                        !row.FotoProfile &&
                                                        <img style={{ borderRadius: "8px", width: "80px", width: "70px" }} src={'https://timelineleap-evhgawa4gxdcecak.z01.azurefd.net/img/avatar.png'} alt='Usuário' />
                                                    }
                                                </TableCell>
                                                <TableCell key={`nome-${row.IdUsuario}`} align='left'>
                                                    {row.Nome}
                                                </TableCell>
                                                <TableCell key={`email-${row.IdUsuario}`} align='left'>
                                                    {row.Email}
                                                </TableCell>
                                                <TableCell key={`celular-${row.IdUsuario}`} align='left' style={{ minWidth: 140 }}>
                                                    {row.Celular}
                                                </TableCell>
                                                <TableCell key={`data-inclusao-${row.IdUsuario}`} align='left'>
                                                    <Typography sx={{ fontSize: 15 }}> {formatDateSimples(row.DtInclusao)} </Typography>
                                                </TableCell>
                                                <TableCell key={`flgAtivo-${row.IdUsuario}`} align='left' style={{ minWidth: 140 }}>
                                                    <Switch
                                                        checked={row.FlgAtivo}
                                                        onChange={() => handleAtivaInativa(row.IdUsuario, !row.FlgAtivo)} />
                                                </TableCell>
                                                <TableCell key={`cliente-${row.IdUsuario}`} align='left'>
                                                    <Stack direction="row">
                                                        <Tooltip title="Lista de compras">
                                                            <IconButton
                                                                onClick={() => {
                                                                    navigate('/edicao-cliente', { state: { idUsuario: row.IdUsuario } })
                                                                }}
                                                            >
                                                                <EditOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${rows.length}/${clientes.length} resultados encontrados para pesquisa "${search}" - Clientes por página: `
                                    : "Clientes por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </>
    )
}

export default ListagemClientes;
