import { Box, styled } from '@mui/material';

export const ContainerImagem = styled(Box)`
    position: fixed;
    padding-top: 20%;
    width: 100%;
    display: block;
    padding-left: 100px;

    @media (max-width: 1020px) {
        display: none;
    }
`;
