import { SUPER_USER_SET_DADOS } from './types';


const superUserReducer = (state = null, action) => {
  switch (action.type) {
    case SUPER_USER_SET_DADOS:
      return action.data;
    default:
      return state;
  }
};

export default superUserReducer;
