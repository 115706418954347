import { useEffect, useState } from "react";
import { ProductCardContainer } from "./styles";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { truncate } from "../../services/utils/functions";

const ProdutoCard = ({ mostrarVendidoPor = true, mostrarCheck = false, produto, produtosRelacionados = [], onChangeSelected }) => {
    const navigate = useNavigate();
    const [mostrarBotoes, setMostrarBotoes] = useState(false);
    const possuiRelacao = produtosRelacionados.filter(a => a.idImagem === produto.idImagem && a.idEtiqueta === produto.idEtiqueta && a.idEtiquetaComplemento === produto.idEtiquetaComplemento).length > 0;

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
            {
                mostrarCheck &&
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Checkbox checked={possuiRelacao}
                        onChange={(e) => {
                            onChangeSelected(produto.idImagem, produto.idEtiqueta, produto.idEtiquetaComplemento, possuiRelacao)
                        }} />
                </Box>
            }

            <ProductCardContainer
                imgSrc={produto.fotoProduto}
                onMouseEnter={() => setMostrarBotoes(true)}
                onMouseLeave={() => setMostrarBotoes(false)}
            >
                {
                    mostrarVendidoPor &&
                    <Box style={{ marginTop: 190, height: 10, width: "100%", display: mostrarBotoes ? 'flex' : 'none', flexWrap: 'wrap', justifyContent: "center" }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate(`/detalhe-produto?pid=${produto.id}`, { state: { produto: produto } });
                            }}
                            style={{ width: "100%", color: 'white', borderColor: 'white', fontSize: '12px', padding: 3, textTransform: 'none' }}
                        >
                            Ver produto
                        </Button>
                        {
                            /*
                                <Button variant="outlined" style={{ width: "100%", color: 'white', borderColor: 'white', fontSize: '12px', padding: 3, marginTop: 7, textTransform: 'none' }}>Adicionar ao carrinho</Button>
                            */
                        }
                    </Box>
                }
            </ProductCardContainer>

            <Box style={{ marginTop: "10px" }}>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{truncate(produto.nome || '-', 22)}</Typography>

                {
                    mostrarVendidoPor &&
                    <Typography style={{ fontSize: 11 }}>Vendido por: <span style={{ fontWeight: 'bold', fontSize: 13 }}>{truncate(produto.loja?.nome || '-', 20)}</span></Typography>
                }

                {
                    produto.cor &&
                    <Typography style={{ fontSize: 13, marginTop: 3, marginBottom: 3 }}>{produto.cor} - {produto.tamanho} {produto.estampa ? ` - ${produto.estampa}` : ''}</Typography>
                }

                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{produto.valor?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                })}
                </Typography>
            </Box>

        </div>
    )
}

export default ProdutoCard;