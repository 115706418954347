import React from 'react';
import { Typography } from '@mui/material';
import ImgNotFound from '../../assets/pagina_nao_encontrada.svg';

const classes = {
  image: {
    marginTop: 300,
    display: 'inline-block',
    maxWidth: '100%',
    width: 860,
  },
};

const NotFound = () => {
  return (
    <div
      style={{
        paddingTop: 80,
        textAlign: 'center',
        width: '100%',
      }}
    >
      <div>
        <Typography variant="h1">404: A página informada não foi encontrada</Typography>
        <Typography variant="h5"><a href="/">Voltar ao início</a></Typography>

        <img
          style={{ marginTop: 150 }}
          alt="Under development"
          className={classes.image}
          src={ImgNotFound}
        />
      </div>
    </div>
  );
};

export default NotFound;
