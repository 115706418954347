import { POPOVER_OPEN, POPOVER_CLOSE } from './types';

const INITIAL_STATE = {
  open: false,
  idPopover: '',
  idItem: 0,
  data: null
};

const popoverReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POPOVER_OPEN:
      return { ...state, open: true, idPopover: action.idPopover, idItem: action.idItem, data: action.data };
    case POPOVER_CLOSE:
      return { ...state, open: false, idPopover: action.idPopover, idItem: 0, data: null };
    default:
      return state;
  }
};

export default popoverReducer;
