import { Box, css, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const ModalContainer = styled(Box)`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 30px;
  border-radius: 0.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300;
  
  height: 220px !important;

  max-height: 95%;
  max-width: 600px;
  
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.51);
  @media screen and (max-width: 600px) {
    width: 90%;
  }

  //animation
  animation: fade-in 300ms ease-out forwards;
  @keyframes fade-in {
    from {
      height: 50%;
      opacity: 0;
    }
    to {
      height: 45%;
      opacity: 1;
    }
  }
`;

export const CloseBtn = styled(CloseIcon)`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 5;
  right: 5;
`;