import {
  DeleteTwoTone,
  PhotoLibraryTwoTone,
} from "@mui/icons-material";
import { IconButton, InputLabel, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material';
import { truncate } from "../../services/utils/functions";
import SearchIcon from '@mui/icons-material/Search';
import { CONTROLEMENSAGEM_AVISO } from "../../store/ControleMensagemReducer/types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { v4 as uuid } from 'uuid';

const UploadImagemContainer = styled(InputLabel)`
  min-width: 200px;
  padding: 1rem;
  height: 100%;
  max-height: 400px;
  border-radius: 7px;
  overflow: hidden;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const UploadImagemDesign = ({
  id,
  idComponent,
  isEditing,
  imgData,
  onChange,
  dados = null,
  mobile = false,
  style = null,
  heightImg = 150,
  widthImg = 200,

  dimensaoHeightImg = 0,
  dimensaoWidthImg = 0,

  borderRadiusImg = 10,
  paddingTopImg = 20,
  descricao = ""
}) => {

  const dispatch = useDispatch();
  const [arquivo, setArquivo] = useState(null);
  const [fileSrc, setFileSrc] = useState(null);

  useEffect(() => {
    if (imgData) {
      setFileSrc(imgData);
    }
  }, [imgData]);

  const ehFile = typeof imgData == 'string';

  const handleChange = async (event) => {
    if (event.target.files.length === 0) return;

    const file = event.target.files[0];
    const imgsize = file.size / 1024;

    if (imgsize > 5000) {
      dispatch({
        type: CONTROLEMENSAGEM_AVISO,
        titulo: 'Aviso',
        message: 'Tamanho do arquivo ultrapassa 2MB, favor tratá-la',
        tipoComponente: 'alert'
      });
      return;
    }

    setArquivo(file);

    if (file.type?.includes('video/mp4')) {
      onloadVideo(file);
    }
    else {
      onloadImage(file);
    }
  }

  const onloadImage = (file) => {
    const imgVerificacao = new Image();
    imgVerificacao.src = window.URL.createObjectURL(file);

    imgVerificacao.onload = function () {
      if (imgVerificacao.width <= dimensaoWidthImg && imgVerificacao.height <= dimensaoHeightImg) {
        const fileUploaded = URL.createObjectURL(file);
        setFileSrc(fileUploaded);

        if (mobile) {
          onChange({
            id: id !== undefined ? id : uuid(),
            name: file.name,
            alternative_text: "Imagem",
            caption: "",
            url: dados?.url || null,
            url_mobile: fileUploaded,

            img_mobile: file,
            img: dados?.img || null,

            extensao: dados?.extensao || null,
            extensaoMobile: file.name.split('.').pop()
          });
        }
        else {
          onChange({
            id: id !== undefined ? id : uuid(),
            name: file.name,
            alternative_text: "Imagem",
            caption: "",
            url: fileUploaded,
            url_mobile: dados?.url_mobile || null,

            img_mobile: dados?.img_mobile || null,
            img: file,

            extensao: file.name.split('.').pop(),
            extensaoMobile: dados?.extensaoMobile || null
          });
        }
      }
      else {
        const resultValidarDimensoes = `${imgVerificacao.width}x${imgVerificacao.height}`;

        if (resultValidarDimensoes.length > 0) {
          dispatch({
            type: CONTROLEMENSAGEM_AVISO,
            titulo: 'Aviso',
            message: `O arquivo deve ter a resolução de ${dimensaoWidthImg} x ${dimensaoHeightImg} pixels. O arquivo possuí ${resultValidarDimensoes}`,
            tipoComponente: 'alert'
          });
          return;
        }
      }
    }
  }

  const onloadVideo = (file) => {
    const videoVerificacao = document.createElement('video');
    videoVerificacao.src = URL.createObjectURL(file);

    videoVerificacao.onloadedmetadata = function () {
      if (videoVerificacao.videoWidth <= dimensaoWidthImg && videoVerificacao.videoHeight <= dimensaoHeightImg) {
        const fileUploaded = URL.createObjectURL(file);
        setFileSrc(fileUploaded);

        if (mobile) {
          onChange({
            id: id !== undefined ? id : uuid(),
            name: file.name,
            alternative_text: "Imagem",
            caption: "",
            url: dados?.url || null,
            url_mobile: fileUploaded,

            img_mobile: file,
            img: dados?.img || null,

            extensao: dados?.extensao || null,
            extensaoMobile: file.name.split('.').pop()
          });
        }
        else {
          onChange({
            id: id !== undefined ? id : uuid(),
            name: file.name,
            alternative_text: "Imagem",
            caption: "",
            url: fileUploaded,
            url_mobile: dados?.url_mobile || null,

            img_mobile: dados?.img_mobile || null,
            img: file,

            extensao: file.name.split('.').pop(),
            extensaoMobile: dados?.extensaoMobile || null
          });
        }
      }
      else {
        const resultValidarDimensoes = `${videoVerificacao.videoWidth}x${videoVerificacao.videoHeight}`;

        if (resultValidarDimensoes.length > 0) {
          dispatch({
            type: CONTROLEMENSAGEM_AVISO,
            titulo: 'Aviso',
            message: `O arquivo deve ter a resolução de ${dimensaoWidthImg} x ${dimensaoHeightImg} pixels. O arquivo possuí ${resultValidarDimensoes}`,
            tipoComponente: 'alert'
          });
          return;
        }
      }
    }
  }

  const handleChangeRemove = (id) => {
    setFileSrc(null);
    if (mobile) {
      onChange({
        id: id,
        name: dados?.name,
        alternative_text: dados?.alternative_text,
        caption: dados?.caption,
        url: dados?.url,
        url_mobile: null,
        img: dados?.img,
        img_mobile: null,
        extensao: null,
        extensaoMobile: dados?.extensaoMobile,
      });
    }
    else {
      onChange({
        id: id,
        name: dados?.name,
        alternative_text: dados?.alternative_text,
        caption: dados?.caption,
        url: null,
        url_mobile: dados?.url_mobile,
        img: null,
        img_mobile: dados?.img_mobile,
        extensao: dados?.extensao,
        extensaoMobile: null,
      });
    }
  }

  return (
    <UploadImagemContainer htmlFor={`file-upload-${idComponent}`} style={style}>
      {imgData ? (
        <Stack direction="column" justifyContent={"center"} alignItems="center" style={{ paddingTop: 10 }}>
          {
            (arquivo?.type?.includes('video/mp4') || fileSrc?.includes('.mp4')) &&
            <video width={'100%'} muted autoPlay loop playsInline style={{ height: heightImg, width: widthImg, borderRadius: borderRadiusImg, paddingTop: paddingTopImg }}>
              <source src={fileSrc} type="video/mp4" />
            </video>
          }
          {
            (!arquivo?.type?.includes('video/mp4') && !fileSrc?.includes('.mp4')) &&
            <img
              alt="not fount"
              width={"100%"}
              style={{ height: heightImg, width: widthImg, borderRadius: borderRadiusImg, paddingTop: paddingTopImg }}
              src={fileSrc}
            />
          }
          <Stack direction="row" alignItems="center" sx={{ mt: 2, marginBottom: 1 }}>
            <Typography sx={{ mr: 1 }}>{imgData?.name ? truncate(imgData?.name, 20) : ''}</Typography>
            <IconButton size="large" onClick={() => handleChangeRemove(id)}>
              <DeleteTwoTone />
            </IconButton>
            <IconButton size="large" onClick={() => window.open(isEditing && ehFile ? fileSrc : URL.createObjectURL(fileSrc), '_blank')}>
              <SearchIcon />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <>
          <PhotoLibraryTwoTone sx={{ fontSize: "5rem", color: "gray" }} />
          <Typography variant="body2" textAlign={"center"}>
            Subir imagem {descricao !== "" ? `(${descricao})` : ""}
          </Typography>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg, image/webp, video/mp4"
            id={`file-upload-${idComponent}`}
            name="myImage"
            style={{ display: "none" }}
            onChange={handleChange}
            onClick={(e) => (e.target.value = null)}
          />
        </>
      )}
    </UploadImagemContainer>
  );
};

export default UploadImagemDesign;
