import { Alert, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { StyledCard } from './styles';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const DashboardCard = ({
    category,
    title,
    icon,
    bgColor,
    darkBgColor,
    idIndicador,
    value,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDarkMode = theme?.palette?.mode !== 'light';

    const [loading, setLoading] = useState(false);
    const [cardValue, setCardValue] = useState(0);
    const [error, setError] = useState(false);

    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const indicadores = useSelector((store) => store.indicadores);

    const getData = () => {
        const qtdIndicador = indicadores?.PedidosMinhaLoja?.find((i) => i.IdStatusPedido === idIndicador)?.Quantidade;
    
        if (qtdIndicador) {
          setCardValue(qtdIndicador);
        } else {
          setCardValue(0);
        }
    };

    useEffect(() => {
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderList?.period, indicadores]);

    return <StyledCard
      theme={theme}
      isDarkMode={isDarkMode}
      darkBgColor={darkBgColor}
      bgColor={bgColor}
      onClick={() => {
        if (title === "Carrinhos abandonados") {
          navigate(`/pedidos/carrinos-abandonados`, { replace: true });
        } else {
            navigate(`/pedidos`, { replace: true, state: { statusId: category } });
        }
      }}
    >
        <Stack
            className="card-header"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography fontWeight="600" variant="body1" sx={{ color: "white", fontSize: 20 }}>
                {title}
            </Typography>
            <span style={{ color: isDarkMode ? bgColor : darkBgColor, marginLeft: 5 }} >
                {icon}
            </span>
        </Stack>
        <Stack className="card-body">
            <Typography sx={{ mb: 3, textAlign: 'center' }} variant="h4">
                {loading ? <CircularProgress size={25} /> : cardValue !== 0 ? cardValue : value || 0 }
                {error && <Alert severity="error">Erro ao buscar dados.</Alert>}
            </Typography>
            {orderList && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="card-footer"
                ></Stack>
            )}
        </Stack>
    </StyledCard>
}

export default DashboardCard;
