import { Fragment, useEffect, useState } from "react";
import { Paper, useMediaQuery, Box, Stack, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, CircularProgress, InputAdornment, Button, TextField, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Container, Descricao, Title } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ImagemSemResultado, dynamicSort, formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import Card from "../../../components/Card";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import { POST_DATA, PUT_DATA } from "../../../services/API";
import { getStyleDestaqueSemaforo } from "../../../services/utils/statusPedido";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";
import * as moment from "moment";

const columns = [
    { id: "sku", label: "Sku", minWidth: 170, maxWidth: 270 },
    { id: "produto", label: "Produto", minWidth: 170, maxWidth: 270 },
    { id: "tamanho", label: "Tamanho", minWidth: 170, maxWidth: 270 },
    { id: "cor", label: "Cor", minWidth: 170, maxWidth: 270 },
    { id: "estampa", label: "Estampa", minWidth: 170, maxWidth: 270 },
    { id: "dataAlteracao", label: "Data Alteração", minWidth: 120, maxWidth: 170 },
    { id: "quantidade", label: "Quantidade", minWidth: 120, maxWidth: 170 }
];

const RelatoriosEstoque = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

    const [tipoFiltro, setTipoFiltro] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [movimentosEstoque, setMovimentosEstoque] = useState([]);

    const [totalProdutos, setTotalProdutos] = useState(0);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [gerarEstoqueAtual, setGerarEstoqueAtual] = useState(true);

    useEffect(() => {
        (async () => {
            await fetchEstoque();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await fetchEstoque();
        })();
    }, [orderList?.period, page]);

    useEffect(() => {
        const tempProdutos = produtos?.map(prdouto => {
            return <Row produto={prdouto} />
        });

        setRows(tempProdutos || []);
    }, [produtos]);

    function agruparMovimentosEtiquetaComplemento(array) {
        // Objeto para armazenar os grupos
        const grupos = {};

        // Iterar sobre o array
        array.forEach(obj => {
            // Obter o último ID
            const ultimoId = obj.IdEtiquetaComplemento;

            // Se o grupo correspondente ao último ID ainda não existir, criá-lo
            if (!grupos[ultimoId]) {
                grupos[ultimoId] = [];
            }

            // Adicionar o objeto ao grupo correspondente
            grupos[ultimoId].push(obj);
        });

        return grupos;
    }

    const GetUltimosMovimentos = (gruposMovimentos) => {
        const movimentos = [];
        for (const ultimoId in gruposMovimentos) {
            if (gruposMovimentos.hasOwnProperty(ultimoId)) {
                const result = gruposMovimentos[ultimoId].sort((a, b) => b.IdMovimentacaoEstoque - a.IdMovimentacaoEstoque);
                movimentos.push(result[0]);
            }
        }
        return movimentos;
    }

    const fetchEstoque = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        if (orderList?.period === undefined || orderList?.period === null) return;

        setLoading(true);

        const dataInicio = moment().add(-30, 'days').format('YYYY-MM-DD');
        const dataFim = moment().add(1, 'days').format('YYYY-MM-DD');

        const movimentos = await POST_DATA(`MovimentacaoEstoque/GetMovimentacaoEstoqueByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}&dataInicio=${dataInicio}&dataFim=${dataFim}&iTipoMovimento=1`);
        for (const movimento of movimentos) {
            movimento._Imagem._Etiquetas = movimento._Imagem._Etiquetas.filter(a => a.IdEtiqueta === movimento.IdEtiqueta).map(et => {
                et._EtiquetaComplemento = et._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === movimento.IdEtiquetaComplemento).map(etc => {
                    return [etc];
                })[0];
                return et;
            });
        }

        const gruposMovimentos = agruparMovimentosEtiquetaComplemento(movimentos);
        const ultimosMovimentos = GetUltimosMovimentos(gruposMovimentos);

        setMovimentosEstoque(ultimosMovimentos);

        let filtroApi = {
            idUsuario: userLoggedIn?.IdUsuario,
            idUsuarioVendedor: userLoggedIn?.IdUsuario,
            idUsuarioComprador: 0,
            iPagina: page,
            Sexo: '0,1,2',
            FlagKids: false,
            Categoria: null,
            Peca: null,
            Tamanho: "",
            Marca: "",
            HashTags: null,
            ValorMinimo: 0,
            ValorMaximo: 0,
            FlgUsado: false,
            FlgFavoritos: false,
            order_by: '_Etiquetas.sDescricao asc',
            FlgAtivo: true,

            Log: {
            }
        }

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        let endPoint = `Imagem/GetListImagemFilterPaginationCRM`;

        if (filtroStr.trim().length > 0 && tipoFiltro == 2)
            filtroApi = { ...filtroApi, Sku: filtroStr.trim() }
        else if (filtroStr.trim().length > 0 && tipoFiltro == 1)
            filtroApi = { ...filtroApi, sDescricaoEtiqueta: filtroStr.trim() }

        const result = await PUT_DATA(endPoint, filtroApi);
        let quantidade = 0;
        result._Imagens.map(produto => {
            produto._Etiquetas?.map(et => {
                return et._EtiquetaComplemento.map(etc => {
                    quantidade += etc.iQuantidade || 0;
                });
            });
        });

        setTotalPages(result.iTotalPagina);
        setProdutos(result._Imagens);
        setLoading(false);

        setTotalProdutos(() => quantidade);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchExcelEtiquetas = async () => {
        if (produtos === null || produtos.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Sku; Prdouto; Tamanho; Cor; Estampa; Data Alteração; Identificador`;
        let dataAlteracao = '-';
        let sku = '';

        if (gerarEstoqueAtual) {
            produtos?.forEach(produto => {
                produto._Etiquetas.map(et => {
                    dataAlteracao = produto.DtAlteracao === produto.DtInclusao ? formatDateSimples(produto.DtInclusao) : formatDateSimples(produto.DtAlteracao);
                    return et._EtiquetaComplemento.map(etc => {
                        sku = etc.Sku !== null ? etc.Sku : '-';

                        for (var i = 1; i <= etc.iQuantidade; i++) {
                            dados += `\n${sku}; ${et.sDescricao}; ${etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}; ${etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'}; ${etc?.Estampa?.Descricao ? etc?.Estampa?.Descricao : '-'}; ${dataAlteracao}; 100${i}/${etc.iQuantidade}`;
                        }

                    })
                })
            });
        }
        else {
            for (const movimento of movimentosEstoque) {
                movimento._Imagem._Etiquetas.map(et => {
                    dataAlteracao = formatDateSimples(movimento.DataMovimento);
                    return et._EtiquetaComplemento.map(etc => {
                        sku = etc.Sku !== null ? etc.Sku : '-';

                        for (var i = 1; i <= movimento.IqtdEntrada; i++) {
                            dados += `\n${sku}; ${et.sDescricao}; ${etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}; ${etc?._Cor?.Descricao ? etc?._Cor?.Descricao : '-'}; ${etc?._Estampa?.Descricao ? etc?._Estampa?.Descricao : '-'}; ${dataAlteracao}; 100${i}/${movimento.IqtdEntrada}`;
                        }
                    })
                })
            }
        }


        const url = await salvarCSV(userLoggedIn.IdUsuario, 'EstoqueEtiquetas.csv', dados);
        window.open(url, '_blank');
    }

    const fetchExcel = async () => {
        if (produtos === null || produtos.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Sku; Prdouto; Tamanho; Cor; Estampa; Data Alteração; Quantidade`;
        let dataAlteracao = '-';
        let sku = '';
        produtos?.forEach(produto => {
            produto._Etiquetas.map(et => {
                dataAlteracao = produto.DtAlteracao === produto.DtInclusao ? formatDateSimples(produto.DtInclusao) : formatDateSimples(produto.DtAlteracao);

                return et._EtiquetaComplemento.map(etc => {
                    sku = etc.Sku !== null ? etc.Sku : '-';
                    dados += `\n${sku}; ${et.sDescricao}; ${etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}; ${etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'}; ${etc?.Estampa?.Descricao ? etc?.Estampa?.Descricao : '-'}; ${dataAlteracao}; ${etc.iQuantidade}`;
                })
            })
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Estoque.csv', dados);
        window.open(url, '_blank');
    }

    const Row = ({ produto }) => {
        return <Fragment key={produto.IdImagem}>
            {
                produto._Etiquetas.map(et => {
                    return et._EtiquetaComplemento.map(etc => {
                        return <TableRow>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc.Sku === null ? '-' : etc.Sku}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {et.sDescricao}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc?.Estampa?.Descricao ? etc?.Estampa?.Descricao : '-'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {produto.DtAlteracao === produto.DtInclusao
                                    ? formatDateSimples(produto.DtInclusao)
                                    : formatDateSimples(produto.DtAlteracao)
                                }
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {
                                    etc.iQuantidade <= 4 && <div style={getStyleDestaqueSemaforo('#ff9999', 'white')}>{etc.iQuantidade}</div>
                                }
                                {
                                    (etc.iQuantidade >= 5 && etc.iQuantidade <= 7) && <div style={getStyleDestaqueSemaforo('orange', 'black')}>{etc.iQuantidade}</div>
                                }
                                {
                                    etc.iQuantidade > 7 && <div style={getStyleDestaqueSemaforo('green', 'white')}>{etc.iQuantidade}</div>
                                }
                            </TableCell>
                        </TableRow>
                    })
                })
            }
        </Fragment>
    }

    return (
        <Container>
            <Confirmation
                title="Gerar etiquetas"
                mensagem="Você deseja gerar as etiquetas do estoque atual ou da última movimentação de estoque de entrada dos produtos nos últimos 30 dias?"
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Estoque atual",
                    onClick: async () => {
                        setGerarEstoqueAtual(true);
                        await fetchExcelEtiquetas();
                    }
                }}
                btn3={{
                    title: "Movimentação do estoque",
                    onClick: async () => {
                        setGerarEstoqueAtual(false);
                        await fetchExcelEtiquetas();
                    }
                }}
            />

            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={9.5} style={{ display: 'flex', justifyContent: 'start' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Relatórios / Estoque</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Total de produtos</Title>
                            <Descricao>{totalProdutos}
                            </Descricao>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'nowrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <FormControl sx={{ marginTop: -2 }}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={1}
                                value={tipoFiltro}
                                name="radio-buttons-group"
                                onChange={(e) => { setTipoFiltro(e.target.value) }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Nome" />
                                <FormControlLabel value={2} control={<Radio />} label="Sku" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            label="Pesquisar"
                            placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchEstoque('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ height: '100%', marginLeft: 10, maxHeight: 43 }}
                            onClick={async () => {
                                if (page === 1)
                                    await fetchEstoque();
                                else
                                    setPage(1);
                            }}
                        >
                            Pesquisar
                        </Button>
                    </Box>
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando vendas</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                            <Pagination
                                size="large"
                                color="primary"
                                showFirstButton
                                showLastButton
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Box>
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10, marginRight: 10 }}
                    onClick={() => getConfirmation()}
                >
                    <FileDownloadIcon />
                    Excel Etiquetas
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default RelatoriosEstoque;