import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)`
    padding-bottom: 150px;
`;

export const AlertError = styled(Typography)`
    padding-top: 10px;
    color: #e15126;
    font-weight: bold;
`;