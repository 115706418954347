import {
    CARRINHO_SET_CLEAR,
    CARRINHO_SET_DADOS
} from './types';

const carrinhoVestiRecuder = (state = [], action) => {
    switch (action.type) {
        case CARRINHO_SET_DADOS:
            return [...action.data];
        case CARRINHO_SET_CLEAR:
            return [];
        default:
            return state;
    }
};

export default carrinhoVestiRecuder;
