import { useEffect, useState } from "react";
import { Container } from "./styles"
import { POST_DATA } from "../../services/API";
import { useSelector } from "react-redux";
import { Box, Button, Card, Divider, InputAdornment, Paper, TextField, Typography } from "@mui/material";

import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoIcon from '@mui/icons-material/Info';
import * as moment from 'moment';
import { getCorByStatusIncludesDescricao } from "../../services/utils/statusPedido";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import ImgNotificacoes from '../../assets/Ecommerce-notification.png';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "dayjs/locale/pt";
import { pt } from 'date-fns/locale';
import Loading from "../../layout/Loader";
import { truncate } from "../../services/utils/functions";


export const Notificacoes = () => {
    const usuarioLogado = useSelector((state) => state.usuarioLogado);
    const [notificacoes, setNotificacoes] = useState([]);    
    const [loading, setLoading] = useState(false);
    const [pesquisar, setPesquisar] = useState(false);
    const [dataPesquisa, setDataPesquisa] = useState(null);
    const [search, setSearch] = useState('pedido');

    const fetchNotificacoes = async () => {
        try{
            if(usuarioLogado === null) return;
            
            setLoading(true);        

            const dataInicio = dataPesquisa ? moment(dataPesquisa).format('YYYY-MM-DD 00:00:00') : null;
            const dataFim = dataPesquisa ? moment(dataPesquisa).format('YYYY-MM-DD 23:59:59') : null;
    
            const url = `Notificacao/GetAllNotificacaoIdUsuarioLoja?idUsuarioLoja=${usuarioLogado.IdUsuario}&dataInicio=${dataInicio}&dataFim=${dataFim}&palavraChave=${search}&maxInstances=20`;        
            const notificacoes = await POST_DATA(url);
            setNotificacoes(notificacoes);
    
            setLoading(false);
        }
        catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await fetchNotificacoes();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await fetchNotificacoes();
        })();
    }, [dataPesquisa, pesquisar]);

    return <Container>
        <Box style={{ width: '100%' }}>
            <Typography variant="h6">Notificações</Typography>
        </Box>
        <Paper style={{ overflowY: 'auto', overflowX: 'none' }}>
            {
                loading &&
                <Box style={{ width: '100%', heigth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Loading marginTop={100} texto="Buscando notificações" />
                </Box>
            }
            {
                !loading &&
                <>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField
                            label="Pesquisar"
                            placeholder='Busque por palavras chaves'
                            size="small"
                            sx={{ width: "350px", marginTop: 1, marginBottom: 2 }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="start"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        setPesquisar(Math.random());
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>,
                                endAdornment: <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setPesquisar(Math.random());
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            }}
                        />

                        <Box style={{ marginTop: 1, marginRight: 10, display: 'flex', alignItems: 'center' }}>
                            <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id='dataPesquisa'
                                    format="dd/MM/yyyy"
                                    value={dataPesquisa}
                                    onChange={(newValue) => setDataPesquisa(newValue)}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: 180 }}
                                />
                                {dataPesquisa !== null && <ClearIcon style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setDataPesquisa(null)} />}
                            </LocalizationProvider>
                        </Box>
                    </Box>

                    {
                        notificacoes.length === 0 &&
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={ImgNotificacoes} alt="Notificações" style={{ width: '400px' }} />
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Sem notificações</Typography>
                        </Box>
                    }
                    <Timeline position="right" style={{ width: 700, marginTop: 10 }}>
                        {
                            notificacoes.length > 0 && notificacoes.map((notificacao, index) => <TimelineItem style={{ width: 300 }}>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot style={{ background: getCorByStatusIncludesDescricao(notificacao._NotificacaoTipo.sDescricao) }}>
                                        {
                                            notificacao._NotificacaoTipo.sDescricao.includes('Pedido') && <ListAltIcon style={{ fontSize: 25, color: 'white' }} />
                                        }
                                        {
                                            !notificacao._NotificacaoTipo.sDescricao.includes('Pedido') && <InfoIcon style={{ fontSize: 25, color: 'white' }} />
                                        }
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Box style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 14, color: getCorByStatusIncludesDescricao(notificacao._NotificacaoTipo.sDescricao) }}>
                                            {notificacao.IdPedido ? `[${notificacao.IdPedido}] ` : ''}
                                            {notificacao._NotificacaoTipo.sDescricao} <span style={{ fontSize: 11, fontWeight: 450 }}>({moment(notificacao.dtNotificacao).format('DD/MM/YYYY HH:mm:ss')})</span>  <DoneAllIcon style={{ marginLeft: 5, fontSize: 18, color: notificacao.FlagVisualizacao ? 'green' : 'black' }} />
                                        </Typography>
                                        <Typography style={{ fontSize: 12, fontWeight: 450, color: '#ababab' }}><span style={{ fontWeight: 'bold', fontSize: 13 }}>{notificacao._UsuarioAcao?.Nome ? `[${truncate(notificacao._UsuarioAcao?.Nome, 18)}] ` : ''}</span>{notificacao.sDescricao}</Typography>

                                        <Divider style={{ width: '100%', minWidth: 340, marginTop: 10 }} />
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            )
                        }
                    </Timeline>
                </>
            }
        </Paper>
    </Container>
}