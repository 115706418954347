import { Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { POST_DATA } from '../../../services/API';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from '../../../services/utils/functions';
import { ManageSearch } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ClearIcon from '@mui/icons-material/Clear';
import PeriodPicker from '../../../components/PeriodPicker';
import StatusPedido from '../../../components/StatusPedido';
import { CONTROLEMENSAGEM_AVISO } from '../../../store/ControleMensagemReducer/types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ListagemClientesVendas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [opcaoSelecionada, setOpcaoSelecionada] = useState(0);

    const [pedidos, setPedidos] = useState([]);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            if (userLoggedIn !== null) {
                await fecthPedidos(opcaoSelecionada);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleBuscarPedidos = async (idStatus = 0) => {
        await fecthPedidos(idStatus);
        setOpcaoSelecionada(idStatus);
    }

    const fecthPedidos = async (idStatus) => {
        setLoading(true);

        const endpoint = `Pedido/PedidosByIdStatusData?idStatus=${idStatus}&dtInicio=${ordenacaoDashboard.period.startDate}&dtFim=${ordenacaoDashboard.period.endDate}&idUsuarioVendedor=${userLoggedIn.IdUsuario}`;
        const result = await POST_DATA(endpoint);
        const pedidos = [...new Map(result.objetoRetorno.map(item => [item['IdUsuarioComprador'], item])).values()];

        setPedidos(pedidos);
        setRows(pedidos);
        setLoading(false);
    }

    const columns = [
        { id: "imagem", label: "Foto", minWidth: 100 },
        {
            id: "nome",
            label: "Nome",
            maxWidth: 120,
            align: "left",
        },
        { id: "email", label: "E-mail", minWidth: 90, maxWidth: 100 },
        {
            id: "celular",
            label: "Celular",
            maxWidth: 150,
            align: "left",
        },
        {
            id: "devolucoes",
            label: "Devoluções",
            maxWidth: 120,
            align: "left",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "concluidos",
            label: "Pedidos Concluídos",
            maxWidth: 120,
            align: "left",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "cancelados",
            label: "Pedidos Cancelados",
            minWidth: 100,
            align: "left",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "valorTotal",
            label: "Valor Total",
            minWidth: 100,
            align: "left",
            format: (value) => value.toFixed(2),
        },
        {
            id: "dataVenda",
            label: "Data da Venda",
            minWidth: 140,
            align: "left",
            format: (value) => value.toFixed(2),
        },
        {
            id: "dataRegistro",
            label: "Data de Registro",
            minWidth: 140,
            align: "left",
            format: (value) => value.toFixed(2),
        },
        {
            id: "acoes",
            label: "",
            maxWidth: 100,
            align: "left",
        },
    ];

    const fetchExcel = async() => {
        if(pedidos === null || pedidos.length === 0){
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Nome; E-mail; Celular; Devoluções; Pedidos Concluídos; pedidos Cancelados; Valor total; Data de Cadastro`;
        pedidos?.forEach(pedido => {
            dados += `\n${pedido._UsuarioComprador.Nome}; ${pedido._UsuarioComprador.Email}; ${pedido._UsuarioComprador.Celular}; ${pedido._UsuarioComprador.QtdDevolucoes}; ${pedido._UsuarioComprador.QtdPedidosConcluidos}; ${pedido._UsuarioComprador.QtdPedidosCancelados}; ${pedido.ValorTotal.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}; ${formatDateSimples(pedido.DtInclusao)}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Clientes.csv', dados);
        window.open(url, '_blank');
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Clientes Vendas</Typography>
                </Box>
            </Box>
            <Stack direction="row" gap={2} sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <TextField
                    label="Pesquisar por e-mail"
                    placeholder='Pesquisar por e-mail'
                    size="small"
                    sx={{ width: querySelector ? "200px" : "250px", marginTop: 2.3 }}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    onBlur={() => setRows(pedidos.filter(a => String(a.Email).includes(search)))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <ManageSearch style={{ cursor: 'pointer' }} onClick={() => setRows(pedidos.filter(a => String(a._UsuarioComprador.Email).includes(search)))} />
                        </InputAdornment>,
                        endAdornment: search.length > 0 && (
                            <InputAdornment
                                style={{ cursor: 'pointer', color: 'black' }}
                                position="end"
                                onClick={() => {
                                    setSearch(() => '');
                                    setRows(pedidos);
                                }}
                            >
                                <ClearIcon />
                            </InputAdornment>
                        )
                    }}
                />

                <Box style={{ display: 'flex' }}>
                    <StatusPedido onClick={handleBuscarPedidos} />
                    <PeriodPicker />
                </Box>
            </Stack>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.IdPedido}
                                            >
                                                <TableCell key={`img-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {
                                                        row._UsuarioComprador?.FotoProfile &&
                                                        <img style={{ borderRadius: "8px", width: "80px", height: "100px" }} src={row._UsuarioComprador?.FotoProfile} alt='Usuário' />
                                                    }
                                                    {
                                                        !row._UsuarioComprador?.FotoProfile &&
                                                        <img style={{ borderRadius: "8px", width: "80px", width: "70px" }} src={'https://timelineleap-evhgawa4gxdcecak.z01.azurefd.net/img/avatar.png'} alt='Usuário' />
                                                    }
                                                </TableCell>
                                                <TableCell key={`nome-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {row._UsuarioComprador.Nome}
                                                </TableCell>
                                                <TableCell key={`email-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {row._UsuarioComprador.Email}
                                                </TableCell>
                                                <TableCell key={`celular-${row._UsuarioComprador.IdUsuario}`} align='left' style={{ minWidth: 140 }}>
                                                    {row._UsuarioComprador.Celular}
                                                </TableCell>
                                                <TableCell key={`devolucoes-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {row._UsuarioComprador.QtdDevolucoes}
                                                </TableCell>
                                                <TableCell key={`pedidos-concluidos-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {row._UsuarioComprador.QtdPedidosConcluidos}
                                                </TableCell>
                                                <TableCell key={`pedidos-cancelados-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {row._UsuarioComprador.QtdPedidosCancelados}
                                                </TableCell>
                                                <TableCell key={`valor-total-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    {row.ValorTotal.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
                                                </TableCell>
                                                <TableCell key={`data-venda-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    <Typography sx={{ fontSize: 15 }}> {row.DataVenda ? formatDateSimples(row.DataVenda) : formatDateSimples(row.DataCadastro)} </Typography>
                                                </TableCell>
                                                <TableCell key={`data-inclusao-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    <Typography sx={{ fontSize: 15 }}> {formatDateSimples(row.DataCadastro)} </Typography>
                                                </TableCell>
                                                <TableCell key={`cliente-${row._UsuarioComprador.IdUsuario}`} align='left'>
                                                    <Stack direction="row">
                                                        <Tooltip title="Lista de compras">
                                                            <IconButton
                                                                onClick={() => {
                                                                    navigate('/clientes-vendas-detalhes', { state: { usuarioCompradorId: row._UsuarioComprador.IdUsuario } })
                                                                }}
                                                            >
                                                                <RemoveRedEyeIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${rows.length}/${pedidos.length} resultados encontrados para pesquisa "${search}" - Clientes por página: `
                                    : "Clientes por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </>
    )
}

export default ListagemClientesVendas;
