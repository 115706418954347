import { Fragment, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA } from "../../../services/API";
import { Container } from "./styles";
import { Box, Paper, Stack, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Switch, TextField, InputAdornment, useMediaQuery, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router";
import * as moment from "moment";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClearIcon from '@mui/icons-material/Clear';
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import { formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import PeriodPicker from "../../../components/PeriodPicker";

const columns = [
    { id: "descricao", label: "Descrição", minWidth: 120, maxWidth: 200 },
    {
        id: "FlgPorcentagem",
        label: "Percentual",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "valor",
        label: "Valor",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "qtdVendasNaoRealizadas",
        label: "Vendas não realizadas",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "totalVendasNaoRealizadas",
        label: "Total vendas não realizadas",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "quantidadeVendasRealizadas",
        label: "Vendas Realizadas",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "totalVendasRealizadas",
        label: "Total vendas realizadas",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "dataValidade",
        label: "Data de Validade",
        minWidth: 170,
        align: "left",
        format: (value) => value.toFixed(2),
    },
    {
        id: "flgCompra",
        label: "Compra",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "flgFrete",
        label: "Frete",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "ativo",
        label: "Ativo",
        maxWidth: 100,
        align: "left",
    }
];


const RelatorioCuponsUso = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const [fieldFocus, setFieldFocus] = useState('');

    const querySelector = useMediaQuery('(max-width:600px)');
    const [search, setSearch] = useState('');

    const [cupons, setCupons] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [somenteAtivos, setSomenteAtivos] = useState(true);

    useEffect(() => {
        (async () => {
            await fetchCupons();
        })();
    }, [userLoggedIn, somenteAtivos, orderList?.period, orderList?.periodToCompare]);

    useEffect(() => {
        const tmpCupons = cupons?.map(cupom => {
            return <Row cupom={cupom} />
        });

        setRows(tmpCupons || []);
    }, [cupons]);

    const fetchCupons = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoading(true);

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        const result = await POST_DATA(`Cupom/GetCupomRelatorioUso`, {
            "IdUsuarioLoja": userLoggedIn.IdUsuario, "Descricao": filtroStr, "dataInicio": orderList.period.startDate, "dataFim": orderList.period.endDate, "FlgAtivo": somenteAtivos
        });

        setCupons(result);
        setLoading(false);
    }

    const Row = ({ cupom }) => {
        return <Fragment key={cupom.IdCupom}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 160 }}>
                    <Typography>{cupom.Descricao}</Typography>
                </TableCell>
                <TableCell align="left">
                    <Switch
                        checked={cupom.FlgPorcentagem}
                    ></Switch>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <Typography>{cupom.FlgPorcentagem ? `${cupom.Valor}%` :
                        cupom.Valor.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}</Typography>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <Typography>{cupom.qtdVendasNaoRealizadas}</Typography>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <Typography>{cupom.totalVendasNaoRealizadas.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })}</Typography>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <Typography>{cupom.qtdVendasRealizadas}</Typography>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <Typography>{cupom.totalVendasRealizadas.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })}</Typography>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 195 }}>
                    <Typography>{moment(cupom.dataValidade).format('DD/MM/YYYY')}</Typography>
                </TableCell>
                <TableCell align="left">
                    <Switch
                        checked={cupom.FlgCompra}
                    ></Switch>
                </TableCell>
                <TableCell align="left">
                    <Switch
                        checked={cupom.FlgFrete}
                    ></Switch>
                </TableCell>
                <TableCell align="left">
                    <Switch
                        checked={cupom.FlgAtivo}
                    ></Switch>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    const fetchExcel = async () => {
        if (cupons === null || cupons.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Código; Percentual?; Valor; Vendas não realizadas; Total vendas não realizadas; Vendas realizadas; Total vendas realizadas; Data de validade; Compra?; Frete?; Ativo`;

        cupons?.forEach(cupom => {

            dados += `\n${cupom.Descricao}; ${cupom.FlgPorcentagem ? 'Sim' : 'Não'}; ${cupom.FlgPorcentagem ? `${cupom.Valor}%` : cupom.Valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${cupom.qtdVendasNaoRealizadas}; ${cupom.totalVendasNaoRealizadas.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${cupom.qtdVendasRealizadas}; ${cupom.totalVendasRealizadas.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${formatDateSimples(cupom.dataValidade)}; ${cupom.FlgCompra ? 'Sim' : 'Não'}; ${cupom.FlgFrete ? 'Sim' : 'Não'}; ${cupom.FlgAtivo ? 'Sim' : 'Não'}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'CuponsUtilizados.csv', dados);
        window.open(url, '_blank');
    }

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Relatórios / Cupons</Typography>
                </Box>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <TextField
                            label="Pesquisar"
                            placeholder='Nome Produto/SKU'
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchCupons('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => fetchCupons()}
                            style={{ marginLeft: 10, height: '100%', height: 43 }}
                        >
                            Pesquisar
                        </Button>
                    </Box>

                    <Box style={{ display: 'flex' }}>
                        <FormControlLabel labelPlacement="top" control={
                            <Switch
                                color="primary"
                                checked={somenteAtivos}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSomenteAtivos(!somenteAtivos);
                                }}
                            />} 
                            label="Ativos"
                        />
                        <PeriodPicker />
                    </Box>
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando cupons</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography fontWeight={800} variant="h6">
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default RelatorioCuponsUso;