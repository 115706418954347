import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Container } from "./styles";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';

import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, Modal, Typography, Grid, FormControlLabel, Switch } from "@mui/material";
import BootstrapDialogTitle from "../../components/Modal/BootstrapDialogTitle";
import { useLocation, useNavigate } from "react-router";
import { GET_DATA, POST_DATA, PUT_DATA, UploadFileAzure } from "../../services/API";
import InputText from "../../components/InputText";
import UploadImagemDesign from "../../components/UploadImagemDesign";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "dayjs/locale/pt";
import { pt } from 'date-fns/locale';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../store/ControleMensagemReducer/types";
import * as moment from "moment";
import './styles.css';
import Editor from "../../components/QuillEditor";

const InitialValue = {
    IdUsuarioCalendarioEventos: 0,
    IdUsuario: 0,
    Nome: '',
    Observacao: '',
    UrlImagem: '',
    Data: null,
    Ano: 0,
    Mes: 0,
    DiaInicio: 0,
    DiaFim: 0,
    FlgAtivo: true,
    FlgEventoNacional: false,
    FlgEventoRegional: false,
    Estado: '',
    DtInclusao: '',
    Imagem: {
        id: 'iamgemEvento',
        ur: '',
        img: null
    }
}

const CalendarioVenda = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const superUser = useSelector((state) => state.superUser);

    const [datasEspeciais, setDatasEspeciais] = useState([]);
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [openModalCadastro, setOpenModalCadastro] = useState(false);

    const [eventoCalendario, setEventoCalendario] = useState(InitialValue);
    const [salvandoEvento, setSalvandoEvento] = useState(false);

    const [mesSelecionado, setMesSelecionado] = useState(1);
    const meses = [
        {
            id: 1,
            mes: 'Janeiro',
        },
        {
            id: 2,
            mes: 'Fevereiro',
        },
        {
            id: 3,
            mes: 'Março',
        },
        {
            id: 4,
            mes: 'Abril',
        },
        {
            id: 5,
            mes: 'Maio',
        },
        {
            id: 6,
            mes: 'Junho',
        },
        {
            id: 7,
            mes: 'Julho',
        },
        {
            id: 8,
            mes: 'Agosto',
        },
        {
            id: 9,
            mes: 'Setembro',
        },
        {
            id: 10,
            mes: 'Outubro',
        },
        {
            id: 11,
            mes: 'Novembro',
        },
        {
            id: 12,
            mes: 'Dezembro',
        },
    ]

    const [evento, setEvento] = useState(null);

    const container = {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        (async () => {
            await fetchEventos();
        })();
    }, [location]);

    const fetchEventos = async () => {
        const result = await GET_DATA('UsuarioCalendarioEventos/GetAllUsuarioCalendarioEventos');
        if (result.status === 200) {
            const eventos = result.obj.filter(a => a.Ano == moment().format('YYYY'));
            setDatasEspeciais(eventos);
        }
    }

    const fetchCadastro = async () => {
        try {
            let msgValidacao = '';

            if (eventoCalendario.Nome.length < 3)
                msgValidacao += '\nFavor informar um nome válido';
            if (eventoCalendario.Observacao.length < 3)
                msgValidacao += '\nFavor informar uma observação válida';
            if (eventoCalendario.Data === null)
                msgValidacao += '\nFavor informar uma data de evento válida';
            if (eventoCalendario.DiaInicio === 0)
                msgValidacao += '\nFavor informar um dia início do evento válido';
            if (eventoCalendario.DiaFim === 0)
                msgValidacao += '\nFavor informar um dia de fim do evento válido';
            if (!eventoCalendario.FlgEventoNacional && !eventoCalendario.FlgEventoRegional)
                msgValidacao += '\nFavor informar se é um evento nacional ou regional';

            if (msgValidacao.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: msgValidacao,
                });
                return;
            }

            setSalvandoEvento(true);

            const obj = { ...eventoCalendario };
            obj.IdUsuario = superUser.IdUsuario; //
            obj.Mes = moment(obj.Data).format('MM');
            obj.Ano = moment(obj.Data).format('YYYY');

            if (obj.Imagem.img !== null && obj.Imagem.img !== undefined) {
                const pasta = `${userLoggedIn.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}`;
                const nommeArquivo = `img${moment().format('YYYYMMDDHHmmss')}.${obj.Imagem.extensao}`;
                obj.Imagem.url = await UploadFileAzure(obj.Imagem.img, `${pasta}/images`, nommeArquivo, `image/${obj.Imagem.extensao}`);
                obj.UrlImagem = obj.Imagem.url;
            }

            let result = null;
            if (obj.IdUsuarioCalendarioEventos === 0) {
                obj.DtInclusao = moment().format('YYYY-MM-DD HH:mm:ss');
                result = await PUT_DATA(`UsuarioCalendarioEventos/InsertUsuarioCalendarioEventos`, obj);
            }
            else {
                obj.DtAlteracao = moment().format('YYYY-MM-DD HH:mm:ss');
                result = await POST_DATA(`UsuarioCalendarioEventos/UpdateUsuarioCalendarioEventos`, obj);
            }

            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Sucesso ao salvar os dados',
                });
            }

            setOpenModalCadastro(false);
            setSalvandoEvento(false);
            await fetchEventos();
        }
        catch (err) {
            setSalvandoEvento(false);
        }
    }

    const RowsEventosMes = ({ datasEspeciais, mesSelecionado }) => {
        let rows = [];
        let encontrouEvento = false;
        rows.push(
            <TimelineItem sx={{ minWidth: 500 }}>
                <TimelineSeparator style={{ height: 'auto', display: 'flex', alignItems: 'flex-start', alignContent: 'flex-start', borderBottom: '1px solid #e3e3e3', paddingBottom: 5, marginBottom: 10 }}>
                    {/* <TimelineOppositeContent
                        sx={{ m: 'auto 0', marginLeft: -2 }}
                        align="right"
                        variant="body1"
                        color="text.secondary"
                    >
                        <Typography style={{ width: 170, textAlign: 'center', fontSize: 20, borderRadius: 30, background: 'black', padding: 5, paddingLeft: 12, paddingRight: 12, color: 'white' }}>{GetMonth(i)}</Typography>
                    </TimelineOppositeContent> */}

                    {
                        datasEspeciais.filter(a => a.Mes === mesSelecionado).map((item, index) => {
                            encontrouEvento = true;
                            return <Fragment key={`icones-${index}`}>

                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: index == 0 ? 30 : 5 }}>
                                    <TimelineDot color="primary">
                                        <TodayIcon />
                                    </TimelineDot>
                                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', minWidth: 200 }}>
                                        <Typography variant="h6" component="span" style={{ fontWeight: 'bold', marginLeft: 10, minWidth: 400 }}>
                                            {item.DiaInicio.toString().padStart(2, '0')} {item.DiaFim != null ? ` a ${item.DiaFim.toString().padStart(2, '0')}` : null}/ {item.Mes.toString().padStart(2, '0')} - {item.Nome}
                                        </Typography>
                                        <Typography component="span" style={{ fontSize: 12, marginLeft: 10 }}>
                                            Evento {item.FlgEventoNacional ? "nacional" : "regional"}
                                        </Typography>
                                    </Box>
                                    <Button style={{ marginLeft: 20, fontSize: 12 }} onClick={() => {
                                        setEvento(item);
                                        setOpenModalDetalhes(true);
                                    }} variant="contained">Detalhe</Button>
                                    {
                                        superUser !== null &&
                                        <Button
                                            variant="contained"
                                            style={{ marginLeft: 10, fontSize: 12 }}
                                            onClick={() => {
                                                setEventoCalendario({ ...item, Data: new Date(item.Data), Imagem: { url: item.UrlImagem } })
                                                setOpenModalCadastro(true);
                                            }}
                                        >Editar</Button>
                                    }
                                </Box>
                            </Fragment>
                        })
                    }
                </TimelineSeparator>

            </TimelineItem>
        )

        if (!encontrouEvento) {
            rows = [];
            rows.push(
                <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                    <Typography variant="h5" component="span" style={{ fontWeight: 'bold', marginLeft: 10, minWidth: 400 }}>
                        Nenhum evento para mês selecionado
                    </Typography>
                </Box>
            )
        }

        return rows;
    }

    return (
        <Container>
            {
                openModalDetalhes &&
                <Modal
                    key="modal"
                    open={openModalDetalhes}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '0%'
                    }}
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '60%',
                                maxWidth: 750,
                                height: 'auto',
                                maxHeight: 850,
                                overflowY: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteMensagem" onClose={() => setOpenModalDetalhes(false)}>
                            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{evento.DiaInicio.toString().padStart(2, '0')} {evento.DiaFim != null ? ` a ${evento.DiaFim.toString().padStart(2, '0')}` : null}/ {evento.Mes.toString().padStart(2, '0')} - {evento.Nome}</Typography>
                            <Typography component="span" style={{ fontSize: 13, marginTop: -7 }}>
                                Evento {evento.FlgEventoNacional ? "nacional" : "regional"}
                            </Typography>
                        </BootstrapDialogTitle>

                        <Box style={{ width: '100%', paddingLeft: 15, display: 'flex' }}>
                            <Typography style={{ fontSize: 14, textAlign: 'justify', maxWidth: 405 }} dangerouslySetInnerHTML={{ __html: evento.Observacao }} />
                            {
                                evento.UrlImagem && <img src={evento.UrlImagem} style={{ width: '100%', maxWidth: 250, marginLeft: 30 }} alt="Imagem" />
                            }
                        </Box>

                        <Box style={{ width: '100%', marginTop: 25, display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted solid #e3e3e3', paddingTop: 10 }}>
                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Qual ação voce quer promover ?</Typography>

                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="contained" onClick={() => navigate('/cupons')}>Criar cupom</Button>
                                <Button disabled style={{ marginLeft: 3 }} variant="contained">E-mail marketing</Button>
                                <Button disabled style={{ marginLeft: 3 }} variant="contained">Gerar post</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            }

            {
                openModalCadastro &&
                <Modal
                    key="modal"
                    open={openModalCadastro}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '0%'
                    }}
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '100%',
                                maxWidth: 1024,
                                height: 'auto',
                                maxHeight: 850,
                                overflowY: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteTitleModalCadastro" onClose={() => setOpenModalCadastro(false)}>
                            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Cadastro de evento</Typography>
                        </BootstrapDialogTitle>

                        <Box style={{ width: '100%', paddingLeft: 15, display: 'flex' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputText
                                        type='text'
                                        label="Nome do evento"
                                        value={eventoCalendario.Nome}
                                        onChange={(e) => {
                                            setEventoCalendario({ ...eventoCalendario, Nome: e.target.value });
                                        }}
                                    />
                                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            id='dataInicio'
                                            format="dd/MM/yyyy"
                                            value={eventoCalendario.Data}
                                            onChange={(newValue) => setEventoCalendario({ ...eventoCalendario, Data: newValue })}
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ width: '100%', marginTop: 2 }}
                                        />
                                    </LocalizationProvider>

                                    <UploadImagemDesign
                                        dados={eventoCalendario.Imagem}
                                        isEditing={true}
                                        mobile={false}
                                        key={eventoCalendario.Imagem?.id}
                                        id={eventoCalendario.Imagem?.id}
                                        imgData={eventoCalendario.Imagem?.url}
                                        onChange={(value) => setEventoCalendario({ ...eventoCalendario, Imagem: value })}
                                        borderRadiusImg={0}
                                        paddingTopImg={0}
                                        widthImg='100%'
                                        heightImg={150}
                                        style={{ height: 278, marginTop: 15, paddingTop: 50 }}

                                        dimensaoWidthImg={846}
                                        dimensaoHeightImg={550}
                                        descricao={'Tamanho máximo 846 x 550'}
                                    />

                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={7.5} style={{ marginTop: 10 }}>
                                            <InputText
                                                estilo={{ width: '100px' }}
                                                type='text'
                                                label="Dia de início"
                                                value={eventoCalendario.DiaInicio}
                                                maxText={2}
                                                onChange={(e) => {
                                                    setEventoCalendario({ ...eventoCalendario, DiaInicio: Number(e.target.value.replace(/[^0-9:]/g, "").trim()) });
                                                }}
                                            />
                                            <InputText
                                                estilo={{ width: '100px', marginLeft: 10 }}
                                                type='text'
                                                label="Dia de fim"
                                                value={eventoCalendario.DiaFim}
                                                maxText={2}
                                                onChange={(e) => {
                                                    setEventoCalendario({ ...eventoCalendario, DiaFim: Number(e.target.value.replace(/[^0-9:]/g, "").trim()) });
                                                }}
                                            />
                                            <InputText
                                                estilo={{ width: '80px', marginLeft: 10 }}
                                                type='text'
                                                label="Estado"
                                                value={eventoCalendario.Estado}
                                                maxText={2}
                                                onChange={(e) => {
                                                    setEventoCalendario({ ...eventoCalendario, Estado: e.target.value });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4.5} style={{ width: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>                                            
                                            <FormControlLabel labelPlacement="left" control={
                                                <Switch
                                                    checked={eventoCalendario.FlgEventoNacional}
                                                    onChange={() => {
                                                        setEventoCalendario({ ...eventoCalendario, FlgEventoNacional: !eventoCalendario.FlgEventoNacional });
                                                    }} />}
                                                label="Evento Nacional"
                                            />
                                            <FormControlLabel labelPlacement="left" control={
                                                <Switch
                                                    checked={eventoCalendario.FlgEventoRegional}
                                                    onChange={() => {
                                                        setEventoCalendario({ ...eventoCalendario, FlgEventoRegional: !eventoCalendario.FlgEventoRegional });
                                                    }} />}
                                                label="Evento Regional"
                                            />
                                            <FormControlLabel labelPlacement="left" control={
                                                <Switch
                                                    checked={eventoCalendario.FlgAtivo}
                                                    onChange={() => {
                                                        setEventoCalendario({ ...eventoCalendario, FlgAtivo: !eventoCalendario.FlgAtivo });
                                                    }} />}
                                                label="Ativo"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box style={{ marginTop: 20 }}>
                                        <Editor
                                            className='ql-editor2'
                                            text={eventoCalendario.Observacao}
                                            handleChange={(valor) => setEventoCalendario({ ...eventoCalendario, Observacao: valor })}
                                        />
                                    </Box>

                                </Grid>

                            </Grid>
                        </Box>

                        <Box style={{ width: '100%', marginTop: 25, display: 'flex', justifyContent: 'flex-end', borderTop: '1px dotted solid #e3e3e3' }}>
                            <Button variant="contained" onClick={() => fetchCadastro()}>Salvar</Button>
                        </Box>
                    </Box>
                </Modal>
            }

            <Box style={{ width: '100%', marginBottom: 15 }}>
                <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Datas especiais de venda</Typography>
            </Box>

            <Box style={{ width: '100%', display: 'flex', overflowY: 'auto' }}>
                {
                    meses.map((mes) => {
                        return <Button className="m_title" variant="contained" key={mes.id} style={{ minWidth: 90, marginRight: 10, marginBottom: 10, textAlign: 'center', fontSize: 16, borderRadius: 30, background: mes.id != mesSelecionado ? 'black' : null, padding: 5, paddingLeft: 12, paddingRight: 12, color: 'white' }} onClick={() => setMesSelecionado(mes.id)}>{mes.mes}</Button>
                    })
                }
            </Box>

            <Timeline sx={{ alignItems: 'start', background: 'white', borderRadius: 2, paddingTop: 2 }}>
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        superUser !== null &&
                        <Button variant="contained" onClick={() => {
                            setEventoCalendario(InitialValue);
                            setOpenModalCadastro(true);
                        }}>Adicionar</Button>
                    }
                </Box>
                {
                    meses.map((mes, index) => {
                        if (mes.id === mesSelecionado) {
                            return <RowsEventosMes key={index} mesSelecionado={mesSelecionado} datasEspeciais={datasEspeciais} />
                        }
                    })
                }
            </Timeline>

        </Container>
    )

}

export default CalendarioVenda;