import { INDICADORES_SET } from './types';

const indicadoresRecuder = (state = [], action) => {
    switch (action.type) {
      case INDICADORES_SET:
        return action.data;
      default:
        return state;
    }
};
  
export default indicadoresRecuder;
