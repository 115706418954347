import { Navigate } from 'react-router';
import { getStorage, removeStorage } from '../hooks/useStorage';
import * as moment from 'moment';
import DashboardLeapLovers from '../pages/LeapLovers/Dashboard';
import MainLayout from '../layout/MainLayout';
import CadastroAfiliado from '../pages/Afiliados/cadastro';
import Vendas from '../pages/LeapLovers/VendasOld';
import Help from '../pages/LeapLovers/Help';
import ListagemPedidosAfiliado from '../pages/LeapLovers/Pedidos';


const Private = ({ children }) => {  
    const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';
    const afiliado = getStorage('usrAfiliado');
    if (!afiliado || !ehLeapLovers) return <Navigate to={'/login'} />;
  
    const objUsuarioLogado = JSON.parse(afiliado);
    const dataLogin = moment(objUsuarioLogado.Time);
    const agora = moment().add(0, 'minutos');
  
    if(dataLogin < agora){
      removeStorage('usrin');
      alert('Sessão expirada!\n Favor realizar o login novamente');
      window.location.href = "/login";
      return null;
    }
  
    return children;
  };

const LeapLoversRoute = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard-afiliado',
            element: <Private>
                <DashboardLeapLovers />
            </Private>
        },
        {
            path: '/meu-perfil',
            element: <Private>
                <CadastroAfiliado />
            </Private>
        },
        {
            path: '/minhas-vendas',
            element: <Private>
                <ListagemPedidosAfiliado />
            </Private>
        },
        {
            path: '/help',
            element: <Private>
                <Help />
            </Private>
        }
    ],
};

export default LeapLoversRoute;
