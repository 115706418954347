import {
    LEAPLOVERS_SET_AFILIADO,
    LEAPLOVERS_SET_CLEAR
} from './types';

const leapLoversRecuder = (state = null, action) => {
    switch (action.type) {
        case LEAPLOVERS_SET_AFILIADO:
            return { ...state, afiliado: action.data };        
        case LEAPLOVERS_SET_CLEAR:
            return null;
        default:
            return state;
    }
};

export default leapLoversRecuder;
