import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CloseBtn, Container, ContainerModal } from './styles';

import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import PopoverPopup from '../PopoverPopup';
import { POPOVER_CLOSE, POPOVER_OPEN } from '../../store/PopoverReducer/types';
import { isMobile } from '../../services/DeviceServices';

const GoWhatsApp = ({ whatsNumber, fixoLocal = false, passouMousePorCima = false }) => {
    const dispatch = useDispatch();
    const storeOwner = useSelector((store) => store.storeOwner);
    const themeStore = useSelector((store) => store.themeStore);
    const [anchorElCurrent, setAnchorElCurrent] = useState(null);
    const ref = useRef();

    useEffect(() => {
        if (ref.current !== undefined) {
            setAnchorElCurrent(ref.current);
        }
    }, [ref]);

    const handleClose = () => {
        dispatch({
            type: POPOVER_CLOSE,
            idPopover: 'whatsApp',
            idItem: 'whatsApp'
        });
    }

    return (
        <Container aria-label="WhatsApp" title="Whats App" href="#" role="button" style={{ position: fixoLocal ? 'static' : 'fixed', right: fixoLocal ? null : '1rem', bottom: fixoLocal ? null : '10rem' }}>
            <Button
                variant='contained'
                style={{ background: passouMousePorCima ? '#f79b31' : '#00d757', color: 'white', fontWeight: 'bold', marginRight: 15, fontSize: isMobile() ? 10 : 15 }}
                onClick={(e) => {
                    setAnchorElCurrent(e.currentTarget);
                    dispatch({
                        type: POPOVER_OPEN,
                        idPopover: 'whatsApp',
                        idItem: 'whatsApp',
                        open: true
                    });
                }}
            >
                <WhatsAppIcon
                    style={{ fill: 'white', fontSize: isMobile() ? 22 : 30 }}
                />
                <Typography style={{ marginLeft: 10, color: 'white', fontWeight: 'bold' }}>Ajuda</Typography>
            </Button>

            <PopoverPopup
                idPopover='whatsApp'
                anchorEl={anchorElCurrent}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{ width: 'auto', heigth: 'auto', background: themeStore != null && themeStore.tipoThema === 5 ? '#5cb85c' : null }}
            >
                <ContainerModal>
                    <Box>
                        <Box style={{ background: '#ef3e80', height: '100px', paddingTop: '15px', paddingLeft: '15px' }}>
                            <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 20 }}>Bem-vindo!</Typography>
                            <CloseBtn onClick={handleClose} />
                        </Box>
                        <Box style={{ width: '90%', background: 'white', marginTop: '-35px', borderRadius: '10px', padding: '15px', marginLeft: 15, marginRight: 10, boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 20 }}>Suporte WhatsApp</Typography>
                            <Box>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>LeapStyle</Typography>
                                <Typography style={{ color: 'gray' }}>{whatsNumber.replace(/(\d{2})(\d{9})/g, '$1 $2')}</Typography>

                                <Button
                                    style={{ marginTop: 25, background: '#5cb85c', borderRadius: '25px', height: '41px', color: 'white', fontSize: '14px', width: '100%' }}
                                    variant="contained"
                                    onClick={() => window.open(`https://wa.me/${whatsNumber}/?text=Ola, estou com duvidas e gostaria de suporte`, '_blank')}>
                                    WhatsApp
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ContainerModal>
            </PopoverPopup>

        </Container>
    );
};

export default GoWhatsApp;
