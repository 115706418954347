import { Fragment, useEffect, useState } from "react";
import { Container } from "./styles";
import { POST_DATA, PUT_DATA } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { ImagemSemResultado, salvarCSV } from "../../../services/utils/functions";
import { POPOVER_OPEN } from "../../../store/PopoverReducer/types";
import ModalEmail from "../../../components/ModalEmail";
import { Avatar, Box, Button, CircularProgress, FormControlLabel, Grid, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import PeriodPicker from "../../../components/PeriodPicker";
import * as moment from "moment";

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const columns = [
    { id: "produto", label: "Produto", minWidth: 150, maxWidth: 170 },
    { id: "email", label: "E-mail", minWidth: 150, maxWidth: 170 },
    { id: "dataEvento", label: "Data evento", minWidth: 150, maxWidth: 170 },
    { id: "notificado", label: "Notificado", minWidth: 150, maxWidth: 170 },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 200,
        align: "right",
    },
];

const SolicitacaoProdutosEsgotados = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);

    const [loading, setLoading] = useState(false);
    const [produtosAvisime, setProdutosAvisime] = useState([]);
    const [anchorEl, setaAchorEl] = useState(null);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    useEffect(() => {
        (async () => {
            if (userLoggedIn === null) return;
            await fetchProdutosAvisime();
        })();
    }, [userLoggedIn, orderList.period]);

    useEffect(() => {
        const tmpSolicitacao = produtosAvisime?.map(solicitacao => {
            return <Row aviseMe={solicitacao} />
        });

        setRows(tmpSolicitacao || []);
    }, [produtosAvisime]);

    const fetchProdutosAvisime = async () => {
        setLoading(true);

        const produtosAvisime = await POST_DATA(`AviseMeProduto/GetListAviseMeProdutoByIdUsuario_Data?idUsuario=${userLoggedIn.IdUsuario}&dtInicio=${orderList.period.startDate}&dtFim=${orderList.period.endDate}`);
        setProdutosAvisime(produtosAvisime);

        setLoading(false);
    }

    const fetchExcel = async () => {
        if (produtosAvisime === null || produtosAvisime.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Email; Produto; Descrição evento;`;
        produtosAvisime?.forEach(aviseme => {
            dados += `\n${aviseme.Email}; ${aviseme.Etiqueta.sDescricao} - Sku: ${aviseme.EtiquetaComplemento.Sku}; ${moment(aviseme.DtInclusao).format('DD/MM/YYYY')}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'ListaEspera.csv', dados);
        window.open(url, '_blank');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleNotificar = async (item) => {
        const aviseMe = { ...item, FlagNotificado: !item.FlagNotificado };
        const result = await PUT_DATA(`AviseMeProduto/CadastroAviseMeProduto`, aviseMe);

        if (result) {
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: `Sucesso ao executar a ação.`,
            });

            await fetchProdutosAvisime();
        }
    }

    const Row = ({ aviseMe }) => {
        return <Fragment key={aviseMe.IdAviseMeProduto}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 300 }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar style={{ background: 'white' }} src={aviseMe.Imagem.LocalIIS} sx={{ width: 70, height: 70 }} />
                        <Typography style={{ marginLeft: 10 }}>{aviseMe.Etiqueta.sDescricao} - Sku: {aviseMe.EtiquetaComplemento.Sku}</Typography>
                    </Box>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>{aviseMe.Email}</TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {moment(aviseMe?.DtInclusao).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    <Switch
                        checked={aviseMe.FlagNotificado}
                        onChange={async () => {
                            await handleNotificar(aviseMe);
                        }} 
                    />
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                    <Button
                        size="small"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmailComAnexo',
                                    idItem: 'poperModalEmailComAnexo',
                                    open: true,
                                    data: {
                                        nomePessoa: '',
                                        emailCliente: aviseMe.Email
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <LocalOfferIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        title="Enviar mala direta"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmail',
                                    idItem: 'poperModalEmail',
                                    open: true,
                                    data: {
                                        nomePessoa: '',
                                        emailCliente: aviseMe.Email
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <EmailIcon style={{ fontSize: 16 }} />
                    </Button>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    return <Container>
        <ModalEmail idPopover='poperModalEmail' anchorEl={anchorEl} />
        <ModalEmail idPopover='poperModalEmailComAnexo' anchorEl={anchorEl} possuiAnexo={true} />

        <Box sx={{ width: "100%" }}>
            <Box style={{ width: "100%" }}>
                <Typography style={{ fontWeight: 'bold' }}>Relatórios / Avise-se - Lista de Espera</Typography>
            </Box>
            <Box style={{ width: "100%", marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                <PeriodPicker />
            </Box>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading ? (
                <Stack
                    sx={{ height: "150px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando avaliações</Typography>
                </Stack>
            ) : (
                <>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(row => {
                                        return row;
                                    })}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={produtosAvisime.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={"Solicitações"}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
        <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                size="small"
                variant="contained"
                style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                onClick={() => fetchExcel()}
            >
                <FileDownloadIcon />
                Excel
            </Button>
        </Box>
    </Container>
}

export default SolicitacaoProdutosEsgotados;