import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { DIALOGCONFIRMATION_SET_CLOSE } from '../../store/DialogConfirmation/types';

const DialogConfirmation = ({ title = 'Titulo', mensagem = 'Mensagem', btn1 = { title: 'Sim', onClick: () => { } }, btn2 = { title: 'Não', onClick: () => { } } }) => {
    const dispatch = useDispatch();
    const store = useSelector((store) => store);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        dispatch({
            type: DIALOGCONFIRMATION_SET_CLOSE,
            value: { open: false },
        });
    };

    useEffect(() => {
        setOpen(store.dialogConfirmation.open);
    }, [store.dialogConfirmation.open]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {mensagem}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={btn1.onClick}>{btn1.title}</Button>
                    {
                        btn2?.onClick !== null &&
                        <Button onClick={btn2.onClick} autoFocus>
                            {btn2.title}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogConfirmation;
