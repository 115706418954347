import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { POST_DATA } from '../../services/API';
import { Alert, AlertTitle, Avatar, Box, Button, CircularProgress, Collapse, IconButton, InputAdornment, Menu, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ImagemSemResultado, getCategoryString } from '../../services/utils/functions';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import * as moment from 'moment';
import { ManageSearch } from '@mui/icons-material';
import './styles.css';
import PeriodPicker from '../../components/PeriodPicker';
import StatusPedido from '../../components/StatusPedido';
import { getDescricaoStatusPedido } from '../../services/utils/statusPedido';

function createData(
  pedido,
  comprador,
  dataCadastro,
  valor,
  tipoEntrega,
  status,
  etiquetaArray
) {
  return {
    pedido,
    comprador,
    dataCadastro,
    valor,
    tipoEntrega,
    status,
    etiquetas: etiquetaArray,
  };
}

const ListagemPedidos = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const usuarioLogado = useSelector((store) => store.usuarioLogado);
  const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);

  const [opcaoSelecionada, setOpcaoSelecionada] = useState(0);

  const [loadingPedidos, setLoadingPedidos] = useState(false);
  const [statusPedido, setStatusPedido] = useState(0);
  const querySelector = useMediaQuery('(max-width:600px)');
  const [search, setSearch] = useState('');
  const [pedidos, setPedidos] = useState([]);

  const [buscaInterna, setBuscaInterna] = useState(false);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    <TableCell style={{ fontWeight: 'bold' }}>Nº do Pedido</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Comprador</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Data</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Valor</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Tipo de entrega</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Status</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="center">Qtd itens: {rows?.length}</TableCell>
  ];

  useEffect(() => {
    (async () => {
      if (usuarioLogado !== null) {
        if (location.state !== null && location.state?.statusId !== undefined) {
          setStatusPedido(location.state.statusId);
          setOpcaoSelecionada(location.state.statusId);
          await buscarPedidos(location.state.statusId);
        }
        else if(location.state !== null && location.state?.idPedido !== undefined){          
          await buscarPedidoByIdPedido(location.state.idPedido);
        }        
        else if (opcaoSelecionada >= 0) {
          await handleBuscarPedidos(opcaoSelecionada);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, usuarioLogado]);

  useEffect(() => {
    navigate(`/pedidos`, { replace: true, state: { statusId: statusPedido } });
  }, [ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleBuscarPedidos = async (idStatus = 0) => {
    navigate(`/pedidos`, { replace: true, state: { statusId: idStatus } });
  }

  const buscarPedidos = async (idStatus = 0) => {
    setLoadingPedidos(true);

    const endpoint = `Pedido/PedidosByIdStatusData?idStatus=${idStatus}&dtInicio=${ordenacaoDashboard.period.startDate}&dtFim=${ordenacaoDashboard.period.endDate}&idUsuarioVendedor=${usuarioLogado.IdUsuario}&FlgCompraPresencial=false`;
    const result = await POST_DATA(endpoint);
    setPedidos(result.objetoRetorno);
    setRows(result.objetoRetorno);
    setLoadingPedidos(false);

    return result.objetoRetorno;
  }

  const buscarPedidoByIdPedido = async (idPedido) => {
    setLoadingPedidos(true);

    const endpoint = `Pedido/GetPedidoByIdPedido?idPedido=${idPedido}`;
    const result = await POST_DATA(endpoint);
    const arr = [result.objetoRetorno];

    setPedidos(arr);
    setRows(arr);
    setLoadingPedidos(false);
  }

  function Row(props) {
    const { row, idPedido } = props;
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <TableRow>
          <TableCell component="th" scope="row">
            {row.pedido}
          </TableCell>
          <TableCell align="right">{row.comprador}</TableCell>
          <TableCell align="left">{row.dataCadastro}</TableCell>
          <TableCell align="left">{row.valor}</TableCell>
          <TableCell align="left">{row?.tipoEntrega == 'Combinar' ? 'À Combinar' : row?.tipoEntrega }</TableCell>
          <TableCell align="left">
            {getDescricaoStatusPedido(row.status)}
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => navigate(`/pedidos/detalhes-pedidos`, { replace: true, state: { idPedido: idPedido, statusId: location.state?.statusId } })}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  const montarRow = (order) => {
    if (order === null || order._UsuarioComprador === null) return null;
    return createData(
      <Tooltip title="Ver detalhes do pedido">
        <Button
          onClick={() => {
            navigate(`/pedidos/detalhes-pedidos`, {
              state: { idPedido: order.IdPedido, statusId: location.state?.statusId }
            });
          }}
          variant="text"
          color="primary"
          size="large"
        >
          {order.IdPedido}
        </Button>
      </Tooltip>,
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          gap: "0.5rem",
        }}
      >
        <Avatar src={order._UsuarioComprador?.FotoProfile || 'https://timelineleap-evhgawa4gxdcecak.z01.azurefd.net/img/avatar.png'} />
        <Typography variant="body2">
          {order._UsuarioComprador?.Nome}
        </Typography>
      </Stack>,
      moment(order.DataCadastro).format("DD/MM/YYYY"),
      order.ValorTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
      order._TipoEntrega.Descricao,
      order.StatusPagamento,
      order._PedidoItens
    );
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box style={{ width: "100%" }}>
          <Typography style={{ fontWeight: 'bold' }}>Pedidos / Lista de Pedidos</Typography>
        </Box>
        <Alert sx={{ mt: 1, width: "100%" }} severity="info">
          <AlertTitle>Filtro aplicado</AlertTitle>
          Exibindo apenas pedidos na categoria{" "}
          <strong>{getCategoryString(statusPedido)}</strong>.
        </Alert>
      </Box>
      <Stack
        direction="row"
        sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
        justifyContent="space-between"
      >
        <Stack direction="row" gap={2}>
          <TextField
            type="number"
            label="Pesquisar por nº do pedido"
            placeholder='Nº do pedido'
            size="small"
            sx={{ width: querySelector ? "200px" : "250px" }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value.trim());
              setRows(pedidos.filter(a => String(a.IdPedido).includes(e.target.value)));
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <ManageSearch />
              </InputAdornment>,
              endAdornment: search.length > 0 && (
                <InputAdornment
                  style={{ cursor: 'pointer', color: 'black' }}
                  position="end"
                  onClick={async () => {
                    setSearch(() => '');
                    setRows(pedidos);
                  }}
                >
                  <ClearIcon />
                </InputAdornment>
              )
            }}
          />
        </Stack>
        <Box style={{ display: 'flex' }}>
          <StatusPedido valorPadrao={statusPedido} onClick={handleBuscarPedidos} />
          <PeriodPicker />
        </Box>
      </Stack>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {loadingPedidos ? (
          <Stack
            sx={{ height: "150px" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>Carregando...</Typography>
          </Stack>
        ) : (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => column)}
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: "100%" }}>
                  {rows && rows.length > 0 &&
                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item) => {
                      const r = montarRow(item);
                      return <Row key={item.IdPedido} row={r} idPedido={item.IdPedido} />
                    }
                    )}
                </TableBody>
              </Table>
              {rows.length === 0 && (
                <Stack
                  sx={{ width: "100%", minHeight: "300px", p: 2 }}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                  <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                    Nenhum resultado encontrado.
                  </Typography>
                </Stack>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={pedidos.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={
                search
                  ? `${rows?.length} resultados encontrados para pesquisa "${search}" - Produtos por página: `
                  : "Produtos por página"
              }
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
}

export default ListagemPedidos;
