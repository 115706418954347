import { Fragment, useEffect, useState } from "react";
import { Paper, useMediaQuery, Box, Stack, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, CircularProgress, InputAdornment, Button, TextField, Grid, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Modal, DialogTitle, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA, PUT_DATA } from "../../../services/API";
import { Container, Descricao, Title } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import Card from "../../../components/Card";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import { getStyleDestaqueSemaforo } from "../../../services/utils/statusPedido";

const columns = [
    { id: "viisualizarImagem", label: "", minWidth: 70, maxWidth: 70 },
    { id: "variacoes", label: "Variações", minWidth: 170, maxWidth: 270 },
    { id: "produto", label: "Produto", minWidth: 170, maxWidth: 270 },
    { id: "dataAlteracao", label: "Data Alteração", minWidth: 120, maxWidth: 170 },
    { id: "quantidade", label: "Quantidade", align: 'center', minWidth: 120, maxWidth: 170 },
    { id: "maisVendido", label: "Mais vendido", align: 'center', minWidth: 120, maxWidth: 170 }
];

const container = {
    position: 'absolute',
    top: '0%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const MaisVendidos = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [tipoFiltro, setTipoFiltro] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [img, setImg] = useState(null);

    const [totalProdutos, setTotalProdutos] = useState(0);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (userLoggedIn === null) return;
        (async () => {
            await fetchProdutos();
        })();
    }, [userLoggedIn]);

    useEffect(() => {
        (async () => {
            await fetchProdutos();
        })();
    }, [orderList?.period, page]);

    useEffect(() => {
        const tempProdutos = produtos?.map(prdouto => {
            return <Row produto={prdouto} />
        });

        setRows(tempProdutos || []);
    }, [produtos]);

    const fetchProdutos = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        if (orderList?.period === undefined || orderList?.period === null) return;

        setLoading(true);

        let filtroApi = {
            idUsuario: userLoggedIn?.IdUsuario,
            idUsuarioVendedor: userLoggedIn?.IdUsuario,
            idUsuarioComprador: 0,
            iPagina: page,
            Sexo: '0,1,2',
            FlagKids: false,
            Categoria: null,
            Peca: null,
            Tamanho: "",
            Marca: "",
            HashTags: null,
            ValorMinimo: 0,
            ValorMaximo: 0,
            FlgUsado: false,
            FlgFavoritos: false,
            order_by: '_Etiquetas.sDescricao asc',

            FlgAtivo: true,

            FlagMaisVendido: true,

            Log: {
            }
        }

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        let endPoint = `Imagem/GetListImagemFilterPaginationCRM`;

        if (filtroStr.trim().length > 0 && tipoFiltro == 2)
            filtroApi = { ...filtroApi, Sku: filtroStr.trim() }
        else if (filtroStr.trim().length > 0 && tipoFiltro == 1)
            filtroApi = { ...filtroApi, sDescricaoEtiqueta: filtroStr.trim() }

        const result = await PUT_DATA(endPoint, filtroApi);
        let quantidade = 0;
        result._Imagens.map(produto => {
            quantidade += produto._Etiquetas.length;
        });

        setTotalPages(result.iTotalPagina);
        setProdutos(result._Imagens);
        setLoading(false);

        setTotalProdutos(() => quantidade);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchExcel = async () => {
        if (produtos === null || produtos.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Prdouto; Variações; Data Alteração; Quantidade`;
        let dataAlteracao = '-';
        let variacoes = '';
        produtos?.forEach(produto => {
            produto._Etiquetas.map(et => {
                dataAlteracao = produto.DtAlteracao === produto.DtInclusao ? formatDateSimples(produto.DtInclusao) : formatDateSimples(produto.DtAlteracao);

                variacoes = et._EtiquetaComplemento.map(etc => {
                    return ` ${etc.Sku === null ? '-' : etc.Sku} - ${etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'} - ${etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'} ${etc?._Estampa?.Descricao ? etc?._Estampa?.Descricao : ''}`
                })

                dados += `\n${et.sDescricao}; ${variacoes}; ${dataAlteracao}; ${et._EtiquetaComplemento.reduce((total, item) => total += item.iQuantidade, 0)}`;                
            })
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Produto Mais Vendidos.csv', dados);
        window.open(url, '_blank');
    }

    const hanfleChangeMaisVendido = async (idImagem, idEtiqueta) => {
        try {
            setLoading(true);

            const endpoint = `Etiqueta/UpdateEtiquetaMaisVendido?idImagem=${idImagem}&idEtiqueta=${idEtiqueta}&FlagMaisVendido=false`;
            await POST_DATA(endpoint);
            await fetchProdutos();

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: `Sucesso ao desmarcar o produto como mais vendido`,
            });

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Ocorreu um erro ao tentar ao desmarcar o produto como mais vendido',
            });
        }
    }

    const Row = ({ produto }) => {
        return <Fragment key={produto.IdImagem}>
            {
                produto._Etiquetas.map(et => {
                    return <TableRow>
                        <TableCell align="left" style={{ minWidth: 100 }}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    setOpenModal(true);
                                    setImg(produto.LocalIIS);
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 100 }}>
                            {et._EtiquetaComplemento.map(etc => {
                                return <Typography>{etc.Sku === null ? '-' : etc.Sku} - {etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'} - {etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'}</Typography>
                            })}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 100 }}>
                            {et.sDescricao}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 100 }}>
                            {produto.DtAlteracao === produto.DtInclusao
                                ? formatDateSimples(produto.DtInclusao)
                                : formatDateSimples(produto.DtAlteracao)
                            }
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                            {et._EtiquetaComplemento.reduce((total, item) => total += item.iQuantidade, 0)}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                            <Switch
                                onClick={() =>
                                    hanfleChangeMaisVendido(produto.idImagem, et.IdEtiqueta)
                                }
                                checked={et.FlagMaisVendido}
                            ></Switch>
                        </TableCell>
                    </TableRow>
                })
            }
        </Fragment>
    }

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 15,
                            top: 35,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <Container>
            {
                openModal &&
                <Modal
                    key="modal"
                    open={openModal}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '0%'
                    }}
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '50%',
                                height: 'auto',
                                maxHeight: 850,
                                overflowY: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteMensagem" onClose={() => setOpenModal(false)}>
                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>Imagem Produto</Box>
                        </BootstrapDialogTitle>
                        <br />

                        <img src={img} style={{ width: '100%' }} />
                    </Box>
                </Modal>
            }

            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={9.5} style={{ display: 'flex', justifyContent: 'start' }}>
                        <Typography style={{ fontWeight: 'bold' }}>eCommerce / MaisVendidos</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Total de produtos</Title>
                            <Descricao>{totalProdutos}
                            </Descricao>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'nowrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <FormControl sx={{ marginTop: -2 }}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={1}
                                value={tipoFiltro}
                                name="radio-buttons-group"
                                onChange={(e) => { setTipoFiltro(e.target.value) }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Nome" />
                                <FormControlLabel value={2} control={<Radio />} label="Sku" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            label="Pesquisar"
                            placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchProdutos('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ height: '100%', marginLeft: 10, maxHeight: 43 }}
                            onClick={async () => {
                                if (page === 1)
                                    await fetchProdutos();
                                else
                                    setPage(1);
                            }}
                        >
                            Pesquisar
                        </Button>
                    </Box>
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando MaisVendidos</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => {
                                            return (

                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>

                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                            <Pagination
                                size="large"
                                color="primary"
                                showFirstButton
                                showLastButton
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Box>
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default MaisVendidos;