import { Box, Button, CircularProgress, Divider, FormControlLabel, Grid, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import Card from "../../../components/Card";
import * as Yup from 'yup';
import { AlertError, Container } from "./styles";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../ui-component/Loader";
import { useFormik } from "formik";
import UploadImagem from "../../../components/UploadImagem";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { valida_cpf_cnpj } from "../../../services/utils/functions";
import axios from "axios";
import * as moment from "moment";

const InitialValue = {
    IdUsuario: 0,
    Nome: '',
    Cpf: '',
    Email: '',
    Celular: '',
    FotoProfile: null,
    IdUsuarioOrigem: 0,
    CodigoIndicado: '',
    FlgAtivo: true,
    DtValidadeAccessToken: moment().format('YYYY-MM-DD'),
    SaltKey: "",
    AccessToken: "",
    _Endereco: [
        {
            END_Id: 0,
            END_IdUsuario: 0,
            END_CEP: '',
            END_Logradouro: '',
            END_Numero: '',
            END_Bairro: '',
            END_Complemento: '',
            END_Cidade: ''
        }
    ]
}

const EdicaoCliente = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [usuario, setUsuario] = useState(InitialValue);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [salvando, setSalvando] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let user = null;

            if (location.state?.idUsuario) {
                user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${location.state.idUsuario}&join=true&Log=`);
                if (user?._Endereco === null || user?._Endereco?.length === 0) {
                    user._Endereco = [
                        {
                            END_Id: 0,
                            END_IdUsuario: user.IdUsuario,
                            END_CEP: '',
                            END_Logradouro: '',
                            END_Numero: '',
                            END_Bairro: '',
                            END_Complemento: '',
                            END_Cidade: ''
                        }
                    ]
                }
            }
            else {
                user = {
                    IdUsuario: 0,
                    Nome: '',
                    Cpf: '',
                    Email: '',
                    Celular: '',
                    FotoProfile: null,
                    IdUsuarioOrigem: userLoggedIn.IdUsuario,
                    CodigoIndicado: userLoggedIn.CodigoIndicado,
                    FlgAtivo: true,
                    DtValidadeAccessToken: moment().format('YYYY-MM-DD'),
                    SaltKey: "",
                    AccessToken: "",
                    _Endereco: [
                        {
                            END_Id: 0,
                            END_IdUsuario: 0,
                            END_CEP: '',
                            END_Logradouro: '',
                            END_Numero: '',
                            END_Bairro: '',
                            END_Complemento: '',
                            END_Cidade: ''
                        }
                    ]
                }
            }

            setUsuario(user);
            setLoading(false);
            formik.resetForm();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const validationSchema = Yup.object().shape({
        Nome: Yup.string().required('*Favor informar o Nome'),
        Celular: Yup.string().required('*Favor informar o Celular'),
        // Cpf: Yup.string().test(
        //     'validateCpf',
        //     '*Favor informar um cpf válido',
        //     (cpf, context) => {
        //         if (cpf?.length >= 11) {
        //             const result = valida_cpf_cnpj(cpf);
        //             return result;
        //         }
        //         return cpf?.length >= 11;
        //     }
        // ),
        // Email: Yup.string().required('*Favor informar o Email'),
    });

    const onSubmit = async (values) => {
        try {
            setSalvando(true);

            if (usuario.IdUsuario > 0) {
                const objUsuario = {
                    ...values,
                    FlgClientePdv: true
                }

                await POST_DATA('Usuario/UpdateDadosUsuario', objUsuario);
                if (!objUsuario.FlgAtivo) {
                    await POST_DATA(`Usuario/DeletarUsuario?IdUsuario=${objUsuario.IdUsuario}&Log=`);
                }
                else {
                    await POST_DATA(`Usuario/AtivarUsuario?IdUsuario=${objUsuario.IdUsuario}&Log=`);
                }
                await PUT_DATA(`Endereco/CadastroEndereco`, objUsuario._Endereco[0]);
            }
            else {
                const objUsuario = {
                    ...values,
                    FlgAtivo: true,
                    Senha: usuario.Cpf.substring(0, 5),
                    FlgClientePdv: true,
                    FlgUserSemSenha: true,
                }
                const resutUsuario = await PUT_DATA('Usuario/CadastroUsuario', objUsuario);

                if(resutUsuario.IdUsuario === 0){
                    dispatch({
                        type: CONTROLEMENSAGEM_AVISO,
                        tipoComponente: 'alert',
                        titulo: 'Aviso',
                        message: resutUsuario.mensagemSucesso,
                    });
                    setSalvando(false);
                    return;
                }
                
                const objEndereco = { ...values._Endereco[0], END_IdUsuario: resutUsuario.IdUsuario };
                await PUT_DATA(`Endereco/CadastroEndereco`, objEndereco);
            }

            setSalvando(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar os dados do Usuário',
            });

            if(location.state?.pdv)
                navigate('/caixa-pdv');
            else 
                navigate('/clientes');
        }
        catch (err) {
            setSalvando(false);
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err?.message,
            });
        }
    };

    const formik = useFormik({
        onSubmit,
        initialValues: usuario,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    const handleCep = (e) => {
        if (e.target.value.length === 8) {
            axios
                .get(`https://viacep.com.br/ws/${e.target.value}/json/`)
                .then((res) => {
                    if (!res.data.erro) {
                        formik.setFieldValue(`_Endereco[0].END_Logradouro`, res.data.logradouro);
                        formik.setFieldValue(`_Endereco[0].END_Bairro`, res.data.bairro);
                        formik.setFieldValue(`_Endereco[0].END_Complemento`, res.data.complemento);
                        formik.setFieldValue(`_Endereco[0].END_Cidade`, res.data.localidade);
                    }
                });
        }
    };

    const styleItemGrid = { width: '100%', marginTop: 25 };

    return (
        <Container>
            <form onSubmit={formik.handleSubmit}>
                {
                    loading ? (
                        <Stack
                            sx={{ height: "150px", marginTop: '10%' }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>Carregando dados do usuário</Typography>
                        </Stack>
                    ) : (
                        <>
                            <Box sx={{ width: "100%" }}>
                                <Box style={{ width: "100%" }}>
                                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Usuário</Typography>
                                </Box>
                                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                    {
                                        location.state?.pdv &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/caixa-pdv')}
                                        >
                                            {salvando && <Loader />}
                                            Voltar
                                        </Button>
                                    }
                                    {
                                        !location.state?.pdv &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/clientes')}
                                        >
                                            {salvando && <Loader />}
                                            Voltar
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={formik.submitForm}
                                    >
                                        {salvando && <Loader />}
                                        Salvar
                                    </Button>
                                </Box>
                            </Box>
                            <Card style={{ marginTop: 10 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            disabled
                                            readOnly
                                            labelPlacement="top"
                                            control={<img src={formik.values?.FotoProfile === null ? 'https://timelineleap-evhgawa4gxdcecak.z01.azurefd.net/img/avatar.png' : formik.values?.FotoProfile} alt={'Imagem Cliente'} style={{ width: 70, marginTop: 5 }} />}
                                            label="Imagem Cliente"
                                        />
                                        <AlertError className="msg-error">{formik.errors.FotoProfile}</AlertError>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label={'Nome'}
                                            style={styleItemGrid}
                                            value={formik.values?.Nome}
                                            onChange={(e) => {
                                                formik.setFieldValue(`Nome`, e.target.value);
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.Nome}</AlertError>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            // disabled={usuario?.IdUsuario > 0 && usuario?.Cpf !== null}
                                            // readOnly={usuario?.IdUsuario > 0 && usuario?.Cpf !== null}
                                            inputProps={{
                                                maxLength: 11,
                                            }}
                                            label={'CPF'}
                                            style={styleItemGrid}
                                            value={formik.values?.Cpf}
                                            onChange={(e) => {
                                                formik.setFieldValue(`Cpf`, e.target.value.replace(/([^\d])+/gim, ''));
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.Cpf}</AlertError>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label={'Email'}
                                            style={styleItemGrid}
                                            value={formik.values?.Email}
                                            onChange={(e) => {
                                                formik.setFieldValue(`Email`, e.target.value);
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.Email}</AlertError>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            inputProps={{
                                                maxLength: 11,
                                            }}
                                            label={'Celular'}
                                            style={styleItemGrid}
                                            value={formik.values?.Celular}
                                            onChange={(e) => {
                                                formik.setFieldValue(`Celular`, e.target.value.replace(/\D/g, ""));
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.Celular}</AlertError>
                                    </Grid>
                                    <Grid item xs={12} md={1} style={{ ...styleItemGrid, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                        <FormControlLabel labelPlacement="top" control={<Switch checked={formik.values?.FlgAtivo} onChange={() => formik.setFieldValue(`FlgAtivo`, !formik.values?.FlgAtivo)} />} label="Ativo" />
                                    </Grid>
                                </Grid>

                                <Divider style={{ width: '100%' }} />
                                {
                                    formik.values?._Endereco !== null && formik.values?._Endereco !== undefined && formik.values?._Endereco.length > 0 &&
                                    <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>
                                        <Grid item xs={12} style={{ textAlign: 'left', marginBottom: -20 }}>
                                            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Endereço</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                inputProps={{
                                                    maxLength: 8,
                                                }}
                                                label={'CEP'}
                                                style={styleItemGrid}
                                                value={formik.values?._Endereco[0]?.END_CEP}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`_Endereco[0].END_CEP`, e.target.value.replace(/\D/g, ""));
                                                    if (Number(e.target.value) < 0) return;
                                                    handleCep(e);
                                                }}
                                            />
                                            <AlertError className="msg-error">{formik.errors?._Endereco && formik.errors._Endereco[0]?.END_CEP}</AlertError>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                label={'Logradouro'}
                                                style={styleItemGrid}
                                                value={formik.values?._Endereco[0]?.END_Logradouro}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`_Endereco[0].END_Logradouro`, e.target.value);
                                                }}
                                            />
                                            <AlertError className="msg-error">{formik.errors?._Endereco && formik.errors._Endereco[0]?.END_Logradouro}</AlertError>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                type="number"
                                                label={'Nº'}
                                                style={styleItemGrid}
                                                value={formik.values?._Endereco[0]?.END_Numero}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`_Endereco[0].END_Numero`, e.target.value);
                                                }}
                                            />
                                            <AlertError className="msg-error">{formik.error?._Endereco[0]?._Endereco && formik.errors._Endereco[0]?.END_Numero}</AlertError>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                label={'Bairro'}
                                                style={styleItemGrid}
                                                value={formik.values?._Endereco[0]?.END_Bairro}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`_Endereco[0].END_Bairro`, e.target.value);
                                                }}
                                            />
                                            <AlertError className="msg-error">{formik.errors?._Endereco && formik.errors._Endereco[0]?.END_Bairro}</AlertError>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                label={'Complemento'}
                                                style={styleItemGrid}
                                                value={formik.values?._Endereco[0]?.END_Complemento}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`_Endereco[0].END_Complemento`, e.target.value);
                                                }}
                                            />
                                            <AlertError className="msg-error">{formik.errors?._Endereco && formik.errors._Endereco[0]?.ENDComplemento}</AlertError>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                label={'Cidade'}
                                                style={styleItemGrid}
                                                value={formik.values?._Endereco[0]?.END_Cidade}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`_Endereco[0].END_Cidade`, e.target.value);
                                                }}
                                            />
                                            <AlertError className="msg-error">{formik.errors?._Endereco && formik.errors._Endereco[0]?.ENDCidade}</AlertError>
                                        </Grid>
                                    </Grid>
                                }
                            </Card>
                        </>
                    )
                }
            </form>
        </Container>
    )
}

export default EdicaoCliente;