import {
    LEAPVESTI_SET_CLEAR,
    LEAPVESTI_SET_DADOS
} from './types';

const leapVestiRecuder = (state = null, action) => {
    switch (action.type) {
        case LEAPVESTI_SET_DADOS:
            return action.data;
        case LEAPVESTI_SET_CLEAR:
            return null;
        default:
            return state;
    }
};

export default leapVestiRecuder;
