import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Alert, Checkbox, FormControlLabel, IconButton, Stack, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";

function createData(id: number, idTamanho: number, idCor: number, idEstampa: number, tamanho: any, qtd: any, acoes: any, _Cor: any, _Estampa: any, Sku: any, FlagPromocao: any, FlagLancamento: any, FlagLiquidacao: any) {
  return { id, idTamanho, idCor, idEstampa, tamanho, qtd, acoes, _Cor, _Estampa, Sku, FlagPromocao, FlagLancamento, FlagLiquidacao };
}

interface TableTamanhosSelecionadosProps {
  listaTamanhosSelecionados: any;
  handleSetListaTamanhosSelecionados: any;
  handleRemoverTamanho: any;
}

export default function TableTamanhosSelecionados({
  listaTamanhosSelecionados,
  handleSetListaTamanhosSelecionados,
  handleRemoverTamanho
}: TableTamanhosSelecionadosProps) {
  const [rows, setRows] = useState([]);
  const handleAtualizarQuantidade = (event: any, idTamanho: number, idCor: number, idEstampa: number, sku: string) => {    
    const { value } = event.target;

    const newLista = listaTamanhosSelecionados?.map((item: any) => {
      if(idEstampa){
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.IdEstampa === idEstampa && item.Sku === sku) {
          return { ...item, qtd: value };
        }
      }
      else {
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.Sku === sku) {
          return { ...item, qtd: value };
        }
      }
      return item;
    });
    handleSetListaTamanhosSelecionados(newLista);
  };

  const handleChangeFlagPromocao = (value: boolean, idTamanho: number, idCor: number, idEstampa: number, sku: string) => {
    const newLista = listaTamanhosSelecionados?.map((item: any) => {
      if(idEstampa){
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.IdEstampa === idEstampa && item.Sku === sku) {
          return { ...item, FlagPromocao: value };
        }
      }
      else {
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.Sku === sku) {
          return { ...item, FlagPromocao: value };
        }
      }
      return item;
    });
    handleSetListaTamanhosSelecionados(newLista);
  }

  const handleChangeFlagLancamento = (value: boolean, idTamanho: number, idCor: number, idEstampa: number, sku: string) => {    
    const newLista = listaTamanhosSelecionados?.map((item: any) => {
      if(idEstampa){
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.IdEstampa === idEstampa && item.Sku === sku) {
          return { ...item, FlagLancamento: value };
        }
      }
      else {
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.Sku === sku) {
          return { ...item, FlagLancamento: value };
        }
      }
      return item;
    });
    handleSetListaTamanhosSelecionados(newLista);
  }

  const handleChangeFlagLiquidacao = (value: boolean, idTamanho: number, idCor: number, idEstampa: number, sku: string) => {    
    const newLista = listaTamanhosSelecionados?.map((item: any) => {
      if(idEstampa){
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.IdEstampa === idEstampa && item.Sku === sku) {
          return { ...item, FlagLiquidacao: value };
        }
      }
      else {
        if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.Sku === sku) {
          return { ...item, FlagLiquidacao: value };
        }
      }
      return item;
    });
    handleSetListaTamanhosSelecionados(newLista);
  }

  useEffect(() => {
    if (listaTamanhosSelecionados?.length !== 0) {
      setShowAlert(false);
      const newRows = listaTamanhosSelecionados?.filter((item: any) => item.visivelListagem).map((item: any, index: number) => {
        return createData(
          index,
          item?.IdTamanho,
          item?.IdCor,
          item?.IdEstampa,
          item?.Descricao,
          <TextField
            type="number"
            sx={{ width: "60px", alignText: "center" }}
            value={item?.qtd}
            onChange={(event) =>
              handleAtualizarQuantidade(event, item.IdTamanho, item.IdCor, item.IdEstampa, item.Sku)
            }
          ></TextField>,
          <Stack style={{ display: 'flex', justifyContent: 'center' }} direction="row">
            <IconButton
              size="small"
              onClick={() => handleRemoverTamanho(item.IdTamanho, item.IdCor, item.IdEstampa, item.Sku)}
            >
              <Delete />
            </IconButton>
          </Stack>,
          item?._Cor,
          item?._Estampa,
          item?.Sku,
          <Checkbox checked={item.FlagPromocao || false} onChange={() => handleChangeFlagPromocao(!item.FlagPromocao, item.IdTamanho, item.IdCor, item.IdEstampa, item.Sku)} />,
          <Checkbox checked={item.FlagLancamento || false} onChange={() => handleChangeFlagLancamento(!item.FlagLancamento, item.IdTamanho, item.IdCor, item.IdEstampa, item.Sku)} />,
          <Checkbox checked={item.FlagLiquidacao || false} onChange={() => handleChangeFlagLiquidacao(!item.FlagLiquidacao, item.IdTamanho, item.IdCor, item.IdEstampa, item.Sku)} />,
        );
      });
      setRows(newRows);
    } else {
      setShowAlert(true);
      setRows([]);
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaTamanhosSelecionados]);

  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      {rows && rows.length !== 0 ? (
        <TableContainer sx={{ mt: 1 }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tamanho</TableCell>
                <TableCell>Cor</TableCell>
                <TableCell>Estampa</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell align="center">Promoção</TableCell>
                <TableCell align="center">Lançamento</TableCell>
                <TableCell align="center">Liquidação</TableCell>
                <TableCell align="right">Quantidade</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.tamanho}</TableCell>
                    <TableCell align="left">{row?._Cor?.Descricao}</TableCell>
                    <TableCell align="left">{row?._Estampa?.Descricao}</TableCell>
                    <TableCell align="left">{row?.Sku}</TableCell>
                    <TableCell align="center">{row.FlagPromocao}</TableCell>
                    <TableCell align="center">{row.FlagLancamento}</TableCell>
                    <TableCell align="center">{row.FlagLiquidacao}</TableCell>
                    <TableCell align="right">{row.qtd}</TableCell>
                    <TableCell align="right">{row.acoes}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="warning" sx={{ width: "100%", mt: 1 }}>
          Não há nenhum tamanho cadastrado para esta etiqueta. Adicione um
          tamanho.
        </Alert>
      )}
    </>
  );
}
