import { Box, Card, Divider, Typography } from "@mui/material";

const CardItem = ({ etapa = null, borda = false, title, descricao, children }) => {
    return <>
        {
            etapa !== null &&
            <>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: 20, borderRadius: 30, background: '#f1416d', padding: 3, paddingLeft: 12, paddingRight: 12, color: 'white' }}>{etapa.numero}</span>
                    <Typography style={{ marginLeft: 10, fontSize: 18, fontWeight: 'bold' }}>{etapa.descricao}</Typography>
                </Box>
                {
                    etapa?.identificacao &&
                    <Box style={{ paddingLeft: 10, paddingTop: 15 }}>
                        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{etapa.identificacao.title}</Typography>
                        <Typography style={{ fontSize: 14 }}>{etapa.identificacao.descricao}</Typography>
                    </Box>
                }
            </>
        }
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: 10, marginTop: 10, border: borda ? `.5px solid #ededed` : null }}>
            <Box style={{ paddingLeft: 10, paddingTop: 15 }}>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{title}</Typography>
                {
                    descricao !== undefined &&
                    <Typography style={{ fontSize: 14 }}>{descricao}</Typography>
                }
                {
                    (title !== undefined || descricao !== undefined) &&
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                }
            </Box>
            <Box style={{ padding: 10, paddingTop: descricao !== null && descricao !== undefined ? 15 : null, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {children}
            </Box>
        </Card>
    </>
}

export default CardItem;