// project imports
import { theme } from '../../styles/theme';

// action - state management
import * as actionTypes from './types';

export const initialState = {
  isOpen: [], // for active default menu
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.6rem',
  opened: true,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_FONT_SIZE:
      return {
        ...state,
        fontSize: action.fontSize,
      };
    case actionTypes.SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.backgroundColor,
    };
    case actionTypes.SET_MODE:
      return {
        ...state,
        backgroundColor: action.mode,
    };
    default:
      return state;
  }
};

export default customizationReducer;
