import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { POST_DATA } from '../../../services/API';
import { Box, CircularProgress, Dialog, IconButton, Paper, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { formatCurrency, formatDateSimples } from '../../../services/utils/functions';

const DetalhesCompra = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [isLoadingData, setIsLoadingData] = useState(false);  
    const [listaCompras, setListaCompras] = useState(false);
  
    useEffect(() => {
        (async() => {
            setIsLoadingData(true);
    
            const response = await POST_DATA(`Pedido/GetPedidosDoClienteLojaByIdUsuarioLoja?idUsuarioLoja=${userLoggedIn.IdUsuario}&idUsuarioComprador=${location.state.usuarioCompradorId}`);
            setListaCompras(response);
            setIsLoadingData(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
      <div>
        <Box sx={{ width: "100%" }}>
            <Box style={{ width: "100%" }}>
                <Typography style={{ fontWeight: 'bold' }}>Detalhes Compra</Typography>
            </Box>
        </Box>
        <Stack justifyContent="center" alignItems="center">
            {" "}
            {isLoadingData ? (
            <Stack
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{ width: "100%", p: 5 }}
            >
                <CircularProgress size={100} />
                <Typography>Carregando lista de compras...</Typography>
            </Stack>
            ) : (
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>Número do Pedido</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Data Venda</TableCell>
                    <TableCell align="left">Tipo de Entrega</TableCell>
                    <TableCell align="left">Forma de Pagamento</TableCell>
                    <TableCell align="left">Status Pagamento</TableCell>
                    <TableCell align="left">Valor do Frete</TableCell>
                    <TableCell align="left">Valor das Etiquetas</TableCell>
                    <TableCell align="left">Valor Total</TableCell>                    
                    <TableCell align="left">Data Registro</TableCell>
                    <TableCell align="left">
                        Data Solicitação Devolução
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listaCompras &&
                    listaCompras?.map((row) => (
                        <TableRow
                        onClick={() => {
                            navigate(
                            `/pedidos/detalhes-pedidos`,
                            {
                                replace: true,
                                state: { idPedido: row.IdPedido, somenteVoltar: true }
                            });
                        }}
                        key={row?.IdPedido}
                        sx={{
                            cursor: "pointer",
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row?.NumeroPedido
                            ? row?.NumeroPedido
                            : "Número inexistente"}
                        </TableCell>                        
                        <TableCell align="left">
                            {row?._PedidoStatus.Descricao}
                        </TableCell>
                        <TableCell align="center">
                            {row.DataVenda ? formatDateSimples(row?.DataVenda) : formatDateSimples(row?.DataSolicitacao)}
                        </TableCell>
                        <TableCell align="left">
                            {row?._TipoEntrega.Descricao}
                        </TableCell>
                        <TableCell align="left">
                            {row?._TipoPagamento.Descricao}
                        </TableCell>
                        <TableCell align="left">
                            {row?.StatusPagamento}
                        </TableCell>
                        <TableCell align="left">
                            {formatCurrency(row?.ValorFrete)}
                        </TableCell>
                        <TableCell align="left">
                            {formatCurrency(row?.ValorEtiquetas)}
                        </TableCell>
                        <TableCell align="left">
                            {formatCurrency(row?.ValorTotal)}
                        </TableCell>
                        <TableCell align="left">
                            {formatDateSimples(row?.DataSolicitacao)}
                        </TableCell>
                        <TableCell align="center">
                            {row?.DataSolicitacaoDevolucao ? '-' : formatDateSimples(row?.DataSolicitacaoDevolucao)}
                        </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            )}
        </Stack>
    </div>);
}

export default DetalhesCompra;
