import { Box, Button, CircularProgress, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Modal, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import Card from "../../../components/Card";
import { AlertError, Container } from "./styles";
import { Fragment, useEffect, useState } from "react";
import { POST_DATA } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import CloseIcon from '@mui/icons-material/Close';
import { getStorage, setStorage } from "../../../hooks/useStorage";
import * as moment from "moment";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";
import { ImagemSemResultado, getYears, months, preventNonNumeric } from "../../../services/utils/functions";

import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { CreditCardOutlined } from "@mui/icons-material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import creditCardType from "credit-card-type";
import elo from '../../../assets/logos/elo.svg';
import visa from '../../../assets/logos/visa.svg';
import mastercard from '../../../assets/logos/mastercard.svg';
import hypercard from '../../../assets/logos/hipercard.svg';
import lasy from '../../../assets/logos/lasy.svg';


const InitialValue = {
    "IdUsuarioEmpresa": 0,
    "IdUsuario": 0,
    "IdUsuarioEmpresaPlano": 0,
    "NomeFantasia": "",
    "TipoEmpresa": "mei",
    "Cnpj": "",
    "Logradouro": "",
    "CEP": "",
    "Telefone": "",
    "Complemento": "",
    "Numero": "",
    "Bairro": "",
    "Cidade": "",
    "Estado": "",
    "FlgAtivo": true,
    "DataCadastro": "",
    "RazaoSocial": "",
    "FlgValidado": false,
    "iStatus": 1,
    "sPathInscricaoEstadual": "",
    "sPathContratoSocial": "",
    "sPathBanner": "",
    "sPathlogo": "",
    "FlgBrecho": false,
    "valorMinimoProduto": 0.0,
    "valorMaximoProduto": 0.0,
    "quantidadeEtapaPreco": 0,
    "qtdEmailsCarrinhosAbandonados": 0,
    "FotoCapaShopLeap": "",
    "Peso": 0,
    "QtdProdutosAVenda": 0,
    "QtdProdutosVendido": 0,
    "FlgSMS": false,
    "FlgWhatsApp": false,
    "contatoWhataapp": "",
    "contatoSms": "",
    "metaFaturamento": 1200,
    "metaVenda": 300,
    "FlgParcelamento": false,
    "qtdParcelamento": 0,
    "ConstrutorSite": null
}

const INITIAL_STATE_CREDIT_CARD = {
    Id: 0,
    IdUsuario: 0,
    IdUsuarioVendedor: 0,
    nameCard: null,
    numberCard: null,
    verification_value: null,
    month: null,
    year: null,
    IdIntegracaoPagamento: 0,
    brand: ''
};


const CardPlano = ({ id, tipoAssinatura, planoSelecionado, titulo, valor, contratado, handleDetalhar }) => {
    return <Card style={{ marginTop: 50, position: 'relative', minWidth: 150, height: tipoAssinatura != 1 ? 200 : 170, background: contratado ? '#fd6b03' : 'white' }}>

        <Box style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', height: 210, minHeight: 150, width: '100%', borderRadius: 20, marginTop: 0, textAlign: 'left' }}>
            <span style={{ width: '100%', height: 35, background: contratado ? '#f5f5f5' : '#fd6b03', color: contratado ? '#fd6b03' : 'white', borderRadius: 10, marginTop: -30, display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ fontSize: 20, fontWeight: 300 }}>{titulo}</Typography>
            </span>

            <Box style={{ width: '100%', marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ fontSize: 20, fontWeight: 'bold', color: contratado ? 'white' : 'black' }}>
                    {valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })}<span style={{ fontSize: 10 }}>/mês </span></Typography>
            </Box>

            {
                tipoAssinatura != 1 &&
                <Box style={{ width: '100%', marginTop: 5, display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontSize: 12, color: contratado ? 'white' : '#1f9c55', fontWeight: 'bold' }}>
                        Economize {((valor * 12) * (tipoAssinatura == 2 ? 0.25 : 0.35)).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })} ao ano
                    </Typography>
                </Box>
            }

            <Box style={{ width: '100%', height: tipoAssinatura != 1 ? '45%' : '75%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', flexDirection: 'column' }}>
                {
                    contratado && <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography onClick={handleDetalhar} style={{ width: 120, marginTop: 10, background: 'white', color: 'black', padding: '8px', borderRadius: 50, textAlign: 'center', fontSize: 18, fontWeight: 'bold', cursor: 'pointer', marginTop: 5 }}>Contratado</Typography>
                    </Box>
                }
                {
                    !contratado && <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography onClick={handleDetalhar} style={{ width: 120, background: id === planoSelecionado?.IdUsuarioEmpresaPlano ? '#fd6b03' : 'black', color: 'white', padding: '8px', borderRadius: 50, textAlign: 'center', fontSize: 18, fontWeight: 'bold', cursor: 'pointer', marginTop: 5 }}>Selecionar</Typography>
                    </Box>
                }
            </Box>
        </Box>
    </Card>
}

const columns = [
    { id: "numeroCobranca", label: "Número", align: 'center', minWidth: 100, maxWidth: 100 },
    { id: "dataCobranca", label: "Data Pagamento", align: 'center', minWidth: 100, maxWidth: 100 },
    { id: "status", label: "Status", align: 'center', minWidth: 120, maxWidth: 170 },
    { id: "download", label: "Download", align: 'center', minWidth: 120, maxWidth: 170 },
];

const AssinaturaLoja = () => {
    const dispatch = useDispatch();
    const [getConfirmationUpdate, ConfirmationUpdate] = useDialogConfirmationAsync();
    const [getConfirmationDowngrade, ConfirmationDowngrade] = useDialogConfirmationAsync();

    const [usuarioEmpresa, setUsuarioEmpresa] = useState(InitialValue);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [loading, setLoading] = useState(false);

    const [cobrancas, setCobrancas] = useState([]);
    const [loadingCobrancas, setLoadingCobrancas] = useState(false);

    const [mostrarAssinatura, setMostrarAssinatura] = useState(true);
    const [tabPlano, setTabPlano] = useState(1);
    const [open, setOpen] = useState(false);
    const [detalhesProdutos, setDetalhesProdutos] = useState([]);
    const [funcionalidadesPlano, setfuncionalidadePlanos] = useState([]);

    const [tipoAssinatura, setTipoAssinatura] = useState(1);
    const [controleTipoAssinatura, setControleTipoAssinatura] = useState(1);

    const [plano, setPlano] = useState(null);
    const [planoSelecionado, setPlanoSelecionado] = useState(null);
    const [planos, setPlanos] = useState([]);
    const estiloAssinaturaSelecionada = { background: '#fd6b03', color: 'white', padding: '8px', borderRadius: 50 };
    const estiloAssinaturaNaoSelecionada = { color: 'black', borderRadius: 50 };

    const [formaPagamento, setFormaPagamento] = useState(0);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [cpfValid, setCpfValid] = useState(false);
    const [cadastrarNovoCartao, setCadastrarNovoCartao] = useState(false);

    const [cartoes, setCartoes] = useState([{
        Id: 0,
        IdUsuario: 0,
        IdUsuarioVendedor: 0,
        nameCard: null,
        numberCard: '**** **** **** 1234',
        verification_value: null,
        month: 12,
        year: 31,
        IdIntegracaoPagamento: 0,
        brand: 'mastercard'
    }]);
    const [creditCardData, setCreditCardData] = useState(INITIAL_STATE_CREDIT_CARD);

    useEffect(() => {
        (async () => {
            if (!userLoggedIn) return;

            setLoading(true);

            const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
            const planosGerais = await POST_DATA(`UsuarioEmpresa/GetPlanosCNPJ`);
            const objFuncionalidadesPlanos = await POST_DATA(`FuncionalidadePlano/GetAllFuncionalidadePlano?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdFuncionalidadePlano`);
            const objFuncionalidadePlanoxUsuarioEmpresaPlano = await POST_DATA(`FuncionalidadePlanoxUsuarioEmpresaPlano/GetAllFuncionalidadePlanoxUsuarioEmpresaPlano?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdFuncionalidadePlanoxUsuarioEmpresaPlano`);

            setDetalhesProdutos(objFuncionalidadePlanoxUsuarioEmpresaPlano);
            setTipoAssinatura(userEmpresa.IdTipoAssinaturaPlano);
            setControleTipoAssinatura(userEmpresa.IdTipoAssinaturaPlano);

            planosGerais.map(plano => {
                const contratato = (plano.IdUsuarioEmpresaPlano === userEmpresa.IdUsuarioEmpresaPlano) || false;
                if (contratato)
                    setPlano(plano);
            });

            setPlanos(planosGerais);
            setUsuarioEmpresa(userEmpresa);
            setfuncionalidadePlanos(objFuncionalidadesPlanos);
            setLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn]);

    useEffect(() => {
        const tempProdutos = cobrancas?.map(cobranca => {
            return <Row cobranca={cobranca} />
        });

        const arr = [
            <Row cobranca={{
                Id: 1,
                DataCobranca: '10/10/2023',
                Status: 'Pago',
                Url: ''
            }} />,
            <Row cobranca={{
                Id: 2,
                DataCobranca: '10/12/2023',
                Status: 'Pago',
                Url: ''
            }} />,
            <Row cobranca={{
                Id: 3,
                DataCobranca: '10/01/2024',
                Status: 'Pago',
                Url: ''
            }} />,
            <Row cobranca={{
                Id: 4,
                DataCobranca: '10/02/2024',
                Status: 'Não pago',
                Url: ''
            }} />
        ]

        if (userLoggedIn?.IdUsuario === 677)
            setRows(arr);
    }, []);

    const handleContratarPlano = async (plano, tpAss = null) => {
        try {
            if (plano === null) return;

            setLoading(true);

            usuarioEmpresa.IdTipoAssinaturaPlano = tpAss !== null ? tpAss : tipoAssinatura;
            setControleTipoAssinatura(usuarioEmpresa.IdTipoAssinaturaPlano);
            usuarioEmpresa.IdUsuarioEmpresaPlano = plano.IdUsuarioEmpresaPlano;
            await POST_DATA(`UsuarioEmpresa/UpdateUsuarioEmpresaPlano`, usuarioEmpresa);

            const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
            setUsuarioEmpresa(userEmpresa);

            const usuarioLogado = JSON.parse(getStorage("usrin"));
            usuarioLogado.PlanoEmpresa = userEmpresa.UsuarioEmpresaPlano;
            usuarioLogado.TipoAssinaturaPlano = userEmpresa.TipoAssinaturaPlano;
            setStorage('usrin', JSON.stringify(usuarioLogado));

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao contratar o plano',
            });

            setLoading(false);
        }
        catch (err) {
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'error',
                titulo: 'Erro',
                message: 'Erro ao contratar o plano',
            });
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const Row = ({ cobranca }) => {
        return <Fragment key={cobranca.Id}>
            <TableRow>
                <TableCell align="center" style={{ minWidth: 100 }}>
                    {cobranca.Id}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                    {cobranca.DataCobranca}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                    {cobranca.Status}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                    <Button variant="text">Baixar</Button>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    const getCreditCardSvg = () => {
        if (creditCardData.numberCard) {
            const typeCard = creditCardType(creditCardData?.numberCard?.slice(0, 4));
            if (creditCardData?.numberCard.length >= 4 && typeCard[0]?.type) {
                switch (typeCard[0]?.type) {
                    case 'visa':
                        return <img style={{ width: 50 }} className="credit-card-svg" src={visa} />;
                    case 'mastercard':
                        return <img style={{ width: 50 }} className="credit-card-svg" src={mastercard} />;
                    case 'elo':
                        <img style={{ width: 50 }} className="credit-card-svg" src={elo} />;
                    case 'hipercard':
                        return <img style={{ width: 50 }} className="credit-card-svg" src={hypercard} />;
                    default:
                        return '';
                }
            }
            return '';
        }
        return '';
    };

    return (
        <Container>
            <ConfirmationUpdate
                title="Upgrade de Plano"
                mensagem={`Olá, você deseja fazer upgrade do seu plano [${plano?.Descricao}] para o plano [${planoSelecionado?.Descricao}]?`}
                subMensagem="Obs : A atualização de valores só ocorrerá no fechamento da sua próxima fatura."
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Contratar",
                    onClick: () => {
                        handleContratarPlano(planoSelecionado);
                        setOpen(false);
                    }
                }}
            />

            <ConfirmationDowngrade
                title="Downgrade de plano"
                mensagem={`Olá, você deseja fazer Downgrade do seu plano [${plano?.Descricao}] para o plano [${planoSelecionado?.Descricao}]?`}
                subMensagem="Obs : Com o downgrade vc não terá alguns recursos que possui hoje. O ajuste  de valores só ocorrerá no fechamento da sua próxima fatura."
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Contratar",
                    onClick: async () => {
                        handleContratarPlano(planoSelecionado);
                        setOpen(false);
                    }
                }}
            />

            <form>
                {
                    loading ? (
                        <Stack
                            sx={{ height: "150px", marginTop: '10%' }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>Carregando dados da empresa</Typography>
                        </Stack>
                    ) : (
                        <>
                            <Box style={{ width: "100%" }}>
                                <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Assinatura Loja</Typography>
                            </Box>
                            <Card style={{ marginTop: 10, paddingBottom: 50 }}>
                                <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Button variant="text" onClick={() => setTabPlano(1)} style={{ fontWeight: 'bold', background: tabPlano === 1 ? '#edd1e7' : null, marginRight: 3 }}>Assinatura</Button> | <Button variant="text" onClick={() => setTabPlano(2)} style={{ fontWeight: 'bold', background: tabPlano === 2 ? '#edd1e7' : null, marginLeft: 3 }}>Histórico de pagamentos</Button> 
                                            {/* | <Button variant="text" onClick={() => setTabPlano(3)} style={{ fontWeight: 'bold', background: tabPlano === 3 ? '#edd1e7' : null, marginLeft: 3 }}>Forma de pagamento</Button> */}
                                        </Box>
                                        <Typography style={{ fontSize: 16, maxWidth: 390, background: '#ebb0de', borderRadius: 10, marginTop: 10, padding: 10 }}> Data de cadastro {moment(usuarioEmpresa.DataCadastro).format('DD/MM/YYYY')} (45 dias de isenção)</Typography>
                                    </Box>

                                    {
                                        tabPlano === 1 &&
                                        <>
                                            <Grid item xs={12} style={{ textAlign: 'left', marginBottom: -20 }}>
                                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Planos</Typography>
                                                <Typography style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', fontSize: 16, fontWeight: 'bold', color: '#910050', marginTop: 10, cursor: 'pointer' }}>
                                                    <Button variant="text" onClick={async () => {
                                                        setTipoAssinatura(() => 1);
                                                        // await handleContratarPlano(plano, 1);
                                                    }}
                                                        style={tipoAssinatura === 1 ? estiloAssinaturaSelecionada : estiloAssinaturaNaoSelecionada}>
                                                        mensal
                                                    </Button> | <span style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Button variant="text"
                                                            onClick={async () => {
                                                                setTipoAssinatura(() => 2);
                                                                // await handleContratarPlano(plano, 2);
                                                            }}
                                                            style={tipoAssinatura === 2 ? estiloAssinaturaSelecionada : estiloAssinaturaNaoSelecionada}>semestral</Button>
                                                        <span style={{ fontSize: 11, background: '#1f9c55', color: 'white', padding: 5, borderRadius: 20, marginTop: -20, marginLeft: -30, zIndex: 9 }}>-25%</span>
                                                    </span> | <span style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Button variant="text"
                                                            onClick={async () => {
                                                                setTipoAssinatura(() => 3);
                                                                // await handleContratarPlano(plano, 3);
                                                            }}
                                                            style={tipoAssinatura === 3 ? estiloAssinaturaSelecionada : estiloAssinaturaNaoSelecionada}>anual</Button>
                                                        <span style={{ fontSize: 11, background: '#1f9c55', color: 'white', padding: 5, borderRadius: 20, marginTop: -20, marginLeft: -24, zIndex: 9 }}>-35%</span>
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            {
                                                planos.map(plano => {
                                                    const contratato = (tipoAssinatura === controleTipoAssinatura && plano.IdUsuarioEmpresaPlano === usuarioEmpresa.IdUsuarioEmpresaPlano) || false;
                                                    let valor = plano.ValorMensal;

                                                    if (tipoAssinatura === 2) {
                                                        valor = plano.ValorSemestral;
                                                    } else if (tipoAssinatura === 3) {
                                                        valor = plano.ValorAnual;
                                                    }

                                                    return <Grid key={plano.IdUsuarioEmpresaPlano} spacing={4} item xs={12} md={3} xl={1.5} style={{ minWidth: 180, maxWidth: 200 }}>
                                                        <CardPlano
                                                            id={plano.IdUsuarioEmpresaPlano}
                                                            titulo={plano.Descricao}
                                                            valor={valor}
                                                            tipoAssinatura={tipoAssinatura}
                                                            contratado={contratato}
                                                            planoSelecionado={planoSelecionado}
                                                            handleDetalhar={() => {
                                                                setPlanoSelecionado({ ...plano, contratado: contratato });
                                                                setOpen(true);
                                                            }}
                                                        />
                                                    </Grid>
                                                })
                                            }

                                            {
                                                open &&
                                                <Grid xs={12} style={{ width: '100%' }}>
                                                    <Box style={{ width: '100%', maxWidth: 785, border: '1px solid #e3e3e3', padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 20, marginLeft: 17, marginTop: 15 }}>
                                                        <Typography style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 20 }}>Comparativo de Planos</Typography>
                                                        <Grid container spacing={2} style={{ padding: 10, paddingLeft: 20 }}>
                                                            <Grid item xs={4} style={{ marginBottom: 10 }}>
                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold', lineHeight: '25px', textAlign: 'start' }}>Itens plano</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ marginBottom: 10 }}>
                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold', lineHeight: '25px', textAlign: 'center' }}>{plano?.Descricao} (Seu plano atual)</Typography>
                                                            </Grid>

                                                            {
                                                                !planoSelecionado.contratado &&
                                                                <Grid item xs={4} style={{ marginBottom: 10 }}>
                                                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', lineHeight: '25px', textAlign: 'center' }}>{planoSelecionado?.Descricao} (Novo plano)</Typography>
                                                                </Grid>
                                                            }
                                                            {
                                                                funcionalidadesPlano.map((item, index) => {
                                                                    const detalheItemPlanoAtual = detalhesProdutos.filter(a => a.IdFuncionalidadePlano == item.IdFuncionalidadePlano && a.IdUsuarioEmpresaPlano == plano.IdUsuarioEmpresaPlano)[0];
                                                                    const detalheItem = detalhesProdutos.filter(a => a.IdFuncionalidadePlano == item.IdFuncionalidadePlano && a.IdUsuarioEmpresaPlano == planoSelecionado.IdUsuarioEmpresaPlano)[0];
                                                                    let observacaoPlanoAtual = detalheItemPlanoAtual.Observacao;
                                                                    if (observacaoPlanoAtual !== null) {
                                                                        if (index == 6 && (detalheItem.IdUsuarioEmpresaPlano === 3 || detalheItem.IdUsuarioEmpresaPlano === 4))
                                                                            observacaoPlanoAtual = `Disparos ${observacaoPlanoAtual}s`
                                                                        else if (index == 6 && detalheItem.IdUsuarioEmpresaPlano !== 3 && detalheItem.IdUsuarioEmpresaPlano !== 4)
                                                                            observacaoPlanoAtual = `${observacaoPlanoAtual} disparos`
                                                                        else
                                                                            observacaoPlanoAtual = observacaoPlanoAtual.charAt(0).toUpperCase() + observacaoPlanoAtual.slice(1)
                                                                    }

                                                                    let observacaoPlanoSelecionado = detalheItem.Observacao;
                                                                    if (observacaoPlanoSelecionado !== null) {
                                                                        if (index == 6 && (detalheItem.IdUsuarioEmpresaPlano === 3 || detalheItem.IdUsuarioEmpresaPlano === 4))
                                                                            observacaoPlanoSelecionado = `Disparos ${observacaoPlanoSelecionado}s`
                                                                        else if (index == 6 && detalheItem.IdUsuarioEmpresaPlano !== 3 && detalheItem.IdUsuarioEmpresaPlano !== 4)
                                                                            observacaoPlanoSelecionado = `${observacaoPlanoSelecionado} disparos`
                                                                        else
                                                                            observacaoPlanoSelecionado = observacaoPlanoSelecionado.charAt(0).toUpperCase() + observacaoPlanoSelecionado.slice(1)
                                                                    }

                                                                    return <div key={item.IdFuncionalidadePlano} style={{ marginBottom: 10, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Grid item xs={4}>
                                                                            <Typography style={{ fontSize: 15, fontWeight: 400, lineHeight: '25px', textAlign: 'start' }}>{item.Nome}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            {
                                                                                detalheItemPlanoAtual.FlagContempla && detalheItemPlanoAtual.Observacao === null && <CheckCircleOutlineIcon />
                                                                            }
                                                                            {
                                                                                !detalheItemPlanoAtual.FlagContempla && <HighlightOffIcon />
                                                                            }
                                                                            {
                                                                                detalheItemPlanoAtual.FlagContempla && detalheItemPlanoAtual.Observacao !== null && <Typography>{observacaoPlanoAtual}</Typography>
                                                                            }
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            {
                                                                                !planoSelecionado.contratado &&
                                                                                <>
                                                                                    {
                                                                                        detalheItem.FlagContempla && detalheItem.Observacao === null && <CheckCircleOutlineIcon />
                                                                                    }
                                                                                    {
                                                                                        !detalheItem.FlagContempla && <HighlightOffIcon />
                                                                                    }
                                                                                    {
                                                                                        detalheItem.FlagContempla && detalheItem.Observacao !== null && <Typography>{observacaoPlanoSelecionado}</Typography>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </Grid>
                                                                    </div>
                                                                })
                                                            }
                                                        </Grid>

                                                        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                                            {
                                                                !planoSelecionado.contratado &&
                                                                <Typography onClick={() => {
                                                                    if (planoSelecionado.IdUsuarioEmpresaPlano < plano.IdUsuarioEmpresaPlano)
                                                                        getConfirmationDowngrade();
                                                                    else
                                                                        getConfirmationUpdate();
                                                                }}
                                                                    style={{ width: 120, marginRight: 68, marginBottom: 10, background: 'black', color: 'white', padding: '8px', borderRadius: 50, textAlign: 'center', fontSize: 18, fontWeight: 'bold', cursor: 'pointer', marginTop: 5 }}>Contratar</Typography>
                                                            }
                                                        </Box>
                                                        <Divider style={{ width: '100%' }} />
                                                        <Button variant="text" style={{ marginTop: 10 }} onClick={() => setOpen(false)}>Fechar</Button>
                                                    </Box>
                                                </Grid>
                                            }
                                        </>
                                    }

                                    {
                                        tabPlano === 2 &&
                                        <Grid spacing={4} item xs={12}>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Histórico de pagamentos</Typography>
                                            </Box>
                                            <Box>
                                                {
                                                    loadingCobrancas ?
                                                        (
                                                            <Stack
                                                                sx={{ height: "150px" }}
                                                                direction="column"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <CircularProgress />
                                                                <Typography sx={{ mt: 1 }}>Carregando cobranças</Typography>
                                                            </Stack>
                                                        ) : (
                                                            <>
                                                                <TableContainer>
                                                                    <Table stickyHeader aria-label="sticky table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {columns.map((column) => {
                                                                                    return (

                                                                                        <TableCell
                                                                                            key={column.id}
                                                                                            align={column.align}
                                                                                            style={{
                                                                                                minWidth: column.minWidth,
                                                                                                maxWidth: column.maxWidth,
                                                                                            }}
                                                                                        >
                                                                                            {column.label}
                                                                                        </TableCell>
                                                                                    )
                                                                                })}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {rows
                                                                                .map(row => {
                                                                                    return row;
                                                                                })}
                                                                        </TableBody>
                                                                    </Table>
                                                                    {rows.length === 0 && (
                                                                        <Stack
                                                                            sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                                                            direction="column"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                                                            <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                                                                Nenhum resultado encontrado.
                                                                            </Typography>
                                                                        </Stack>
                                                                    )}
                                                                </TableContainer>
                                                                <TablePagination
                                                                    rowsPerPageOptions={[10, 25, 100]}
                                                                    component="div"
                                                                    count={rows.length}
                                                                    rowsPerPage={rowsPerPage}
                                                                    page={page}
                                                                    labelRowsPerPage="Produtos por página"
                                                                    onPageChange={handleChangePage}
                                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                                />
                                                            </>
                                                        )
                                                }
                                            </Box>
                                        </Grid>
                                    }

                                    {
                                        tabPlano === 3 &&
                                        <>
                                            <Grid item xs={12} style={{ textAlign: 'left', marginBottom: -20 }}>
                                                <Card style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row' }}>
                                                    <FormControl style={{ width: 500 }}>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue={formaPagamento}
                                                            name="radio-buttons-group"
                                                        >
                                                            <FormControlLabel value={1} onClick={() => setFormaPagamento(1)} control={<Radio />} label={<Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <CreditCardIcon style={{ fontSize: 50 }} />
                                                                <Typography style={{ marginLeft: 10 }}>Cartão de crédito</Typography>
                                                                <Button variant="contained" onClick={() => setCadastrarNovoCartao(true) } style={{ background: 'black', color: 'white', marginLeft: 10, width: 90 }}>Adicionar</Button>
                                                            </Box>} />
                                                            <FormControlLabel value={2} onClick={() => setFormaPagamento(2)} control={<Radio />} label={<Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <PixIcon style={{ fontSize: 50 }} />
                                                                <Typography style={{ marginLeft: 10 }}>Pix</Typography>
                                                            </Box>} />
                                                            <FormControlLabel value={3} onClick={() => setFormaPagamento(3)} control={<Radio />} label={<Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <ReceiptIcon style={{ fontSize: 50 }} />
                                                                <Typography style={{ marginLeft: 10 }}>Boleto</Typography>
                                                            </Box>} />
                                                        </RadioGroup>

                                                    </FormControl>
                                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: 500, maxWidth: 800, width: '100%' }}>
                                                        {
                                                            formaPagamento === 1 &&
                                                            <>
                                                                <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Cartõe(s) cadastrado(s)</Typography>

                                                                    {
                                                                        cartoes.map(cartao => {
                                                                            return (
                                                                                <>
                                                                                    <Box
                                                                                        key={cartao.Id}
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            alignItems: "center",
                                                                                            width: "100%",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <Radio
                                                                                            value={cartao.Id}
                                                                                            name="radio-buttons"
                                                                                        />
                                                                                        {cartao?.brand?.toLowerCase() === "mastercard" ? (
                                                                                            <img style={{ width: 60 }} className="credit-card-svg" src={mastercard} />
                                                                                        ) : cartao?.brand?.toLowerCase() === "visa" ? (
                                                                                            <img style={{ width: 60 }} className="credit-card-svg" src={visa} />
                                                                                        ) : cartao?.brand?.toLowerCase() === "elo" ? (
                                                                                            <img style={{ width: 60 }} className="credit-card-svg" src={elo} />
                                                                                        ) : (
                                                                                            <CreditCardOutlined style={{ fontSize: 30 }} />
                                                                                        )}

                                                                                        <Typography>{cartao.numberCard}</Typography>
                                                                                        <Button variant="contained" onClick={() => console.log('Removido')} style={{ width: 85, background: 'black', color: 'white', marginLeft: 15 }}>Remover</Button>
                                                                                    </Box>
                                                                                </>
                                                                            );
                                                                        })
                                                                    }
                                                                </Card>

                                                                {
                                                                    cadastrarNovoCartao &&
                                                                    <Card style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                                        <Typography style={{ marginBottom: 1, fontWeight: 'bold' }}>Novo cartão</Typography>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="input-field"
                                                                            id="numero-cartao"
                                                                            sx={{ marginTop: 1 }}
                                                                            InputProps={{
                                                                                endAdornment: cpfValid && (
                                                                                    <DoneAllIcon style={{ color: "green" }} />
                                                                                ),
                                                                            }}
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            variant="outlined"
                                                                            label="CPF"
                                                                            onKeyPress={preventNonNumeric}
                                                                        ></TextField>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="input-field"
                                                                            id="numero-cartao"
                                                                            variant="outlined"
                                                                            label="Número do cartão"
                                                                            onKeyPress={preventNonNumeric}
                                                                            sx={{ marginTop: 1 }}
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            InputProps={{
                                                                                startAdornment: getCreditCardSvg(),
                                                                                style: { fontSize: 14 },
                                                                            }}
                                                                            onChange={(e) =>
                                                                                setCreditCardData({
                                                                                    ...creditCardData,
                                                                                    numberCard: e.target.value,
                                                                                })
                                                                            }
                                                                        ></TextField>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="input-field"
                                                                            id="nome-cartao"
                                                                            sx={{ marginTop: 1 }}
                                                                            InputProps={{ style: { fontSize: 14 } }}
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            variant="outlined"
                                                                            label="Nome Completo"
                                                                            onChange={(e) =>
                                                                                setCreditCardData({
                                                                                    ...creditCardData,
                                                                                    nameCard: e.target.value,
                                                                                })
                                                                            }
                                                                        ></TextField>
                                                                        <Stack direction="row" style={{ width: '100%' }}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant="body2" sx={{ marginTop: 1, marginBottom: -1, fontWeight: 'bold' }}>Data de Expiração</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={3}>
                                                                                    <Typography variant="body2" sx={{ mx: 1 }}>
                                                                                        Mês
                                                                                    </Typography>
                                                                                    <Select
                                                                                        fullWidth
                                                                                        style={{ width: '100%' }}
                                                                                        InputProps={{
                                                                                            startAdornment: getCreditCardSvg(),
                                                                                            style: { fontSize: 14 },
                                                                                        }}
                                                                                        labelId="demo-simple-select-label"
                                                                                        className="input-field expiration-date"
                                                                                        id="select-month"
                                                                                        onChange={(e) =>
                                                                                            setCreditCardData({
                                                                                                ...creditCardData,
                                                                                                month: Number(e.target.value),
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        {months.map((month) => (
                                                                                            <MenuItem value={month}>
                                                                                                {month < 10 ? `0${month}` : month}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={3}>
                                                                                    <Typography variant="body2" sx={{ mx: 1 }}>
                                                                                        Ano
                                                                                    </Typography>
                                                                                    <Select
                                                                                        fullWidth
                                                                                        style={{ width: '100%' }}
                                                                                        InputProps={{
                                                                                            startAdornment: getCreditCardSvg(),
                                                                                            style: { fontSize: 14 },
                                                                                        }}
                                                                                        labelId="demo-simple-select-label"
                                                                                        className="input-field expiration-date"
                                                                                        sx={{ input: { color: "white" }, width: '100%' }}
                                                                                        id="select-year"
                                                                                        onChange={(e) =>
                                                                                            setCreditCardData({
                                                                                                ...creditCardData,
                                                                                                year: Number(e.target.value),
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        {getYears(12).map((year) => (
                                                                                            <MenuItem value={Number(String(year).slice(-2))}>
                                                                                                {year}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={3}>
                                                                                    <Typography variant="body2" sx={{ mx: 1 }}>
                                                                                        Código de Verificação
                                                                                    </Typography>
                                                                                    <TextField
                                                                                        id="verif-code"
                                                                                        style={{ width: '100%' }}
                                                                                        InputProps={{
                                                                                            startAdornment: getCreditCardSvg(),
                                                                                            style: { fontSize: 14 },
                                                                                        }}
                                                                                        onInput={(e) => {
                                                                                            e.target.value = e.target.value.replace(/([^\d])+/gim, '').slice(0, 3);
                                                                                        }}
                                                                                        className="input-field expiration-date"
                                                                                        variant="outlined"
                                                                                        onKeyPress={preventNonNumeric}
                                                                                        onChange={(e) =>
                                                                                            setCreditCardData({
                                                                                                ...creditCardData,
                                                                                                verification_value: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Button variant="contained" style={{ background: 'black', color: 'white' }}>Cadastrar</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Stack>
                                                                        
                                                                        <Button variant="text" onClick={() => setCadastrarNovoCartao(false)} style={{ marginTop: 10 }}>Fechar</Button>
                                                                    </Card>
                                                                }
                                                            </>
                                                        }
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        </>
                                    }
                                </Grid>

                            </Card>
                        </>
                    )
                }
            </form>
        </Container >
    )
}

export default AssinaturaLoja;