import { Avatar, Chip, ListItemAvatar, ListItemText, Tooltip, Typography, useTheme } from '@mui/material';
import { Indicador } from './styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { calcularPorcentagem } from '../../services/utils/functions';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useNavigate } from 'react-router';

const IndicadoresListItem = ({
    icon,
    indicadorExibido,
    indicadorComparativo,
    indicadorTitulo,
    link,
    valor,
    isLoading,
  }) => {
    const themeProvider = useTheme();
    const navigate = useNavigate();
    const indicadores = useSelector((store) => store.indicadores);
    const [porcentagemValue, setPorcentagemValue] = useState(0);

    useEffect(() => {        
        if (indicadores) {
            const res = calcularPorcentagem(
                indicadores[indicadorExibido],
                indicadores[indicadorComparativo]
            ) || 0;
          setPorcentagemValue(res);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indicadores]);

    const tooltipTitle = `Houve um ${porcentagemValue >= 0 ? 'crescimento' : 'decréscimo'} de ${porcentagemValue}% em relação ao período passado.`;
    return (
        <>
            <Indicador onClick={() => navigate(link, { replace: true })}>
                <ListItemAvatar>
                    <Avatar style={{ background: '#57B528', color: 'white', width: 55, height: 55, marginRight: 10 }}>{icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{
                        fontSize: "25px",
                        color: themeProvider.palette.mode === 'light' ? "white" : "black",
                    }}
                    secondaryTypographyProps={{ fontSize: "20px" }}
                    primary={indicadorTitulo}
                    secondary={
                        valor ? valor : indicadores ? indicadores[indicadorExibido] || 0 : "Carregando..."
                    }
                />
                {
                    /* 
                    <Tooltip style={{ marginLeft: 20 }} title={tooltipTitle}>
                        <Chip
                            //icon={porcentagemValue >=0 ? <TrendingUpIcon style={{ color: 'white' }} /> : <TrendingDownIcon style={{ color: 'white' }} />}
                            icon={<TrendingUpIcon style={{ color: 'white' }} />}
                            label={`${porcentagemValue < 0 ? 0 : porcentagemValue.toFixed()}%`}
                            sx={{
                                ml: 4,
                                backgroundColor: "#57B528",
                                color: "#ffff",
                            }}
                        />
                    </Tooltip>
                    */
                }
            </Indicador>
        </>
    );

}

export default IndicadoresListItem;
