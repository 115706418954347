import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AddShoppingCartTwoTone,
  CancelTwoTone,
  ContentPasteSearchTwoTone,
  DomainVerificationTwoTone,
  LocalShippingTwoTone,
  RoomTwoTone
} from "@mui/icons-material";
import {
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatDate } from '../../services/utils/functions';
import CheckIcon from '@mui/icons-material/Check';

const StatusSteppers = ({ pedido, historicoStatusPedido }) => {
  const theme = useTheme();
  const [thirdStatus, setThirdStatus] = useState({
    desc: "Aprovado",
    icon: <DomainVerificationTwoTone />,
  });

  const [activeStep, setActiveStep] = useState(0);
  const isDarkMode = theme?.palette?.mode !== 'light';

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: isDarkMode ? "gray" : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: isDarkMode ? "gray" : "white",
    zIndex: 1,
    color: "#fff",
    borderStyle: 'solid',
    borderColor: 'rgb(242,113,33)',
    marginTop: 5,
    width: 35,
    height: 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...((ownerState.active || ownerState.completed) && {
      background: ownerState.cancelado ? 'rgb(242,113,33)' : 'rgb(32,171,75)',
      boxShadow: ownerState.cancelado ? '0 4px 10px 0 rgb(242,113,33)' : "0 4px 10px 0 rgb(32,171,75)",
      borderColor: ownerState.cancelado ? 'rgb(242,113,33)' : 'rgb(32,171,75)',
    }),
    ...(ownerState.icon === 1 && {
      background: 'rgb(32,171,75)',
      borderColor: 'rgb(32,171,75)',
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
  }));

  const getCurrentStep = () => {
    let numberOfStepsInHistorico = historicoStatusPedido.length;
    setActiveStep(numberOfStepsInHistorico);
  };

  useEffect(() => {
    getCurrentStep();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido, historicoStatusPedido]);

  function ColorlibStepIcon(props) {
    const { active, error, completed, className, icon } = props;
    //const icons = { 1: <CheckIcon style={{ fontSize: 30 }} /> };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, icon, cancelado: error }}
        className={className}
      >
        {(active || completed) && <CheckIcon style={{ fontSize: 30 }} />}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      {pedido && historicoStatusPedido ? (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          <Step key={"pedido-solicitado-stepper"}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              Pedido solicitado
            </StepLabel>
            <Typography
              variant="subtitle2"
              textAlign="center"
              sx={{ color: "gray" }}
            >
              {formatDate(pedido.DataCadastro)}
            </Typography>
          </Step>

          {historicoStatusPedido.map((status) => (
            <Step key={status.IdPedidoStatusHistorico}>
              <StepLabel
                error={status._PedidoStatus.IdStatus === 6 || status._PedidoStatus.IdStatus === 7 || status._PedidoStatus.IdStatus === 8 || status._PedidoStatus.IdStatus === 12}
                StepIconComponent={ColorlibStepIcon}
              >
                {status._PedidoStatus.Descricao !== 'Cancelado Robô' && status._PedidoStatus.Descricao}
                {status._PedidoStatus.Descricao === 'Cancelado Robô' && 'Cancelado Inatividade'}
              </StepLabel>
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{ color: "gray" }}
              >
                {formatDate(status.DataCadastro)}
              </Typography>
            </Step>
          ))}

          {
            pedido.IdStatusPedido !== 4 && historicoStatusPedido.filter(a => a._PedidoStatus.IdStatus === 11).length === 0 && //Em Preparação
            <Step key={"pedido-em-preparacao-stepper"}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>Em Preparação</StepLabel>
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{ color: "gray" }}
              >
                {pedido.DataEntregue ? formatDate(pedido.DataEntregue) : "-"}
              </Typography>
            </Step>
          }

          {
            pedido.IdStatusPedido !== 4 && historicoStatusPedido.filter(a => a._PedidoStatus.IdStatus === 3).length === 0 && //Encaminhado para entrega
            <Step key={"pedido-a-caminho-stepper"}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>Encaminhado para entrega</StepLabel>
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{ color: "gray" }}
              >
                {pedido.DataEntregue ? formatDate(pedido.DataEntregue) : "-"}
              </Typography>
            </Step>
          }

          {
            pedido.IdStatusPedido !== 4 &&
            <Step key={"pedido-entregue-stepper"}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>Entregue</StepLabel>
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{ color: "gray" }}
              >
                {pedido.DataEntregue ? formatDate(pedido.DataEntregue) : "-"}
              </Typography>
            </Step>
          }

        </Stepper>
      ) : (
        <CircularProgress />
      )}
      {pedido._PedidoStatus.IdStatus > 5 && (
        <Alert severity="error" color="error" variant="outlined">
          <AlertTitle>Atenção!</AlertTitle>O pedido não foi entregue. Pedido
          marcado como <strong>{pedido._PedidoStatus.Descricao}</strong>.
        </Alert>
      )}
    </Stack>
  );
};

export default StatusSteppers;
