import { Typography } from '@material-ui/core';
import { Label } from '@mui/icons-material';
import { Box, styled } from '@mui/material';


export const Container = styled(Box)`
`;

export const Title = styled(Typography)`
    color: #757575;
    font-size: 20px !important;
    font-weight: bold;
`;

export const Descricao = styled(Typography)`
    color: rgb(247, 147, 30);
    font-size: 30px !important;
    font-weight: 700 !important;
    padding-top: 10px;
`;

export const Circle = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    padding: 10px;

    background-size: 100% 100%, 100% 50%,100% 100%, 100% 70%;
    background-repeat: no-repeat;
    background-image: linear-gradient(white, white),
    linear-gradient(360deg, #f1416d 100%, lightgrey 100%),
    linear-gradient(360deg, rgb(247, 147, 30) 100%, lightgrey 100%);
    background-position: center center, left top, right top, left bottom, right bottom;
    background-origin: content-box, border-box, border-box, border-box, border-box;
    background-clip: content-box, border-box, border-box, border-box, border-box;

    border: 6px solid transparent;
    color: #000;
    text-align: center;
    font: 32px Arial, sans-serif;
`;