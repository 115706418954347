import { Box, Button, ButtonGroup, CircularProgress, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Typography } from "@mui/material";
import Card from "../../../components/Card";
import Countdown, { zeroPad } from "react-countdown";
import * as Yup from 'yup';
import { AlertError, Container, Descricao, Title } from "./styles";
import { useNavigate } from "react-router";
import { Fragment, useEffect, useRef, useState } from "react";
import { POST_DATA,  PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../ui-component/Loader";
import { useFormik } from "formik";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import * as moment from "moment";
import { ImagemSemResultado, atualizarOnboardStore, bancos, isEmail, valida_cpf_cnpj } from "../../../services/utils/functions";
import LoadingAllPage from "../../../components/LoadingAllPage";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

const InitialValus = {
    "IdUsuarioDadosBancarios": 0,
    "IdUsuario": 0,
    "NomeResponsavel": "",
    "IdBanco": 0,
    "Agencia": "",
    "Conta": "",
    "TipoConta": "Tipo Conta",
    "TipoPix": "CPF",
    "ChavePix": "",
    "FlgAtivo": true,
    "FlgSubcontaAsaas": false,
    "FlgPix": false,
    "IdIncluidoPor": 0,
    "IdAlteradoPor": 0
}

const taxasAsaasFixed = {
    "payment": {
        "bankSlip": {
            "defaultValue": 1.99,
            "discountValue": 0.99,
            "expirationDate": "2024-03-01 00:00:00",
            "daysToReceive": 1
        },
        "creditCard": {
            "operationValue": 0.49,
            "oneInstallmentPercentage": 2.99,
            "upToSixInstallmentsPercentage": 3.49,
            "upToTwelveInstallmentsPercentage": 3.99,
            "discountOneInstallmentPercentage": 1.99,
            "discountUpToSixInstallmentsPercentage": 2.49,
            "discountUpToTwelveInstallmentsPercentage": 2.99,
            "hasValidDiscount": false,
            "daysToReceive": 32,
            "discountExpiration": "2024-03-01 00:00:00"
        },
        "debitCard": {
            "operationValue": 0.35,
            "defaultPercentage": 1.89,
            "daysToReceive": 3
        },
        "pix": {
            "fixedFeeValue": 1.99,
            "fixedFeeValueWithDiscount": 0.99,
            "percentageFee": null,
            "minimumFeeValue": null,
            "maximumFeeValue": null,
            "discountExpiration": "2024-03-01 00:00:00",
            "type": "FIXED",
            "monthlyCreditsWithoutFee": 0,
            "creditsReceivedOfCurrentMonth": 0
        }
    },
    "transfer": {
        "monthlyTransfersWithoutFee": 30,
        "ted": {
            "feeValue": 5.00,
            "consideredInMonthlyTransfersWithoutFee": false
        },
        "pix": {
            "feeValue": 2.00,
            "discountValue": null,
            "expirationDate": null,
            "consideredInMonthlyTransfersWithoutFee": true
        }
    },
    "asaasCard": {
        "debit": {
            "requestFeeValue": 0.00,
            "deniedReasons": null,
            "nationalCashWithdrawalFeeValue": 7.60,
            "internationalCashWithdrawalProcessingFeePercentage": 0.745,
            "internationalCashWithdrawalExchangeFeeValue": 1.99,
            "internationalPurchaseWithdrawalFeePercentage": 5.38
        },
        "prepaid": {
            "requestFeeValue": 0.00,
            "deniedReasons": null,
            "nationalCashWithdrawalFeeValue": 7.60,
            "internationalCashWithdrawalProcessingFeePercentage": 0.745,
            "internationalCashWithdrawalExchangeFeeValue": 1.99,
            "internationalPurchaseWithdrawalFeePercentage": 5.38
        },
        "credit": {
            "requestFeeValue": 0.00,
            "deniedReasons": null,
            "nationalCashWithdrawalFeeValue": 7.60,
            "internationalCashWithdrawalProcessingFeePercentage": 0.745,
            "internationalCashWithdrawalExchangeFeeValue": 1.99,
            "internationalPurchaseWithdrawalFeePercentage": 5.38
        }
    },
    "notification": {
        "phoneCallFeeValue": 0.55,
        "whatsAppFeeValue": 0.55,
        "messagingFeeValue": 0.99,
        "postalServiceFeeValue": 2.91,
        "smsFeeValue": 0.50
    },
    "creditBureauReport": {
        "naturalPersonFeeValue": 16.99,
        "legalPersonFeeValue": 16.99
    },
    "invoice": {
        "feeValue": 0.99
    },
    "anticipation": {
        "creditCard": {
            "detachedMonthlyFeeValue": 2.00,
            "installmentMonthlyFeeValue": 2.49
        },
        "bankSlip": {
            "monthlyFeePercentage": 5.79
        }
    },
    "bill": {
        "utilityFeeValue": 1
    },
    "childAccount": {
        "creationFeeValue": 12.90
    }
}

const columns = [
    { id: "id", label: "Id", minWidth: 150, maxWidth: 200 },
    { id: "data", label: "Data", minWidth: 150, maxWidth: 200 },
    { id: "valor", label: "Valor", minWidth: 150, maxWidth: 200 },
    { id: "operacao", label: "Oepração", minWidth: 150, maxWidth: 200 },
    { id: "status", label: "Status", minWidth: 150, maxWidth: 200 },
    { id: "comprovante", label: "Comprovante", minWidth: 150, maxWidth: 200 }
];

const Row = ({ transferencia }) => {
    return <Fragment key={transferencia.IdAvaliacaoPedido}>
        <TableRow>
            <TableCell align="left" style={{ minWidth: 100 }}>
                {transferencia.id}
            </TableCell>
            <TableCell align="left" style={{ minWidth: 100 }}>
                {moment(transferencia?.effectiveDate).format('DD/MM/YYYY HH:mm:ss')}
            </TableCell>
            <TableCell align="left" style={{ minWidth: 100 }}>
                {transferencia.netValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}
            </TableCell>
            <TableCell align="left" style={{ minWidth: 100 }}>
                {transferencia.operationType}
            </TableCell>
            <TableCell align="left" style={{ minWidth: 100 }}>
                {transferencia.status}
            </TableCell>
            <TableCell align="left" style={{ minWidth: 100 }}>
                <Button variant="contained" onClick={() => window.open(transferencia.transactionReceiptUrl, '_blank')}>Comprovante</Button>
            </TableCell>
        </TableRow>
    </Fragment>
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const DadosBancariosMarketplace = ({ mostrarDadosAsaas = true }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const onBoard = useSelector((store) => store.onBoard);
    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

    const [possuiIntegracaoDireta, setPossuiIntegracaoDireta] = useState(false);
    const [taxasAsaas, setTaxasAsaas] = useState(taxasAsaasFixed);

    const [dadosBancarios, setDadosBancarios] = useState(InitialValus);
    const [dadosUsuarioLoja, setDadosUsuarioLoja] = useState(null);
    const [dadosSubcontaAsaas, setDadosSubcontaAsaas] = useState(null);
    const [documentosSubcontaAsaas, setDocumentosSubcontaAsaas] = useState(null);
    const [saldoSubcontaAsaas, setSaldoSubcontaAsaas] = useState(null);
    const [transferenciasSubcontaAsaas, setTransferenciasSubcontaAsaas] = useState([]);
    const [codigoBanco, setCodigoBanco] = useState('');

    const [salvando, setSalvando] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingAsaas, setLoadingAsaas] = useState(false);
    const [dataHoraGerarSubConta, setDataHoraGerarSubConta] = useState(null);
    const [textLoading, setTextLoading] = useState('Comunicando com o Asaas..');

    const [tabValue, setTabValue] = useState(0);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        (async () => {
            if (!userLoggedIn) return;

            setLoading(true);

            await fetchDadosBancarios();

            setLoading(false);
        })();

        if (mostrarDadosAsaas) {
            ref.current = setInterval(async () => {
                try {

                    if (formik.values?.FlgSubcontaAsaas) {
                        const resultDocumentosSubconta = await POST_DATA(`UsuarioDadosSubContaAsaas/ConsultaDocumentosSubContaByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}`);
                        setDocumentosSubcontaAsaas(resultDocumentosSubconta?.data?.documents || []);
                    }
                }
                catch { }
            }, 10000);

            return () => {
                if (ref.current) {
                    clearInterval(ref.current);
                }
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn]);

    useEffect(() => {
        const tmpTransferencia = transferenciasSubcontaAsaas?.map(transferencia => {
            return <Row transferencia={transferencia} />
        });

        setRows(tmpTransferencia || []);
    }, [transferenciasSubcontaAsaas]);

    const fetchDadosBancarios = async () => {
        const resultDadosBancario = await POST_DATA(`UsuarioDadosBancario/GetUsuarioDadosBancarioByIdPessoa?IdPessoa=${userLoggedIn.IdUsuario}`);
        if (resultDadosBancario !== null && resultDadosBancario !== undefined) {
            if (resultDadosBancario.IdUsuarioDadosBancarios > 0) {
                const banco = bancos.filter(a => a.id === resultDadosBancario.IdBanco)[0];
                setCodigoBanco(banco ? banco.codigo : '');
            }
        }

        if (resultDadosBancario !== null) {
            if (mostrarDadosAsaas)
                await fetchDadosSubContaAsaas(resultDadosBancario);
            else {
                setDadosBancarios({ ...resultDadosBancario, FlgSubcontaAsaas: false });
                formik.resetForm();
            }
        }
    }

    const fetchDadosSubContaAsaas = async (dadosBancarios) => {
        try {
            const integracaoAtual = await POST_DATA(`IntegracaoParametros/GetAllIntegracaoParametrosByIdIntegracaoTipoAndIdUsuario?IdIntegracaoTipo=3&IdUsuario=${userLoggedIn?.IdUsuario}`);
            setPossuiIntegracaoDireta(integracaoAtual.length > 0);

            const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
            setDadosUsuarioLoja(userEmpresa);

            const resultSubconta = await POST_DATA(`UsuarioDadosSubContaAsaas/ConsultaSubContaByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}`);
            setDadosSubcontaAsaas(resultSubconta);

            const resultSaldoSubconta = await POST_DATA(`UsuarioDadosSubContaAsaas/ConsultaSaldoSubContaByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}`);
            setSaldoSubcontaAsaas(resultSaldoSubconta?.data || null);

            const resultTransferenciasSubconta = await POST_DATA(`UsuarioDadosSubContaAsaas/ListTransferSubContaByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}`);
            setTransferenciasSubcontaAsaas(resultTransferenciasSubconta?.data?.data || []);

            try{
                const resultTaxas = await POST_DATA(`UsuarioDadosSubContaAsaas/ConsultaTaxasSubContaByIdUsuario?idUsuario=null`);
                setTaxasAsaas(resultTaxas.data);
            }
            catch { }

            try {
                const resultDocumentosSubconta = await POST_DATA(`UsuarioDadosSubContaAsaas/ConsultaDocumentosSubContaByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}`);
                setDocumentosSubcontaAsaas(resultDocumentosSubconta?.data?.documents || []);
            }
            catch { }

            setDadosBancarios({ ...dadosBancarios, FlgSubcontaAsaas: true });
            formik.resetForm();
        }
        catch (err) {
            setDadosSubcontaAsaas(null);
            setDocumentosSubcontaAsaas([]);

            setDadosBancarios({ ...dadosBancarios, FlgSubcontaAsaas: true });
            formik.resetForm();

            if (err.message[0] !== 'Usuário sem subConta ASAAS.' && err.message[0] !== 'Não foi possível realizar a consulta.') {
                dispatch({
                    type: CONTROLEMENSAGEM_ERRO,
                    tipoComponente: 'alert',
                    titulo: err.titulo,
                    message: err.message[0],
                });
            }

        }
    }


    const validationSchema = Yup.object().shape({
        NomeResponsavel: Yup.string().required('*Favor informar o Nome do Responsável'),

        TipoConta: Yup.string().test(
            'validateTipoConta',
            '*Favor informar o tipo de conta',
            (tipoConta, context) => {
                return tipoConta !== "Tipo Conta";
            }
        ),

        TipoPix: Yup.string().test(
            'validateTipoPix',
            '*Favor informar o tipo de Pix',
            (tipoPix, context) => {
                if (!context.parent.FlgPix) return true;
                return tipoPix !== 'Tipo de pix';
            }
        ),

        ChavePix: Yup.string().test(
            'validateTipoPix',
            '*Favor informar uma chave Pix válida',
            (chavePix, context) => {
                if (!context.parent.FlgPix) return true;
                if ((context.parent.TipoPix === 'CPF' || context.parent.TipoPix === 'CNPJ') && chavePix?.toString().length >= 11) {
                    return valida_cpf_cnpj(chavePix);
                }
                else if ((context.parent.TipoPix === 'CPF' || context.parent.TipoPix === 'CNPJ')) {
                    return false;
                }

                if (context.parent.TipoPix === 'E-mail')
                    return chavePix && isEmail(chavePix?.toString());

                return chavePix && chavePix?.toString().length >= 5;
            }
        ),

        IdBanco: Yup.string().test(
            'validateIdBanco',
            '*Favor informar o banco da conta',
            (idBanco, context) => {
                return idBanco > 0;
            }
        ),

        Agencia: Yup.string().test(
            'validateAgendacia',
            '*Favor informar uma agência válida',
            (agencia, context) => {
                return agencia && agencia?.toString().length > 2;
            }
        ),

        Conta: Yup.string().test(
            'validateConta',
            '*Favor informar uma conta válida',
            (conta, context) => {
                return conta && conta?.toString().length > 2;
            }
        ),
    });

    const onSubmit = async (values) => {
        try {
            setSalvando(true);

            values.IdUsuario = userLoggedIn.IdUsuario;
            if (values.IdUsuarioDadosBancarios === 0) {
                values.IdIncluidoPor = userLoggedIn.IdUsuario;
            }
            else {
                values.IdAlteradoPor = userLoggedIn.IdUsuario;
            }

            await PUT_DATA('UsuarioDadosBancario/CadastroUsuarioDadosBancario', values);

            setSalvando(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar os dados bancários',
            });

            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 4)[0].FlagStatus) {
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 4;

                const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 4)[0];
                itemOnboard.FlagStatus = true;

                atualizarOnboardStore(objOnboard, itemOnboard);

                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });

                navigate('/on-board');
                return;
            }

            await fetchDadosBancarios();
        }
        catch (err) {
            setSalvando(false);
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err?.message,
            });
        }
    };

    const handleCreateSubcontaAsaas = async () => {

        if (dadosUsuarioLoja.Telefone === null || dadosUsuarioLoja.Telefone === '') {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: 'Favor informar o telefone de contato na configuração da empresa',
            });
            return;
        }

        setLoadingAsaas(true);
        setDataHoraGerarSubConta(Date.now() + 15000);

        try {
            const telefone = dadosUsuarioLoja.Telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
            const cnpj = dadosUsuarioLoja.Cnpj.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");
            const email = `${cnpj}@leapstyle.com`;
            //const email = userLoggedIn.Email;
            const obj = {
                "idUsuario": dadosUsuarioLoja.IdUsuario,
                "name": dadosUsuarioLoja.RazaoSocial,
                "email": email,
                "loginEmail": email,
                "cpfCnpj": cnpj,
                "phone": telefone,
                "mobilePhone": telefone,
                "incomeValue": 1000,
                "address": dadosUsuarioLoja.Logradouro,
                "addressNumber": dadosUsuarioLoja.Numero,
                "complement": dadosUsuarioLoja.Complemento,
                "province": dadosUsuarioLoja.Cidade,
                "postalCode": dadosUsuarioLoja.CEP,
                "companyType": dadosUsuarioLoja.TipoEmpresa,
                "incomeValue": 1000
            }
            await PUT_DATA('UsuarioDadosSubContaAsaas/CadastraSubConta', obj);
        }
        catch (err) {
            setLoadingAsaas(false);
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: err?.message,
                message: err.message[0]
            });
        }
    }

    const formik = useFormik({
        onSubmit,
        initialValues: dadosBancarios,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    useEffect(() => {
        //console.log(formik.values);
    }, [formik.values]);

    const styleItemGrid = { width: '100%' };


    const copyToClipboard = (content) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(content);
        }

        dispatch({
            type: CONTROLEMENSAGEM_SUCESSO,
            tipoComponente: 'alert',
            titulo: 'Link copiado com sucesso'
        });
    };

    const getTipoPix = () => {
        switch (dadosBancarios.TipoPix) {
            case 'CPF':
                return 'CPF';
            case 'CNPJ':
                return 'CNPJ';
            case 'E-mail':
                return 'EMAIL';
            case 'Telefone':
                return 'PHONE';
            case 'Aleatória':
                return 'EVP';
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleTraduzirStatus = (status) => {
        if (status === 'APPROVED') return 'APROVADO';
        else if (status === 'REJECTED') return 'REJEITADO';
        else if (status === 'PENDING') return 'PENDENTE';
        else if (status === 'NOT_SENT') return 'NÃO ENVIADO';

        return status;
    }

    return (
        <Container>
            {
                loadingAsaas &&
                <LoadingAllPage text={textLoading} />
            }

            {
                dataHoraGerarSubConta !== null &&
                <Countdown date={dataHoraGerarSubConta} renderer={({ hours, minutes, seconds, completed }) => {
                    setTextLoading(`Comunicando com o Asaas..   ${zeroPad(minutes)}:${zeroPad(seconds)}s`);
                }}
                    onComplete={async () => {
                        await fetchDadosBancarios();

                        setDataHoraGerarSubConta(null);
                        setLoadingAsaas(false);

                        dispatch({
                            type: CONTROLEMENSAGEM_SUCESSO,
                            tipoComponente: 'alert',
                            titulo: 'Sucesso',
                            message: 'Sucesso ao salvar os dados bancários',
                        });
                    }} />
            }

            <Confirmation
                title="Transferir valor via Pix?"
                mensagem={`Tem certeza que deseja transferir ${saldoSubcontaAsaas?.balance?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })} para o pix ${dadosBancarios.ChavePix}?`}
                btn1={{ title: "Não", onClick: null }}
                btn2={{
                    title: "Sim",
                    onClick: async () => {
                        try {
                            setLoadingAsaas(true);

                            const obj = {
                                "idUsuario": userLoggedIn.IdUsuario,
                                "value": saldoSubcontaAsaas?.balance,
                                "operationType": "PIX",
                                "pixAddressKey": dadosBancarios?.ChavePix,
                                "pixAddressKeyType": getTipoPix(),
                                "description": "Transferência saldo subconta Asaas"
                            };

                            await PUT_DATA(`UsuarioDadosSubContaAsaas/TransferSubContaByIdUsuario`, obj);
                            await fetchDadosSubContaAsaas();

                            dispatch({
                                type: CONTROLEMENSAGEM_SUCESSO,
                                tipoComponente: 'alert',
                                titulo: 'Transferência realizada com sucesso.'
                            });

                            setLoadingAsaas(false);
                        }
                        catch (err) {
                            setLoadingAsaas(false);
                            dispatch({
                                type: CONTROLEMENSAGEM_ERRO,
                                tipoComponente: 'alert',
                                titulo: `Ocorreu um erro ao tentar transferir o valor`,
                                message: 'Motivo: ' + err.message
                            });
                        }
                    }
                }}
            />

            <form onSubmit={formik.handleSubmit}>
                {
                    loading ? (
                        <Stack
                            sx={{ height: "150px", marginTop: '10%' }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>Carregando dados bancários</Typography>
                        </Stack>
                    ) : (
                        <>
                            <Box sx={{ width: "100%" }}>
                                <Box style={{ width: "100%" }}>
                                    <Typography style={{ fontWeight: 'bold' }}>Marketplace / Meio de Pagamento / Dados Bancários</Typography>
                                </Box>
                            </Box>
                            <Card style={{ marginTop: 10, paddingBottom: 50 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 20, paddingTop: 10 }}>
                                        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Dados para transferência de saldo</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            labelId="tamanho-selector"
                                            id="tamanho-selector-id"
                                            value={formik.values?.TipoConta || "Tipo Conta"}
                                            sx={{ width: '100%', textAlign: 'start' }}
                                            onChange={(e) => {
                                                formik.setFieldValue(`TipoConta`, e.target.value);
                                            }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: 300,
                                                },
                                            }}
                                        >
                                            <MenuItem value={"Tipo Conta"} disabled>Tipo Conta</MenuItem>
                                            <MenuItem value={'PF - Pessoa física'}>PF - Pessoa física</MenuItem>
                                            <MenuItem value={'PJ - Pessoa jurídica'}>PJ - Pessoa jurídica</MenuItem>
                                        </Select>
                                        <AlertError className="msg-error">{formik.errors.TipoConta}</AlertError>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField
                                            label={'Nome Responsável'}
                                            style={styleItemGrid}
                                            value={formik.values?.NomeResponsavel}
                                            onChange={(e) => formik.setFieldValue(`NomeResponsavel`, e.target.value)}
                                        />
                                        <AlertError className="msg-error">{formik.errors.NomeResponsavel}</AlertError>
                                    </Grid>


                                    <Grid item xs={1.5}>
                                        <Select
                                            labelId="tamanho-selector"
                                            id="tamanho-selector-id"
                                            value={formik.values?.IdBanco}
                                            sx={{ width: '100%', textAlign: 'start' }}
                                            onChange={(e) => {
                                                const id = Number(e.target.value);
                                                formik.setFieldValue(`IdBanco`, id);

                                                const banco = bancos.filter(a => a.id === id)[0];
                                                setCodigoBanco(banco ? banco.codigo : '');
                                            }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: 300,
                                                },
                                            }}
                                        >
                                            <MenuItem value={0} disabled>Nenhum(a)</MenuItem>
                                            {
                                                bancos.map((item, index) => {
                                                    return <MenuItem key={index} value={item.id}>{item.nome}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        <AlertError className="msg-error">{formik.errors.IdBanco}</AlertError>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField
                                            disabled
                                            label={'Código Banco'}
                                            style={styleItemGrid}
                                            value={codigoBanco}
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField
                                            label={'Agência'}
                                            style={styleItemGrid}
                                            value={formik.values?.Agencia}
                                            onChange={(e) => formik.setFieldValue(`Agencia`, e.target.value.replace(/([^\d])+/gim, ''))}
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.Agencia}</AlertError>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField
                                            label={'Conta'}
                                            style={styleItemGrid}
                                            value={formik.values?.Conta}
                                            onChange={(e) => formik.setFieldValue(`Conta`, e.target.value.replace(/([^\d])+/gim, ''))}
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.Conta}</AlertError>
                                    </Grid>

                                    <Grid item xs={12} md={1.5}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={
                                                <Switch
                                                    checked={formik.values?.FlgPix}
                                                    onChange={() => {
                                                        formik.setFieldValue(`FlgPix`, !formik.values?.FlgPix);
                                                    }} />}
                                            label="Pix?"
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <FormControl fullWidth>
                                            <InputLabel id="genero-selector">Tipo pix</InputLabel>
                                            <Select
                                                labelId="tamanho-selector"
                                                id="tamanho-selector-id"
                                                value={formik.values?.TipoPix || 0}
                                                sx={{ width: '100%', textAlign: 'start' }}
                                                label='Tipo pix'
                                                onChange={(e) => {
                                                    formik.setFieldValue(`TipoPix`, e.target.value);
                                                }}
                                                MenuProps={{
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                }}
                                            >
                                                <MenuItem value={'Tipo de pix'} disabled>Tipo de pix</MenuItem>
                                                <MenuItem value={'CPF'}>CPF</MenuItem>
                                                <MenuItem value={'CNPJ'}>CNPJ</MenuItem>
                                                <MenuItem value={'E-mail'}>E-mail</MenuItem>
                                                <MenuItem value={'Telefone'}>Telefone</MenuItem>
                                                <MenuItem value={'Aleatória'}>Aleatória</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <AlertError className="msg-error">{formik.errors.TipoPix}</AlertError>
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                            type={'text'}
                                            label={'Chave pix'}
                                            style={styleItemGrid}
                                            value={formik.values?.ChavePix}
                                            onChange={(e) => {
                                                if (formik.values.TipoPix == 'CPF' || formik.values.TipoPix == 'CNPJ' || formik.values.TipoPix == 'Telefone') {
                                                    formik.setFieldValue(`ChavePix`, e.target.value.replace(/([^\d])+/gim, ''))
                                                }
                                                else {
                                                    formik.setFieldValue(`ChavePix`, e.target.value)
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: (formik.values.TipoPix == 'CPF' || formik.values.TipoPix == 'Telefone') ? 11 : formik.values.TipoPix == 'CNPJ' ? 14 : null,
                                            }}
                                        />
                                        <AlertError className="msg-error">{formik.errors.ChavePix}</AlertError>
                                    </Grid>

                                    <Grid item xs={12} md={mostrarDadosAsaas ? 5.5 : 3.5}>
                                        {
                                            (mostrarDadosAsaas && formik.values.IdUsuarioDadosBancarios > 0) &&
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={
                                                    <Switch
                                                        checked={formik.values?.FlgSubcontaAsaas}
                                                        onChange={() => {
                                                            if (possuiIntegracaoDireta) {
                                                                dispatch({
                                                                    type: CONTROLEMENSAGEM_AVISO,
                                                                    tipoComponente: 'alert',
                                                                    titulo: 'Aviso',
                                                                    message: 'Não é possível habilitar conta pois já existe integração ativa!',
                                                                });
                                                                return;
                                                            }
                                                            formik.setFieldValue(`FlgSubcontaAsaas`, !formik.values?.FlgSubcontaAsaas);
                                                        }} />}
                                                label="Habilitar conta?"
                                            />
                                        }

                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={
                                                <Switch
                                                    checked={formik.values?.FlgAtivo}
                                                    onChange={() => {
                                                        formik.setFieldValue(`FlgAtivo`, !formik.values?.FlgAtivo);
                                                    }} />}
                                            label="Ativo?"
                                        />

                                        <Button
                                            disabled={salvando}
                                            variant="contained"
                                            color="primary"
                                            onClick={formik.submitForm}
                                        >
                                            {salvando && <Loader />}
                                            Salvar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{ width: '100%' }} />
                                    </Grid>

                                    {
                                        formik.values?.FlgSubcontaAsaas && mostrarDadosAsaas &&
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'start' }}>Conta integrada</Typography>
                                            <Tabs
                                                value={tabValue}
                                                onChange={handleChangeTab}
                                                aria-label="basic tabs example"
                                            >
                                                <Tab label="Conta integrada" {...a11yProps(0)} />
                                                <Tab label="Saldo" {...a11yProps(1)} />
                                                <Tab label="Transferências" {...a11yProps(2)} />
                                                <Tab label="Taxas" {...a11yProps(3)} />

                                            </Tabs>

                                            <TabPanel value={tabValue} index={0}>
                                                {
                                                    dadosSubcontaAsaas === null &&
                                                    <Box>
                                                        <Button variant="contained" onClick={() => handleCreateSubcontaAsaas()}>Integrar a conta</Button>
                                                    </Box>
                                                }
                                                {
                                                    dadosSubcontaAsaas !== null && documentosSubcontaAsaas.length > 0 &&
                                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Documentos necessários para habilitar subconta Asaas</Typography>
                                                        <Typography style={{ fontSize: 12 }}>Para enviar esse(s) documento(s), copie os links abaixo e acesse pelo seu celular.</Typography>
                                                        {
                                                            documentosSubcontaAsaas.map((doc, index) => {
                                                                return <Card key={index} style={{ width: 500, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
                                                                    <Box style={{ width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                        <Typography>Responsável: {doc.responsible.name} ({doc.responsible.type === 'PARTNER' ? 'PARCEIRO' : doc.responsible.type})</Typography>
                                                                        <Typography>Status: <span style={{ fontWeight: 'bold', color: doc.status === 'APPROVED' ? '#0c8821' : doc.status === 'REJECTED' ? '#d34747' : 'black' }}>{handleTraduzirStatus(doc.status)}</span></Typography>
                                                                        <Typography style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>{doc.title}</Typography>
                                                                        <a style={{ cursor: 'pointer', color: '#0030b9', marginTop: 10 }}>{doc.onboardingUrl}</a>
                                                                    </Box>
                                                                    {
                                                                        doc.status !== 'APPROVED' &&
                                                                        <Box style={{ width: '40%', height: 85, display: 'flex', alignItems: 'flex-end' }}>
                                                                            <Button variant="contained" style={{ background: 'black', fontSize: 12, marginRight: 5 }} onClick={() => window.open(doc.onboardingUrl, '_blank')} >Abrir</Button>
                                                                            <Button variant="contained" style={{ background: 'black', fontSize: 12 }} onClick={() => copyToClipboard(doc.onboardingUrl)} >Copiar</Button>
                                                                        </Box>
                                                                    }
                                                                </Card>
                                                            })
                                                        }
                                                        <Typography style={{ fontSize: 13, marginTop: 10, color: '#0030b9' }}>*Para a validação da sua subconta, é necessário realizar as validações acima.</Typography>
                                                    </Box>
                                                }
                                                {
                                                    dadosSubcontaAsaas !== null && !loadingAsaas && documentosSubcontaAsaas.length === 0 &&
                                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Documentos validados com sucesso!</Typography>
                                                    </Box>
                                                }
                                            </TabPanel>

                                            <TabPanel value={tabValue} index={1}>
                                                {
                                                    saldoSubcontaAsaas === null &&
                                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Sem dados a serem exibidos!</Typography>
                                                    </Box>
                                                }
                                                {
                                                    saldoSubcontaAsaas != null &&
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3}>
                                                            <Card style={{ minHeight: '80px !important' }}>
                                                                <Title>Valor em conta</Title>
                                                                <Descricao>{saldoSubcontaAsaas?.balance?.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })}
                                                                </Descricao>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <Card style={{ minHeight: '80px !important' }}>
                                                                <Title>Entrada</Title>
                                                                <Descricao>{saldoSubcontaAsaas?.income?.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })}
                                                                </Descricao>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <Card style={{ minHeight: '80px !important' }}>
                                                                <Title>Saída</Title>
                                                                <Descricao>{saldoSubcontaAsaas?.outcome?.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })}
                                                                </Descricao>
                                                            </Card>
                                                        </Grid>

                                                        <Grid item xs={12} md={3}>
                                                            <Button variant="contained" onClick={() => {
                                                                if (saldoSubcontaAsaas?.balance === 0) {
                                                                    dispatch({
                                                                        type: CONTROLEMENSAGEM_AVISO,
                                                                        tipoComponente: 'alert',
                                                                        titulo: 'Aviso',
                                                                        message: 'Valor precisa ser maior do que R$0,00.'
                                                                    });
                                                                    return;
                                                                }

                                                                getConfirmation();
                                                            }}>Transferir valor total para pix</Button>
                                                        </Grid>

                                                    </Grid>
                                                }
                                            </TabPanel>

                                            <TabPanel value={tabValue} index={2}>
                                                <TableContainer>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{
                                                                            minWidth: column.minWidth,
                                                                            maxWidth: column.maxWidth,
                                                                        }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows
                                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map(row => {
                                                                    return row;
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                    {rows.length === 0 && (
                                                        <Stack
                                                            sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                                            <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                                                Nenhum resultado encontrado.
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 100]}
                                                    component="div"
                                                    count={transferenciasSubcontaAsaas.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    labelRowsPerPage={"Avaliações por página"}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TabPanel>

                                            <TabPanel value={tabValue} index={3}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <Card style={{ marginTop: 10, marginLeft: 15, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', height: 160 }}>
                                                            <Typography style={{ color: '#0030b9', fontSize: 20, fontWeight: 'bold', marginBottom: 5 }}>Pix</Typography>
                                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 5 }}>{taxasAsaas.payment?.pix?.fixedFeeValue.toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })}</Typography>
                                                            <Typography style={{ color: 'gray', marginBottom: 5 }}>por transação recebida</Typography>
                                                        </Card>
                                                        <Card style={{ marginTop: 10, marginLeft: 15, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', height: 160 }}>
                                                            <Typography style={{ color: '#0030b9', fontSize: 20, fontWeight: 'bold', marginBottom: 5 }}>Cartão de débito</Typography>
                                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 5 }}>{taxasAsaas.payment.debitCard.operationValue.toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })} + {taxasAsaas.payment.debitCard.defaultPercentage}%</Typography>
                                                            <Typography style={{ color: 'gray', marginBottom: 5 }}>por transação recebida</Typography>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Card style={{ marginTop: 10, marginLeft: 15, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', height: 330 }}>
                                                            <Typography style={{ color: '#0030b9', fontSize: 20, fontWeight: 'bold', marginBottom: 5 }}>Cartão de crédito</Typography>
                                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10, marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                <span style={{ fontSize: 10, color: '#0030b9' }}>Á vista</span>
                                                                {taxasAsaas.payment.creditCard.operationValue.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })} + {taxasAsaas?.payment?.creditCard?.oneInstallmentPercentage?.toString()?.replace('.', ',')}%</Typography>

                                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10, marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                <span style={{ fontSize: 10, color: '#0030b9' }}>2 à 6 parcelas</span>
                                                                {taxasAsaas.payment.creditCard.operationValue.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })} + {taxasAsaas?.payment?.creditCard?.upToSixInstallmentsPercentage?.toString()?.replace('.', ',')}%</Typography>

                                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10, marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                <span style={{ fontSize: 10, color: '#0030b9' }}>7 à 12 parcelas</span>
                                                                {taxasAsaas.payment.creditCard.operationValue.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })} + {taxasAsaas?.payment?.creditCard?.upToTwelveInstallmentsPercentage?.toString()?.replace('.', ',')}%</Typography>

                                                            <Typography style={{ color: 'gray', fontSize: 12, marginTop: 5, marginBottom: 5, textAlign: 'start' }}>Sobre o valor total da venda para parcelamentos e por cobranças recebidas para assinaturas.</Typography>
                                                        </Card>
                                                    </Grid>

                                                </Grid>
                                            </TabPanel>
                                        </Grid>
                                    }
                                </Grid>
                            </Card>
                        </>
                    )
                }
            </form>
        </Container>
    )
}

export default DadosBancariosMarketplace;