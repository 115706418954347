export const theme = {
  typography: {
    fontFamily: "poppins",
  },
  palette: { 
    primary: {
      main: "#F1416C",
    },
    secondary: {
        main: "#F1416C",
    },
    error: {
        main: "#e02424",
    },
    info: {
        main: "#ACDDF9",
    },
    warning: {
        main: "#FFC731",
    },
    background: {
        default: "#F5F8FA",
        paper: "#FFFFFF",
    },
    contrastThreshold: 2,
    tonalOffset: 0.04,
  },
  media: {
    lteMedium: '(max-width: 768px)',
  }
};
