import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel  from 'react-bootstrap/Carousel';
import { Container } from './styles';
import { Divider, useMediaQuery } from '@mui/material';

const CarrosselImagens = ({carrossel_images, marginTop = 0}) => {
    const matchDownMd = useMediaQuery('(max-width: 768px)');

    return (
        carrossel_images && carrossel_images.length > 0 &&
        <Container style={{ marginTop: marginTop }}>
            {
                !matchDownMd && carrossel_images.filter(a => a.url !== null && a.url !== "").length > 1 &&
                <Carousel style={{ width: matchDownMd ? 'auto' : '1240px', height: '100%' }}>
                    {carrossel_images.map((item) => {
                        if(item.url === null || item.url === "") return null;
                        return (
                            <Carousel.Item key={item.id} interval={30000}>
                                <img
                                    style={{ borderRadius: 15 }}
                                    className="d-block w-100"
                                    src={item.url}
                                    alt={item.alternative_text}
                                />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            }
            {
                matchDownMd && carrossel_images.filter(a => a.url_mobile !== null && a.url_mobile !== "").length > 1 &&
                <Carousel style={{ width: matchDownMd ? 'auto' : '1240px', height: '100%', paddingLeft: 15, paddingRight: 15 }}>
                    {carrossel_images.map((item) => {
                        if(item.url_mobile === null || item.url_mobile === "") return null;              
                        return (
                            <Carousel.Item key={item.id} interval={30000}>
                                <img
                                    style={{ borderRadius: 15 }}
                                    className="d-block w-100"
                                    src={item.url_mobile}
                                    alt={item.alternative_text}
                                />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            }
            {
                !matchDownMd && carrossel_images.filter(a => a.url !== null && a.url !== "").length === 1 &&
                <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
                    <img src={carrossel_images[0].url} alt={carrossel_images[0].alternative_text} style={{ borderRadius: '6px' }} width='100%'  />
                </div>
            }
            {
                matchDownMd && carrossel_images.filter(a => a.url !== null && a.url !== "").length === 1 &&
                <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
                    <img src={carrossel_images[0].url_mobile ? carrossel_images[0].url_mobile : carrossel_images[0].url} alt={carrossel_images[0].alternative_text} style={{ borderRadius: '6px' }} width='100%'  />
                </div>
            }
        </Container>
    );
}

export default CarrosselImagens;
