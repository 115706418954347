import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
  ${({ theme }) => css`
    width: 100vw;
    height: auto;
    margin-top: 40px;
    padding-bottom: 40px;
    padding-left: 20;
    padding-right: 20;
    display: flex;
    flex-wrap: wrap;
  `}
`;
