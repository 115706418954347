import { Masonry } from '@mui/lab';
import { List, ListItem, Stack, useMediaQuery } from '@mui/material';
import { ContainerListagem } from './styles';
import { useBeforeRender } from '../../hooks/useBeforeRender';
import ProdutoCardAtacado from './ProdutoCardAtacado';
import ProdutoCard from './ProdutoCard';

const ProductList = ({ ehAtacado = true, productList, rowsPerPage, page, produtosRelacionados, onChangeSelected }) => {
  const matchDownMd = useMediaQuery('(max-width: 768px)');

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        if (e.message !== 'ResizeObserver loop limit exceeded') return;
        if (e.message !== 'ResizeObserver loop completed with undelivered notifications') return;
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );

        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  return (
    <ContainerListagem>
      <List spacing={1} component={Stack} direction="row" col style={{ justifyContent: matchDownMd ? '' : 'center' }}>
        <Masonry className='masonryBody' columns={{ xs: 2, sm: 2, md: 4, lg: 6, xl: 8 }} style={{ display: 'flex', alignContent: 'center', maxWidth: '1100px', paddingLeft: matchDownMd ? 30 : 0, paddingRight: matchDownMd ? 10 : 0 }}>

          {productList ? (
            <>
              {
                !ehAtacado && productList.map((prd, i, arr) => {
                  return prd._Etiquetas.map((et) => {
                    return et._EtiquetaComplemento.map((etc) => {                      
                      return (
                        <ListItem key={etc.IdEtiquetaComplemento} style={{ minWidth: 270, maxWidth: 220, display: 'flex', justifyContent: 'center', zIndex: 0, padding: 10, ppddingRight: 0, paddingLeft: 0, background: 'white' }}>
                          <ProdutoCard
                            mostrarVendidoPor={false}
                            mostrarCheck={true}
                            produtosRelacionados={produtosRelacionados}
                            onChangeSelected={onChangeSelected}
                            produto={{
                              idImagem: prd.idImagem,
                              idEtiqueta: et.IdEtiqueta,
                              idEtiquetaComplemento: etc.IdEtiquetaComplemento,
                              fotoProduto: prd.LocalIIS,
                              nome: et.sDescricao,
                              valor: et.mValor,
                              cor: etc.Cor?.Descricao,
                              tamanho: etc.Tamanho?.Descricao,
                              estampa: etc.Estampa?.Descricao,
                              sku: etc.Sku
                            }}
                          />
                        </ListItem>
                      );
                    })
                  })
                })
              }
              {
                ehAtacado && productList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prd, i, arr) => {
                  return (
                    <ListItem key={prd.id} style={{ minWidth: 270, maxWidth: 220, display: 'flex', justifyContent: 'center', zIndex: 0, padding: 10, ppddingRight: 0, paddingLeft: 0, background: 'white' }}>
                      <ProdutoCard
                        mostrarVendidoPor={true}
                        mostrarCheck={false}
                        produtosRelacionados={produtosRelacionados}
                        onChangeSelected={onChangeSelected}
                        produto={prd}
                      />
                    </ListItem>
                  );
                })
              }
            </>
          ) : (
            <h1>Carregando...</h1>
          )}

        </Masonry>
      </List>
    </ContainerListagem>
  )
}

export default ProductList;