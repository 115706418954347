import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)`
    padding-bottom: 150px;
`;

export const AlertError = styled(Typography)`
    padding-top: 10px;
    color: #e15126;
    font-size: 11px;
    font-weight: bold;
`;

export const Title = styled(Typography)`
    color: #757575;
    font-size: 16px !important;
    font-weight: bold;
`;

export const Descricao = styled(Typography)`
    color: rgb(247, 147, 30);
    font-size: 24px !important;
    font-weight: 700 !important;
    padding-top: 10px;
`;