import { Box, Button, Divider, Grid, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_SHOPPING_CART_CLOSE } from "../../store/ModalShoppingCartReducer/types";
import { CloseBtn, ModalContainer } from "./styles";

import './styles.css';

import { v4 as uuid } from 'uuid';

import imgCarrinho from '../../assets/carrinho.svg';
import { Add, Remove, Delete } from "@mui/icons-material";
import { CARRINHO_SET_CLEAR, CARRINHO_SET_DADOS } from "../../store/CarrinhoVesti/types";
import useDialogConfirmationAsync from "../DialogConfirmation/DialogConfirmationAsync";
import { POST_DATA, SalvarLogSentry } from "../../services/API";
import * as moment from "moment";
import LoadingAllPage from "../LoadingAllPage";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../store/ControleMensagemReducer/types";

const ShoppingCartModal = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const modalShoppingCart = useSelector((store) => store.modalShoppingCart);
    const carrinhoVesti = useSelector((store) => store.carrinhoVesti);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [loadingSalvando, setLoadingSalvando] = useState(false);

    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

    useEffect(() => {
        setModalIsOpen(modalShoppingCart.isOpen);
    }, [modalShoppingCart?.isOpen]);

    const toggleModal = () => {
        dispatch({
            type: MODAL_SHOPPING_CART_CLOSE
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') return false;
        if (reason === 'escapeKeyDown') return false;

        dispatch({
            type: MODAL_SHOPPING_CART_CLOSE
        });
    };

    const handleChangeQuantidade = (item, acao) => {
        let itens = carrinhoVesti;

        const itenCarrinho = carrinhoVesti.filter(a => a.idStock === item.idStock)[0];

        const idxCarrinho = carrinhoVesti.indexOf(itenCarrinho);

        if (acao === 'add') {
            itenCarrinho.quantidadeSelecionada = itenCarrinho.quantidadeSelecionada - 1;

            if (itenCarrinho.quantidadeSelecionada === 0) {
                itens = itens.filter(a => a.idStock !== item.idStock);
            }
            else {
                itens[idxCarrinho] = itenCarrinho;
            }
        }
        else if (acao === 'sub') {
            itenCarrinho.quantidadeSelecionada = itenCarrinho.quantidadeSelecionada + 1;

            itens[idxCarrinho] = itenCarrinho;
        }
        else if (acao === 'remove') {
            itenCarrinho.quantidadeSelecionada = itenCarrinho.quantidadeSelecionada - 1;
            itens = itens.filter(a => a.idStock !== item.idStock);
        }

        dispatch({
            type: CARRINHO_SET_DADOS,
            data: itens
        });
    }

    return modalIsOpen &&
        <>
            <Confirmation
                title="Confirmar Pedido?"
                mensagem="Tem certeza que deseja confirmar o pedido com a Vesti?"
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Confirmar",
                    onClick: async () => {
                        try {
                            let quantidadeSelecionada = 0;
                            const totalProdutos = carrinhoVesti.reduce((soma, item) => {
                                quantidadeSelecionada += item.quantidadeSelecionada;
                                return soma += item.quantidadeSelecionada * (item.produto.valorPromocional > 0 ? Number(item.produto.valorPromocional) : Number(item.produto.valor))
                            }, 0);                        

                            if (carrinhoVesti[0].produto.loja.catalogue.cart_requirements?.tipo === 'pecas') {
                                if (quantidadeSelecionada < Number(carrinhoVesti[0].produto.loja.catalogue.cart_requirements.qtdevalor)) {
                                    const msg = `É necessário ter ${carrinhoVesti[0].produto.loja.catalogue.cart_requirements.qtdevalor} peças selecionadas para criar o pedido`;

                                    dispatch({
                                        type: CONTROLEMENSAGEM_AVISO,
                                        tipoComponente: 'alert',
                                        titulo: 'Aviso',
                                        message: msg,
                                    });
                                    return;
                                }
                            }
                            else {
                                if (totalProdutos < Number(carrinhoVesti[0].produto.loja.catalogue.cart_requirements.qtdevalor)) {
                                    const msg = `O valor mínimo de compra é ${Number(carrinhoVesti[0].produto.loja.catalogue.cart_requirements.qtdevalor).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })} para criar o pedido`;

                                    dispatch({
                                        type: CONTROLEMENSAGEM_AVISO,
                                        tipoComponente: 'alert',
                                        titulo: 'Aviso',
                                        message: msg,
                                    });
                                    return;
                                }
                            }

                            return;



                            setLoadingSalvando(true);

                            const pedido = {
                                IdTipoPagamento: 2, //Pix
                                IdTipoEntrega: 1,
                                IdStatusPedido: 1,
                                NumeroPedido: uuid(),
                                IdFornecedorVesti: carrinhoVesti[0].produto.loja.id,
                                IdUsuario: userLoggedIn.IdUsuario,
                                IdEnderecoUsuario: userLoggedIn.IdEndereco,
                                ValorProdutos: totalProdutos,
                                ValorTotal: totalProdutos,
                                FlgAtivo: true,
                                DataCadastro: moment().format('YYYY-MM-DD HH:mm:ss'),
                                DataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss'),
                            }

                            const resultPedidoVesti = await POST_DATA(`PedidoVesti/InsertPedidoVesti`, pedido);

                            pedido.IdPedidoVesti = resultPedidoVesti.IdPedidoVesti;

                            //Salvar produtos do pedido
                            for (const item of carrinhoVesti) {
                                const pedidoProduto = {
                                    IdPedidoVesti: pedido.IdPedidoVesti,
                                    IdProduto: item.produto.id,
                                    IdVariacao: item.idStock,
                                    Sku: item.stock.sku,
                                    Nome: item.produto.nome,
                                    Quantidade: item.quantidadeSelecionada,
                                    Valor: item.produto.valor,
                                    ValorPromocional: item.produto.valorPromocional,
                                    IdTamanho: item.idTamanho,
                                    Tamanho: item.produto.tamanhos.filter(a => a.id === item.idTamanho)[0]?.name,
                                    IdCor: item.idCor,
                                    Cor: item.produto.colors.filter(a => a.id === item.idCor)[0]?.name,
                                    IdMarca: item.produto.marca.id,
                                    Marca: item.produto.marca.name,
                                    IdCategoria: item.produto.categoriaId,
                                    Categoria: item.produto.categoria.name,
                                    FlgAtivo: true
                                };

                                await POST_DATA(`PedidoProdutoVesti/InsertPedidoProdutoVesti`, pedidoProduto);
                            }

                            dispatch({
                                type: CARRINHO_SET_CLEAR
                            });

                            dispatch({
                                type: MODAL_SHOPPING_CART_CLOSE
                            });

                            dispatch({
                                type: CONTROLEMENSAGEM_SUCESSO,
                                tipoComponente: 'alert',
                                titulo: 'Sucesso',
                                message: 'Sucesso ao gerar o pedido',
                            });

                            setLoadingSalvando(false);
                        }
                        catch (err) {
                            setLoadingSalvando(false);
                            SalvarLogSentry(err);
                        }
                    }
                }}
            />

            {loadingSalvando && <LoadingAllPage text={'Salvando pedido Vesti...'} />}

            <Modal
                open={modalIsOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                keepMounted
                disableEscapeKeyDown
                disableAutoFocus
            >
                <ModalContainer>
                    <Stack direction="row" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', align: 'flex-end', alignItems: 'center', marginRight: 30 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                            Carrinho
                        </Typography>
                        <div style={{ minHeight: 20 }}>
                            <CloseBtn style={{ fontSize: 25 }} onClick={toggleModal} />
                        </div>
                    </Stack>
                    <hr />

                    {
                        carrinhoVesti.length === 0 &&
                        <Box style={{ marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Typography variant="body2" component="p" fontSize={18}>
                                Carrinho vazio
                            </Typography>
                            <img alt="Carrinho" src={imgCarrinho} style={{ width: '50%', marginTop: 100 }} />
                        </Box>
                    }

                    <Grid container spacing={3} style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15 }}>
                        {
                            carrinhoVesti.length > 0 &&
                            <Grid item xs={12} style={{ marginBottom: 15, paddingTop: 0, paddingBottom: 10, borderBottom: '1px solid #d1d1d1' }}>
                                <Typography style={{ color: '#d1d1d1' }}>Seu pedido em</Typography>
                                <Typography style={{ fontWeight: 'bold' }}>{carrinhoVesti.length > 0 && carrinhoVesti[0].produto.loja.nome}</Typography>
                                <Typography style={{ fontSize: 12, color: '#878787' }}>
                                    {
                                        carrinhoVesti[0].produto.loja.catalogue.cart_requirements?.tipo === 'pecas' &&
                                        <span>Qtd. mínima de compra: <span style={{ fontWeight: 'bold' }}>{carrinhoVesti[0].produto.loja.catalogue.cart_requirements.qtdevalor} peças </span></span>
                                    }
                                    {
                                        carrinhoVesti[0].produto.loja.catalogue.cart_requirements?.tipo !== 'pecas' &&
                                        <span>Valor mínimo de compra: <span style={{ fontWeight: 'bold' }}>{Number(carrinhoVesti[0].produto.loja.catalogue.cart_requirements.qtdevalor).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })} </span></span>
                                    }
                                </Typography>
                            </Grid>
                        }

                        {
                            carrinhoVesti.map((item, idx) => {
                                return <Box key={idx} style={{ marginBottom: 10, display: 'flex', borderBottom: '1px solid #d1d1d1', paddingBottom: 10 }}>
                                    <Grid item xs={3.5} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={item?.produto?.fotoProduto} style={{ minWidth: 100, width: '100%', borderRadius: 10 }} />
                                    </Grid>
                                    <Grid item xs={8.5} style={{ paddingLeft: 10 }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 12, marginBottom: 10 }}>{item.produto.nome}</Typography>

                                        <Grid container spacing={3} style={{ marginTop: 10 }}>
                                            <Grid item xs={8}>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 10, color: '#999898' }}>SKU: {item.stock.sku}</Typography>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 10, color: '#999898' }}>{item.produto.colors.filter(a => a.id === item.idCor)[0].name} | {item.produto.tamanhos.filter(a => a.id === item.idTamanho)[0].name}</Typography>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 10, color: '#999898' }}>Em estoque: {item.stock.quantity}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {
                                                    item.produto.valorPromocional > 0 &&
                                                    <Typography style={{ fontWeight: 'bold', fontSize: 11, color: 'black', textDecoration: 'line-through' }}>{
                                                        item.produto.valor.toLocaleString("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })
                                                    }</Typography>
                                                }
                                                <Typography style={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>{item.produto.valorPromocional > 0 ? item.produto.valorPromocional.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }) : item.produto.valor.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}</Typography>
                                            </Grid>
                                        </Grid>

                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                sx={{ fontSize: 10, maxWidth: 100, height: 40, fontFamily: 'Arimo', background: 'white', marginTop: 1 }}
                                                type="number"
                                                id={`quantidadePedido`}
                                                variant="outlined"
                                                readOnly={true}
                                                value={item.quantidadeSelecionada}
                                                InputProps={{
                                                    style: { fontWeight: 'bold', height: 40, fontSize: 10, fontFamily: 'Arimo', background: 'white', textAlign: 'center' },
                                                    inputProps: {
                                                        min: 0,
                                                        max: item.produto.stock?.filter(a => a.color_id === item.idCor && a.size_id === item.idTamanho)[0]?.quantity,
                                                        style: {
                                                            background: 'white',
                                                            textAlign: 'center',
                                                            height: 0
                                                        }
                                                    },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Remove sx={{ width: 15, marginLeft: '-7px', cursor: 'pointer', background: 'white' }} onClick={() => {
                                                                if ((item.quantidadeSelecionada - 1) === 0) return;

                                                                handleChangeQuantidade(item, 'add');
                                                            }} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Add sx={{ width: 15, marginRight: '-7px', cursor: 'pointer', background: 'white' }} onClick={() => {
                                                                const quantidade = item.produto.estoque?.filter(a => a.color_id === item.idCor && a.size_id === item.idTamanho)[0]?.quantity;

                                                                if ((item.quantidadeSelecionada + 1) > quantidade) return;

                                                                handleChangeQuantidade(item, 'sub');
                                                            }} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            <Delete style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => handleChangeQuantidade(item, 'remove')} />
                                        </Box>
                                    </Grid>
                                </Box>
                            })
                        }

                        <Grid item xx={12} style={{ minHeight: '43vh', height: '100%', display: 'flex', flexDirection: 'çolumn', alignItems: 'flex-end' }}>
                            {
                                carrinhoVesti.length > 0 &&
                                <Box className="checkout" style={{ width: '100%' }}>
                                    <Divider style={{ width: '90%', marginBottom: 10 }} />
                                    <Box style={{ width: 220, display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                        <Typography style={{ fontWeight: 'bold' }}>Total</Typography>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {
                                                carrinhoVesti.reduce((soma, item) => {
                                                    return soma += item.quantidadeSelecionada * (item.produto.valorPromocional > 0 ? Number(item.produto.valorPromocional) : Number(item.produto.valor))
                                                }, 0).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                            }</Typography>
                                    </Box>
                                    <Button disabled={loadingSalvando} variant='contained' style={{ width: 220 }} onClick={() => getConfirmation()}>Realizar pedido</Button>
                                </Box>
                            }

                        </Grid>
                    </Grid>


                </ModalContainer>
            </Modal>
        </>
}

export default ShoppingCartModal;