import { Fragment, useEffect, useState } from "react";
import { Box, Stack, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, CircularProgress, InputAdornment, Button, TextField, Grid, useMediaQuery, Paper } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA } from "../../../services/API";
import { Container, Descricao, Title } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PeriodPicker from "../../../components/PeriodPicker";
import * as moment from "moment";
import { ImagemSemResultado, formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import Card from "../../../components/Card";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import StatusPedido from "../../../components/StatusPedido";
import { getDescricaoStatusPedido } from "../../../services/utils/statusPedido";

const columns = [
    { id: "cliente", label: "Cliente", minWidth: 120, maxWidth: 170 },
    { id: "status", label: "Status", minWidth: 120, maxWidth: 170 },
    { id: "codigoPedido", label: "Código Pedido", minWidth: 120, maxWidth: 200 },
    { id: "dataVenda", label: "Data da venda", minWidth: 120, maxWidth: 170 },
    { id: "quantidade", label: "Quantidade", minWidth: 120, maxWidth: 170 },
    { id: "valorProdutos", label: "Valor produto(s)", minWidth: 120, maxWidth: 170 },
    { id: "desconto", label: "Desconto", minWidth: 120, maxWidth: 170 },
    { id: "subTotal", label: "Sub Total", minWidth: 120, maxWidth: 170 },
    { id: "frete", label: "Frete", minWidth: 120, maxWidth: 170 },
    { id: "total", label: "Total", minWidth: 120, maxWidth: 170 },
    { id: "dataRegistro", label: "Data de registro", minWidth: 120, maxWidth: 170 },
];

const RelatoriosVendasCliente = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [opcaoSelecionada, setOpcaoSelecionada] = useState(0);

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [vendas, setVendas] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [totalVendas, setTotalVendas] = useState(0);
    const [quantidadePedidos, setQuantidadePedidos] = useState(0);
    const [custoFrete, setCustoFrete] = useState(0);

    const [pesquisar, setPesquisar] = useState(0);

    useEffect(() => {
        (async () => {
            if (userLoggedIn !== null) {
                await fetchVendasCliente(opcaoSelecionada);
            }
        })();
    }, [userLoggedIn, orderList?.period, orderList?.periodToCompare, pesquisar]);

    useEffect(() => {
        const tempVendas = vendas?.map(venda => {
            return <Row venda={venda} />
        });

        setRows(tempVendas || []);
    }, [vendas]);

    const handleBuscarPedidos = async (idStatus = 0) => {
        await fetchVendasCliente(idStatus);
        setOpcaoSelecionada(idStatus);
    }

    const fetchVendasCliente = async (idStatus = 0, filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        if (orderList?.period === undefined || orderList?.period === null) return;

        setLoading(true);

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        const endpoint = `Pedido/PedidosByIdStatusData?idStatus=${idStatus}&dtInicio=${orderList.period.startDate}&dtFim=${orderList.period.endDate}&idUsuarioVendedor=${userLoggedIn.IdUsuario}`;
        const result = await POST_DATA(endpoint);

        let totalVendas = 0;
        let quantidadeVendas = 0;
        let custoFrete = 0;
        result.objetoRetorno.forEach(pedido => {
            if (pedido._PedidoItens.length > 0) {
                totalVendas += pedido.ValorTotal;
                quantidadeVendas += 1;
                custoFrete += pedido.ValorFrete;
            }
        });

        setTotalVendas(totalVendas);
        setQuantidadePedidos(quantidadeVendas);
        setCustoFrete(custoFrete);

        setVendas(result.objetoRetorno);
        setLoading(false);

        if (filtroStr.trim().length > 0) {
            const tmp = result.objetoRetorno.filter(item => item?._UsuarioComprador?.Nome?.includes(`${search}`));
            setVendas(tmp);
            setLoading(false);
        }
    }

    const fetchExcel = async () => {
        if (vendas === null || vendas.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Cliente; Status; Código Pedido; Produto; Data da venda; Quantidade; Sub Total; Desconto; Total; Data de registro`;
        let sku = '';
        let cor = '';
        let quantidade = '';
        vendas?.forEach(venda => {
            venda._PedidoItens.map(produto => {
                sku = produto._EtiquetaComplemento.Sku !== null ? `${produto._EtiquetaComplemento.Sku} - ` : '';
                cor = produto._EtiquetaComplemento?._Cor?.Descricao ? ` - ${produto._EtiquetaComplemento?._Cor?.Descricao}` : '';
                quantidade = venda._PedidoItens.reduce((soma, item) => { return soma += item?.iQuantidade }, 0)

                dados += `\n${venda._UsuarioComprador.Nome}; ${venda._PedidoStatus.Descricao}; ${venda.NumeroPedido}; ${sku}${produto._Etiqueta.sDescricao} (${produto._EtiquetaComplemento.Tamanho.Descricao}${cor}); ${venda.DataVenda ? formatDateSimples(venda.DataVenda) : formatDateSimples(venda.DataCadastro)}; ${quantidade}; ${venda.ValorEtiquetas.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })};${venda.ValorDesconto.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}; ${venda.ValorTotal.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}; ${formatDateSimples(venda.DataCadastro)};`;
            });
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'VendasClientes.csv', dados);
        window.open(url, '_blank');
    }

    const Row = ({ venda }) => {
        return <Fragment key={venda.IdPedido}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda._UsuarioComprador.Nome}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 210 }}>
                    {getDescricaoStatusPedido(venda._PedidoStatus.Descricao)}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.NumeroPedido}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.DataVenda ? formatDateSimples(venda.DataVenda) : formatDateSimples(venda.DataCadastro) }
                </TableCell>                
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda._PedidoItens.reduce((soma, item) => { return soma += item?.iQuantidade }, 0)}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.ValorEtiquetas.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.ValorDesconto.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {(venda.ValorEtiquetas - venda.ValorDesconto).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.ValorFrete.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.ValorTotal.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {formatDateSimples(venda.DataCadastro)}
                </TableCell>
            </TableRow>
            {
                venda._PedidoItens.map(produto => {
                    return (
                        <TableRow style={{ background: '#fafafa' }}>
                            <TableCell colSpan={2} align="left" style={{ minWidth: 100 }}></TableCell>
                            <TableCell colSpan={2} align="left" style={{ minWidth: 100 }}>
                                {produto._EtiquetaComplemento.Sku > 0 ? `${produto._EtiquetaComplemento.Sku}-` : ''} {produto._Etiqueta.sDescricao} ({produto._EtiquetaComplemento.Tamanho.Descricao}{produto._EtiquetaComplemento._Cor ? `${produto._EtiquetaComplemento._Cor?.Descricao}- ` : ''})
                            </TableCell>
                            <TableCell colSpan={1} align="left" style={{ minWidth: 100 }}>
                                {produto.iQuantidade}
                            </TableCell>
                            <TableCell colSpan={7} align="left" style={{ minWidth: 100 }}>
                                {produto._Etiqueta?.mValor.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </TableCell>
                        </TableRow>
                    )
                })
            }

        </Fragment>
    }

    return (
        <Container>
            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                <Grid container spacing={1} style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>Relatórios / Vendas cliente</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Total de vendas</Title>
                            <Descricao>{totalVendas.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })}
                            </Descricao>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Total de pedidos</Title>
                            <Descricao>{quantidadePedidos}</Descricao>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Custo do frete</Title>
                            <Descricao>{custoFrete.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })}
                            </Descricao>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Lucro</Title>
                            <Descricao>R$0,00</Descricao>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'nowrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <TextField
                            label="Pesquisar"
                            placeholder='Nome do Clientes'
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        setPesquisar(Math.random());
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ height: '100%', marginLeft: 10, height: 43 }}
                            onClick={() => fetchVendasCliente()}
                        >
                            Pesquisar
                        </Button>
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <StatusPedido onClick={handleBuscarPedidos} />
                        <PeriodPicker />
                    </Box>
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando vendas</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default RelatoriosVendasCliente;