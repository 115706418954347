import PropTypes from 'prop-types';

import { Navigate, useLocation } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import MinimalLayout from '../layout/MinimalLayout';
import { getStorage } from '../hooks/useStorage';
import { useSelector } from 'react-redux';
import LoginLeapLovers from '../pages/LeapLovers/Login';
import LoginLeapVesti from '../pages/LeapVesti/Login';
import Recovery from '../pages/Recovery';

const VerificaSeEstaLogado = ({ children }) => {
    const usuarioLogado = getStorage('usrin');
    const location = useLocation();

    if (usuarioLogado && location.pathname === '') return <Navigate to={'/'} />;

    return children;
};

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: (
                <VerificaSeEstaLogado>
                    {
                        process.env.REACT_APP_LEAP_LOVERS == "true" ? <LoginLeapLovers /> : process.env.REACT_APP_LEAP_VESTI === 'true' ? <LoginLeapVesti /> : <Login />
                    }
                </VerificaSeEstaLogado>
            ),
        },
        {
            path: '/Account/recovery',
            element: <Recovery />,
        },
        {
            path: '/recovery',
            element: <Recovery />,
        },
        {
            path: '/*',
            element: <NotFound />,
        },
    ],
};

VerificaSeEstaLogado.propTypes = {
    children: PropTypes.node,
};

export default AuthenticationRoutes;
