import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Alert, AlertTitle, Avatar, Box, Button, Checkbox, CircularProgress, Collapse, IconButton, InputAdornment, Menu, MenuItem, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import * as moment from 'moment';
import { ManageSearch } from '@mui/icons-material';
import { ImagemSemResultado } from '../../../services/utils/functions';
import { POST_DATA, PUT_DATA, UploadFileAzure } from '../../../services/API';
import UploadImagem from '../../../components/UploadImagem';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';

const columns = [
    <TableCell style={{ fontWeight: 'bold', minWidth: 220 }}>Título</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 220 }} align="left">Mensagem</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 220 }} align="left">Imagem</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 70 }} align="left">Ativo</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 70 }} align="left">Entrada</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 70 }} align="left">Saída</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 70 }} align="left">Data de alteração</TableCell>,
    <TableCell style={{ fontWeight: 'bold', minWidth: 70 }} align="left">Ações</TableCell>
];

const Campanha = () => {
    const dispatch = useDispatch();
    const usuarioLogado = useSelector((store) => store.usuarioLogado);
    const [fieldFocus, setFieldFocus] = useState('');

    const [loadingCampanhas, setLoadingCampanhas] = useState(false);
    const querySelector = useMediaQuery('(max-width:600px)');
    const [search, setSearch] = useState('');
    const [campanhas, setCampanhas] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            await getCampanhas();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarioLogado]);

    useEffect(() => {
        const tmpCampanhas = campanhas?.map(campanha => {
            return <Row campanha={campanha} />
        });

        setRows(tmpCampanhas || []);
    }, [campanhas]);

    const getCampanhas = async() => {
        if (usuarioLogado === null) return;
        setLoadingCampanhas(true);
        const result = await POST_DATA(`PopUpGlobalLead/GetAllPopUpGlobalLeadByValorExato?strValorExato=${usuarioLogado?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=false&join=true&maxInstances=0&order_by=IdPopUpGlobalLead desc&Log=`);
        setCampanhas(result);
        setLoadingCampanhas(false);
    }

    const handleAdicionar = () => {
        const campanha = {
            IdPopUpGlobalLead: 0,
            IdUsuarioEmpresa: usuarioLogado?.IdUsuarioEmpresa,
            Titulo: 'CAMPANHA',
            Descricao: '',
            UrlImagem: '',
            DtCadastro: moment(),
            FlagAtivo: true,
            FlagEntrada: true,
            FlagSaida: false
        }

        setCampanhas([...campanhas, campanha]);
        setFieldFocus('Titulo0');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeCampanha = (id, titulo, property, value) => {
        const tmpCampanhas = [...campanhas];
        tmpCampanhas.forEach(item => {
            if (item.IdPopUpGlobalLead === id && item.Titulo === titulo) {
                item[property] = value;
            }
        });

        setCampanhas(tmpCampanhas);
        setFieldFocus(() => `${property}${id}`);
    }

    const Row = ({ campanha }) => {
        const bloquearEdicao = false;

        const handlSalvar = async () => {
            setLoadingCampanhas(true);

            //buscar campanhas
            const campanhas = await POST_DATA(`PopUpGlobalLead/GetAllPopUpGlobalLeadByValorExato?strValorExato=${usuarioLogado?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=false&join=true&maxInstances=0&order_by=IdPopUpGlobalLead desc&Log=`);
            const campanhasEntradaAtivo = campanhas.filter(c => c.FlagEntrada && c.FlagAtivo);
            const campanhasSaidaAtivas = campanhas.filter(c => c.FlagSaida && c.FlagAtivo);

            if (campanha.FlagEntrada) {
                if (campanhasEntradaAtivo.length > 0) {
                    campanhasEntradaAtivo.forEach(async (c) => {
                        await PUT_DATA(`PopUpGlobalLead/DeletarPopUpGlobalLead?IdPopUpGlobalLead=${c.IdPopUpGlobalLead}&Log=`);
                    });
                }
            }
            else {
                if (campanhasSaidaAtivas.length > 0) {
                    campanhasSaidaAtivas.forEach(async (c) => {
                        await PUT_DATA(`PopUpGlobalLead/DeletarPopUpGlobalLead?IdPopUpGlobalLead=${c.IdPopUpGlobalLead}&Log=`);
                    });
                }
            }

            if (campanha.UrlImagem !== null && campanha.UrlImagem !== "" && !campanha.UrlImagem?.toString().includes('http')) {
                const extensao = campanha.UrlImagem.type.split("/");
                const nomeArquivo = `imagem${moment().format('DDMMYYYYHHmmss')}.${extensao[1]}`;
                const pasta = `${usuarioLogado.Cnpj}/images`;
                const result = await UploadFileAzure(campanha.UrlImagem, pasta, nomeArquivo, campanha.UrlImagem.type);

                campanha.UrlImagem = result;
            }

            await PUT_DATA(`PopUpGlobalLead/CadastroPopUpGlobalLead`, campanha);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Camanha salva!',
            });

            setLoadingCampanhas(false);

            await getCampanhas();
        }

        const handleVeificacaoAtivar = async() => {
            const campanhas = await POST_DATA(`PopUpGlobalLead/GetAllPopUpGlobalLeadByValorExato?strValorExato=${usuarioLogado?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=false&join=true&maxInstances=0&order_by=IdPopUpGlobalLead desc&Log=`);
            const campanhasEntradaAtivo = campanhas.filter(c => c.FlagEntrada && c.FlagAtivo);
            const campanhasSaidaAtivas = campanhas.filter(c => c.FlagSaida && c.FlagAtivo);

            if (campanha.FlagEntrada && campanhasEntradaAtivo.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Deve-se ter somente uma campanha de entrada ativa!',
                });
                return false;
            }
            if (campanha.FlagSaida && campanhasSaidaAtivas.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Deve-se ter somente uma campanha de saída ativa!',
                });
                return false;
            }

            return true;
        }

        const handleDesativar = async (mostrarMensagemSucesso = true) => {
            try {
                if (mostrarMensagemSucesso)
                    setLoadingCampanhas(true);

                await PUT_DATA(`PopUpGlobalLead/DeletarPopUpGlobalLead?IdPopUpGlobalLead=${campanha.IdPopUpGlobalLead}&Log=`);

                if (mostrarMensagemSucesso)
                    setLoadingCampanhas(false);

                if (mostrarMensagemSucesso) {
                    dispatch({
                        type: CONTROLEMENSAGEM_SUCESSO,
                        tipoComponente: 'alert',
                        titulo: 'Sucesso',
                        message: 'Sucesso ao desativar a campanha',
                    });
                }

                await getCampanhas();

                return true;
            } catch (error) {
                setLoadingCampanhas(false);
                return false;
            }
        }

        const handleChangeOptions = (clicouEntrada) => {
            if (!clicouEntrada)
                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'FlagEntrada', false);
            else
                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'FlagSaida', false);
        }

        return (
            <Fragment>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <TextField
                            multiline
                            maxRows={5}
                            minRows={5}
                            fullWidth
                            id={`Totulo${campanha.IdPopUpGlobalLead}`}
                            disabled={bloquearEdicao}
                            readOnly={bloquearEdicao}
                            type='text'
                            autoFocus={`Titulo${campanha.IdPopUpGlobalLead}` === fieldFocus}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            InputProps={{ style: { fontSize: 14 } }}
                            variant='outlined'
                            onClick={() => {
                                setFieldFocus(() => `Titulo${campanha.IdPopUpGlobalLead}`);
                            }}
                            onChange={(e) => {
                                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'Titulo', e.target.value);
                            }}
                            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                            value={campanha.Titulo}
                        />
                    </TableCell>
                    <TableCell align="left">
                        <TextField
                            fullWidth
                            multiline
                            maxRows={5}
                            minRows={5}
                            id={`Descricao${campanha.IdPopUpGlobalLead}`}
                            disabled={bloquearEdicao}
                            readOnly={bloquearEdicao}
                            type='text'
                            autoFocus={`Descricao${campanha.IdPopUpGlobalLead}` === fieldFocus}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            InputProps={{ style: { fontSize: 14 } }}
                            variant='outlined'
                            onClick={() => {
                                setFieldFocus(() => `Descricao${campanha.IdPopUpGlobalLead}`);
                            }}
                            onChange={(e) => {
                                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'Descricao', e.target.value);
                            }}
                            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                            value={campanha.Descricao}
                        />
                    </TableCell>
                    <TableCell align="left">
                        <UploadImagem
                            isEditing
                            imgData={campanha.UrlImagem}
                            setImgData={(img, idx) => {
                                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'UrlImagem', img);
                            }}
                            index={campanha.IdPopUpGlobalLead}
                            heightImg={110}
                            widthImg={110}
                            style={{ width: 170, height: 170 }}
                        />
                    </TableCell>
                    <TableCell align="left">
                        <Switch
                            checked={campanha.FlagAtivo}
                            disabled={campanha.IdPopUpGlobalLead === 0}
                            readOnly={campanha.IdPopUpGlobalLead === 0}
                            onClick={async () => {
                                //Aqui está desativado
                                if(!campanha.FlagAtivo){
                                    const result = await handleVeificacaoAtivar();
                                    if(!result) return;
                                }

                                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'FlagAtivo', !campanha.FlagAtivo);
                                //Aqui está ativo
                                if (!campanha.FlagAtivo) {
                                    
                                    await handleDesativar();
                                }
                            }}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': `transfer-list-all-item-${campanha.IdPopUpGlobalLead}-flag-ativo`,
                            }}
                        />
                    </TableCell>
                    <TableCell align="left">
                        <Switch
                            checked={campanha.FlagEntrada}
                            onClick={() => {
                                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'FlagEntrada', !campanha.FlagEntrada);
                                handleChangeOptions(true);
                            }}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': `transfer-list-all-item-${campanha.IdPopUpGlobalLead}-flag-entrada`,
                            }}
                        />
                    </TableCell>
                    <TableCell align="left">
                        <Switch
                            checked={campanha.FlagSaida}
                            onClick={() => {
                                handleChangeCampanha(campanha.IdPopUpGlobalLead, campanha.Titulo, 'FlagSaida', !campanha.FlagSaida);
                                handleChangeOptions(false);
                            }}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': `transfer-list-all-item-${campanha.IdPopUpGlobalLead}-flag-entrada`,
                            }}
                        />
                    </TableCell>
                    <TableCell align="left">{moment(campanha.DtCadastro).format("DD/MM/YYYY")}</TableCell>
                    <TableCell align="left">
                        <Button onClick={() => handlSalvar()} variant="contained">Salvar</Button>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Typography style={{ fontWeight: 'bold' }}>Marketing / Campanhas</Typography>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2}>
                    <TextField
                        type="text"
                        label="Pesquisar por título"
                        placeholder='Título'
                        size="small"
                        sx={{ width: querySelector ? "200px" : "250px" }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            const tmpCampanhas = campanhas.filter(a => a.Titulo.toUpperCase().includes(e.target.value.toUpperCase()))?.map(campanha => {
                                return <Row campanha={campanha} />
                            });
                    
                            setRows(tmpCampanhas);
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <ManageSearch />
                            </InputAdornment>,
                            endAdornment: search.length > 0 && (
                                <InputAdornment
                                    style={{ cursor: 'pointer', color: 'black' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        const tmpCampanhas = campanhas.map(campanha => {
                                            return <Row campanha={campanha} />
                                        });

                                        setRows(tmpCampanhas);
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <Box style={{ display: 'flex' }}>
                    <Button onClick={() => handleAdicionar()} variant='contained'>Novo</Button>
                </Box>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loadingCampanhas ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => column)}
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ width: "100%" }}>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={campanhas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${rows?.length} resultados encontrados para pesquisa "${search}" - Produtos por página: `
                                    : "Produtos por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
        </>
    );
}

export default Campanha;