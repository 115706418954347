import { Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA_DESING, UploadFileAzure } from "../../../services/API";
import { TEMA_LOJA_SET, TEMA_LOJA_SET_GOOGLE_ANALITICS } from "../../../store/TemaLojaReducer/types";
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";

const GoogleAnalytics = () => {
    const dispatch = useDispatch();

    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const themeStorage = useSelector((store) => store.temaLoja);

    const [construtorSite, setConstrutorSite] = useState(null);
    const [themeStorageSelected, setThemeStorageSelected] = useState(null);
    const [tokenDesign, setTokenDesign] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };

            const login = await POST_AUTH(obj);
            setTokenDesign(login.token);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (tokenDesign === null) return;
            await GetDadosTema();
        })();
    }, [tokenDesign]);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store) {
            const construtorSiteSelected = themeStorage.themes_store[0];

            setThemeStorageSelected(construtorSiteSelected);
        }
    }, [themeStorage]);
    

    const GetDadosTema = async () => {
        try {
            setLoading(true);

            const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
            setConstrutorSite(construtorSite.data);

            dispatch({
                type: TEMA_LOJA_SET,
                data: construtorSite.data.arquivo
            });

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const handleChangeGoogleAnalitics = (property, value) => {
        const data = { ...themeStorageSelected?.googleAnalytics };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_GOOGLE_ANALITICS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            googleAnalytics: data
        });
    }

    const handlePublish = async () => {
        try {
            setLoading(true);

            const construtorSiteObj = { ...construtorSite };

            if (construtorSiteObj.id > 0) {
                construtorSiteObj.ArquivoStr = JSON.stringify(themeStorage);
                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, construtorSiteObj);
            }

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso ao salvar os dados'
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: 'Ocorreu um erro ao salvar os dados da loja',
            });
        }
    };

    return <>
        {
            loading && <Stack
                sx={{ height: "450px", marginTop: '10%' }}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress />
                <Typography sx={{ mt: 1 }}>Carregando dados do eCommerce</Typography>
            </Stack>
        }

        {
            (!loading && themeStorageSelected?.googleAnalytics) && <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                        <Typography style={{ fontWeight: 'bold' }}>Marketing / Google Analytics / Tag Manager</Typography>
                    </Box>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={4}
                            title={'Head'}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            value={themeStorageSelected.googleAnalytics.head || ''}
                            onChange={(e) => {
                                handleChangeGoogleAnalitics('head', e.target.value);
                            }}
                            placeholder="Cole o código head vindo do gerenciado de tags do Google"
                            label='Head'
                        />
                        <TextField
                            fullWidth
                            multiline
                            minRows={4}
                            title={'Body'}
                            style={{ marginTop: 10 }}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            value={themeStorageSelected.googleAnalytics.body || ''}
                            onChange={(e) => {
                                handleChangeGoogleAnalitics('body', e.target.value);
                            }}
                            placeholder="Cole o código body vindo do gerenciado de tags do Google"
                            label='Body'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={4}
                            title={'Pós head'}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            value={themeStorageSelected.googleAnalytics.afterHead || ''}
                            onChange={(e) => {
                                handleChangeGoogleAnalitics('afterHead', e.target.value);
                            }}
                            placeholder="Cole o código pós head vindo do gerenciado de tags do Google"
                            label='Pós head'
                        />
                        <TextField
                            fullWidth
                            multiline
                            title={'Meta'}
                            style={{ marginTop: 10 }}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            value={themeStorageSelected.googleAnalytics.meta || ''}
                            onChange={(e) => {
                                handleChangeGoogleAnalitics('meta', e.target.value);
                            }}
                            placeholder="Cole o código meta vindo do gerenciado de tags do Google"
                            label='Meta'
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" onClick={() => handlePublish()}>Salvar</Button>
                    </Box>
                </Grid>
            </Grid>
        }
    </>
}

export default GoogleAnalytics;