import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import HelpIcon from '@mui/icons-material/Help';

// ==============================|| MENU ITEMS ||============================== //

const dashboard = {
    id: 'initial',
    type: 'group',
    children: [
        {
            id: 'dashboard-afiliado',
            title: 'Início',
            type: 'item',
            url: '/dashboard-afiliado',
            icon: HomeIcon,
            breadcrumbs: false
        }
    ]
};

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'meu-perfil',
            title: 'Meu Perfil',
            type: 'item',
            url: '/meu-perfil',
            icon: PersonIcon,
            breadcrumbs: false
        },
        {
            id: 'minhas-vendas',
            title: 'Minhas vendas',
            type: 'item',
            url: '/minhas-vendas',
            icon: PointOfSaleIcon,
            breadcrumbs: false
        },
        {
            id: 'help',
            title: 'Ajuda',
            type: 'item',
            url: '/help',
            icon: HelpIcon,
            breadcrumbs: false
        }
    ]
};


const menuLeapLovers = {
    items: [dashboard, pages]
};

export default menuLeapLovers;
