import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Buffer } from 'buffer';
import { initSentry } from './services/API/Sentry';

window.Buffer = window.Buffer ?? Buffer;

// Inicializa o Sentry
initSentry();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
