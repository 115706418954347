import { MODAL_SHOPPING_CART_ABRIR, MODAL_SHOPPING_CART_CLOSE } from './types';

const INITIAL_STATE = {
  isOpen: false
};

const modalShoppingCartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODAL_SHOPPING_CART_ABRIR:
      return { isOpen: true };
    case MODAL_SHOPPING_CART_CLOSE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default modalShoppingCartReducer;
