import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getCorByStatusPedido } from "../../services/utils/statusPedido";

const StatusPedido = ({ onClick, valorPadrao }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [opcaoSelecionada, setOpcaoSelecionada] = useState(valorPadrao || 0);
    const open = Boolean(anchorEl);

    const getOpcao = (id) => {
        let descricao = '';
        switch (id) {
            case 0:
                descricao = 'Todos';
                break;
            case 1:
                descricao = 'Em análise';
                break;
            case 2:
                descricao = 'Aprovado';
                break;
            case 3:
                descricao = 'Encaminhado para entrega';
                break;
            case 4:
                descricao = 'Entregue';
                break;
            case 5:
                descricao = 'Avaliar';
                break;
            case 6:
                descricao = 'Reprovado';
                break;
            case 7:
                descricao = 'Cancelado Comprador';
                break;
            case 8:
                descricao = 'Cancelado Vendedor';
                break;
            case 9:
                descricao = 'Devolução Solicitada';
                break;
            case 10:
                descricao = 'Devolução Confirmada';
                break;
            case 11:
                descricao = 'Em Preparação';
                break;
            case 12:
                descricao = 'Cancelado Inatividade';
                break;
            case 13:
                descricao = 'Erro de Processamento';
                break;
        }

        const cor = getCorByStatusPedido(descricao);
        return <Typography><span style={{ padding: 0, paddingLeft: 8, paddingRight: 8, background: cor, borderRadius: 50, border: '0.5px solid gray', marginRight: 10 }} />{descricao}</Typography>;
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Typography style={{ fontSize: 12, marginBottom: 7 }}>Status:</Typography>
            <Button
                color='secondary'
                style={{ marginRight: "1rem", background: '#d6d6d6', color: 'black' }}
                variant="contained"
                size="large"
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
            >
                {getOpcao(valorPadrao || opcaoSelecionada)}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={async () => { setOpcaoSelecionada(0); await onClick(0); handleClose(); }}>{getOpcao(0)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(1); await onClick(1); handleClose(); }}>{getOpcao(1)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(2); await onClick(2); handleClose(); }}>{getOpcao(2)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(3); await onClick(3); handleClose(); }}>{getOpcao(3)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(4); await onClick(4); handleClose(); }}>{getOpcao(4)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(5); await onClick(5); handleClose(); }}>{getOpcao(5)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(6); await onClick(6); handleClose(); }}>{getOpcao(6)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(7); await onClick(7); handleClose(); }}>{getOpcao(7)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(8); await onClick(8); handleClose(); }}>{getOpcao(8)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(9); await onClick(9); handleClose(); }}>{getOpcao(9)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(10); await onClick(10); handleClose(); }}>{getOpcao(10)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(11); await onClick(11); handleClose(); }}>{getOpcao(11)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(12); await onClick(12); handleClose(); }}>{getOpcao(12)}</MenuItem>
                <MenuItem onClick={async () => { setOpcaoSelecionada(13); await onClick(13); handleClose(); }}>{getOpcao(13)}</MenuItem>
            </Menu>
        </Box>
    )
}

export default StatusPedido;