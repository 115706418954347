import { useEffect, useRef, useState } from "react";
import { Container, containerModal } from "./styles";
import { Autocomplete, Box, Button, Card, CircularProgress, Divider, Grid, LinearProgress, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import { SET_MENU } from "../../../store/Customization/types";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";

import { GET_DATA, GET_DATA_DESIGN, POST_AUTH, POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { useLocation, useNavigate } from "react-router";
import LoadingAllPage from "../../../components/LoadingAllPage";
import * as moment from "moment";

import BootstrapDialogTitle from "../../../components/Modal/BootstrapDialogTitle";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PersonalVideoOutlinedIcon from '@mui/icons-material/PersonalVideoOutlined';
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

import './styles.css';
import { isMobile } from "../../../services/DeviceServices";

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import fireworks from '../../../assets/fireworks.gif';

const OnboardDesignSite = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const location = useLocation();

    const carregouDados = useRef(null);

    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

    const matchDownMd = useMediaQuery('(max-width: 1600px)');
    const telaMenorIgual1024 = useMediaQuery('(max-width: 1024px)');

    const [loading, setLoading] = useState(false);
    const [loadingMensagem, setLoadingMensagem] = useState(false);

    const [dataHoraAgora, setDataHoraAgora] = useState(0);
    const [percentualEtapa, setPercentualEtapa] = useState(0);

    const [publicoAlvoLoja, setPublicoAlvoLoja] = useState([]);

    const [sexoXCategorias, setSexoXCategorias] = useState([]);
    const [opcaoRespostaCategorias, setOpcaoRespostaCategorias] = useState([]);

    const [pecasXCategoria, setPecasXCategoria] = useState([]);
    const [loadingPecaXCategorias, setLoadingPecaXCategorias] = useState(false);
    const [opcaoRespostaPexasXCategorias, setOpcaoRespostaPexasXCategorias] = useState([]);

    const [loadingPecaXSubPecas, setLoadingPecaXSubPecas] = useState(false);
    const [opcaoRespostaSubPecas, setOpcaoRespostaSubPecas] = useState([]);

    const [opcoesUsoTema, setOpcoesUsoTema] = useState([]);
    const [tiposEcommerce, setTiposEcommerce] = useState([]);

    const [temas, setTemas] = useState([]);
    const [temasFiltrados, setTemasFiltrados] = useState([]);
    const [tipoLojaNome, setTipoLojaNome] = useState('');
    const [idTemaSelecionado, setIdTemaSelecionado] = useState(0);
    const [tipoLojaTema, setTipoLojaTema] = useState([]);
    const [tipoLojaTemaSeleciondo, setTipoLojaTemaSelecionado] = useState([]);

    const [dadosOnboard, setDadosOnboard] = useState(null);
    const [dadosRespostaTema, setDadosRespostaTema] = useState({
        etapaAtual: 1,

        questao1: [], //Opcões de sexo
        questao1IdPerguntaQuestonario: 0,

        questao2: [], //Opções de categorias
        questao2IdPerguntaQuestonario: 0,

        questao3: [], //Opções de pecas x categoria
        questao3IdPerguntaQuestonario: 0,

        questao4: 0,
        questao4IdPerguntaQuestonario: 0,
        questao4Imput: '',
        questao4InputValueAutocomplete: 0,

        questao5: 0,
        questao5IdPerguntaQuestonario: 0,
        questao5Imput: '',
        questao5InputValueAutocomplete: 0,

        questao6: [],
        questao6IdPerguntaQuestonario: 0,
    });

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: false });
        if (userLoggedIn === null) return;

        if (carregouDados.current) return;

        carregouDados.current = true;

        (async () => {
            try {
                setLoading(true);
                setLoadingMensagem(true);                

                const temasResult = await GET_DATA(`Tema/GetAllTemas?flgAtivo=true`);
                setTemas(temasResult);

                let onboardResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=2&idUsuario=${userLoggedIn.IdUsuario}`);

                onboardResult = { ...onboardResult, paginaAtual: 0 };

                let contador = 1;
                let arrRepostas = [];

                let objetoRespostaTema = { ...dadosRespostaTema };
                let qtdQuestoesCompletas = 0;
                for (const pergunta of onboardResult._QuestionarioPergunta) {
                    arrRepostas = [];
                    pergunta.etapa = contador;

                    if (pergunta.Posicao === 1) { //Tela 1
                        objetoRespostaTema.questao1IdPerguntaQuestonario = pergunta.IdQuestionarioPergunta;

                        for (const resposta of pergunta._QuestionarioResposta) {
                            const objResposta = { id: resposta.IdQuestionarioResposta, nome: resposta.Descricao, idTemaRef: resposta.IdTemaRef, idTipoLojaRef: resposta.IdTipoLojaRef, idPecaRef: resposta.IdPecaRef, idSexoRef: resposta.IdSexoRef, idCategoriaRef: resposta.IdCategoriaRef };
                            if (resposta.Checked) {
                                objetoRespostaTema.questao1.push(resposta);
                            }
                            arrRepostas.push(objResposta)
                        }
                        setPublicoAlvoLoja(arrRepostas);
                    }
                    else if (pergunta.Posicao === 2) { //Tela 2
                        objetoRespostaTema.questao2IdPerguntaQuestonario = pergunta.IdQuestionarioPergunta;

                        for (const resposta of pergunta._QuestionarioResposta) {

                            const objResposta = { id: resposta.IdQuestionarioResposta, nome: resposta.Descricao, idTemaRef: resposta.IdTemaRef, idTipoLojaRef: resposta.IdTipoLojaRef, idPecaRef: resposta.IdPecaRef, idSexoRef: resposta.IdSexoRef, idCategoriaRef: resposta.IdCategoriaRef };

                            if (resposta.Checked) {
                                objetoRespostaTema.questao2.push(resposta);
                            }
                            arrRepostas.push(objResposta)
                        }

                        setOpcaoRespostaCategorias(arrRepostas);
                    }
                    else if (pergunta.Posicao === 3) { //Tela 3
                        objetoRespostaTema.questao3IdPerguntaQuestonario = pergunta.IdQuestionarioPergunta;

                        for (const resposta of pergunta._QuestionarioResposta) {
                            const objResposta = { id: resposta.IdQuestionarioResposta, nome: resposta.Descricao, idTemaRef: resposta.IdTemaRef, idTipoLojaRef: resposta.IdTipoLojaRef, idPecaRef: resposta.IdPecaRef, idSubPecaRef: resposta.IdSubPecaRef, idSexoRef: resposta.IdSexoRef, idCategoriaRef: resposta.IdCategoriaRef };
                            if (resposta.Checked) {
                                objetoRespostaTema.questao3.push(resposta);
                            }
                            arrRepostas.push(objResposta)
                        }

                        setOpcaoRespostaPexasXCategorias(arrRepostas);
                    }
                    else if (pergunta.Posicao === 4) { //Tela 4
                        objetoRespostaTema.questao4IdPerguntaQuestonario = pergunta.IdQuestionarioPergunta;

                        for (const resposta of pergunta._QuestionarioResposta) {
                            const objResposta = { id: resposta.IdQuestionarioResposta, nome: resposta.Descricao, idTemaRef: resposta.IdTemaRef, idTipoLojaRef: resposta.IdTipoLojaRef, idPecaRef: resposta.IdPecaRef, idSexoRef: resposta.IdSexoRef, idCategoriaRef: resposta.IdCategoriaRef };
                            if (resposta.Checked) {
                                objetoRespostaTema.questao4 = resposta;
                                objetoRespostaTema.questao4Imput = objResposta;
                                objetoRespostaTema.questao4InputValueAutocomplete = resposta.Descricao;
                            }
                            arrRepostas.push(objResposta);
                        }
                        setOpcoesUsoTema(arrRepostas);
                    }
                    else if (pergunta.Posicao === 5) { //Tela 5
                        objetoRespostaTema.questao5IdPerguntaQuestonario = pergunta.IdQuestionarioPergunta;

                        const arrTipoTemasLoja = [];
                        for (const resposta of pergunta._QuestionarioResposta) {
                            const objResposta = { id: resposta.IdQuestionarioResposta, nome: resposta.Descricao, idTemaRef: resposta.IdTemaRef, idTipoLojaRef: resposta.IdTipoLojaRef, idPecaRef: resposta.IdPecaRef, idSexoRef: resposta.IdSexoRef, idCategoriaRef: resposta.IdCategoriaRef };
                            if (resposta.Checked) {
                                setTipoLojaNome(resposta.Descricao);
                                objetoRespostaTema.questao5 = resposta.IdQuestionarioResposta;
                                objetoRespostaTema.questao5Imput = objResposta;
                                objetoRespostaTema.questao5InputValueAutocomplete = resposta.Descricao;

                                const tipoLojaTemaResult = await GET_DATA(`Tema/GetTemasByIdTipoLoja?id=${resposta.IdTipoLojaRef}`);
                                setTemasFiltrados(tipoLojaTemaResult);

                                for (const itemTema of tipoLojaTemaResult) {
                                    arrTipoTemasLoja.push({ IdTema: itemTema.IdTema, IdTipoLojaRef: resposta.IdTipoLojaRef });
                                }
                            }
                            else {
                                const tipoLojaTemaResult = await GET_DATA(`Tema/GetTemasByIdTipoLoja?id=${resposta.IdTipoLojaRef}`);
                                for (const itemTema of tipoLojaTemaResult) {
                                    arrTipoTemasLoja.push({ IdTema: itemTema.IdTema, IdTipoLojaRef: resposta.IdTipoLojaRef });
                                }
                            }

                            arrRepostas.push(objResposta);

                        }

                        setTiposEcommerce(arrRepostas);
                        setTipoLojaTema(arrTipoTemasLoja);
                    }
                    else if (pergunta.Posicao === 6) {
                        objetoRespostaTema.questao6IdPerguntaQuestonario = pergunta.IdQuestionarioPergunta;

                        const arrRepostas = [];
                        for (const resposta of pergunta._QuestionarioResposta) {
                            const objResposta = { id: resposta.IdQuestionarioResposta, nome: resposta.Descricao, idTemaRef: resposta.IdTemaRef, idTipoLojaRef: resposta.IdTipoLojaRef, idPecaRef: resposta.IdPecaRef, idSubPecaRef: resposta.IdSubPecaRef, idSexoRef: resposta.IdSexoRef, idCategoriaRef: resposta.IdCategoriaRef };
                            if (resposta.Checked) {
                                objetoRespostaTema.questao6.push(resposta);
                            }
                            arrRepostas.push(objResposta);
                        }
                        setOpcaoRespostaSubPecas(arrRepostas);
                    }

                    qtdQuestoesCompletas += pergunta._QuestionarioResposta.filter(a => a.Checked).length > 0 ? 1 : 0;

                    contador++;
                }

                if (qtdQuestoesCompletas === (onboardResult._QuestionarioPergunta.length - 1)) {
                    setDataHoraAgora(Date.now() + 100);
                    objetoRespostaTema.etapaAtual = 7;
                }

                setDadosRespostaTema(objetoRespostaTema);
                setDadosOnboard(onboardResult);

                setLoading(false);
            }
            catch (err) {
                SalvarLogSentry(err);
            }
            finally {
                setLoading(false);
                setLoadingMensagem(false);
            }
        })();

        const handleBeforeUnload = (event) => {
            // Perform actions before the component unloads

            event.preventDefault();
            event.returnValue = 'Você realmente deseja sair do site sem finalizar?';
        };

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }

    }, [userLoggedIn]);

    const ModalDescricao = ({ idTema, open, handleClose, urlImagemDescricao = null, linkLoja = null }) => {
        const [desktop, setDesktop] = useState(true);

        return open ? <Modal
            key="modal"
            open={open}
            style={{
                width: '100%',
                height: '100%',
            }}
            keepMounted
            disableAutoFocus
        >
            <Box
                sx={[
                    containerModal,
                    {
                        width: '100%',
                        height: '100%',
                        maxHeight: '100%',
                        overflowY: urlImagemDescricao !== null ? null : 'auto',
                        borderRadius: 2,
                        backgroundColor: '#ffff',
                    },
                ]}
            >
                {
                    linkLoja === null &&
                    <BootstrapDialogTitle id="componenteMensagem" onClose={handleClose} />
                }
                {
                    linkLoja !== null &&
                    <Box style={{ width: '100%' }}>
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' alt='Logo leap' width={'100px'} height={'50px'} style={{ filter: 'invert(1)', marginRight: 20 }} />
                                <Divider orientation='vertical' style={{ height: 50, width: 2, background: 'black', marginRight: 20 }} />
                                <Typography style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleClose}><KeyboardBackspaceIcon style={{ marginRight: 10 }} /> Volta para layouts</Typography>
                            </Box>

                            <Box style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
                                <PersonalVideoOutlinedIcon style={{ marginRight: 20, color: desktop ? '#faa03c' : null, cursor: 'pointer' }} onClick={() => setDesktop(true)} />
                                <Divider orientation='vertical' style={{ height: 50, width: 2, background: 'black', marginRight: 20 }} />
                                <SmartphoneIcon style={{ marginRight: 20, color: !desktop ? '#faa03c' : null, cursor: 'pointer' }} onClick={() => setDesktop(false)} />

                                <Button variant="contained" onClick={(e) => {

                                    setIdTemaSelecionado(idTema);
                                    getConfirmation();
                                }}>Escolher layout</Button>
                            </Box>
                        </Box>
                        <Divider style={{ width: '100%', marginTop: 15 }} />
                    </Box>
                }

                <Box style={{ width: '100%', height: '100%', minHeight: 700, marginTop: 20 }}>
                    {
                        urlImagemDescricao !== null &&
                        <iframe
                            className="iframeModal"
                            title="Descrição temas"
                            style={{ '-moz-transform': 'scale(0.75)' }}
                            width="100%"
                            height="100%"
                            loading="lazy"
                            allowFullScreen
                            src={urlImagemDescricao}
                        ></iframe>
                    }
                    {
                        linkLoja !== null && !desktop ? <Box style={{ width: '100%', minHeight: 700, display: 'flex', justifyContent: 'center' }}>
                            <iframe
                                className="iframeModal"
                                title="Temas"
                                width={350}
                                style={{ padding: 0, width: 350, minHeight: 700, border: '1px solid #cccccc' }}
                                height="100%"
                                loading="lazy"
                                allowFullScreen
                                src={linkLoja}
                            ></iframe>
                        </Box> : <iframe
                            title="Temas"
                            width="100%"
                            height="100%"
                            loading="lazy"
                            allowFullScreen
                            src={linkLoja}
                        ></iframe>

                    }
                </Box>

            </Box>
        </Modal> : null;
    }

    const CardTema = ({ tema, index }) => {
        const [passouMouse, setPassouMouse] = useState(false);
        const [openModalDescricao, setOpenModalDescricao] = useState(false);
        const [openModalLojaDemonstracao, setOpenModalDemonstracao] = useState(false);

        return <Box
            style={{ width: 400, height: 350, marginBottom: 80 }}
            onMouseEnter={() => setPassouMouse(true)}
            onMouseLeave={() => setPassouMouse(false)}
        >
            {
                openModalDescricao &&
                <ModalDescricao idTema={tema.IdTema} open={openModalDescricao} urlImagemDescricao={tema.UrlImagemDescricaoTema} handleClose={() => setOpenModalDescricao(false)} />
            }
            {
                openModalLojaDemonstracao &&
                <ModalDescricao idTema={tema.IdTema} open={openModalLojaDemonstracao} linkLoja={tema.UrlTema} handleClose={() => setOpenModalDemonstracao(false)} />
            }

            <img style={{ opacity: passouMouse ? .5 : 1 }} width={'100%'} height={'320px'} src={tema.UrlImagem ? tema.UrlImagem : 'https://leapstyle.blob.core.windows.net/crm-onboard/coffe_mini.png'} />

            {
                passouMouse &&
                <Box style={{ position: 'relative', bottom: 180, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" style={{ width: 115, background: 'black' }} onClick={() => setOpenModalDemonstracao(true)}>Ver loja</Button>
                    <Button variant="contained" style={{ marginTop: 10 }} onClick={() => setOpenModalDescricao(true)}>Descrição</Button>
                </Box>
            }

            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-top', marginTop: passouMouse ? -60 : 10 }}>
                <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>{tema.Nome}</Typography>

                <Box style={{ width: 320, overflowY: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                    {
                        tipoLojaTema.filter(a => a.IdTema === tema.IdTema).map(item => {
                            const tipos = tiposEcommerce.filter(a => a.idTipoLojaRef === item.IdTipoLojaRef);

                            return tipos.map((tipo, index) => {
                                return <Typography key={`tipo-${index}`} style={{ fontSize: 15, borderRadius: 20, border: '1px solid #d39116', padding: 3, width: 'auto', minWidth: 100, maxWidth: 130, textAlign: 'center', height: 28, marginRight: 5, marginBottom: 5 }}>{tipo.nome}</Typography>
                            })

                        })
                    }
                </Box>
            </Box>
        </Box>
    }

    const handleCheckResposta = (idPergunta, idResposta, text = '', idSexo = 0, idCategoria = 0, idPeca = 0) => {
        const perguntas = [...dadosOnboard._QuestionarioPergunta];

        dadosOnboard._QuestionarioPergunta.forEach(pergunta => {
            if (pergunta.IdQuestionarioPergunta === idPergunta) {
                pergunta._QuestionarioResposta.forEach(resposta => {
                    if (pergunta.Tipo === 'RADIO') {
                        if (resposta.IdQuestionarioResposta === idResposta) {
                            resposta.Checked = !resposta.Checked
                        }
                        else {
                            resposta.Checked = false;
                        }
                    }
                    else if (pergunta.Tipo === 'CHECK' && resposta.IdQuestionarioResposta === idResposta) {
                        resposta.Checked = !resposta.Checked;
                        resposta.IdSexoRef = idSexo;
                        resposta.IdCategoriaRef = idCategoria;
                        resposta.IdPecaRef = idPeca;
                    }
                    else if (pergunta.Tipo === 'TEXT' && resposta.IdQuestionarioResposta === idResposta) {
                        resposta.RespostaTexto = text;
                    }
                });
            }
        });

        setDadosOnboard({ ...dadosOnboard, _QuestionarioPergunta: perguntas });
    }

    useEffect(() => {
        if (dadosRespostaTema.etapaAtual === 6) {
            setDataHoraAgora(Date.now() + 4000);

            for (let index = 1; index <= 4; index++) {
                setTimeout(() => {
                    setPercentualEtapa(index);
                }, 1000);
            }
        }
    }, [dadosRespostaTema?.etapaAtual]);


    const Etapas = () => {
        const styleProgress = {
            width: telaMenorIgual1024 && !isMobile() ? 70 : isMobile() ? 50 : 100,
            height: 6,
            borderRadius: 12,
            background: '#d4d4d4',
            border: '1px solid #d4d4d4',
            marginRight: 10
        }

        return <Box style={{ width: telaMenorIgual1024 && !isMobile() ? 700 : null, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 60 }}>
            <Typography style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 15 }}>Etapas</Typography>

            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box style={{ ...styleProgress, background: dadosRespostaTema?.questao1?.length > 0 ? '#f1416c' : '#d4d4d4' }}></Box>
                <Box style={{ ...styleProgress, background: dadosRespostaTema?.questao2?.length > 0 ? '#f1416c' : '#d4d4d4' }}></Box>
                <Box style={{ ...styleProgress, background: dadosRespostaTema?.questao3?.length > 0 ? '#f1416c' : '#d4d4d4' }}></Box>
                <Box style={{ ...styleProgress, background: dadosRespostaTema?.questao4 !== 0 ? '#f1416c' : '#d4d4d4' }}></Box>
                <Box style={{ ...styleProgress, background: dadosRespostaTema?.questao5 !== 0 ? '#f1416c' : '#d4d4d4' }}></Box>
            </Box>

        </Box>
    }

    const Footer = () => {
        return <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 50, paddingLeft: 30, paddingRight: 15 }}>
            <Typography style={{ color: '#d4d4d4', fontSize: 16 }}>Você pode editar essas informações no seu painel de controle.</Typography>
        </Box>
    }


    if (isMobile() && !location.state?.veioTelaProduto) { 
        navigate('/configurar-ecommerce', { replace: true, state: { idTemaSelecionado: 5 } }); //5 = Lollypop
    }

    // if (isMobile()) {

    //     return <Box style={{ width: '100%', height: '100%', minHeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //         <Card style={{ width: 350, height: 'auto', minHeight: 400, padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    //             <Box style={{ width: '100%' }}>
    //                 <WarningIcon style={{ fontSize: 60 }} color="primary" />
    //             </Box>
    //             <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Para continuar, recomendamos o uso de um computador ou tablet para melhor desempenho e experiência.</Typography>

    //             <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Gostaria de continuar no computador? Envie este link para seu email e acesse de lá.</Typography>

    //             <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid #c0c0c0', padding: 10, borderRadius: 10 }}>https://crm.leapstyle.me/ <ContentCopyIcon style={{ marginLeft: 15, fontSize: 25, cursor: 'pointer' }} onClick={() => {
    //                 try {
    //                     navigator.clipboard.writeText('https://crm.leapstyle.me/');

    //                     dispatch({
    //                         type: CONTROLEMENSAGEM_SUCESSO,
    //                         tipoComponente: 'alert',
    //                         titulo: 'Aviso',
    //                         message: 'Link copiado',
    //                     });
    //                 }
    //                 catch (err) {

    //                 }
    //             }} /></Typography>

    //             <Button variant="contained" onClick={() => {
    //                 removeStorage('usrin');
    //                 removeStorage('superUsrin');
    //                 removeStorage('onBoard');
    //                 navigate('/login');
    //             }}>Concluir</Button>
    //         </Card>
    //     </Box>
    // }

    try {
        return loading ? <LoadingAllPage text={loadingMensagem ? "Agora vamos configurar a sua loja" : "Buscando dados..."} /> : <Container style={{ minHeight: isMobile() ? 1100 : null, marginBottom: isMobile() ? 150 : null }}>
            {/* <GoWhatsApp whatsNumber={`11976548907`} /> */}

            <Confirmation
                title="Selecionar tema"
                mensagem={`Você tem certeza que deseja selecionar este tema para sua loja?`}
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Confirmar",
                    onClick: () => {
                        navigate('/configurar-ecommerce', { replace: true, state: { idTemaSelecionado: idTemaSelecionado } });
                    }
                }}
            />

            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 15 }}>
                <Button style={{ background: 'black' }} variant="contained" onClick={() => navigate('/on-board')}>Cancelar</Button>
            </Box>

            {
                !location.state?.escolherTemplate && <>
                    {
                        dadosOnboard?._QuestionarioPergunta?.length > 0 && dadosRespostaTema.etapaAtual === 1 &&
                        <Grid container spacing={3} style={{ display: 'flex', height: '90vh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={'https://leapstyle.blob.core.windows.net/crm-onboard/onboard_laranja.png'} width={'100%'} height={'100%'} style={{ maxWidth: 600, maxHeight: 570 }} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <Box style={{ maxWidth: telaMenorIgual1024 ? 500 : 600, paddingLeft: telaMenorIgual1024 ? 20 : 0, height: 670, paddingTop: 70, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Etapas />

                                    <Typography style={{ fontSize: isMobile() ? 18 : 22, fontWeight: 'bold', color: '#555556' }}>{dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === 1)[0].Descricao}</Typography>

                                    <Box style={{ marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        {
                                            publicoAlvoLoja.map(item => {
                                                return <Button
                                                    key={`etapa1-${item.id}`}
                                                    variant="contained"
                                                    style={{ marginTop: 15, fontWeight: 'bold', background: dadosRespostaTema?.questao1?.filter(a => a.id === item.id || a.IdQuestionarioResposta === item.id).length > 0 ? '#f1416c' : 'white', color: dadosRespostaTema?.questao1?.filter(a => a.id === item.id || a.IdQuestionarioResposta === item.id).length > 0 ? 'white' : 'black', height: 60, width: 180, marginRight: 20 }}
                                                    onClick={() => {
                                                        if (dadosRespostaTema?.questao1?.filter(a => a.id === item.id).length > 0) {
                                                            const questao1 = dadosRespostaTema.questao1?.filter(a => a.id !== item.id);
                                                            setDadosRespostaTema({ ...dadosRespostaTema, questao1: questao1 });
                                                        }
                                                        else {
                                                            const questao1 = dadosRespostaTema.questao1;
                                                            questao1.push(item);

                                                            setDadosRespostaTema({ ...dadosRespostaTema, questao1: questao1 });
                                                        }
                                                    }}
                                                >
                                                    {item.nome}
                                                </Button>
                                            })
                                        }
                                    </Box>

                                    <Footer />

                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 67 }}>
                                        <Button
                                            disabled={dadosRespostaTema?.questao1?.length === 0}
                                            variant="contained"
                                            style={{ background: dadosRespostaTema?.questao1?.length === 0 ? '#d4d4d4' : '#f7931e', color: '#ffff' }}
                                            onClick={async () => {
                                                if (dadosRespostaTema.questao1.length === 0) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: 'Favor selecionar ao menos um publico algo da loja',
                                                    });
                                                    return;
                                                }

                                                const categoriaAcessorios = {
                                                    Nome: 'Acessórios',
                                                    FlagPai: true,
                                                    FlagAcessorio: true,
                                                    Categorias: []
                                                }

                                                const categoriaBeleza = {
                                                    Nome: 'Beleza',
                                                    FlagPai: true,
                                                    FlagAcessorio: true,
                                                    Categorias: []
                                                }

                                                const questao1 = [...dadosRespostaTema.questao1];
                                                for (const questao of questao1) {
                                                    const categoriasSexo = [];
                                                    categoriaBeleza.Categorias = [];
                                                    categoriaAcessorios.Categorias = [];

                                                    const respCategorias = await POST_DATA(`Categoria/GetCategoriaByIdSexoIN?sIdSexo=${questao.idSexoRef}&Log=`);
                                                    for (const categoria of respCategorias[0]?._Categoria) {
                                                        if (categoria.FlagAcessorio) {
                                                            categoriaAcessorios.Categorias.push(categoria);
                                                        }
                                                        else if (categoria.FlagBeleza) {
                                                            categoriaBeleza.Categorias.push(categoria);
                                                        }
                                                        else {
                                                            categoriasSexo.push(categoria);
                                                        }
                                                    }

                                                    categoriasSexo.push(categoriaAcessorios);
                                                    categoriasSexo.push(categoriaBeleza);

                                                    questao._Categoria = categoriasSexo;
                                                }

                                                setSexoXCategorias(questao1);

                                                setDadosRespostaTema({ ...dadosRespostaTema, questao1: questao1, etapaAtual: 2 });

                                                window.scrollTo(0, 0);
                                            }}>Continuar</Button>
                                    </Box>
                                </Box>

                            </Grid>
                        </Grid>
                    }

                    {
                        dadosRespostaTema.etapaAtual === 2 &&
                        <Grid container spacing={3} style={{ display: 'flex', height: '90vh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={'https://leapstyle.blob.core.windows.net/crm-onboard/onboard_laranja.png'} width={'100%'} height={'100%'} style={{ maxWidth: 600, maxHeight: 570 }} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <Box style={{ maxWidth: telaMenorIgual1024 ? 400 : 600, paddingLeft: telaMenorIgual1024 && !isMobile() ? 100 : isMobile() ? 65 : 0, height: isMobile() ? 700 : 570, overflowY: 'auto', paddingTop: 30, paddingBottom: 10, paddingRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Etapas />

                                    <Typography style={{ fontSize: isMobile() ? 18 : 22, fontWeight: 'bold', color: '#555556', marginBottom: 30 }}>{dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === 2)[0].Descricao}</Typography>

                                    {
                                        sexoXCategorias && sexoXCategorias?.map(item => {
                                            return <Box key={`sexo-${item.idSexoRef}`} style={{ width: '100%', maxWidth: '900px', marginBottom: 20 }}>

                                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 15 }}>{item.nome}</Typography>

                                                <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', overflowY: 'auto', justifyContent: 'center' }}>
                                                    {
                                                        item._Categoria.map((categoria, cont) => {
                                                            if (categoria.FlagPai) {
                                                                return <Box key={`grupo-categoria-${cont}`} style={{ width: '100%' }}>
                                                                    <Typography style={{ fontWeight: 'bold', marginTop: 20 }}>{categoria.Nome}</Typography>

                                                                    <Box style={{ width: '100%', marginTop: 15, display: 'flex', flexWrap: 'wrap' }}>
                                                                        {
                                                                            categoria.Categorias.map((catcat, idxIdx) => {
                                                                                return <Button
                                                                                    key={`etapa2-${item.idSexoRef}-${catcat.IdCategoria}`}
                                                                                    variant="contained"
                                                                                    style={{ marginRight: 15, fontWeight: 'bold', background: dadosRespostaTema.questao2.filter(a => a.idCategoria === catcat.IdCategoria && a.idSexo === item.idSexoRef).length > 0 ? '#f1416c' : 'white', color: dadosRespostaTema.questao2.filter(a => a.idCategoria === catcat.IdCategoria && a.idSexo === item.idSexoRef).length > 0 ? 'white' : 'black', height: 60, width: '10%', minWidth: 165, maxWidth: 165, marginRight: 20, marginBottom: 15 }}
                                                                                    onClick={() => {

                                                                                        const opcaoCategoria = opcaoRespostaCategorias.filter(a => a.idCategoriaRef === catcat.IdCategoria)[0];
                                                                                        const resposta = {
                                                                                            id: opcaoCategoria.id,
                                                                                            idCategoria: catcat.IdCategoria,
                                                                                            nomeCategoria: catcat.Descricao,
                                                                                            idSexo: item.idSexoRef,
                                                                                            nomeSexo: item.nome
                                                                                        }

                                                                                        if (dadosRespostaTema.questao2.filter(a => a.idCategoria === resposta.idCategoria && a.idSexo === resposta.idSexo).length > 0) {
                                                                                            const questao2 = dadosRespostaTema.questao2;
                                                                                            const idx = dadosRespostaTema.questao2.findIndex(a => a.idCategoria === resposta.idCategoria && a.idSexo === resposta.idSexo);

                                                                                            if (idx !== -1) {
                                                                                                questao2.splice(idx, 1);
                                                                                                setDadosRespostaTema({ ...dadosRespostaTema, questao2: questao2 });
                                                                                            }

                                                                                        }
                                                                                        else {
                                                                                            const questao2 = dadosRespostaTema.questao2;
                                                                                            questao2.push(resposta);

                                                                                            setDadosRespostaTema({ ...dadosRespostaTema, questao2: questao2 });
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {catcat.Descricao}
                                                                                </Button>
                                                                            })
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            }
                                                            else {
                                                                return <Button
                                                                    key={`etapa2-${item.idSexoRef}-${categoria.IdCategoria}`}
                                                                    variant="contained"
                                                                    style={{ marginRight: 15, fontWeight: 'bold', background: dadosRespostaTema.questao2.filter(a => a.idCategoria === categoria.IdCategoria && a.idSexo === item.idSexoRef).length > 0 ? '#f1416c' : 'white', color: dadosRespostaTema.questao2.filter(a => a.idCategoria === categoria.IdCategoria && a.idSexo === item.idSexoRef).length > 0 ? 'white' : 'black', height: 60, width: '10%', minWidth: 165, maxWidth: 165, marginRight: 20, marginBottom: 15 }}
                                                                    onClick={() => {

                                                                        const opcaoCategoria = opcaoRespostaCategorias.filter(a => a.idCategoriaRef === categoria.IdCategoria)[0];
                                                                        const resposta = {
                                                                            id: opcaoCategoria.id,
                                                                            idCategoria: categoria.IdCategoria,
                                                                            nomeCategoria: categoria.Descricao,
                                                                            idSexo: item.idSexoRef,
                                                                            nomeSexo: item.nome
                                                                        }

                                                                        if (dadosRespostaTema.questao2.filter(a => a.idCategoria === resposta.idCategoria && a.idSexo === resposta.idSexo).length > 0) {
                                                                            const questao2 = dadosRespostaTema.questao2;
                                                                            const idx = dadosRespostaTema.questao2.findIndex(a => a.idCategoria === resposta.idCategoria && a.idSexo === resposta.idSexo);

                                                                            if (idx !== -1) {
                                                                                questao2.splice(idx, 1);
                                                                                setDadosRespostaTema({ ...dadosRespostaTema, questao2: questao2 });
                                                                            }

                                                                        }
                                                                        else {
                                                                            const questao2 = dadosRespostaTema.questao2;
                                                                            questao2.push(resposta);

                                                                            setDadosRespostaTema({ ...dadosRespostaTema, questao2: questao2 });
                                                                        }
                                                                    }}
                                                                >
                                                                    {categoria.Descricao}
                                                                </Button>
                                                            }
                                                        })
                                                    }

                                                    <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                                                </Box>
                                            </Box>
                                        })
                                    }

                                    <Footer />

                                    <Box style={{ width: '100%', maxWidth: '900px', display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
                                        <Button variant="contained" style={{ background: 'black', color: '#ffff' }} onClick={() => setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 1 })}>Voltar</Button>
                                        <Button
                                            disabled={(dadosRespostaTema?.questao2?.length === 0 || loadingPecaXCategorias)}
                                            variant="contained"
                                            style={{ background: (dadosRespostaTema?.questao2?.length === 0 || loadingPecaXCategorias) ? '#d4d4d4' : '#f7931e', color: '#ffff' }}
                                            onClick={async () => {
                                                if (dadosRespostaTema.questao2.length === 0) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: 'Favor selecionar ao menos uma categoria da loja',
                                                    });
                                                    return;
                                                }

                                                setLoadingPecaXCategorias(true);

                                                const cats = [];
                                                const questao2 = [...dadosRespostaTema.questao2];
                                                for (const questao of questao2) {
                                                    questao.idSexoRef = questao.idSexo;
                                                    questao.idCategoriaRef = questao.idCategoria;
                                                    cats.push(questao.idCategoria);
                                                }

                                                const resp = await POST_DATA(`Peca/GetPecasByIdCategorias?ids=${cats.join(',')}`);

                                                setPecasXCategoria(resp);

                                                setDadosRespostaTema({ ...dadosRespostaTema, questao2: questao2, etapaAtual: 3 });

                                                setLoadingPecaXCategorias(false);

                                                window.scrollTo(0, 0);
                                            }}>
                                            {loadingPecaXCategorias && <CircularProgress size={25} style={{ marginRight: 10 }} />}
                                            Continuar
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    }

                    {
                        dadosRespostaTema.etapaAtual === 3 &&
                        <Grid container spacing={3} style={{ display: 'flex', height: '90vh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={'https://leapstyle.blob.core.windows.net/crm-onboard/onboard_laranja.png'} width={'100%'} height={'100%'} style={{ maxWidth: 600, maxHeight: 570 }} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <Box style={{ maxWidth: telaMenorIgual1024 ? 500 : 600, paddingLeft: telaMenorIgual1024 && !isMobile() ? 20 : isMobile() ? 65 : 0, maxHeight: isMobile() ? 700 : 570, overflowY: 'auto', paddingTop: 30, paddingBottom: 20, paddingRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Etapas />

                                    <Typography style={{ fontSize: 22, fontWeight: 'bold', color: '#555556', marginBottom: 30 }}>{dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === 3)[0].Descricao}</Typography>

                                    {
                                        dadosRespostaTema.questao2.map(questao => {
                                            return <Box key={`categorias-${questao.idSexo}-${questao.idCategoria}`} style={{ width: '100%', maxWidth: '900px', marginBottom: 20 }}>
                                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 15 }}>{questao.nomeSexo} - {questao.nomeCategoria} <span style={{ fontSize: 11, color: '#d4d4d4' }}>(É necessário selecionar pelo menos um item abaixo)</span></Typography>

                                                <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                                    {
                                                        pecasXCategoria.filter(a => a.IdSexo === questao.idSexo && a.IdCategoria === questao.idCategoria).map((item, index) => {
                                                            return <Button
                                                                key={`etapa3-${questao.idSexo}-${questao.idCategoria}-${item.IdPeca}-${index}`}
                                                                variant="contained"
                                                                style={{ marginRight: 15, fontWeight: 'bold', background: dadosRespostaTema.questao3.filter(a => a.idPeca === item.IdPeca && a.idCategoria === item.IdCategoria && a.idSexo === item.IdSexo).length > 0 ? '#f1416c' : 'white', color: dadosRespostaTema.questao2.filter(a => a.idPeca === item.idPeca && a.idCategoria === item.idCategoria && a.idSexo === item.idSexo).length > 0 ? 'white' : 'black', height: 60, width: '10%', minWidth: 165, maxWidth: 165, marginRight: 20, marginBottom: 15 }}
                                                                onClick={() => {
                                                                    const opcaoPeca = opcaoRespostaPexasXCategorias.filter(a => a.idPecaRef === item.IdPeca)[0];

                                                                    const resposta = {
                                                                        id: opcaoPeca.id,
                                                                        idCategoria: item.IdCategoria,
                                                                        idSexo: item.IdSexo,
                                                                        idPeca: item.IdPeca
                                                                    }

                                                                    if (dadosRespostaTema.questao3.filter(a => a.idCategoria === resposta.idCategoria && a.idSexo === resposta.idSexo && a.idPeca === resposta.idPeca).length > 0) {
                                                                        const questao3 = dadosRespostaTema.questao3;
                                                                        const idx = dadosRespostaTema.questao3.findIndex(a => a.idCategoria === resposta.idCategoria && a.idSexo === resposta.idSexo && a.idPeca === resposta.idPeca);

                                                                        if (idx !== -1) {
                                                                            questao3.splice(idx, 1);
                                                                            setDadosRespostaTema({ ...dadosRespostaTema, questao3: questao3 });
                                                                        }

                                                                    }
                                                                    else {
                                                                        const questao3 = dadosRespostaTema.questao3;
                                                                        questao3.push(resposta);

                                                                        setDadosRespostaTema({ ...dadosRespostaTema, questao3: questao3 });
                                                                    }
                                                                }}
                                                            >
                                                                {item.Nome}
                                                            </Button>
                                                        })
                                                    }
                                                </Box>

                                                <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                                            </Box>
                                        })
                                    }

                                    <Footer />

                                    <Box style={{ width: '100%', maxWidth: '900px', display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
                                        <Button variant="contained" style={{ background: 'black', color: '#ffff' }} onClick={() => setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 2 })}>Voltar</Button>
                                        <Button
                                            disabled={(dadosRespostaTema?.questao3?.length === 0 || loadingPecaXSubPecas)}
                                            variant="contained"
                                            style={{ background: (dadosRespostaTema?.questao3?.length === 0 || loadingPecaXSubPecas) ? '#d4d4d4' : '#f7931e', color: '#ffff' }}
                                            onClick={async () => {
                                                if (dadosRespostaTema.questao3.length === 0) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: 'Favor selecionar ao menos uma peça que será trabalhada',
                                                    });
                                                    return;
                                                }

                                                setLoadingPecaXSubPecas(true);

                                                const questao3 = [...dadosRespostaTema.questao3];
                                                const questao6 = [];

                                                for (const questao of questao3) {
                                                    questao.idSexoRef = questao.idSexo;
                                                    questao.idCategoriaRef = questao.idCategoria;
                                                    questao.idPecaRef = questao.idPeca;


                                                    const subPecas = await POST_DATA(`SubPeca/GetSubPecaByIdsPecas?ids=${questao.idPeca}`);
                                                    for (const subPeca of subPecas) {
                                                        const quest6 = opcaoRespostaSubPecas.filter(a => a.idSubPecaRef === subPeca.IdSubPeca)[0]; //Sub Peças

                                                        if (questao6.filter(a => a.idSexoRef === questao.idSexo && a.idCategoriaRef === questao.idCategoria && a.idPecaRef === questao.idPeca && a.idSubPecaRef === subPeca.IdSubPeca).length > 0) continue;

                                                        if (quest6 === undefined) continue;

                                                        quest6.idSexoRef = questao.idSexo;
                                                        quest6.idCategoriaRef = questao.idCategoria;
                                                        quest6.idPecaRef = questao.idPeca;
                                                        quest6.idSubPecaRef = subPeca.IdSubPeca;

                                                        questao6.push(quest6);
                                                    }
                                                }

                                                setDadosRespostaTema({ ...dadosRespostaTema, questao3: questao3, questao6: questao6, etapaAtual: 4 });

                                                setLoadingPecaXSubPecas(false);

                                                window.scrollTo(0, 0);
                                            }}>
                                            {loadingPecaXSubPecas && <CircularProgress size={25} style={{ marginRight: 10 }} />}
                                            Continuar
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    }

                    {
                        dadosRespostaTema.etapaAtual === 4 &&
                        <Grid container spacing={3} style={{ display: 'flex', height: '90vh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={'https://leapstyle.blob.core.windows.net/crm-onboard/onboard_laranja.png'} width={'100%'} height={'100%'} style={{ maxWidth: 600, maxHeight: 570 }} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <Box style={{ maxWidth: telaMenorIgual1024 ? 500 : 600, paddingLeft: telaMenorIgual1024 && !isMobile() ? 20 : isMobile() ? 65 : 0, height: isMobile() ? 700 : 590, overflowY: 'auto', paddingTop: 30, paddingBottom: 20, paddingRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Etapas />

                                    <Box style={{ width: '100%', maxWidth: '900px', marginBottom: 20 }}>
                                        <Typography style={{ fontSize: 22, fontWeight: 'bold', color: '#555556' }}>{dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === 4)[0].Descricao}</Typography>
                                        <Autocomplete
                                            style={{ width: '100%', marginTop: 100 }}
                                            id={`opcoesTema`}
                                            options={opcoesUsoTema}
                                            value={dadosRespostaTema.questao4Imput}
                                            defaultValue={0}
                                            getOptionLabel={(option) => option?.nome || 'Selecionar'}
                                            onChange={(event, newValue) => {
                                                setDadosRespostaTema({ ...dadosRespostaTema, questao4: newValue?.id, questao4Imput: newValue })
                                            }}
                                            inputValue={dadosRespostaTema.questao4InputValueAutocomplete}
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue === '') setDadosRespostaTema({ ...dadosRespostaTema, questao4: 0, questao4InputValueAutocomplete: '' });
                                                setDadosRespostaTema({ ...dadosRespostaTema, questao4InputValueAutocomplete: newInputValue });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" placeholder="Opção de criação do site" />
                                            )}
                                        />
                                        {/* <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: 20 }}>
                                <Typography style={{ color: '#838383', fontSize: 11 }}>Exemplos</Typography>
                                {
                                    opcoesUsoTema.map(item => {
                                        return <Typography style={{ color: '#838383' }} key={item.id}>{item.nome}</Typography>
                                    })
                                }
                            </Box> */}
                                    </Box>

                                    <Box style={{ width: '100%', maxWidth: '900px', display: 'flex', justifyContent: 'space-between', marginTop: 210 }}>
                                        <Button variant="contained" style={{ background: 'black', color: '#ffff' }} onClick={() => setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 3 })}>Voltar</Button>

                                        <Button variant="contained" style={{ background: '#f7931e', color: '#ffff' }} onClick={() => {
                                            if (dadosRespostaTema.questao4 === 0) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    tipoComponente: 'alert',
                                                    titulo: 'Aviso',
                                                    message: 'Favor selecionar uma opção de site',
                                                });
                                                return;
                                            }

                                            setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 5 });
                                            handleCheckResposta(dadosRespostaTema.questao4IdPerguntaQuestonario, dadosRespostaTema.questao4);

                                            window.scrollTo(0, 0);
                                        }}>Continuar</Button>
                                    </Box>
                                </Box>

                            </Grid>
                        </Grid>
                    }

                    {
                        dadosRespostaTema.etapaAtual === 5 &&
                        <Grid container spacing={3} style={{ display: 'flex', height: '90vh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={'https://leapstyle.blob.core.windows.net/crm-onboard/onboard_rosa.png'} width={'100%'} height={'100%'} style={{ maxWidth: 600, maxHeight: 570 }} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: 20 }}>

                                <Box style={{ maxWidth: telaMenorIgual1024 ? 500 : 600, paddingLeft: telaMenorIgual1024 && !isMobile() ? 20 : isMobile() ? 65 : 0, height: isMobile() ? 700 : 600, overflowY: 'auto', paddingTop: 30, paddingBottom: 0, paddingRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Etapas />

                                    <Box style={{ width: '100%', maxWidth: '900px', marginBottom: 20 }}>
                                        <Typography style={{ fontSize: 22, fontWeight: 'bold', color: '#555556' }}>{dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === 5)[0].Descricao}</Typography>
                                        <Autocomplete
                                            style={{ width: '100%', marginTop: 100 }}
                                            id={`tipoLoja`}
                                            options={tiposEcommerce}
                                            value={dadosRespostaTema.questao5Imput}
                                            defaultValue={0}
                                            getOptionLabel={(option) => option?.nome || 'Selecionar'}
                                            onChange={(event, newValue) => {
                                                setDadosRespostaTema({ ...dadosRespostaTema, questao5: newValue?.id, questao5Imput: newValue });
                                                setTipoLojaTemaSelecionado(newValue);
                                            }}
                                            inputValue={dadosRespostaTema.questao5InputValueAutocomplete}
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue === '') setDadosRespostaTema({ ...dadosRespostaTema, questao5InputValueAutocomplete: 0 });
                                                setDadosRespostaTema({ ...dadosRespostaTema, questao5InputValueAutocomplete: newInputValue });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" placeholder="Opção de e-commerce" />
                                            )}
                                        />
                                        {/* <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: 20 }}>
                                <Typography style={{ color: '#838383', fontSize: 11 }}>Exemplos</Typography>
                                {
                                    tiposEcommerce.map(item => {
                                        return <Typography style={{ color: '#838383' }} key={item.id}>{item.nome}</Typography>
                                    })
                                }
                            </Box> */}

                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 230 }}>
                                            <Button variant="contained" style={{ background: 'black', color: '#ffff' }} onClick={() => setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 4 })}>Voltar</Button>
                                            <Button variant="contained" style={{ background: '#f7931e', color: '#ffff' }} onClick={async () => {
                                                if (dadosRespostaTema.questao5 === 0) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: 'Favor selecionar um tipo de e-commerce',
                                                    });
                                                    return;
                                                }

                                                setTipoLojaNome(tipoLojaTemaSeleciondo.nome);
                                                const tipoLojaTemaResult = await GET_DATA(`Tema/GetTemasByIdTipoLoja?id=${tipoLojaTemaSeleciondo.idTipoLojaRef}`);
                                                if (tipoLojaTemaResult.length > 0)
                                                    setTemasFiltrados(tipoLojaTemaResult);
                                                else
                                                    setTemasFiltrados(temas);

                                                const arrTipoTemasLoja = [];
                                                for (const itemTema of tipoLojaTemaResult) {
                                                    arrTipoTemasLoja.push({ IdTema: itemTema.IdTema, IdTipoLojaRef: tipoLojaTemaSeleciondo.idTipoLojaRef });
                                                }
                                                setTipoLojaTema(arrTipoTemasLoja);

                                                setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 6 });
                                                handleCheckResposta(dadosRespostaTema.questao5IdPerguntaQuestonario, dadosRespostaTema.questao5);

                                                window.scrollTo(0, 0);
                                            }}>Continuar</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    }

                    {
                        dadosRespostaTema.etapaAtual === 6 &&
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                            <Countdown date={dataHoraAgora} renderer={null} onComplete={async () => {
                                const respostasSalvar = [];

                                for (const questao1 of dadosRespostaTema.questao1) {
                                    respostasSalvar.push({
                                        "IdQuestionario": 2,
                                        "IdQuestionarioPergunta": dadosRespostaTema.questao1IdPerguntaQuestonario,
                                        "IdQuestionarioResposta": questao1.id,
                                        "IdUsuario": userLoggedIn.IdUsuario,
                                        "RespostaTexto": '',
                                        "FlagAtivo": true,
                                        "IdSexo": questao1.idSexoRef,
                                        "IdCategoria": 0,
                                        "IdPeca": 0,
                                        "IdSubPeca": 0,
                                        "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                                    });
                                }

                                for (const questao2 of dadosRespostaTema.questao2) {
                                    respostasSalvar.push({
                                        "IdQuestionario": 2,
                                        "IdQuestionarioPergunta": dadosRespostaTema.questao2IdPerguntaQuestonario,
                                        "IdQuestionarioResposta": questao2.id,
                                        "IdUsuario": userLoggedIn.IdUsuario,
                                        "RespostaTexto": '',
                                        "FlagAtivo": true,
                                        "IdSexo": questao2.idSexoRef,
                                        "IdCategoria": questao2.idCategoriaRef,
                                        "IdPeca": 0,
                                        "IdSubPeca": 0,
                                        "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                                    });
                                }

                                for (const questao3 of dadosRespostaTema.questao3) {
                                    respostasSalvar.push({
                                        "IdQuestionario": 2,
                                        "IdQuestionarioPergunta": dadosRespostaTema.questao3IdPerguntaQuestonario,
                                        "IdQuestionarioResposta": questao3.id,
                                        "IdUsuario": userLoggedIn.IdUsuario,
                                        "RespostaTexto": '',
                                        "FlagAtivo": true,
                                        "IdSexo": questao3.idSexoRef,
                                        "IdCategoria": questao3.idCategoriaRef,
                                        "IdPeca": questao3.idPecaRef,
                                        "IdSubPeca": 0,
                                        "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                                    });
                                }

                                if (dadosRespostaTema.questao6.length > 0) {
                                    for (const questao6 of dadosRespostaTema.questao6) {
                                        respostasSalvar.push({
                                            "IdQuestionario": 2,
                                            "IdQuestionarioPergunta": dadosRespostaTema.questao6IdPerguntaQuestonario,
                                            "IdQuestionarioResposta": questao6.id,
                                            "IdUsuario": userLoggedIn.IdUsuario,
                                            "RespostaTexto": '',
                                            "FlagAtivo": true,
                                            "IdSexo": questao6.idSexoRef,
                                            "IdCategoria": questao6.idCategoriaRef,
                                            "IdPeca": questao6.idPecaRef,
                                            "IdSubPeca": questao6.idSubPecaRef,
                                            "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                                        });
                                    }
                                }
                                else {
                                    respostasSalvar.push({
                                        "IdQuestionario": 2,
                                        "IdQuestionarioPergunta": dadosRespostaTema.questao6IdPerguntaQuestonario,
                                        "IdQuestionarioResposta": -1,
                                        "IdUsuario": userLoggedIn.IdUsuario,
                                        "RespostaTexto": '',
                                        "FlagAtivo": true,
                                        "IdSexo": 0,
                                        "IdCategoria": -1,
                                        "IdPeca": -1,
                                        "IdSubPeca": -1,
                                        "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                                    });
                                }


                                for (const pergunta of dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === 4 || a.Posicao === 5)) { //Respostas
                                    for (const resposta of pergunta._QuestionarioResposta.filter(a => a.Checked || a.RespostaTexto?.length > 0)) {
                                        respostasSalvar.push({
                                            "IdQuestionario": pergunta.IdQuestionario,
                                            "IdQuestionarioPergunta": pergunta.IdQuestionarioPergunta,
                                            "IdQuestionarioResposta": resposta.IdQuestionarioResposta,
                                            "IdUsuario": userLoggedIn.IdUsuario,
                                            "RespostaTexto": resposta.RespostaTexto || '',
                                            "FlagAtivo": true,
                                            "IdSexo": resposta.IdSexoRef,
                                            "IdCategoria": resposta.IdCategoriaRef,
                                            "IdPeca": resposta.IdPecaRef,
                                            "IdSubPeca": resposta.IdSubPecaRef,
                                            "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                                        });
                                    }
                                }

                                await PUT_DATA(`Questionario/InsertQuestionarioUsuario`, respostasSalvar);
                                //Salvar as referências
                                await PUT_DATA(`Questionario/SalvarQuationarioUsuarioReferencias?idUsuario=${userLoggedIn.IdUsuario}&idQuestionario=2`);

                                setDadosRespostaTema({ ...dadosRespostaTema, etapaAtual: 7 });

                                window.scrollTo(0, 0);
                            }} />

                            <Box style={{ width: '800px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#f0426c', paddingLeft: 20, paddingRight: 20 }}>
                                <Typography style={{ color: '#ffff', fontSize: 18, fontWeight: 'bold' }}>Estamos selecionando os melhores layouts para seu tipo de negócio</Typography>

                                <Box style={{ width: '100%', borderRadius: 30, background: 'white', padding: 10, marginTop: 20 }}>
                                    <LinearProgress
                                        sx={{ width: '100%', height: 25, borderRadius: 10 }}
                                        variant="buffer"
                                        valueBuffer={100}
                                        value={(((percentualEtapa * 25) * 100) / 100)}
                                        color="primary"
                                    />
                                </Box>
                                <Typography style={{ color: '#ffff', fontSize: 11 }}>Melhorando busca...</Typography>
                            </Box>

                        </Box>
                    }

                    {
                        dadosRespostaTema.etapaAtual === 7 && <Countdown date={dataHoraAgora}  renderer={props => <div></div>} onComplete={async () => {
                            Swal.fire({
                                backdrop: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                title: "Parabéns, mais um passo foi dado na liberação da sua Loja 🙂",
                                width: 600,
                                padding: "3em",
                                color: "#33cccc",
                                imageUrl: fireworks,
                                imageHeight: 200,
                                imageAlt: "Finalizar",
                                confirmButtonText: "Continuar",
                                confirmButtonColor: '#F1416C'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    navigate(`/on-board`, { replace: true });
                                }
                            })
                        }} />
                    }

                </>
            }

            {
                location.state?.escolherTemplate &&
                <Box style={{ width: '100%' }}>
                    <Box style={{ width: '100%' }}>
                        <Typography style={{ fontSize: 22, color: '#f7931e', fontWeight: 'bold' }}>Layouts que selecionamos para combinar com seu negócio.</Typography>
                        <Typography style={{ fontSize: 13, color: '#838383' }}>Escolha seu template favorite e crie sua loja.</Typography>
                        <Divider style={{ marginTop: 10 }} />
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 16, color: 'black' }}>{tipoLojaNome}</Typography>
                        <Box style={{ display: 'flex' }}>
                            <Autocomplete
                                style={{ width: '100%', marginTop: 10, minWidth: 150 }}
                                id={`tipoLoja`}
                                options={tiposEcommerce}
                                value={dadosRespostaTema.questao5Imput}
                                defaultValue={0}
                                getOptionLabel={(option) => option?.nome || 'Selecionar'}
                                onChange={async (event, newValue) => {
                                    setDadosRespostaTema({ ...dadosRespostaTema, questao5: newValue?.id, questao5Imput: newValue });

                                    if (newValue !== null) {
                                        const tipoLojaTemaResult = await GET_DATA(`/Tema/GetTemasByIdTipoLoja?id=${newValue.idTipoLojaRef}`);
                                        if (tipoLojaTemaResult.length > 0)
                                            setTemasFiltrados(tipoLojaTemaResult);
                                        else
                                            setTemasFiltrados(temas);
                                    }
                                }}
                                inputValue={dadosRespostaTema.questao5InputValueAutocomplete}
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue === '') {
                                        setTemasFiltrados(temas);
                                        setDadosRespostaTema({ ...dadosRespostaTema, questao5InputValueAutocomplete: 0 });
                                    }
                                    setDadosRespostaTema({ ...dadosRespostaTema, questao5InputValueAutocomplete: newInputValue });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Opção de e-commerce" />
                                )}
                            />
                            <Button variant="contained" style={{ background: 'black', color: 'white', marginLeft: 20, fontSize: 12, minWidth: 180, marginTop: 10 }} onClick={() => setTemasFiltrados(temas)}>Todos os templates</Button>
                        </Box>
                    </Box>
                    <Divider style={{ marginTop: 10 }} />

                    <Grid container spacing={2} style={{ marginTop: 50 }}>
                        {
                            temas.filter(a => a.IdTema !== 1).map((tema, index) => {
                                return <Grid key={`tema-${index}`} item xs={12} md={6} lg={4} style={{ marginBottom: 30, display: 'flex', justifyContent: 'center' }} >
                                    <CardTema key={`tema-${index}`} tema={tema} index={index} matchDownMd={matchDownMd} />
                                </Grid>
                            })
                        }
                    </Grid>
                </Box>
            }

        </Container>
    }
    catch (err) {
        const obj = {
            Descricao: 'Erro ao executar tela do questionário 1',
            Erro: err,
            Controller: 'CRM',
            DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
        };
        PUT_DATA(`/LogErro/CadastroLogErro`, obj);

        dispatch({
            type: CONTROLEMENSAGEM_ERRO,
            titulo: 'Erro',
            message: `Ocorreu um erro inesperado\n${err}`,
            tipoComponente: 'alert'
        });

        SalvarLogSentry(err);
    }
}

export default OnboardDesignSite;