import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, Switch, Tab, Tabs, TextField, Typography } from "@mui/material";
import Card from "../../components/Card";
import * as Yup from 'yup';
import { AlertError, Container } from "./styles";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { bancos, isEmail, valida_cpf_cnpj } from '../../services/utils/functions';
import axios from "axios";
import * as moment from "moment";
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../store/ControleMensagemReducer/types";
import Loader from "../../ui-component/Loader";

import PersonIcon from '@mui/icons-material/Person';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import EmailIcon from '@mui/icons-material/Email';

const InitialValue = {
    IdUsuarioLoginInfluencer: 0,
    Nome: '',
    NickName: '',
    Cpf: '',
    Sexo: 2,
    Email: '',
    Celular: '',
    Senha: '',
    SenhaConfirmacao: '',
    Telefone: '',
    FotoProfile: null,
    IdUsuarioOrigem: 0,
    CodigoIndicado: '',
    FlgAtivo: true,
    DtValidadeAccessToken: moment().format('YYYY-MM-DD'),
    SaltKey: "",
    AccessToken: "",

    CEP: '',
    Logradouro: '',
    Numero: '',
    Bairro: '',
    Complemento: '',
    Cidade: '',

    _DadosBancarios: {
        "IdUsuarioDadosBancarios": 0,
        "IdUsuario": 0,
        "NomeResponsavel": "",
        "IdBanco": 0,
        "Agencia": "",
        "Conta": "",
        "TipoConta": "PF - Pessoa física",
        "TipoPix": "CPF",
        "ChavePix": "",
        "FlgAtivo": true,
        "FlgPix": true,
        "IdIncluidoPor": 0,
        "IdAlteradoPor": 0,
        "IdUsuarioInfluencer": 0
    }
}

const CadastroAfiliado = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [usuario, setUsuario] = useState(InitialValue);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const leapLovers = useSelector((state) => state.leapLovers);
    const [salvando, setSalvando] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [codigoBanco, setCodigoBanco] = useState('');
    const [nomeLoja, setNomeLoja] = useState('');

    useEffect(() => {
        (async () => {
            await fetchAfiliado();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);    

    const fetchAfiliado = async () => {
        setLoading(true);
        let user = InitialValue;

        if (leapLovers !== null && leapLovers.afiliado !== null) {
            user = await POST_DATA(`UsuarioLoginInfluencer/GetUsuarioLoginInfluencerById?IdUsuario=${leapLovers.afiliado.IdUsuarioLoginInfluencer}&join=true&Log=`);
            user = { ...user, Senha: '', SenhaConfirmacao: '' };
            setNomeLoja(leapLovers.afiliado.Loja.Nome);
        }
        else if (location.state && location.state?.IdUsuarioLoginInfluencer) {
            user = await POST_DATA(`UsuarioLoginInfluencer/GetUsuarioLoginInfluencerById?IdUsuario=${location.state.IdUsuarioLoginInfluencer}&join=true&Log=`);
            user = { ...user, IdIncluidoPor: userLoggedIn.IdUsuario, Senha: '', SenhaConfirmacao: '' };
            setNomeLoja(userLoggedIn.NomeFantasia);
        }

        const banco = bancos.filter(a => a.id === user._DadosBancarios.IdBanco)[0];
        setCodigoBanco(banco ? banco.codigo : '');

        setUsuario(user);
        setLoading(false);
        formik.resetForm();
    }

    const validationSchema = Yup.object().shape({
        Nome: Yup.string().required('*Favor informar o Nome'),
        Celular: Yup.string().required('*Favor informar o Celular'),
        Cpf: Yup.string().test(
            'validateCpf',
            '*Favor informar um cpf válido',
            (cpf, context) => {
                if (cpf?.length >= 11) {
                    const result = valida_cpf_cnpj(cpf);
                    return result;
                }
                return cpf?.length >= 11;
            }
        ),        
        Senha: Yup.string().test(
            'validateSenha',
            '*As senhas são diferentes',
            (senha, context) => {
                if (!senha && !context.parent?.SenhaConfirmacao) return true;
                if (senha !== context.parent?.SenhaConfirmacao) return false;

                return true;
            }
        ),
        _DadosBancarios: Yup.object().shape({
            ChavePix: Yup.string().test(
                'validateCpfDadosBancarios',
                '*Favor informar uma chave pix válida',
                (chavePix, context) => {
                    if(context.parent.TipoPix === 'E-mail' && !isEmail(chavePix)) return false;

                    if(context.parent.TipoPix !== 'CPF' && context.parent.TipoPix !== 'CNPJ') return true;


                    if (chavePix?.length >= 11) {
                        const result = valida_cpf_cnpj(chavePix);
                        if(!result) setTabValue(1);
                        return result;
                    }
                    return chavePix?.length >= 11;
                }
            )
        }),
        SenhaConfirmacao: Yup.string().test(
            'validateSenhaConfirmacao',
            '*As senhas são diferentes',
            (senhaConfirmacao, context) => {
                if (!senhaConfirmacao && !context.parent?.Senha) return true;
                if (senhaConfirmacao !== context.parent?.Senha) return false;
                return true;
            }
        ),
        Email: Yup.string().test(
            'validateEmail',
            '*Favor informar um e-mail válido',
            (email, context) => {
                return isEmail(email);
            }
        ),
        CEP: Yup.string().required('*Favor informar o CEP'),
        Numero: Yup.string().required('*Favor informar o Número da rua'),
    });

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const onSubmit = async (values) => {
        try {
            setSalvando(true);

            const objetoToSave = leapLovers !== null ? { ...values } : { ...values, IdIncluidoPor: userLoggedIn.IdUsuario };
            const cpf = objetoToSave.Cpf.replace(/([^\d])+/gim, '');

            if (objetoToSave.IdUsuarioLoginInfluencer === 0) {
                objetoToSave.Login = `${leapLovers !== null ? objetoToSave.IdIncluidoPor : userLoggedIn.IdUsuario}${moment().format('YYYYMMDDHHmmss')}`;
                objetoToSave.Senha = cpf.substring(0, 5);
            }

            const result = await PUT_DATA(`UsuarioLoginInfluencer/CadastroUsuarioLoginInfluencer`, objetoToSave);
            const dadosBancarios = { ...values._DadosBancarios, IdUsuarioInfluencer: result.IdUsuarioLoginInfluencer };
            await PUT_DATA('UsuarioDadosBancario/CadastroUsuarioDadosBancario', dadosBancarios);

            setSalvando(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar os dados do Usuário',
            });

            if (leapLovers === null) navigate('/afiliados');
            else await fetchAfiliado();
        }
        catch (err) {
            setSalvando(false);
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: err?.message,
                message: err.message[0]
            });
        }
    };

    const formik = useFormik({
        onSubmit,
        initialValues: usuario,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    // useEffect(() => {
    //     console.log('errors', formik.errors);
    // }, [formik.errors]);

    const handleCep = (e) => {
        if (e.target.value.length === 8) {
            axios
                .get(`https://viacep.com.br/ws/${e.target.value}/json/`)
                .then((res) => {
                    if (!res.data.erro) {
                        formik.setFieldValue(`Logradouro`, res.data.logradouro);
                        formik.setFieldValue(`Bairro`, res.data.bairro);
                        formik.setFieldValue(`Complemento`, res.data.complemento);
                        formik.setFieldValue(`Cidade`, res.data.localidade);
                    }
                });
        }
    };

    const styleItemGrid = { width: '100%' };

    return (
        <Container>
            <form onSubmit={formik.handleSubmit}>
                {
                    loading ? (
                        <Stack
                            sx={{ height: "150px", marginTop: '10%' }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>Carregando dados do usuário</Typography>
                        </Stack>
                    ) : (
                        <>
                            <Box sx={{ width: "100%" }}>
                                <Box style={{ width: "100%" }}>
                                    <Typography style={{ fontWeight: 'bold' }}>Gestão de Afiliado</Typography>
                                </Box>
                                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: leapLovers === null ? 'space-between' : 'flex-end' }}>
                                    {
                                        leapLovers === null &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/afiliados', { replace: true })}
                                        >
                                            {salvando && <Loader />}
                                            Voltar
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={formik.submitForm}
                                    >
                                        {salvando && <Loader />}
                                        Salvar
                                    </Button>
                                </Box>
                            </Box>

                            <Tabs
                                value={tabValue}
                                onChange={handleChangeTab}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Dados pessoas" {...a11yProps(0)} />
                                <Tab label="Dados para pagamento" {...a11yProps(1)} />
                            </Tabs>

                            {
                                tabValue === 0 &&
                                <Box style={{ width: '100%', display: 'flex' }}>
                                    <Card style={{ marginTop: 10, width: '49%', marginRight: 10, minHeight: 621, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Dados pessoais</Typography>
                                                <Divider style={{ marginTop: 10 }} />
                                            </Grid>

                                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControlLabel
                                                    disabled
                                                    readOnly
                                                    labelPlacement="top"
                                                    control={<img src={formik.values?.FotoProfile === null ? 'https://timelineleap-evhgawa4gxdcecak.z01.azurefd.net/img/avatar.png' : formik.values?.FotoProfile} alt={'Imagem Cliente'} style={{ width: 70, marginTop: 5 }} />}
                                                    label="Imagem do Influencer"
                                                />
                                                <AlertError className="msg-error">{formik.errors.FotoProfile}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    label={'Nome'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Nome}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Nome`, e.target.value);
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <PersonIcon />
                                                        </InputAdornment>
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Nome}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    label={'Como gostaria de ser chamado (a)?'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.NickName}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`NickName`, e.target.value);
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <PersonIcon />
                                                        </InputAdornment>
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.NickName}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="genero-selector">Gênero</InputLabel>
                                                    <Select
                                                        labelId="genero-selector"
                                                        id="genero-selector-id"
                                                        value={formik.values?.Sexo || 2}
                                                        label="Gênero"
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Sexo`, Number(e.target.value));
                                                        }}
                                                    >
                                                        <MenuItem value={1}>Masculino</MenuItem>
                                                        <MenuItem value={2}>Feminino</MenuItem>
                                                        <MenuItem value={3}>Outro</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <AlertError className="msg-error">{formik.errors.Sexo}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    label={'Email'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Email}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Email`, e.target.value);
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <EmailIcon />
                                                        </InputAdornment>
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Email}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    disabled={usuario?.IdUsuario > 0 && usuario?.Cpf !== null}
                                                    readOnly={usuario?.IdUsuario > 0 && usuario?.Cpf !== null}
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    label={'CPF'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Cpf}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Cpf`, e.target.value.replace(/([^\d])+/gim, ''));
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Cpf}</AlertError>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    label={'Telefone Pessoal'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Celular}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Celular`, e.target.value.replace(/\D/g, ""));
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Celular}</AlertError>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    label={'Telefone comercial/Whatsapp'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Telefone}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Telefone`, e.target.value.replace(/\D/g, ""));
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Telefone}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ ...styleItemGrid, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <TextField
                                                    disabled
                                                    label={'Nome lojinha'}
                                                    style={{ ...styleItemGrid, width: 200 }}
                                                    value={nomeLoja}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            <StoreMallDirectoryIcon />
                                                        </InputAdornment>
                                                    }}
                                                />
                                                <FormControlLabel labelPlacement="top" control={<Switch checked={formik.values?.FlgAtivo} onChange={() => formik.setFieldValue(`FlgAtivo`, !formik.values?.FlgAtivo)} />} label="Ativo" />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Box style={{ marginTop: 10, width: '50%', display: 'flex', flexDirection: 'column' }}>
                                        <Card style={{ height: 390 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                    <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Endereço</Typography>
                                                    <Divider style={{ marginTop: 10 }} />
                                                </Grid>

                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <TextField
                                                        inputProps={{
                                                            maxLength: 8,
                                                        }}
                                                        label={'CEP'}
                                                        style={{ ...styleItemGrid, width: 200, marginTop: 10 }}
                                                        value={formik.values?.CEP}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`CEP`, e.target.value.replace(/\D/g, ""));
                                                            if (Number(e.target.value) < 0) return;
                                                            handleCep(e);
                                                        }}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.CEP}</AlertError>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label={'Rua/Av.'}
                                                        style={styleItemGrid}
                                                        value={formik.values?.Logradouro}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Logradouro`, e.target.value);
                                                        }}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.Logradouro}</AlertError>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label={'Número'}
                                                        style={styleItemGrid}
                                                        value={formik.values?.Numero}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Numero`, e.target.value.replace(/\D/g, ""));
                                                        }}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.Numero}</AlertError>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label={'Bairro'}
                                                        style={styleItemGrid}
                                                        value={formik.values?.Bairro}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Bairro`, e.target.value);
                                                        }}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.Bairro}</AlertError>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label={'Complemento'}
                                                        style={styleItemGrid}
                                                        value={formik.values?.Complemento}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Complemento`, e.target.value);
                                                        }}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.ENDComplemento}</AlertError>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label={'Cidade'}
                                                        style={styleItemGrid}
                                                        value={formik.values?.Cidade}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Cidade`, e.target.value);
                                                        }}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.ENDCidade}</AlertError>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                        <Card style={{ marginTop: 10, height: 220 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                    <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Login</Typography>
                                                    <Divider style={{ marginTop: 10 }} />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        disabled
                                                        label={'Login'}
                                                        style={styleItemGrid}
                                                        value={formik.values?.Login}
                                                    />
                                                    <AlertError className="msg-error">{formik.errors.Login}</AlertError>
                                                </Grid>
                                                {
                                                    leapLovers === null &&
                                                    <Grid item xs={12} md={12}>
                                                        <Typography style={{ fontWeight: 'bold', textAlign: 'left' }}>*Senha do afiliado são os 5 primeiros dígitos de seu CPF</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    leapLovers !== null &&
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                type="password"
                                                                label={'Senha'}
                                                                style={styleItemGrid}
                                                                value={formik.values?.Senha}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`Senha`, e.target.value);
                                                                }}
                                                            />
                                                            <AlertError className="msg-error">{formik.errors.Senha}</AlertError>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                type="password"
                                                                label={'Senha de Confirmação'}
                                                                style={styleItemGrid}
                                                                value={formik.values?.SenhaConfirmacao}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(`SenhaConfirmacao`, e.target.value);
                                                                }}
                                                            />
                                                            <AlertError className="msg-error">{formik.errors.SenhaConfirmacao}</AlertError>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>

                                        </Card>
                                    </Box>
                                </Box>
                            }

                            {
                                tabValue === 1 &&
                                <Box style={{ width: '100%' }}>
                                    <Card style={{ marginTop: 10, paddingBottom: 50 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 20, paddingTop: 10 }}>
                                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Dados para transferência de saldo</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="tamanho-selector"
                                                    id="tamanho-selector-id"
                                                    value={formik.values?._DadosBancarios?.TipoConta || "Tipo Conta"}
                                                    sx={{ width: '100%', textAlign: 'start' }}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`_DadosBancarios.TipoConta`, e.target.value);
                                                    }}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={"Tipo Conta"} disabled>Tipo Conta</MenuItem>
                                                    <MenuItem value={'PF - Pessoa física'}>PF - Pessoa física</MenuItem>
                                                    <MenuItem value={'PJ - Pessoa jurídica'}>PJ - Pessoa jurídica</MenuItem>
                                                </Select>
                                                <AlertError className="msg-error">{formik.errors_DadosBancarios?.TipoConta}</AlertError>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField
                                                    label={'Nome Titular'}
                                                    style={styleItemGrid}
                                                    value={formik.values?._DadosBancarios?.NomeResponsavel}
                                                    onChange={(e) => formik.setFieldValue(`_DadosBancarios.NomeResponsavel`, e.target.value)}
                                                />
                                                <AlertError className="msg-error">{formik.errors._DadosBancarios?.NomeResponsavel}</AlertError>
                                            </Grid>


                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="tamanho-selector"
                                                    id="tamanho-selector-id"
                                                    value={formik.values?._DadosBancarios?.IdBanco}
                                                    sx={{ width: '100%', textAlign: 'start' }}
                                                    onChange={(e) => {
                                                        const id = Number(e.target.value);
                                                        formik.setFieldValue(`_DadosBancarios.IdBanco`, id);

                                                        const banco = bancos.filter(a => a.id === id)[0];
                                                        setCodigoBanco(banco ? banco.codigo : '');
                                                    }}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={0} disabled>Nenhum(a)</MenuItem>
                                                    {
                                                        bancos.map((item, index) => {
                                                            return <MenuItem key={index} value={item.id}>{item.nome}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                <AlertError className="msg-error">{formik.errors.DadosBancarios?.IdBanco}</AlertError>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <TextField
                                                    disabled
                                                    label={'Código Banco'}
                                                    style={styleItemGrid}
                                                    value={codigoBanco}
                                                />
                                            </Grid>

                                            <Grid item xs={2}>
                                                <TextField
                                                    label={'Agência'}
                                                    style={styleItemGrid}
                                                    value={formik.values?._DadosBancarios?.Agencia}
                                                    inputProps={{ maxLength: 4 }}
                                                    onChange={(e) => formik.setFieldValue(`_DadosBancarios.Agencia`, e.target.value.replace(/[^0-9:]/g, ""))}
                                                />
                                                <AlertError className="msg-error">{formik.errors._DadosBancarios?.Agencia}</AlertError>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <TextField
                                                    label={'Conta'}
                                                    style={styleItemGrid}
                                                    value={formik.values?._DadosBancarios?.Conta}
                                                    inputProps={{ maxLength: 10 }}
                                                    onChange={(e) => formik.setFieldValue(`_DadosBancarios.Conta`, e.target.value.replace(/[^0-9:]/g, ""))}
                                                />
                                                <AlertError className="msg-error">{formik.errors._DadosBancarios?.Conta}</AlertError>
                                            </Grid>

                                            <Grid item xs={12} md={1.5}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Switch
                                                            checked={formik.values?._DadosBancarios?.FlgPix}
                                                            onChange={() => {
                                                                formik.setFieldValue(`_DadosBancarios.FlgPix`, !formik.values?._DadosBancarios?.FlgPix);
                                                            }} />}
                                                    label="Pix?"
                                                />
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                <Select
                                                    labelId="tamanho-selector"
                                                    id="tamanho-selector-id"
                                                    value={formik.values?._DadosBancarios?.TipoPix || 0}
                                                    sx={{ width: '100%', textAlign: 'start' }}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`_DadosBancarios.TipoPix`, e.target.value);
                                                    }}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={'Tipo de pix'} disabled>Tipo de pix</MenuItem>
                                                    <MenuItem value={'CPF'}>CPF</MenuItem>
                                                    <MenuItem value={'CNPJ'}>CNPJ</MenuItem>
                                                    <MenuItem value={'E-mail'}>E-mail</MenuItem>
                                                    <MenuItem value={'Telefone'}>Telefone</MenuItem>
                                                    <MenuItem value={'Aleatória'}>Aleatória</MenuItem>
                                                </Select>
                                                <AlertError className="msg-error">{formik.errors._DadosBancarios?.TipoPix}</AlertError>
                                            </Grid>
                                            <Grid item xs={3.5}>
                                                <TextField
                                                    label={'Chave pix'}
                                                    style={styleItemGrid}
                                                    value={formik.values?._DadosBancarios?.ChavePix}
                                                    inputProps={{ maxLength: formik.values._DadosBancarios?.TipoPix === 'CPF' || formik.values._DadosBancarios?.TipoPix === 'Telefone' ? 11 : formik.values._DadosBancarios?.TipoPix === 'CNPJ' ? 14 : null }}
                                                    onChange={(e) => {
                                                        if(formik.values._DadosBancarios?.TipoPix === 'CPF' || formik.values._DadosBancarios?.TipoPix === 'CNPJ' || formik.values._DadosBancarios?.TipoPix === 'Telefone')
                                                            formik.setFieldValue(`_DadosBancarios.ChavePix`, e.target.value.replace(/[^0-9:]/g, ""));
                                                        else 
                                                            formik.setFieldValue(`_DadosBancarios.ChavePix`, e.target.value);

                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors._DadosBancarios?.ChavePix}</AlertError>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Box>
                            }

                        </>
                    )
                }
            </form>
        </Container >
    )
}

export default CadastroAfiliado;