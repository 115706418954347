import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
    width: 250px;
    height: 250px;
`;

export const ContainerImagem = styled(Box)`
    width: 100%;
    border: 1px solid black;
    height: auto;
    max-height: 85px;
    background-repeat: no-repeat;
    background: ${({ imgSrc, colorGradient }) => `linear-gradient(${colorGradient}, ${colorGradient}), url(${imgSrc})` };
`;
