import * as moment from 'moment';
import { ORDENACAO_DASHBOARD_SET } from './types';

const INITIAL_STATE = {
  orderList: null,
  ordersEmAnalise: null,
  ordersAprovadas: null,
  ordersEncaminhadasParaEntrega: null,
  ordersEntregues: null,
  ordersReprovadas: null,
  ordersCanceladasComprador: null,
  ordersCanceladasVendedor: null,
  ordersDevolvidas: null,
  period: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    desc: "Hoje",
  },
  periodToCompare: {
    startDate: moment().add(-1, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  }
};

const ordenacaoDashboardRecuder = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ORDENACAO_DASHBOARD_SET:
        return action.data;
      default:
        return state;
    }
};
  
export default ordenacaoDashboardRecuder;
