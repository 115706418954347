import { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU, drawerWidth } from '../../store/Customization/types';
import Header from './Header';
import { Outlet, useLocation, useNavigate } from 'react-router';
import Sidebar from './Sidebar';
import MuiAppBar from '@mui/material/AppBar';
import { CssBaseline, styled, useTheme } from '@mui/material';
import Footer from '../../components/Footer';
import { isMobile } from '../../services/DeviceServices';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, comprimentoMenuLateral, lojaConfigurada }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: comprimentoMenuLateral > 0 ? (lojaConfigurada ? '90px' : (isMobile() ? null : 10)) : 0,
      width: comprimentoMenuLateral > 0 && lojaConfigurada ? '97%' : '100%'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: isMobile() ? null : '20px',
      width: '100%',
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: comprimentoMenuLateral > 0 ? (lojaConfigurada ? '90px' : (isMobile() ? null : 10)) : 0,
      width: '100%',
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: !lojaConfigurada ? '100%' : `calc(100% - ${comprimentoMenuLateral}px)`,
    marginLeft: isMobile() || !lojaConfigurada ? null : comprimentoMenuLateral,
    [theme.breakpoints.down('md')]: {
      marginLeft: isMobile() ? null : '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, comprimentoMenuLateral, lojaConfigurada }) => ({
  zIndex: 0,
  marginLeft: 0,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: comprimentoMenuLateral,
    width: !lojaConfigurada ? '100%' : `calc(100% - ${comprimentoMenuLateral}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainLayout = () => {
  const location = useLocation();
  const themeContext = useTheme();
  const dispatch = useDispatch();
  const onBoard = useSelector((store) => store.onBoard);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';
  const ehLeapVesti = process.env.REACT_APP_LEAP_VESTI === 'true';
  const matchDownMd = useMediaQuery(themeContext.breakpoints.down('lg'));
  const [comprimentoMenuLateral, setComprimentoMenuLateral] = useState(0);

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    setComprimentoMenuLateral(!location.pathname.includes('lojas') && !isMobile() ? drawerWidth : 0);
  }, [location]);

  const lojaConfigurada = ehLeapLovers || ehLeapVesti ? true : !location.pathname.includes('lojas') && !(location.pathname.replace('/', '') === 'onboard-lojista') && !(location.pathname.replace('/', '') === 'on-board-design-site') && onBoard?.ListOnboardingStorexOnboardingStoreItem && onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus;
  
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        comprimentoMenuLateral={comprimentoMenuLateral}
        lojaConfigurada={lojaConfigurada}
        open={leftDrawerOpened}
        sx={{
          zIndex: 999,
          bgcolor: themeContext.palette.background.default,
          transition: leftDrawerOpened ? themeContext.transitions.create('width') : 'none'
        }}
      >
        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
      </AppBar>

      {/* drawer */}
      {
        (!isMobile() && onBoard && lojaConfigurada) &&
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      }
      {
        (ehLeapLovers || ehLeapVesti) &&
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      }      

      <Main theme={themeContext} open={leftDrawerOpened} comprimentoMenuLateral={comprimentoMenuLateral} lojaConfigurada={lojaConfigurada} style={{ background: themeContext.palette.background.default, minHeight: '100vh', height: '100%', padding: 20, paddingTop: 90, paddingBottom: 20, color: 'black' }}>
        {/* breadcrumb */}
        <Outlet />
      </Main>

      <Footer width={!lojaConfigurada ? '100%' : leftDrawerOpened ? `calc(100% - ${comprimentoMenuLateral}px)` : `calc(100% - 90px)` } />
    </Box>
  );
}

export default MainLayout;
