import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import Popover from '@mui/material/Popover';
import { useSelector, useDispatch } from 'react-redux';
import { POPOVER_CLOSE } from '../../store/PopoverReducer/types';

const PopoverPopup = ({ children, idPopover, anchorEl, estilo = {}, anchorOrigin = {}, transformOrigin = {} }) => {
  const dispatch = useDispatch();
  const popover = useSelector((store) => store.popoverPopup);
  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    if(popover.idPopover === idPopover) {
      setOpenPopover(popover.open);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popover.open]);

  const closeMenu = () => {
    dispatch({
      type: POPOVER_CLOSE,
      idPopover: idPopover,
    });
  };

  return (
    anchorEl !== undefined &&
    <Popover
      id={idPopover}
      anchorEl={anchorEl}
      open={openPopover}
      onClose={() => closeMenu()}
      style={estilo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {children}
    </Popover>
  );
};

PopoverPopup.propTypes = {
  children: P.node.isRequired,
  idPopover: P.string.isRequired,
  anchorEl: P.object,
};

export default PopoverPopup;
