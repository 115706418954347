import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ORDENACAO_DASHBOARD_SET } from '../../store/OrdenacaoDashboardReducer/types';
import * as moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "dayjs/locale/pt";
import { pt } from 'date-fns/locale';
import { CONTROLEMENSAGEM_AVISO } from "../../store/ControleMensagemReducer/types";

const PeriodPicker = () => {
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [dataInicio, setDataInicio] = useState(new Date(moment().startOf('month')));
    const [dataFim, setDataFim] = useState(new Date(moment().endOf('month').add(1, 'days')));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const switchPeriod = (id) => {
        if (id === 1) {
            dispatch({
                type: ORDENACAO_DASHBOARD_SET,
                data: {
                    ...ordenacaoDashboard,
                    period: {
                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
                        desc: "Hoje",
                    },
                    // periodToCompare: {
                    //     startDate: moment().add(-1, "day").format('YYYY-MM-DD'),
                    //     endDate: moment().format('YYYY-MM-DD'),
                    //     desc: "Ontem",
                    // }
                }
            });
        } else if (id === 0) {
            dispatch({
                type: ORDENACAO_DASHBOARD_SET,
                data: {
                    ...ordenacaoDashboard,
                    period: {
                        startDate: moment().add(-1, 'years').format('YYYY-MM-DD'),
                        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
                        desc: "Último ano",
                    },
                    // periodToCompare: {
                    //     startDate: moment().add(-2, 'years').format('YYYY-MM-DD'),
                    //     endDate: moment().add(-1, 'years').format('YYYY-MM-DD'),
                    //     desc: "Ano retrasado",
                    // }
                }
            });
        } else if (id === 7) {
            dispatch({
                type: ORDENACAO_DASHBOARD_SET,
                data: {
                    ...ordenacaoDashboard,
                    period: {
                        startDate: moment().add(-7, 'days').format('YYYY-MM-DD'),
                        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
                        desc: "Última semana",
                    },
                    // periodToCompare: {
                    //     startDate: moment().add(-14, 'days').format('YYYY-MM-DD'),
                    //     endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                    // }
                }
            });
        } else if (id === 15) {
            dispatch({
                type: ORDENACAO_DASHBOARD_SET,
                data: {
                    ...ordenacaoDashboard,
                    period: {
                        startDate: moment().add(-15, 'days').format('YYYY-MM-DD'),
                        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
                        desc: "Última quinzena",
                    },
                    // periodToCompare: {
                    //     startDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
                    //     endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                    // }
                }
            });
        } else if (id === 30) {
            dispatch({
                type: ORDENACAO_DASHBOARD_SET,
                data: {
                    ...ordenacaoDashboard,
                    period: {
                        startDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
                        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
                        desc: "Último mês",
                    },
                    // periodToCompare: {
                    //     startDate: moment().add(-60, 'days').format('YYYY-MM-DD'),
                    //     endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                    // }
                }
            });
        }
        else if (id === 'pesquisar') {
            const dtInicio = moment(dataInicio);
            const dtFim = moment(dataFim);
            const diferenca = dtFim.diff(dtInicio, 'months');

            if(diferenca > 6){
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Período máximo de pesquisa é de 6 meses',
                });
                return;
            }

            dispatch({
                type: ORDENACAO_DASHBOARD_SET,
                data: {
                    ...ordenacaoDashboard,
                    period: {
                        startDate: dtInicio.format('YYYY-MM-DD'),
                        endDate: dtFim.add(1, "day").format('YYYY-MM-DD'),
                        desc: `${dtInicio.format('DD/MM/YYYY')} - ${dtFim.add(-1, "day").format('DD/MM/YYYY')}`,
                    },
                    // periodToCompare: {
                    //     startDate: moment(dataInicio).add(-2, 'years').format('YYYY-MM-DD'),
                    //     endDate: moment(dataFim).add(-1, 'years').format('YYYY-MM-DD'),
                    // }
                }
            });
        }
        handleClose();
    };

    return (
        <div>
            <Typography style={{ fontSize: 12, marginBottom: 7 }}>Filtrar por:</Typography>
            <Button
                style={{ marginRight: "1rem", minWidth: 130 }}
                variant="contained"
                size="large"
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
            >
                {ordenacaoDashboard?.period?.desc}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => switchPeriod(1)}>Hoje</MenuItem>
                <MenuItem onClick={() => switchPeriod(7)}>Última semana</MenuItem>
                <MenuItem onClick={() => switchPeriod(15)}>Última quinzena</MenuItem>
                <MenuItem onClick={() => switchPeriod(30)}>Último mês</MenuItem>
                {/* <MenuItem onClick={() => switchPeriod(0)}>Último ano</MenuItem> */}

                <MenuItem>
                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id='dataInicio'
                            format="dd/MM/yyyy"
                            value={dataInicio}
                            onChange={(newValue) => setDataInicio(newValue)}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{ width: 180 }}
                        />
                        <DatePicker
                            id='dataFim'
                            format="dd/MM/yyyy"
                            value={dataFim}
                            onChange={(newValue) => setDataFim(newValue)}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{ width: 180 }}
                        />
                        <Button style={{ marginLeft: 10 }} variant="contained" onClick={() => switchPeriod('pesquisar')}>Buscar</Button>
                    </LocalizationProvider>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default PeriodPicker;
