import { useEffect, useState, useContext } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { TEMA_LOJA_SET_EXIBICAO_ITENS } from '../../store/TemaLojaReducer/types';
import UploadImagemDesign from '../UploadImagemDesign';
import { POST_DATA } from '../../services/API';

const ExibicaoItens = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const themeStorage = useSelector((store) => store.temaLoja);
    const [themeStoreSelected, setThemeStoreSelected] = useState(null);

    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store && themeStoreSelected === null) {
            const themeStore = themeStorage.themes_store.filter(a => a.id === Number(themeStorage.idThemeStoreSelected))[0];
            setThemeStoreSelected(themeStore);

            if(themeStorage?.tipoThema === 10 && themeStore.exibicao_itens.filter(a => a.id === 'exibicao4').length === 0){
                themeStore.exibicao_itens = [
                    ...themeStore.exibicao_itens, 
                    {
                        "id": "exibicao4",
                        "title": "",
                        "categoria_id": "0",
                        "imagem": {
                            "id": 1,
                            "name": "",
                            "alternative_text": "Imagem",
                            "caption": "",
                            "url": null,
                            "url_mobile": null,
                            "extensao": ""
                        }
                    }
                ]
            }

            setThemeStoreSelected(themeStore);
        }

        (async () => {
            await fetchCategorias();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeStorage]);

    const fetchCategorias = async() => {
        const responseCategorias = await POST_DATA(`Categoria/GetAllCategoria?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCategoria&Log=`);
        const responseCategoriasLoja = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
        const arr = [];
        let categoriasFiltradas = responseCategorias;

        responseCategoriasLoja.forEach(item => {
            categoriasFiltradas = categoriasFiltradas.filter(categoria => categoria.IdCategoria !== item.IdCategoria);
            arr.push({
                IdCategoria: item.IdCategoria,
                IdUsuarioEmpresa: item.IdUsuarioEmpresa,
                Descricao: responseCategorias.filter(categoria => categoria.IdCategoria === item.IdCategoria)[0]?.Descricao,
                FlagAtivo: item.FlagAtivo
            });
        });
        
        setCategorias(arr);
    }

    const handleOnChangeImagem = (id, dataImagem) => {
        const itensExibicao = themeStoreSelected.exibicao_itens.filter(item => item.id === id)[0];
        itensExibicao.imagem = dataImagem;

        dispatch({
            type: TEMA_LOJA_SET_EXIBICAO_ITENS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: itensExibicao
        });
    }

    const handleOnChangeTitle = (id, title) => {
        const itensExibicao = themeStoreSelected.exibicao_itens.filter(item => item.id === id)[0];
        itensExibicao.title = title;

        dispatch({
            type: TEMA_LOJA_SET_EXIBICAO_ITENS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: itensExibicao
        });
    }

    const handleOnChangeCategoria = (id, categoriaId) => {
        const itensExibicao = themeStoreSelected.exibicao_itens.filter(item => item.id === id)[0];
        itensExibicao.categoria_id = categoriaId;

        dispatch({
            type: TEMA_LOJA_SET_EXIBICAO_ITENS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: itensExibicao
        });
    }

    return (
        themeStoreSelected &&
        <Container>
            <Grid container spacing={2} style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Itens a serem exibidos</Typography>
                    <Typography style={{ fontSize: '16px', color: 'gray' }}>Favor informar a imagem e descrição dos 3 principais itens a serem exibidos</Typography>
                </Grid>
                {themeStoreSelected.exibicao_itens && themeStoreSelected.exibicao_itens.map((item, index) => {
                    return (
                        <Grid key={item.id} item xs={themeStorage?.tipoThema === 10 ? 6 : 4} style={{ maxWidth: '450px' }}>
                            <UploadImagemDesign
                                dados={item.imagem}
                                isEditing={true}
                                mobile={false}
                                key={item.id}
                                id={item.id}
                                idComponent={`exibicao_item_${item.id}`}
                                imgData={item.imagem.url}
                                onChange={(value) => handleOnChangeImagem(item.id, value)}
                                borderRadiusImg={0}
                                paddingTopImg={0}
                                widthImg='100%'
                                heightImg={150}
                                style={{ height: 240 }}

                                dimensaoWidthImg={846}
                                dimensaoHeightImg={550}
                                descricao={'Tamanho máximo 846 x 550'}
                            />

                            <TextField
                                style={{ width: '100%', marginTop: '10px' }}
                                variant="outlined"
                                label='Descrição'
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                                value={item.title}
                                onChange={(e) => handleOnChangeTitle(item.id, e.target.value)}
                            />
                            <Box style={{ width: '100%' }}>
                                <Select
                                    variant="outlined"
                                    labelId={item.id}
                                    id="label-select"
                                    value={item.categoria_id}
                                    label="Categoria Peças"
                                    onChange={(e) => handleOnChangeCategoria(item.id, e.target.value)}
                                    style={{ marginTop: 10, width: '76%' }}
                                    onFocus={async() => {
                                        if(categorias.length === 0){
                                            await fetchCategorias();
                                        }
                                    }}
                                >
                                    <MenuItem value="0">Nenhum(a)</MenuItem>
                                    {
                                        categorias.map((menu) => {
                                            return <MenuItem key={menu.IdCategoria} value={menu.IdCategoria.toString()}>{menu.Descricao}</MenuItem>
                                        })
                                    }
                                </Select>
                                <Button 
                                    variant='contained' 
                                    style={{ marginLeft: 5, background: 'black' }}
                                    onClick={() => window.open('/categorias', '_blank')}
                                >Novo</Button>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    );
}

export default ExibicaoItens;
