import React from 'react';
import P from 'prop-types';
import { CircularProgress } from '@mui/material';

const Loading = ({ texto = 'Carregando dados', marginTop = '40%' }) => {
  return (
    <div style={{ marginTop: marginTop, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>      
      <CircularProgress style={{ width: 50 }} />
        <p style={{ fontWeight: 'bold', fontSize: 16, marginTop: 40 }}>{texto}</p>
    </div>
  );
};

Loading.propTypes = {
  texto: P.string,
};

export default Loading;
