import rootReducer from './store/reducer';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import multi from 'redux-multi';

const store = configureStore({ 
    reducer: rootReducer,
    middleware: [thunk, multi],
    devTools: process.env.REACT_APP_LOCAL === 'true'
});

export { store };
