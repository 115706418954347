import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { TEMA_LOJA_SET_HEADER_MENSAGENS } from '../../store/TemaLojaReducer/types';

const MensagemMarketing = () => {
    const dispatch = useDispatch();
    const themeStorage = useSelector((store) => store.temaLoja);
    const [themeStoreSelected, setThemeStoreSelected] = useState(null);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store) {
            const themeStore = themeStorage.themes_store.filter(a => a.id === Number(themeStorage.idThemeStoreSelected))[0];    
            setThemeStoreSelected(themeStore);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeStorage]);

    const handleChangeHeadersMensagem = (id, property, value) => {
        const headersMensagem = [...themeStoreSelected.headersMensagem];
        headersMensagem.forEach(m => {
            if (m.id === id && property === 'selected') {
                m[property] = !m[property];
            }
            else if (m.id === id) {
                m[property] = value;
            }
            return m;
        });

        dispatch({
            type: TEMA_LOJA_SET_HEADER_MENSAGENS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            headersMensagem: headersMensagem
        });
    }

    return (
        <Container>
            <Grid container spacing={2} style={{ padding: 20 }}>
                {themeStoreSelected?.headersMensagem && themeStoreSelected?.headersMensagem?.map((item, index) => {

                    return (
                        <Grid key={item.id} item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                sx={{
                                    width: '40px', height: '40px'
                                }}
                                name={item.id}
                                id={item.id}
                                checked={item.selected}
                                onChange={() => handleChangeHeadersMensagem(item.id, 'selected')}
                            />
                            <TextField
                                variant="outlined"
                                type='string'
                                sx={{marginRight: 2}}
                                InputLabelProps={{style: {fontSize: 16}}}
                                InputProps={{style: {fontSize: 16}}}
                                style={{ width: '90%' }}
                                label={`Mensagem ${index + 1}`}
                                value={item.mensagem}
                                onChange={(e) => {
                                    handleChangeHeadersMensagem(item.id, 'mensagem', e.target.value)
                                }}
                            />
                            <TextField
                                variant="outlined"
                                type='string'
                                InputLabelProps={{style: {fontSize: 16}}}
                                InputProps={{style: {fontSize: 16}}}
                                style={{ width: '90%' }}
                                label={`Link ${index + 1}`}
                                value={item.url}
                                onChange={(e) => {
                                    handleChangeHeadersMensagem(item.id, 'url', e.target.value)
                                }}
                            />
                        </Grid>   
                    )
                })}
            </Grid>
        </Container>
    );
}

export default MensagemMarketing;
