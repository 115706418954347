import { Fragment, useEffect, useState } from "react";
import { Container } from "../styles";
import { GET_DATA_SERVICOS_ROBO, POST_DATA, POST_DATA_FILE, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import * as moment from "moment";
import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ImagemSemResultado, salvarCSV, truncate } from "../../../services/utils/functions";

import GetAppIcon from '@mui/icons-material/GetApp';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';

import QrCodeIcon from '@mui/icons-material/QrCode';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "dayjs/locale/pt";
import { pt } from 'date-fns/locale';
import { getCorByStatusPedido } from "../../../services/utils/statusPedido";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";
import { isMobile } from "../../../services/DeviceServices";
import LoadingAllPage from "../../../components/LoadingAllPage";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";

const stautsPedido = [
    { id: 1, nome: 'Em análise' },
    { id: 2, nome: 'Aprovado' },
    { id: 3, nome: 'Encaminhado para entrega' },
    { id: 4, nome: 'Entregue' },
    { id: 5, nome: 'Avaliar' },
    { id: 6, nome: 'Reprovado' },
    { id: 7, nome: 'Cancelado Comprador' },
    { id: 8, nome: 'Cancelado Vendedor' },
    { id: 9, nome: 'Devolução Solicitada' },
    { id: 10, nome: 'Devolução Confirmada' },
    { id: 11, nome: 'Em Preparação' },
    { id: 12, nome: 'Cancelado Robô' },
    { id: 13, nome: 'Erro de Processamento' }
]

const columnsPedidos = [
    { id: "expandir", label: "", minWidth: 50 },
    {
        id: "status",
        label: "Status",
        minWidth: 140,
        align: "left",
    },
    { id: "fornecedor", label: "Fornecedor", minWidth: 180 },
    { id: "company", label: "Company", minWidth: 180 },
    { id: "telefoneFornecedor", label: "Telefone fornecedor", minWidth: 120 },
    { id: "data", label: "Data", minWidth: 180 },
    { id: "numeroPedido", label: "Código", minWidth: 180 },
    {
        id: "formaPagamento",
        label: "Forma de Pagamento",
        maxWidth: 120,
        align: "left",
    },
    {
        id: "valorProdutos",
        label: "Produtos",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "valor",
        label: "Total",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "linkPagamentoPix",
        label: "Link de pagamento",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "pedidoCancelado",
        label: "Pedido Cancelado?",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "produtosImportados",
        label: "Produtos importados?",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 150,
        align: "left",
    }
];

const columnsProduto = [
    { id: "id", label: "Id", minWidth: 100 },
    { id: "idProduto", label: "Id Produto", minWidth: 100 },
    {
        id: "nome",
        label: "Nome",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "sku",
        label: "SKU",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "categoria",
        label: "Categoria",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "cor",
        label: "Cor",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "tamanho",
        label: "Tamanho",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "quantidade",
        label: "Quantidade",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "valorPromocional",
        label: "Valor promocional",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "valor",
        label: "Valor",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "total",
        label: "Total",
        maxWidth: 150,
        align: "left",
    },
];

const containerModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4
};

const Row = ({ row, onLoadingSalvar }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openModalImportacao, setOpenModalImportacao] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [loading, setLoading] = useState(false);

    const [idSexo, setIdSexo] = useState(-1);

    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState(null);
    const [idCategoria, setIdCategoria] = useState(0);
    const [inputCategoria, setInputCategoria] = useState(null);

    const [pecas, setPecas] = useState([]);
    const [peca, setPeca] = useState(null);
    const [idPeca, setIdPeca] = useState(0);
    const [inputPeca, setInputPeca] = useState(null);

    const [subPeca, setSubPeca] = useState(null);
    const [subPecas, setSubPecas] = useState([]);
    const [idSubPeca, setIdSubPeca] = useState(0);
    const [inputSubPeca, setInputSubPeca] = useState(null);

    const [sexosLoja, setSexosLoja] = useState([]);
    const [categoriasLoja, setCategoriasLoja] = useState([]);

    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    useEffect(() => {
        (async () => {
            if (openModalImportacao) {
                const respSexosLoja = await POST_DATA(`UsuarioEmpresaXSexo/GetAllUsuarioEmpresaXSexoByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);

                //Feito isso dia 02/08/2024 pq na API ele verifica de outra forma 
                respSexosLoja.forEach((item) => {
                    item.IdSexo = item.IdSexo + 1;
                    item._Sexo.SEX_Id = item._Sexo.SEX_Id + 1;
                });

                setSexosLoja(respSexosLoja);

                const responseCategoriasLoja = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
                const arrCategorias = [];

                responseCategoriasLoja.forEach(item => arrCategorias.push(item.Categoria))

                setCategoriasLoja(() => responseCategoriasLoja);
            }

            if (open && produtos.length === 0) {
                try {
                    setLoading(true);
                    const query = [
                        {
                            "sAndOr": "and",
                            "sCampo": "IdPedidoVesti",
                            "sOrder": "Nome ASC",
                            "sCondicao": "=",
                            "sValorCondicao": row.IdPedidoVesti.toString(),
                            "sTipoCampo": "int"
                        }
                    ];

                    const produtosPedido = await POST_DATA(`PedidoProdutoVesti/FiltroQuery`, query);

                    setProdutos(produtosPedido);
                    setLoading(false);
                }
                catch (err) {
                    setLoading(false);
                    SalvarLogSentry(err);
                }
            }
        })();
    }, [open, openModalImportacao]);

    try {
        const fetchCategorias = async (idSexo) => {
            //const result = await POST_DATA(`Categoria/GetCategoriaByIdSexoIN?sIdSexo=${idSexo}&Log=`);

            //Removendo o acrescimo feito no buscar o sexo
            idSexo = idSexo - 1;

            const arrCategorias = [];
            categoriasLoja.filter(a => a.IdSexo === idSexo).map(item => {
                arrCategorias.push(item.Categoria);
            });

            const opcoes = [{ IdCategoria: 0, Descricao: 'Nenhum(a)' }, ...arrCategorias];

            setCategorias(opcoes);
        }

        const fetchPecas = async (categoriaId) => {
            const respPecasLoja = await POST_DATA(`UsuarioEmpresaXPeca/GetAllUsuarioEmpresaXPecaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=Up.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Up.idSexo&Log=`);
            const respPecas = await POST_DATA(`Peca/GetPecasByIdCategorias?ids=${categoriaId}`);

            //Removendo o acrescimo feito no buscar o sexo
            const opcoes = [{ IdPeca: 0, Nome: 'Nenhum(a)' }, ...respPecasLoja.filter(a => a.IdSexo === (idSexo - 1) && (a.IdSubPeca === null || a.IdSubPeca === 0) && respPecas.filter(b => b.IdPeca === a.IdPeca).length > 0).map(pecaLoja => pecaLoja._Peca)];

            setPecas(opcoes);
            setSubPecas([{ IdPeca: 0, Nome: 'Nenhum(a)' }]);
        }

        const fetchSubPecas = async (pecaId) => {
            const respSubPecasLoja = await POST_DATA(`UsuarioEmpresaXPeca/GetAllUsuarioEmpresaXPecaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=Up.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Up.idSexo&Log=`);
            const opcoes = [{ IdPeca: 0, Nome: 'Nenhum(a)' }, ...respSubPecasLoja.filter(a => a.IdPeca === pecaId && a.IdSubPeca > 0 && a.IdSexo === (idSexo - 1)).map(pecaLoja => pecaLoja._SubPeca)];

            setSubPecas(opcoes);
        }

        const fetchExcel = async () => {
            let prods = produtos;

            if (produtos.length === 0) {
                const query = [
                    {
                        "sAndOr": "and",
                        "sCampo": "IdPedidoVesti",
                        "sOrder": "Nome ASC",
                        "sCondicao": "=",
                        "sValorCondicao": row.IdPedidoVesti.toString(),
                        "sTipoCampo": "int"
                    }
                ];

                prods = await POST_DATA(`PedidoProdutoVesti/FiltroQuery`, query);
            }

            let dados = `Fornecedor: ${row._FornecedorVesti.Nome} (${row._FornecedorVesti.CNPJ}) - Company: ${row._FornecedorVesti.Company}`;

            dados += `\n\nComprador: ${userLoggedIn.NomeFantasia} - ${userLoggedIn.Cnpj}`;
            dados += `\nContato comprador: E-mail ${userLoggedIn.Email} - Celualr ${userLoggedIn.Celular}`;
            dados += row._EnderecoUsuario ? `\nEndereço comprador: ${row._EnderecoUsuario?.END_Logradouro}, n° ${row._EnderecoUsuario?.END_Numero ? row._EnderecoUsuario?.END_Numero : ''}, bairro ${row._EnderecoUsuario?.END_Bairro}, cidade ${row._EnderecoUsuario?.END_Cidade} - ${row._EnderecoUsuario?.END_Estado} - CEP ${row._EnderecoUsuario?.END_CEP}` : '\nEndereço comprador: Sem dados de endereço';

            dados += `\n\nPedido: ${row.NumeroPedido}`;
            dados += `\nStatus; Data; Fornecedor; Forma de Pagamento; Produtos; Total; Link de pagamento; Motivo Cancelamento`;

            dados += `\n${row._PedidoStatus.Descricao}; ${moment(row.DataCadastro).format('DD/MM/YYYY HH:mm:ss')}; ${row._FornecedorVesti.Nome}; ${row._TipoPagamento.Descricao}; ${row.ValorProdutos.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${row.ValorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${row.UrlPagamentoPixVesti ? row.UrlPagamentoPixVesti : '-'}; ${row.MotivoCancelamento ? row.MotivoCancelamento : '-'}`;

            dados += `\n\nProdutos:`;

            dados += `\nId; Id Produto; Nome; SKU; Categoria; Cor; Tamanho; Quantidade; Valor Promocional; Valor; Total`;

            prods?.forEach(produto => {
                dados += `\n${produto.IdPedidoProdutosVesti}; ${produto.IdProduto}; ${produto.Nome};  ${produto.Sku.toString()};  ${produto.Categoria};  ${produto.Cor};  ${produto.Tamanho};  ${produto.Quantidade};  ${produto.ValorPromocional.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                })};  ${produto.Valor.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                })}; ${(produto.Quantidade * (produto.ValorPromocional > 0 ? produto.ValorPromocional : produto.Valor)).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                })} `;
            });

            const url = await salvarCSV(userLoggedIn.IdUsuario, 'Pedido.csv', dados);
            window.open(url, '_blank');
        }

        const handleImportarProdutos = async () => {
            try {
                if (idSexo <= 0) {
                    dispatch({
                        type: CONTROLEMENSAGEM_AVISO,
                        tipoComponente: 'alert',
                        titulo: 'Aviso',
                        message: 'Favor selecionar o gênero para importação',
                    });

                    return;
                }

                if (idCategoria === 0) {
                    dispatch({
                        type: CONTROLEMENSAGEM_AVISO,
                        tipoComponente: 'alert',
                        titulo: 'Aviso',
                        message: 'Favor selecionar a categoria para importação',
                    });

                    return;
                }

                if (idPeca === 0) {
                    dispatch({
                        type: CONTROLEMENSAGEM_AVISO,
                        tipoComponente: 'alert',
                        titulo: 'Aviso',
                        message: 'Favor selecionar o tipo de peça para importação',
                    });

                    return;
                }

                onLoadingSalvar(true);

                let prods = produtos;

                if (produtos.length === 0) {
                    const query = [
                        {
                            "sAndOr": "and",
                            "sCampo": "IdPedidoVesti",
                            "sOrder": "Nome ASC",
                            "sCondicao": "=",
                            "sValorCondicao": row.IdPedidoVesti.toString(),
                            "sTipoCampo": "int"
                        }
                    ];

                    prods = await POST_DATA(`PedidoProdutoVesti/FiltroQuery`, query);
                }

                let ids = [];
                prods.forEach(item => ids.push(item.IdProduto));

                const produtosVesti = await GET_DATA_SERVICOS_ROBO(`BuscaPedidoVesti_?CompanyId=${row._FornecedorVesti.IdFornecedor}&collection=Products&ProductIds=${ids.join(';')}`);

                for (const item of produtosVesti) {
                    //Verificar se a marca importada já existe na base, se não tiver salvar

                    //Marca
                    let marcaLeap = null;
                    const marcasLeap = await POST_DATA(`Marca/GetAllMarcaByValorExato?strValorExato=${item.brand.name}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Nome&Log=`);

                    if (marcasLeap.length === 0) {
                        marcaLeap = await PUT_DATA(`Marca/CadastroMarca`, {
                            Nome: item.brand.name,
                            FlgAtivo: true,
                            DtInclusao: new Date(),
                            IdIncluidoPor: userLoggedIn.IdUsuario
                        });

                        await PUT_DATA(`Cor/CadastroCor`, {
                            IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                            IdMarca: marcaLeap.IdMarca,
                            FlagMarca: true
                        });
                    }
                    else {
                        marcaLeap = marcasLeap[0];
                    }

                    let variacoes = [];

                    for (const element of prods.filter(a => a.IdProduto === item.id)) {
                        //Cor
                        let corLeap = null;
                        const coresLeap = await POST_DATA(`Cor/GetAllCorByValorExato?strValorExato=${element.Cor}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Descricao&Log=`);

                        if (coresLeap.length === 0) {
                            corLeap = await PUT_DATA(`Cor/CadastroCor`, {
                                Descricao: element.Cor,
                                FlagAtivo: true,
                                hexadecimal: item.colors.filter(a => a.id === element.IdCor)[0].code
                            });
                        }
                        else {
                            corLeap = coresLeap[0];
                        }

                        //Tamanho
                        let tamanhoLeap = null;
                        const tamanhosLeap = await POST_DATA(`Tamanho/GetAllTamanhoByValorExato?strValorExato=${element.Tamanho}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&flagKids=false&maxInstances=0&order_by=Descricao&Log=`);

                        if (tamanhosLeap.length === 0) {
                            tamanhoLeap = await PUT_DATA(`Tamanho/CadastroTamanho`, {
                                Descricao: element.Tamanho,
                                FlagAtivo: true
                            });
                        }
                        else {
                            tamanhoLeap = tamanhosLeap[0];
                        }

                        variacoes.push({
                            iQuantidade: element.Quantidade,
                            sTamanho: element.Tamanho,
                            mValor: 0,
                            mValorCusto: element.Valor,
                            FlagAtivo: true,
                            IdUsuarioInclusao: userLoggedIn.IdUsuario,
                            DtInclusao: new Date(),
                            Sku: element.Sku,
                            IdCor: corLeap.IdCor,
                            IdTamanho: tamanhoLeap.IdTamanho,
                            sTamanho: tamanhoLeap.Descricao,
                            FlagAtivo: true,
                        })
                    }

                    let produto = {
                        IdUsuario: userLoggedIn.IdUsuario,
                        IdIncluidoPor: userLoggedIn.IdUsuario,
                        FlgPrincipal: true,
                        idImagem: 0,
                        DtInclusao: new Date(),
                        FlgAtivo: false,
                        FlagDisponivelVenda: true,
                        Descricao: item.description ? item.description : item.name,
                        DescricaoDetalhe: item.composition,
                        LocalIIS: item.main_media.normal.url,
                        LocalThumIIS: item.main_media.thumb.url,
                        FlgLoja: true,

                        _Etiquetas: [
                            {
                                IdUsuario: userLoggedIn.IdUsuario,
                                sDescricao: item.name,
                                IdEtiqueta: 0,
                                DtInclusao: new Date(),
                                FlagAtivo: true,

                                idPublicoAlvo: idSexo,
                                IdCategoria: idCategoria,
                                IdPeca: idPeca,
                                IdSubPeca: idSubPeca,
                                IdMarca: marcaLeap.IdMarca,
                                idMaterial: 0,

                                Altura: item.length,
                                Largura: item.width,
                                Profundidade: 0,
                                Peso: item.weight,

                                X: 10,
                                Y: 10,

                                FlagPecaNova: true,

                                mValorCusto: item.price,

                                FlagDisponivelVenda: true,

                                _EtiquetaComplemento: variacoes
                            }
                        ]
                    }

                    let data = new FormData();
                    data.append("objImagem", JSON.stringify(produto));

                    const produtoVariacoes = { ...produto };

                    produto = await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja_ComUrlImagem`, data);

                    produtoVariacoes.IdImagemPrincipal = produto.obj.idImagem;

                    if (item.media !== null) {
                        for (const element of item.media) {
                            produtoVariacoes.IdImagemPrincipal = produto.obj.idImagem;
                            produtoVariacoes.idImagem = 0;
                            produtoVariacoes.FlgPrincipal = false;
                            produtoVariacoes.FlgAtivo = true;

                            produtoVariacoes.LocalIIS = element.normal.url;
                            produtoVariacoes.LocalThumIIS = element.thumb.url;

                            delete produtoVariacoes._Etiquetas;
                            delete produtoVariacoes._EtiquetaComplemento;

                            data = new FormData();
                            data.append("objImagem", JSON.stringify(produtoVariacoes));

                            await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja_ComUrlImagem`, data);
                        }
                    }
                }

                row.FlgProdutosImportados = true;

                await PUT_DATA(`PedidoVesti/UpdatePedidoVesti`, row);

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Importação dos produtos de atacado realizado com sucesso',
                    message: 'Para ativar os produtos importados para Venda é necessário editar os mesmos na tela de produtos.',
                });

                setOpenModalImportacao(false);

                onLoadingSalvar(false);
            }
            catch (err) {
                onLoadingSalvar(false);
                SalvarLogSentry(err);
            }
        }

        return (
            <Fragment key={row.IdPedidoVesti}>
                {
                    openModalImportacao && <Modal
                        key="modal"
                        open={openModalImportacao}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        keepMounted
                        disableAutoFocus
                    >
                        <Box
                            sx={[
                                containerModal,
                                {
                                    width: 600,
                                    height: 420,
                                    overflowY: null,
                                    borderRadius: 2,
                                    backgroundColor: '#ffff',
                                },
                            ]}
                        >
                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontWeight: 'bold' }}>Confirmar Importação dos produtos?</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setOpenModalImportacao(false)}
                                    sx={{
                                        position: 'absolute',
                                        right: 15,
                                        top: 35,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Box style={{ width: '100%', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

                                <Typography>Tem certeza que deseja confirmar a importação dos produtos de atacado? Se sim, informe os seguintes dados para a importação.</Typography>

                                <Grid container spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="tamanho-selector">Gênero</InputLabel>
                                            <Select
                                                labelId="tamanho-selector"
                                                id="sexo-selector-id"
                                                value={idSexo}
                                                label="Gênero"
                                                style={{ width: '100%' }}
                                                onChange={async (e) => {
                                                    setIdSexo(Number(e.target.value));
                                                    await fetchCategorias(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={-1} disabled>Favor selecionar um gênero</MenuItem>
                                                {
                                                    sexosLoja.map(sexo => {
                                                        return <MenuItem key={`sexo-${sexo.IdSexo}`} value={sexo.IdSexo}>{sexo._Sexo.SEX_Nome}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            id={`categoria`}
                                            options={categorias}
                                            value={categoria || categorias[0]}
                                            defaultValue={0}
                                            getOptionLabel={(option) => option?.Descricao || ''}
                                            onChange={async (event, newValue) => {
                                                setIdCategoria(newValue?.IdCategoria);
                                                setCategoria(newValue);

                                                setInputPeca({ IdPeca: 0, Nome: 'Nenhum(a)' });

                                                await fetchPecas(newValue?.IdCategoria);

                                            }}
                                            inputValue={inputCategoria}
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue === '') setIdCategoria(0);

                                                setInputCategoria(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Categoria" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            id={`peca`}
                                            options={pecas}
                                            value={peca || pecas[0]}
                                            defaultValue={0}
                                            getOptionLabel={(option) => option?.Nome || ''}
                                            onChange={async (event, newValue) => {
                                                setIdPeca(newValue?.IdPeca);
                                                setPeca(newValue);

                                                await fetchSubPecas(newValue?.IdPeca);

                                                setInputSubPeca({ IdPeca: 0, Nome: 'Nenhum(a)' });
                                            }}
                                            inputValue={inputPeca}
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue === '') setIdPeca(0);

                                                setInputPeca(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Peça" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            id={`subPeca`}
                                            options={subPecas}
                                            value={subPeca || subPecas[0]}
                                            defaultValue={0}
                                            getOptionLabel={(option) => option?.Nome || ''}
                                            onChange={(event, newValue) => {
                                                setIdSubPeca(newValue?.IdSubPeca);
                                                setSubPeca(newValue);
                                            }}
                                            inputValue={inputSubPeca}
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue === '') setIdSubPeca(0);

                                                setInputSubPeca(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Sub Peça" />
                                            )}
                                        />
                                    </Grid>

                                </Grid>

                                <Typography style={{ fontSize: 10, fontWeight: 'bold', color: '#b6b6b6', marginTop: 10 }}>*Após realizar a importacão é possível alterar os dados de gênero, categoria, peças e sub peças na edição dos produtos.</Typography>


                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                    <Button style={{ width: 180, marginTop: 20 }} variant="contained" onClick={() => handleImportarProdutos()}>Importar</Button>
                                </Box>
                            </Box>

                        </Box>

                    </Modal>
                }

                <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell key={`status-${row.IdPedidoVesti}`} align='left'>
                        <Typography style={{ padding: 2, paddingLeft: 8, paddingRight: 8, color: 'black', background: getCorByStatusPedido(row._PedidoStatus.Descricao), borderRadius: 10, border: '0.5px solid #e4e4e4', textAlign: 'center', fontWeight: 'bold' }}>{row._PedidoStatus.Descricao}</Typography>
                    </TableCell>
                    <TableCell key={`nome-fornecedor-${row.IdPedidoVesti}`} align='left'>
                        {row._FornecedorVesti.Nome}
                    </TableCell>
                    <TableCell key={`company-fornecedor-${row.IdPedidoVesti}`} align='left'>
                        {row._FornecedorVesti.Company}
                    </TableCell>
                    <TableCell key={`telefone-fornecedor-${row.IdPedidoVesti}`} align='left'>
                        {row._FornecedorVesti.Telefone ? row._FornecedorVesti.Telefone : "-"}
                    </TableCell>
                    <TableCell key={`data-${row.IdPedidoVesti}`} align='left'>
                        {moment(row.DataCadastro).format('DD/MM/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell key={`numero_pedido-${row.IdPedidoVesti}`} align='left'>
                        {row.NumeroPedido}
                    </TableCell>
                    <TableCell key={`tipo-pagamento-${row.IdPedidoVesti}`} align='left'>
                        {row._TipoPagamento.Descricao}
                    </TableCell>
                    <TableCell key={`valor-produtos-${row.IdPedidoVesti}`} align='left'>
                        {row.ValorProdutos.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}
                    </TableCell>
                    <TableCell key={`valor-total-${row.IdPedidoVesti}`} align='left'>
                        {row.ValorTotal.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}
                    </TableCell>
                    <TableCell key={`urlPagamento_pedido-${row.IdPedidoVesti}`} align='center'>
                        {row.UrlPagamentoPixVesti ? <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{truncate(row.UrlPagamentoPixVesti, 12)}</Typography>
                            <span title="Copiar link de pagamento do pedido">
                                <QrCodeIcon style={{ marginLeft: 10, fontSize: 28, cursor: 'pointer' }} onClick={() => {
                                    navigator.clipboard.writeText(row.UrlPagamentoPixVesti);
                                    dispatch({
                                        type: CONTROLEMENSAGEM_SUCESSO,
                                        tipoComponente: 'alert',
                                        titulo: 'Sucesso',
                                        message: 'Link de pagamento do pedido cópiado com sucesso',
                                    });
                                }} />
                            </span>
                        </Box> : "-"}
                    </TableCell>
                    <TableCell key={`motivoCancelamento_pedido-${row.IdPedidoVesti}`} align='left'>
                        {row.MotivoCancelamento ? row.MotivoCancelamento : "-"}
                    </TableCell>
                    <TableCell key={`produtoImportado_pedido-${row.IdPedidoVesti}`} align='left'>
                        <Typography style={{ padding: 2, paddingLeft: 8, paddingRight: 8, color: 'white', background: 'black', borderRadius: 10, border: '0.5px solid #e4e4e4', textAlign: 'center', fontWeight: 'bold' }}>{row.FlgProdutosImportados ? "Sim" : "Não"}</Typography>
                    </TableCell>
                    <TableCell key={`acoes-${row.IdPedidoVesti}`} align='left'>
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            {
                                (!row.FlgProdutosImportados && (row.IdStatusPedido > 1 && row.IdStatusPedido <= 4) || row.IdStatusPedido === 11 || row.IdStatusPedido === 13) &&
                                <span title="Importar produtos">
                                    <GetAppIcon style={{ fontSize: 28, cursor: 'pointer', marginRight: 5, color: 'gray', marginRight: 5 }} onClick={() => {
                                        setOpenModalImportacao(true);
                                    }} />
                                </span>
                            }
                            <Button
                                size="small"
                                variant="contained"
                                style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                                onClick={() => fetchExcel()}
                            >
                                <FileDownloadIcon />
                                CSV
                            </Button>
                        </Box>
                    </TableCell>
                </TableRow>

                {
                    open && loading && <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`itens-pedido-${row.IdPedidoVesti}`}
                        rowSpan={1}
                    >
                        <TableCell colSpan={columnsPedidos.length} key={`pedidos-${row.IdPedidoVesti}`}>
                            <Stack
                                sx={{ height: "150px" }}
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                                <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                }

                {
                    open && !loading && produtos.length === 0 && <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`itens-pedido-${row.IdPedidoVesti}`}
                        rowspan={1}
                    >
                        <TableCell colSpan={columnsPedidos.length} key={`pedidos-${row.IdPedidoVesti}`}>
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum produto encontrado.
                                </Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                }

                {
                    open && !loading && produtos.length > 0 &&
                    <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`itens-pedido-${row.IdPedidoVesti}`}
                        rowspan={1}
                    >
                        <TableCell colSpan={columnsPedidos.length} key={`pedidos-${row.IdPedidoVesti}`} align='left'>
                            <TableContainer style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columnsProduto.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {produtos.map((roww) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={`produto-${roww.IdPedidoProdutosVesti}`}
                                                >
                                                    <TableCell key={`id-${roww.IdPedidoProdutosVesti}`} align='left'>
                                                        {roww.IdPedidoProdutosVesti}
                                                    </TableCell>
                                                    <TableCell key={`id-${roww.IdPedidoProdutosVesti}`} align='left'>
                                                        {roww.IdProduto}
                                                    </TableCell>
                                                    <TableCell key={`nome-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.Nome}
                                                    </TableCell>
                                                    <TableCell key={`sku-${roww.IdPedidoProdutosVesti}`} align='left'>
                                                        {roww.Sku}
                                                    </TableCell>
                                                    <TableCell key={`categoria-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.Categoria}
                                                    </TableCell>
                                                    <TableCell key={`cor-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.Cor}
                                                    </TableCell>
                                                    <TableCell key={`tamanho-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.Tamanho}
                                                    </TableCell>
                                                    <TableCell key={`quantidade-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.Quantidade}
                                                    </TableCell>
                                                    <TableCell key={`valor-promocional-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.ValorPromocional.toLocaleString("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })}
                                                    </TableCell>
                                                    <TableCell key={`valor-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {roww.Valor.toLocaleString("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })}
                                                    </TableCell>
                                                    <TableCell key={`valor-total-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                        {(roww.Quantidade * (roww.ValorPromocional > 0 ? roww.ValorPromocional : roww.Valor)).toLocaleString("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableCell>
                    </TableRow>
                }
            </Fragment>
        );
    }
    catch (err) {
        SalvarLogSentry(err);
    }
};


const PedidosAtacado = () => {
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    const [buscarPedidos, setBuscarPedidos] = useState(false);

    const [pedidos, setPedidos] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(false);

    const [loadingSalvar, setLoadingSalvar] = useState(false);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (userLoggedIn === null) return;

        (async () => {
            if (userLoggedIn.PlanoEmpresa.IdUsuarioEmpresaPlano < 4) {
                navigate('/pagina-em-dev');
                return;
            }

            await fecthPedidos();
        })();

    }, [userLoggedIn, buscarPedidos]);


    const fecthPedidos = async () => {
        try {
            setLoading(true);
            const query = [
                {
                    "sAndOr": "and",
                    "sCampo": "U.IdUsuario",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": "=",
                    "sValorCondicao": userLoggedIn.IdUsuario.toString(),
                    "sTipoCampo": "int"
                },
                {
                    "sAndOr": "and",
                    "sCampo": "PV.FlgAtivo",
                    "sCondicao": "=",
                    "sValorCondicao": 1,
                    "sTipoCampo": "bool"
                }
            ];

            if (status > 0) {
                query.push({
                    "sAndOr": "and",
                    "sCampo": "IdStatusPedido",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": "=",
                    "sValorCondicao": status,
                    "sTipoCampo": "int"
                })
            }

            if (dataInicio !== null) {
                query.push({
                    "sAndOr": "and",
                    "sCampo": "DataCadastro",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": ">=",
                    "sValorCondicao": moment(dataInicio).format('YYYY-MM-DD'),
                    "sTipoCampo": "datetime"
                })
            }

            if (dataFim !== null) {
                query.push({
                    "sAndOr": "and",
                    "sCampo": "DataCadastro",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": "<=",
                    "sValorCondicao": moment(dataFim).add(1, 'days').format('YYYY-MM-DD'),
                    "sTipoCampo": "datetime"
                })
            }

            const pedidos = await POST_DATA(`PedidoVesti/GetAllPedidosVestiByFiltroQuery`, query);

            if (search.length > 0) {
                const pedidoByFornecedor = pedidos.filter(a => a._FornecedorVesti.Nome.trim().toUpperCase().includes(search.trim().toLocaleUpperCase()));

                setPedidos(pedidoByFornecedor);
                setRows(pedidoByFornecedor);
            }
            else {
                setPedidos(pedidos);
                setRows(pedidos);
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            SalvarLogSentry(err);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return <Container>
        {loadingSalvar && <LoadingAllPage text='Importando produtos...' />}

        <Box style={{ width: "100%" }}>
            <Typography style={{ fontWeight: 'bold' }}>Atacado / Pedidos</Typography>
        </Box>
        <Stack
            direction="row"
            sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-end' }}
            justifyContent="space-between"
        >
            <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel style={{ fontWeight: 'bold' }} id="dataInicio">Data de início</InputLabel>
                    <DatePicker
                        id='dataInicio'
                        format="dd/MM/yyyy"
                        value={dataInicio}
                        onChange={(newValue) => setDataInicio(newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ width: 180, marginRight: 1 }}
                    />

                </Box>

                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel style={{ fontWeight: 'bold' }} id="dataFim">Data de fim</InputLabel>
                    <DatePicker
                        id='dataFim'
                        format="dd/MM/yyyy"
                        value={dataFim}
                        onChange={(newValue) => setDataFim(newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ width: 180, marginRight: 1 }}
                    />
                </Box>

                <FormControl style={{ width: 150, marginRight: 1, marginTop: isMobile() ? 10 : null }}>
                    <InputLabel id="status-selector">Status</InputLabel>
                    <Select
                        labelId="status-selector"
                        id="status-selector-id"
                        value={status}
                        label="Status"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            setStatus(Number(e.target.value))
                        }}
                    >
                        <MenuItem value={0}>Todos</MenuItem>
                        {
                            stautsPedido.map(item => {
                                return <MenuItem key={`status-${item.id}`} value={item.id}>{item.nome}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <Box>
                    <Button style={{ marginLeft: 10 }} variant="contained" onClick={async () => await fecthPedidos()}>Buscar</Button>
                </Box>

            </LocalizationProvider>

        </Stack>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading ? (
                <Stack
                    sx={{ height: "150px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                </Stack>
            ) : (
                <>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnsPedidos.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <Row key={i} row={row} onLoadingSalvar={(valor) => setLoadingSalvar(valor)} />
                                ))}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={
                            search
                                ? `${rows.length}/${pedidos.length} resultados encontrados para pesquisa "${search}" - pedidosFornecedor por página: `
                                : "pedidos por página"
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    </Container>;
}

export default PedidosAtacado;