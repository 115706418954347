import { NOTIFICACOES_CLOSE, NOTIFICACOES_OPEN } from './types';

const INITIAL_VALUES = {
  open: false
}

const NotificacoesModalReducer = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case NOTIFICACOES_OPEN:
      return { open: true };
    case NOTIFICACOES_CLOSE:
      return { open: false };
    default:
      return state;
  }
};

export default NotificacoesModalReducer;
