import { useDispatch, useSelector } from 'react-redux';
import { POST_DATA } from '../../services/API';
import { Fragment, useEffect, useState } from 'react';
import { Button, TablePagination, Alert, Box, CircularProgress, Collapse, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import PeriodPicker from '../../components/PeriodPicker';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ImagemSemResultado, formatCurrency, formatDateSimples, salvarCSV, truncate } from '../../services/utils/functions';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { POPOVER_OPEN } from '../../store/PopoverReducer/types';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ModalWhats from '../../components/ModalWhats';
import ModalEmail from '../../components/ModalEmail';
import { CONTROLEMENSAGEM_AVISO } from '../../store/ControleMensagemReducer/types';

const Row = ({ row, setaAchorEl }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    return (
        <Fragment key={row.IdCarrinho}>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
            >
                <TableCell>
                    {
                        row._CarrinhoItens.length > 0 &&
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                </TableCell>
                <TableCell align="left">{row._UsuarioComprador.Email}</TableCell>
                <TableCell align="left">{row._UsuarioComprador.Celular}</TableCell>
                <TableCell align="left">{row._CarrinhoItens.length}</TableCell>
                <TableCell align="left">{row._CarrinhoItens.reduce((soma, item) => { return soma += item?._Etiqueta.mValor }, 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}
                </TableCell>
                <TableCell align="left">{formatDateSimples(row.DataCadastro)}</TableCell>
                <TableCell align="right">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        style={{ marginRight: 10 }}
                    >
                        <RemoveRedEyeIcon />
                    </IconButton>

                    <Button
                        title="Enviar cupom"
                        size="small"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmailComAnexo',
                                    idItem: 'poperModalEmailComAnexo',
                                    open: true,
                                    data: {
                                        nomePessoa: row._UsuarioComprador.Nome,
                                        emailCliente: row._UsuarioComprador.Email
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <LocalOfferIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        title="Enviar mala direta"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmail',
                                    idItem: 'poperModalEmail',
                                    open: true,
                                    data: {
                                        nomePessoa: row._UsuarioComprador.Nome,
                                        emailCliente: row._UsuarioComprador.Email
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <EmailIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        title="Enviar mensagem"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalWhats',
                                    idItem: 'poperModalWhats',
                                    open: true,
                                    data: {
                                        nomePessoa: row._UsuarioComprador.Nome,
                                        numeroWhatsapp: (row._UsuarioComprador?.Celular || '')
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <WhatsAppIcon style={{ fontSize: 16 }} />
                    </Button>

                </TableCell>
            </TableRow>
            {
                row._CarrinhoItens.length > 0 &&
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Itens
                                </Typography>
                                <Alert severity="info">
                                    Esses são os itens que o usuário abandonou.
                                </Alert>
                                <Table
                                    size="medium"
                                    stickyHeader
                                    aria-label="purchases"
                                    sx={{ border: "1px solid lightgray", mb: 4 }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Etiqueta</TableCell>
                                            <TableCell>Marca</TableCell>
                                            <TableCell align="right">Peça</TableCell>
                                            <TableCell align="right">Valor (R$)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row._CarrinhoItens.map((item, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    <Stack direction="row" alignItems="center" gap={1}>
                                                        <img
                                                            src={item._Etiqueta._Imagem.LocalIIS}
                                                            alt="etq-img"
                                                            style={{
                                                                width: "64px",
                                                                border: "1px solid lightgray",
                                                                marginRight: 10
                                                            }}
                                                        ></img>
                                                        <Stack direction="column" gap={1}>
                                                            <Typography>{item._Etiqueta.sDescricao}</Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: "gray", maxWidth: "300px" }}
                                                                dangerouslySetInnerHTML={{ __html: truncate(item._Etiqueta._Imagem.Descricao, 100) }}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>{item._Etiqueta._Marca.Nome}</TableCell>
                                                <TableCell align="right">
                                                    {item._Etiqueta._Peca.Nome}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrency(item._Etiqueta.mValor)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }

        </Fragment>
    );
}

const CarrinhoAbandonados = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((store) => store.usuarioLogado);
    const ordenacao = useSelector((store) => store.ordenacaoDashboard);
    const [anchorEl, setaAchorEl] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [carrinhosAbandonados, setCarrinhosAbandonados] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setIsLoading(true);

        (async () => {
            if (userLoggedIn) {
                await buscarCarrinhosAbandonados();
                setIsLoading(false);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn, ordenacao?.period, ordenacao?.periodToCompare]);

    const buscarCarrinhosAbandonados = async () => {
        const endpoint = `/Carrinho/GetCarrinhosAbandonadosByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}&dataInicio=${ordenacao.period.startDate}&dataFim=${ordenacao.period.endDate}`;
        const resultCarrinhos = await POST_DATA(endpoint);
        const result = resultCarrinhos.filter(item => item._CarrinhoItens.length > 0);
        setCarrinhosAbandonados(result);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchExcel = async () => {
        if (carrinhosAbandonados === null || carrinhosAbandonados.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `E-mail; Telefone; Qtd. de itens; Valor; Data`;
        let celular = '-';
        carrinhosAbandonados?.forEach(carrinho => {
            celular = carrinho?._UsuarioComprador?.Celular !== null ? carrinho._UsuarioComprador?.Celular : '-';

            dados += `\n${carrinho._UsuarioComprador?.Email}; ${celular}; ${carrinho._CarrinhoItens.length}; ${carrinho._CarrinhoItens.reduce((soma, item) => { return soma += item?._Etiqueta.mValor }, 0).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            })}; ${formatDateSimples(carrinho.DtInclusao)}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'CarrinhosAbnandonados.csv', dados);
        window.open(url, '_blank');
    }

    return (
        <>
            <ModalWhats anchorEl={anchorEl} />
            <ModalEmail idPopover='poperModalEmail' anchorEl={anchorEl} />
            <ModalEmail idPopover='poperModalEmailComAnexo' anchorEl={anchorEl} possuiAnexo={true} />

            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Pedidos / Carrinhos Abandonados</Typography>
                </Box>
                <Box style={{ width: '100%' }}>
                    {carrinhosAbandonados.length !== 0 && (
                        <Alert severity="info" style={{ marginTop: 10 }}>
                            {carrinhosAbandonados.length} resultados encontrados entre{" "}
                            {ordenacao.period.startDate} e {ordenacao.period.endDate} ({ordenacao.period.desc})
                        </Alert>
                    )}
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography></Typography>
                    <PeriodPicker />
                </Box>                
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
                {isLoading ? (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", p: 2 }}
                        gap={2}
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando...</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">E-mail</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Telefone</TableCell>
                                        <TableCell style={{ fontWeight: 'bold', width: '120px' }} align="left">Qtd. de itens</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Valor</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Data</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {carrinhosAbandonados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                        <Row setaAchorEl={setaAchorEl} key={i} row={row} />
                                    ))}
                                </TableBody>
                            </Table>
                            {carrinhosAbandonados.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={carrinhosAbandonados.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"Produtos por página"}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )
                }

            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </>
    );

}

export default CarrinhoAbandonados;
