import { MENU_OPEN, MENU_CLOSE } from './types';

const INITIAL_STATE = {
  open: false,
  idMenu: '',
  idItem: 0,
};

const menuPopupRecuder = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_OPEN:
      return { ...state, open: true, idMenu: action.idModal, idItem: action.idItem };
    case MENU_CLOSE:
      return { ...state, open: false, idMenu: '', idItem: 0 };
    default:
      return state;
  }
};

export default menuPopupRecuder;
