import { useEffect, useState } from "react";
import PopoverPopup from "../PopoverPopup";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { POPOVER_CLOSE } from "../../store/PopoverReducer/types";

const ModalWhats = ({ anchorEl }) => {
    const dispatch = useDispatch();
    const [mensagem, setMensagem] = useState(null);
    const [numeroWhatsapp, setNumeroWhatsapp] = useState('');
    const popover = useSelector((store) => store.popoverPopup);

    useEffect(() => {
        if(popover.idPopover === 'poperModalWhats'){
            setNumeroWhatsapp(popover.data?.numeroWhatsapp?.replace("(", "")?.replace(")", "")?.replace("-", "")?.replace(" ", ""));
            setMensagem(`Olá ${popover.data?.nomePessoa}, tudo bem?`);
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [popover.open]);

    const handleClick = () => {
        window.open(`https://api.whatsapp.com/send/?phone=${numeroWhatsapp}&text=${mensagem}&type=phone_number&app_absent=0`, '_blank')
    }

    return <PopoverPopup idPopover='poperModalWhats' anchorEl={anchorEl}>
        <Box style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Enviar mensagem via Whatsapp</Typography>
            <TextField
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                label='Mensagem'
                name='login'
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
                style={{ width: 480, marginTop: 10 }}
                InputLabelProps={{
                    style: {
                        color: 'black',
                        fontSize: '14px'
                    }
                }}
                InputProps={{
                    style: {
                        color: 'black',
                        fontSize: '14px'
                    }
                }}
            />
            <Divider style={{ marginTop: 10 }} />
            <Button style={{ marginTop: 10, background: 'black', color: 'white' }} variant="contained" onClick={handleClick}>Enviar</Button>
            <Button style={{ marginTop: 10 }} variant="text" onClick={() => {
                dispatch({
                    type: POPOVER_CLOSE,
                    idPopover: 'poperModalWhats',
                    idItem: 'poperModalWhats',
                    open: true
                });
            }}>Cancelar</Button>
        </Box>
    </PopoverPopup>
};

export default ModalWhats;