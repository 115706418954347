import { Fab, Tooltip } from "@mui/material";
import { useState } from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  const scrollToTopHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkScrollVisibility = () => {
    if (!showScroll && window.pageYOffset > 700) setShowScroll(true);
    else if (showScroll && window.pageYOffset <= 700) setShowScroll(false);
  };

  window.addEventListener("scroll", checkScrollVisibility);

  return showScroll ? (
    <Tooltip title="Voltar para o topo">
      <Fab
        sx={{ position: "fixed", bottom: "50px", right: "30px " }}
        size="small"
        color="secondary"
        aria-label="scroll"
        onClick={() => scrollToTopHandler()}
      >
        <KeyboardDoubleArrowUpIcon />
      </Fab>
    </Tooltip>
  ) : (
    <></>
  );
};

export default ScrollToTop;
