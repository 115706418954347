import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
    height: 100vh;
    width: 100%;
    min-height: 730px;
    padding: 20px;
    padding-right: 70px;
    padding-left: 70px;
    background: #f0dce1;
`;