import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useEffect, useState } from "react";
import { Circle } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Stack, Typography } from "@mui/material";

interface ColorPickerProps {
  index: any;
  handleChangeCorEtiqueta: any;
}

export default function ColorPicker({ index, handleChangeCorEtiqueta }: ColorPickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ETIQUETA_COLORS = [
    "#0084BE",
    "#B0D304",
    "#FF6800",
    "#ff2474",
    "#9753aa",
  ];

  return (
    <div>
      <Button
        id="basic-button"
        variant="outlined"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<Circle sx={{ color: ETIQUETA_COLORS[index] }} />}
      >
        Cor da Etiqueta
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {ETIQUETA_COLORS.map((color, i) => {
          return (
            <MenuItem key={i} onClick={() => handleChangeCorEtiqueta({ index: i, cor: color })}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Circle sx={{ color: color }} />
                <Typography>Cor {i + 1}</Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
