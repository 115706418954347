import { Fragment, useEffect, useState } from "react";
import { Box, Stack, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, CircularProgress, InputAdornment, Button, TextField, Grid, Modal, Divider, useMediaQuery, Paper } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA } from "../../../services/API";
import { Container, Descricao, Title } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PeriodPicker from "../../../components/PeriodPicker";
import * as moment from "moment";
import { ImagemSemResultado, formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import Card from "../../../components/Card";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import StatusPedido from "../../../components/StatusPedido";
import BootstrapDialogTitle from "../../../components/Modal/BootstrapDialogTitle";
import { ModalContainer } from "../Caixa/styles";

const columns = [
    { id: "dataHoraVenda", label: "Data/hora", minWidth: 120, maxWidth: 170 },
    { id: "cliente", label: "Cliente", minWidth: 120, maxWidth: 170 },
    { id: "vendedor", label: "Vendedor", minWidth: 120, maxWidth: 170 },
    { id: "formaPagamento", label: "Forma de pagamento", minWidth: 120, maxWidth: 170 },
    { id: "quantidadeVendida", label: "Quantidade", minWidth: 120, maxWidth: 200 },
    { id: "valorProdutos", label: "Valor produtos", minWidth: 120, maxWidth: 170 },
    { id: "desconto", label: "Desconto", minWidth: 120, maxWidth: 170 },
    { id: "acrescimo", label: "Acréscimo", minWidth: 120, maxWidth: 170 },
    { id: "valorTotal", label: "Total", minWidth: 120, maxWidth: 170 },
    { id: "dataRegistro", label: "Data registro", minWidth: 120, maxWidth: 170 },
    { id: "acoes", label: "", minWidth: 120, maxWidth: 170 }
];

const VendasPDV = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [opcaoSelecionada, setOpcaoSelecionada] = useState(0);
    const [openModalDetalhe, setOpenModalDetalhe] = useState(false);

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [vendas, setVendas] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [totalVendas, setTotalVendas] = useState(0);
    const [quantidadePedidos, setQuantidadePedidos] = useState(0);
    const [custoFrete, setCustoFrete] = useState(0);

    const [valorVenda, setValorVenda] = useState(0);
    const [acrescimo, setAcrescimo] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const [valorDinheiro, setValorDinheiro] = useState(0);
    const [valorPix, setValorPix] = useState(0);
    const [valorCartaoCredito, setValorCartaoCredito] = useState(0);
    const [valorCartaoDebito, setValorCartaoDebito] = useState(0);
    const [qtdParcelas, setQtdPecaleas] = useState(0);

    useEffect(() => {
        (async () => {
            if (userLoggedIn !== null) {
                await fetchVendasCliente(opcaoSelecionada);
            }
        })();
    }, [userLoggedIn, orderList?.period, orderList?.periodToCompare]);

    useEffect(() => {
        const tempVendas = vendas?.map(venda => {
            return <Row venda={venda} />
        });

        setRows(tempVendas || []);
    }, [vendas]);

    const handleBuscarPedidos = async (idStatus = 0) => {
        await fetchVendasCliente(idStatus);
        setOpcaoSelecionada(idStatus);
    }

    const fetchVendasCliente = async (idStatus, filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        if (orderList?.period === undefined || orderList?.period === null) return;

        setLoading(true);

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        const endpoint = `Pedido/PedidosByIdStatusData?idStatus=4&dtInicio=${orderList.period.startDate}&dtFim=${orderList.period.endDate}&idUsuarioVendedor=${userLoggedIn.IdUsuario}&FlgCompraPresencial=true`;
        const result = await POST_DATA(endpoint);

        let totalVendas = 0;
        let quantidadeVendas = 0;
        let custoFrete = 0;
        result.objetoRetorno.forEach(pedido => {
            if (pedido._PedidoItens.length > 0) {
                totalVendas += pedido.ValorTotal;
                quantidadeVendas += 1;
                custoFrete += pedido.ValorFrete;
            }
        });

        setTotalVendas(totalVendas);
        setQuantidadePedidos(quantidadeVendas);
        setCustoFrete(custoFrete);

        setVendas(result.objetoRetorno);
        setLoading(false);

        if (filtroStr.trim().length > 0) {
            const tmp = result.objetoRetorno.filter(item => item?._UsuarioComprador?.Nome?.includes(`${search}`));
            setVendas(tmp);
            setLoading(false);
        }
        else {
            setVendas(result.objetoRetorno);
            setLoading(false);
        }
    }

    const fetchExcel = async () => {
        if (vendas === null || vendas.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Cliente; Status; Código Pedido; Produto; Data da venda; Quantidade; Sub Total; Desconto; Acréscimo; Total; Data de registro`;
        let sku = '';
        let cor = '';
        let quantidade = '';
        vendas?.forEach(venda => {
            venda._PedidoItens.map(produto => {
                sku = produto._EtiquetaComplemento.Sku !== null ? `${produto._EtiquetaComplemento.Sku} - ` : '';
                cor = produto._EtiquetaComplemento?._Cor?.Descricao ? ` - ${produto._EtiquetaComplemento?._Cor?.Descricao}` : '';
                quantidade = venda._PedidoItens.reduce((soma, item) => { return soma += item?.iQuantidade }, 0)

                dados += `\n${venda._UsuarioComprador.Nome}; ${venda._PedidoStatus.Descricao}; ${venda.NumeroPedido}; ${sku}${produto._Etiqueta.sDescricao} (${produto._EtiquetaComplemento.Tamanho.Descricao}${cor}); ${venda.DataVenda ? formatDateSimples(venda.DataVenda) : formatDateSimples(venda.DataCadastro)}; ${quantidade}; ${(venda.ValorEtiquetas - (venda.ValorDesconto || 0)).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })};${(venda.ValorDescontoTela || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })};${(venda.ValorAcrescimoTela || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}; ${venda.ValorTotal.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}; ${formatDateSimples(venda.DataCadastro)}`;
            });
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'VendasClientes.csv', dados);
        window.open(url, '_blank');
    }

    const calculateTotalEtiqueta = (etq) => {
        const valorEtiqueta = (etq?.EtiquetaComplementoSelecionada?.iQuantidadeVendida || 1) * (etq.mValor - (etq.mValor * ((etq?.mPorcentoDesconto || 0) / 100)));
        return valorEtiqueta;
    };

    const Row = ({ venda }) => {
        const fetchPeidoById = async () => {
            const result = await POST_DATA(`Pedido/GetPedidoByIdPedido?idPedido=${venda.IdPedido}`);
            const formasPagamento = result.objetoRetorno?._MultiplosPagamentos;

            setValorVenda(result.objetoRetorno.ValorTotal);
            setAcrescimo(result.objetoRetorno.ValorAcrescimoTela);
            setDesconto(result.objetoRetorno.ValorDescontoTela);
            if (formasPagamento) {
                formasPagamento.forEach((forma) => {
                    if (forma.IdTipoPagamento === 1) //Cartão de crédito
                    {
                        setValorCartaoCredito(forma.Valor);
                        setQtdPecaleas(forma.QtdParcelas);
                    }
                    else if (forma.IdTipoPagamento === 2) //Pix
                        setValorPix(forma.Valor);
                    else if (forma.IdTipoPagamento === 3) //Dinheiro
                        setValorDinheiro(forma.Valor);
                    else if (forma.IdTipoPagamento === 6) //Cartão de débito
                        setValorCartaoDebito(forma.Valor);
                })
            }
        }

        return <Fragment key={venda.IdPedido}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.DataVenda ? formatDateSimples(venda.DataVenda) : formatDateSimples(venda.DataCadastro)}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda._UsuarioComprador.Nome}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.NomeVendedorPresencial}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda._TipoPagamento.Descricao}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda._PedidoItens.reduce((soma, item) => { return soma += item?.iQuantidade }, 0)}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {(venda.ValorEtiquetas - (venda.ValorDesconto || 0)).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.ValorDescontoTela?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {venda.ValorAcrescimoTela?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {(venda.ValorTotal).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {formatDateSimples(venda.DataCadastro)}
                </TableCell>
                <TableCell align="right" style={{ minWidth: 140 }}>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button variant="contained" style={{ fontSize: 12, background: 'black', color: 'white', marginRight: 10, maxHeight: 50 }} onClick={() => alert('Baixar recibo')}>Baixar recibo</Button>
                        <Button variant="contained" style={{ fontSize: 12, background: 'black', color: 'white', maxHeight: 50 }} onClick={async () => {
                            setValorVenda(0);
                            setValorDinheiro(0);
                            setValorPix(0);
                            setValorCartaoCredito(0);
                            setValorCartaoDebito(0);
                            setQtdPecaleas(0);

                            await fetchPeidoById();

                            setOpenModalDetalhe(true);
                        }}>Detalhes</Button>
                    </Box>
                </TableCell>
            </TableRow>
            {
                venda._PedidoItens.map(produto => {
                    return (
                        <TableRow style={{ background: '#fafafa' }}>
                            <TableCell colSpan={2} align="left" style={{ minWidth: 100 }}></TableCell>
                            <TableCell colSpan={5} align="left" style={{ minWidth: 100 }}>
                                {produto._EtiquetaComplemento.Sku > 0 ? `${produto._EtiquetaComplemento.Sku}-` : ''} {produto._Etiqueta.sDescricao} ({produto._EtiquetaComplemento.Tamanho.Descricao}{produto._EtiquetaComplemento._Cor ? `${produto._EtiquetaComplemento._Cor?.Descricao}- ` : ''})
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {produto.iQuantidade}
                            </TableCell>
                            <TableCell colSpan={3} align="left" style={{ minWidth: 100 }}>
                                {calculateTotalEtiqueta(produto._Etiqueta).toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </TableCell>
                        </TableRow>
                    )
                })
            }

        </Fragment>
    }

    return (
        <Container>

            {
                openModalDetalhe &&
                <Modal
                    open={openModalDetalhe}
                    onClose={() => setOpenModalDetalhe(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalContainer style={{ background: '#f9f9f9', flexDirection: 'column', maxWidth: 600, maxHeight: 600 }}>
                        <BootstrapDialogTitle id={'modalFinalizarVenda'} onClose={() => setOpenModalDetalhe(false)}>
                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>
                                <h3 style={{ color: '#d35393' }}>Detalhes da venda</h3>
                                <Divider />
                            </Box>
                        </BootstrapDialogTitle>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Box style={{ width: 400, background: 'white', borderRadius: 5 }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                                    Total: {valorVenda.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}
                                </Typography>
                            </Box>

                            <Divider style={{ width: '75%', opacity: 0.3, marginTop: 10, marginBottom: 10, background: 'black' }} />
                            <TextField
                                readOnly
                                label="Acréscimo"
                                size="small"
                                sx={{ width: "400px", marginTop: 1 }}
                                value={acrescimo.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            />
                            <TextField
                                readOnly
                                label="Acréscimo"
                                size="small"
                                sx={{ width: "400px", marginTop: 1 }}
                                value={desconto.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            />
                            <Divider style={{ width: '75%', opacity: 0.3, marginTop: 10, marginBottom: 10, background: 'black' }} />

                            <TextField
                                readOnly
                                label="Dinheiro"
                                size="small"
                                sx={{ width: "400px", marginTop: 1 }}
                                value={valorDinheiro.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            />
                            <TextField
                                readOnly
                                label="Pix"
                                size="small"
                                sx={{ width: "400px", marginTop: 2 }}
                                value={valorPix.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            />
                            <TextField
                                readOnly
                                label="Cartão de crédito"
                                size="small"
                                sx={{ width: "400px", marginTop: 2 }}
                                value={valorCartaoCredito.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            />
                            <TextField
                                readOnly
                                label="Cartão de débito"
                                size="small"
                                sx={{ width: "400px", marginTop: 2 }}
                                value={valorCartaoDebito.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            />

                            <TextField
                                label="Número de parcelas cartão de crédito"
                                size="small"
                                sx={{ width: "400px", marginTop: 2 }}
                                value={qtdParcelas}
                                InputLabelProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
                                InputProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
                                inputProps={{
                                    maxLength: 2,
                                }}
                            />
                        </Box>
                    </ModalContainer>
                </Modal>
            }

            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                <Grid container spacing={1} style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={4}>
                        <Typography style={{ fontWeight: 'bold' }}>PDV / Vendas</Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={2} />
                    <Grid item xs={2}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Total de vendas</Title>
                            <Descricao>{totalVendas.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })}
                            </Descricao>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Quantidade vendas</Title>
                            <Descricao>{quantidadePedidos}</Descricao>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'nowrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <TextField
                            label="Pesquisar"
                            placeholder='Nome do Clientes'
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await handleBuscarPedidos('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ height: '100%', marginLeft: 10, height: 43 }}
                            onClick={() => fetchVendasCliente()}
                        >
                            Pesquisar
                        </Button>
                    </Box>
                    <Box style={{ display: 'flex' }}>
                        <PeriodPicker />
                    </Box>
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando vendas</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default VendasPDV;