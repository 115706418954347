import { Box, styled } from "@mui/material";

export const ContainerDetalheProduto = styled(Box)`
  width: 100%;
  height: auto;
  padding: 10px;
  background: white;
  padding: 30px;
  border-radius: 15px;
`;

export const ProductCardContainer = styled(Box)`
  border-radius: 10px;
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 10px;
  margin-left: 5px;

  &:hover{
      width: 100%;
      background-image: linear-gradient(
        rgba(117, 117, 115,.5), rgba(117, 117, 115,.5)
      ), url(${({ imgSrc }) => `${imgSrc}`});
  }

  cursor: pointer;
  ${({ size }) => {
    if (size === 'md') {
      return 'height: 400px;';
    } else {
      return 'height: 300px;';
    }
  }}
  background-image: url(${({ imgSrc }) => `${imgSrc}`});
  background-size: cover;

  @media screen and (max-width: 600px) {    
    max-width: 140px;
    max-height: 170px;
  }
`;

export const ContainerListagem = styled(Box)`
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  position: relative;
  justify-content: flex-end;
`