import { Box, css, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const ShoppingCartContainer = styled(Box)`
    border-radius: 1rem;
    cursor: pointer;
    ${({ size }) => {
        if (size === 'md') {
            return 'height: 400px;';
        } else {
            return 'height: 350px;';
        }
    }}
    background-image: url(${({ imgSrc }) => `${imgSrc}`});
    background-size: cover;

    @media screen and (min-width: 600px) {
    ${({ size }) => {
        if (size === 'md') {
            return 'height: 450px;';
        } else {
            return 'height: 430px;';
        }
    }}
  }
`
export const ModalContainer = styled(Box)`
  ${({ theme }) => css`
    background: rgb(240, 243, 246);
    right: 0;
    width: 30%;
    max-width: 450px;
  `}

  z-index: 1;

  padding: 0.8rem;
  position: absolute;  
  height: 100vh;
  min-height: 100%;
  overflow-y: auto;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.51);

  padding-top: 20px;
  padding-bottom: 30px;
  
  //animation
  animation: fade-in 300ms ease-out forwards;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const CloseBtn = styled(CloseIcon)`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 5;
  right: 5;
`;
