import { Box, Button, Grid, LinearProgress, Menu, MenuItem, Paper, Typography, linearProgressClasses } from "@mui/material";
import { Container } from "./styles";
import Card from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";

import WalletIcon from '@mui/icons-material/Wallet';
import SavingsIcon from '@mui/icons-material/Savings';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { POST_DATA, SalvarLogSentry } from "../../../services/API";
import { useEffect, useState } from "react";
import PeriodPicker from "../../../components/PeriodPicker";
import VerifiedIcon from '@mui/icons-material/Verified';
import { CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";


const DashboardLeapLovers = () => {
    const dispatch = useDispatch();
    const leapLovers = useSelector((state) => state.leapLovers);
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);

    const [totalRecebido, setTotalRecebido] = useState(0);
    const [totalAReceber, setTotalAReceber] = useState(0);
    const [itensVendidos, setItensVendidos] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [cupons, setCupons] = useState([]);

    useEffect(() => {
        if (leapLovers === null) return;

        setTotalRecebido(0);
        setTotalAReceber(0);
        setItensVendidos(0);

        (async () => {
            await fetchPedidos();

            const resultCupons = await POST_DATA(`Cupom/GetAllCupomByIdUsuarioInfluencer?IdUsuarioInfluencer=${leapLovers.afiliado.IdUsuarioLoginInfluencer}&flagAtivo=true`);
            setCupons(resultCupons);
        })();
    }, [leapLovers, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    const fetchPedidos = async () => {
        try {
            const result = await POST_DATA(`UsuarioLoginInfluencer/GetListMinhasVendasFilter?idUsuarioLoja=${leapLovers.afiliado.IdLoja}&idUsuarioLoginiInfluencer=${leapLovers.afiliado.IdUsuarioLoginInfluencer}&dataInicio=${ordenacaoDashboard.period.startDate}&dataFim=${ordenacaoDashboard.period.endDate}`);
            setTotalAReceber(() => result.reduce((total, item) => total += item.ValorTotal, 0));
            setTotalRecebido(() => result.reduce((total, item) => total += item.ValorPago, 0));
            setItensVendidos(() => result.reduce((total, item) => total += (item._pedido ? item._pedido?._PedidoItens.reduce((total2, item2) => total2 += item2.iQuantidade, 0) : 0), 0));
        }
        catch (err) {
            SalvarLogSentry(err);
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCopiar = (cupom) => {
        navigator.clipboard.writeText(cupom);

        dispatch({
            type: CONTROLEMENSAGEM_SUCESSO,
            tipoComponente: 'alert',
            titulo: 'Sucesso',
            message: `Cupom ${cupom} copiado com sucesso.`,
        });
        handleClose();
    }

    return (
        <Container>
            <Grid style={{ width: '100%' }} container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <PeriodPicker />
                </Grid>
                <Grid item xs={12}>
                    <Card style={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#f7931e', color: 'white' }}>
                        <Box style={{ width: '40%', heigth: '100%' }}>
                            <Typography style={{ fontSize: 28 }}>Olá, {leapLovers?.afiliado?.Nome}</Typography>
                        </Box>
                        <Box style={{ width: '60%', heigth: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Você vendeu</Typography>
                            <Typography style={{ fontSize: 15, marginTop: 10 }}>{(totalAReceber).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })}</Typography>
                            <LinearProgress
                                sx={{ width: '80%', height: 15, borderRadius: 10 }}
                                variant="buffer"
                                valueBuffer={100}
                                value={((totalRecebido * 100) / totalAReceber)}
                                color="primary"
                            />
                            <Typography style={{ fontSize: 15, fontWeight: 'bold', marginTop: 15 }}>Falta {(totalAReceber - totalRecebido).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })} para completar sua meta</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card style={{ width: '100%', background: '#f7931e', color: 'white', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Typography style={{ fontSize: 18, marginTop: 30 }}>
                            <WalletIcon style={{ marginRight: 10 }} />
                            Você recebeu
                        </Typography>
                        <Box style={{ width: '100%', marginTop: 60, height: 40, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', color: 'black' }}>
                            <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{totalRecebido.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card style={{ width: '100%', border: '1px solid #842f66', height: 200, color: '#842f66', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Typography style={{ fontSize: 18, marginTop: 30 }}>
                            <SavingsIcon style={{ marginRight: 10 }} />
                            Falta receber
                        </Typography>
                        <Box style={{ width: '100%', border: '1px solid #842f66', marginTop: 60, height: 40, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                            <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{(totalAReceber - totalRecebido).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card style={{ width: '100%', border: '1px solid #842f66', height: 200, color: '#842f66', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Typography style={{ fontSize: 18, marginTop: 30 }}>
                            <TurnedInIcon style={{ marginRight: 10 }} />
                            Itens vendidos
                        </Typography>
                        <Box style={{ width: '100%', border: '1px solid #842f66', marginTop: 60, height: 40, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                            <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{itensVendidos}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card style={{ width: '100%', border: '1px solid #842f66', height: 200, color: '#842f66', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Typography style={{ fontSize: 18 }}>
                            <VerifiedIcon style={{ marginRight: 10 }} />
                            Meu código
                        </Typography>
                        <Typography style={{ fontSize: 12, color: 'gray', marginTop: 10 }}>
                            Compartilhe seu código de influencer para garantir ainda mais vendas e seus clientes terem um desconto especial!
                        </Typography>
                        <Box style={{ width: '100%', marginTop: 30 }}>
                            {
                                cupons.length === 1 &&
                                <Button variant='outlined' onClick={() => {
                                    if (cupons.length > 0) {
                                        navigator.clipboard.writeText(cupons[0].Descricao);

                                        dispatch({
                                            type: CONTROLEMENSAGEM_SUCESSO,
                                            tipoComponente: 'alert',
                                            titulo: 'Sucesso',
                                            message: `Cupom ${cupons[0].Descricao} copiado com sucesso.`,
                                        });
                                    }
                                }}>Copiar meu código</Button>
                            }
                            {
                                cupons.length > 1 &&
                                <Button variant='outlined' onClick={(e) => {
                                    handleClick(e);
                                }}>Cupons</Button>
                            }

                            <Menu
                                id="menu"
                                aria-labelledby="positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {
                                    cupons.map((cupom, index) => {
                                        return <MenuItem key={index} onClick={() => handleCopiar(cupom.Descricao)}>{cupom.Descricao}</MenuItem>
                                    })
                                }
                            </Menu>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default DashboardLeapLovers;