import { ListItem, styled } from '@mui/material';

export const Indicador = styled(ListItem)`
  width: 260px;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  cursor: pointer;

  transition: 0.3s ease all;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;
