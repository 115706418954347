import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 1024px;
        max-height: 245px;
    `}
`;
