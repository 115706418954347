import * as Styled from './styles';

export const PaginaEmDev = ({ children }) => {
    return (
        <Styled.Container>
            <div>
                <h2>Funcionalidade somente Disponivel a Partir do Plano Londres</h2>
            </div>
        </Styled.Container>
    );
};
