import React, { useEffect, useState } from "react";

import "./styles.css";

function Video({ content = null }) {
   const [videoId, setVideoId] = useState(null);

   useEffect(() => {
      if (content?.CaminhaVideo) setVideoId(content.CaminhaVideo.split("v=")[1]);
   }, [content]);

   return (
      <div>
         {content && (
            <>
               <div className="aula-header-video">
                  <text>{content.Titulo}</text>
                  {content.CaminhaVideo !== null ? (
                     // <iframe
                     //    width="100%"
                     //    height="440"
                     //    src={content.CaminhaVideo}
                     //    title="YouTube video player"
                     //    frameborder="0"
                     //    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                     //    allowfullscreen
                     // ></iframe>
                     <iframe
                        src={`https://www.youtube.com/embed/${videoId}`}
                        style={{
                           width: "100%",
                           height: 440,
                        }}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                     />
                  ) : (
                     <img
                        src={content.CaminhaCapa}
                        style={{ width: "100%", height: 440 }}
                        alt="capa da imagem"
                     />
                  )}
               </div>

               <div className="aula-description-container">
                  <text>Descrição:</text>
                  <text>{content.Descricao}</text>
               </div>
            </>
         )}

         {!content && (
            <>
               <div className="loading-screen">
                  <text> </text>
                  <div style={{ width: "100%", height: 440 }} />
               </div>

               <div className="aula-description-container">
                  <text> </text>
                  <text> </text>
               </div>
            </>
         )}
      </div>
   );
}

export default Video;
