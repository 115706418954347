import { Avatar, Box, Button, Divider, Grid, InputAdornment, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ContainerDetalheProduto } from "./styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MODAL_SHOPPING_CART_ABRIR } from "../../store/ModalShoppingCartReducer/types";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartModal from "../ShoppingCartModal";

import { Add, Remove } from '@mui/icons-material';
import { CARRINHO_SET_DADOS } from "../../store/CarrinhoVesti/types";
import { CONTROLEMENSAGEM_AVISO } from "../../store/ControleMensagemReducer/types";
import { StyleValueCart } from "../../pages/Atacado/styles";


const DetalhesProduto = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const carrinhoVesti = useSelector((store) => store.carrinhoVesti);

    const [produto, setProduto] = useState(null);
    const [imagemSelecionada, setImagemSelecionada] = useState('');
    const [tabSelecionada, setTabSelecionada] = useState(0);

    const [idCorSelecionada, setIdCorSelecionada] = useState(0);
    const [idTamanhoSelecionadao, setIdTamamhoSelecionado] = useState(0);
    const [quantidadePedido, setQuantidadePedido] = useState(0);

    const [cep, setCep] = useState('');

    useEffect(() => {
        setProduto(location.state?.produto);
        setImagemSelecionada(location.state?.produto.fotoProduto);

        const stock = location.state?.produto?.estoque[0];
        if (stock) {
            setIdCorSelecionada(stock.color_id);
            setIdTamamhoSelecionado(stock.size_id);
        }

    }, []);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                style={{ paddingTop: 12 }}
            >
                {value === index && children}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setTabSelecionada(newValue);
    };

    const handleAddProdutoCarrinho = () => {
        if (quantidadePedido === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Favor informar a quantidade',
            });

            return;
        }

        const possuiProdutoOutraLoja = carrinhoVesti.filter(a => a.produto.loja.id !== produto.loja.id).length > 0;

        if (possuiProdutoOutraLoja) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Não é possível adicionar produto de outra loja',
            });

            return;
        }

        const stock = produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0];

        if (carrinhoVesti.filter(a => a.stock.id === stock.id).length > 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Produto já adicionado',
            });

            return;
        }

        const produtoCarrinho = {
            idStock: produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0].id,
            idCor: idCorSelecionada,
            idTamanho: idTamanhoSelecionadao,
            quantidadeSelecionada: quantidadePedido,
            stock: produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0],
            produto: produto
        }

        if (carrinhoVesti === null) {
            dispatch({
                type: CARRINHO_SET_DADOS,
                data: [...carrinhoVesti, produtoCarrinho]
            });
        }
        else {

            dispatch({
                type: CARRINHO_SET_DADOS,
                data: [...carrinhoVesti, produtoCarrinho]
            });
        }

        dispatch({
            type: MODAL_SHOPPING_CART_ABRIR
        });
    }

    return produto && <ContainerDetalheProduto>
        <ShoppingCartModal />

        <Box style={{ width: '100%', height: 30, background: '#878787', paddingTop: 8, marginBottom: 10 }}>
            <Typography style={{ fontSize: 12, color: '#fff', textAlign: 'center' }}>
                {produto.loja.nome} - 
                {
                    produto.loja.catalogue.cart_requirements?.tipo === 'peca' &&
                    <span>Quantidade mínima de compra: <span style={{ fontWeight: 'bold' }}>{produto.loja.catalogue.cart_requirements.qtdevalor} peças </span></span>
                }
                {
                    produto.loja.catalogue.cart_requirements?.tipo !== 'pecas' &&
                    <span>Valor mínimo de compra: <span style={{ fontWeight: 'bold' }}>{Number(produto.loja.catalogue.cart_requirements.qtdevalor).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })} </span></span>
                }
            </Typography>
        </Box>

        <Grid container spacing={2} style={{ paddingRight: 10 }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="text" onClick={() => navigate(-1)}>
                    Voltar
                </Button>

                <Box style={{ marginRight: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    {/* {
                        produto.flgCuradoriaVesti &&
                        <img src='https://hv3.meuvesti.com/images/logo/logo_vesti_padrao.png' style={{ width: 100 }} />
                    } */}
                    <span className="material-symbols-outlined" style={{ marginLeft: 10, fontSize: 30 }} onClick={() => {
                        dispatch({
                            type: MODAL_SHOPPING_CART_ABRIR
                        });
                    }}>
                        shopping_cart
                    </span>
                    <StyleValueCart>
                        {carrinhoVesti.length}
                    </StyleValueCart>
                </Box>
            </Grid>

            <Grid item xs={12} md={3} style={{ width: '100%', maxWidth: 220, paddingLeft: 10, maxHeight: 600, overflowY: 'auto' }}>
                <img
                    alt="Produto"
                    src={produto.fotoProduto}
                    style={{ height: '200px', cursor: 'pointer', border: produto.fotoProduto === imagemSelecionada ? '2.5px solid rgb(254, 158, 64)' : 'none' }}
                    onClick={() => setImagemSelecionada(produto.fotoProduto)}
                />
                {
                    produto.imagensCompolementares.map((img, index) => {
                        return <img
                            key={index}
                            alt="Produto"
                            src={img.fotoProduto}
                            style={{ height: '200px', marginTop: 10, cursor: 'pointer', border: img.fotoProduto === imagemSelecionada ? '2.5px solid rgb(254, 158, 64)' : 'none' }}
                            onClick={() => setImagemSelecionada(img.fotoProduto)}
                        />
                    })
                }
            </Grid>

            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <img alt="Produto" src={imagemSelecionada} style={{ width: '100%', maxWidth: '90%', height: 'auto' }} />
            </Grid>

            <Grid item xs={12} md={3} style={{ paddingRight: 15, paddingBottom: 15, marginTop: -10 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%", display: 'flex', flexWrap: 'wrap' }}
                >
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'start', paddingTop: 10 }}>
                        <Typography style={{ width: '100%', fontWeight: 700, textTransform: 'uppercase', fontSize: 20, textAlign: 'start' }}>
                            {produto.nome}
                        </Typography>
                    </Box>

                    <Box style={{ width: '100%' }}>
                        <Typography sx={{ fontSize: 16, marginTop: 0.8 }}>
                            <span style={{ fontWeight: 'bold' }}>Marca: </span>{produto.marca.name}
                        </Typography>
                    </Box>
                    <Box style={{ width: '100%', marginTop: 10, marginBottom: 2 }}>
                        <Typography sx={{ textAlign: 'start', marginBottom: 2, fontSize: 12 }}>
                            Aproveite, este produto só tem <span style={{ fontWeight: 'bold' }}>{produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0]?.quantity}  unidade(s) disponível(eis)</span>
                        </Typography>
                    </Box>

                    {
                        produto.valorPromocional > 0 &&
                        <>
                            <Box style={{ width: '100%', marginLeft: 10 }}>
                                <Typography sx={{ textDecoration: 'line-through', fontWeight: 'bold', color: 'gray', fontSize: 16 }}>
                                    {
                                        produto.valor?.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })
                                    }
                                </Typography>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: produto.valorPromocional > 0 ? 10 : 0 }}>
                                <Typography sx={{ fontWeight: '500', fontSize: 24, color: 'rgb(64, 64, 64)' }}>
                                    {produto.valorPromocional?.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}
                                </Typography>
                            </Box>
                        </>
                    }
                    {
                        produto.valorPromocional == 0 &&
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: produto.valorPromocional > 0 ? 10 : 0 }}>
                            <Typography sx={{ fontWeight: '500', fontSize: 24, color: 'rgb(64, 64, 64)' }}>
                                {produto.valor?.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </Typography>
                        </Box>
                    }

                    <Box style={{ width: '100%' }}>
                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Cores</Typography>
                        <Box style={{ width: '100%', display: 'flex', marginTop: 5, marginBottom: 10 }}>
                            {
                                produto.colors && [...new Map(produto.colors.map(item => [item['id'], item])).values()].map(cor => {
                                    return <div key={cor.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography style={{ color: 'gray', fontSize: 11, marginLeft: 10 }}><span style={{ fontWeight: 'bold' }}>{cor.name}</span></Typography>
                                        <span
                                            onClick={() => setIdCorSelecionada(cor.id)}
                                            style={{
                                                marginLeft: 10,
                                                padding: 10,
                                                border: idCorSelecionada === cor.id ? '3px solid #929292' : '1px solid white',
                                                borderRadius: 20,
                                                background: cor.code,
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                width: 30,
                                                height: 30
                                            }}
                                        >
                                        </span>
                                    </div>
                                })
                            }
                        </Box>
                    </Box>

                    <Box style={{ width: '100%' }}>
                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Tamanhos</Typography>
                        <Box style={{ width: '100%', display: 'flex', marginTop: 5, marginBottom: 10 }}>
                            {
                                produto.tamanhos && [...new Map(produto.tamanhos.map(item => [item['id'], item])).values()].map(tamanho => {
                                    return <div key={tamanho.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <span
                                            onClick={() => setIdTamamhoSelecionado(tamanho.id)}
                                            style={{
                                                marginLeft: 10,
                                                padding: 10,
                                                paddingTop: 9,
                                                paddingLeft: idTamanhoSelecionadao === tamanho.id ? 8 : 10,
                                                fontSize: 14,
                                                border: idTamanhoSelecionadao === tamanho.id ? '3px solid #929292' : '1px solid white',
                                                borderRadius: 20,
                                                background: '#cfcfcf',
                                                color: 'black',
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                width: 40,
                                                height: 40,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {tamanho.name}
                                        </span>
                                    </div>
                                })
                            }
                        </Box>
                    </Box>

                    <Box style={{ width: '100%' }}>
                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Quantidade</Typography>

                        <TextField
                            sx={{ fontSize: 10, fontFamily: 'Arimo', background: 'white', marginTop: 1 }}
                            type="number"
                            id={`quantidadePedido`}
                            variant="outlined"
                            readOnly={true}
                            value={quantidadePedido}
                            InputProps={{
                                style: { fontWeight: 'bold', fontSize: 10, fontFamily: 'Arimo', background: 'white', textAlign: 'center' },
                                inputProps: {
                                    min: 0,
                                    max: produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0]?.quantity,
                                    style: {
                                        background: 'white',
                                        textAlign: 'center'
                                    }
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Remove sx={{ width: 15, marginLeft: '-7px', cursor: 'pointer', background: 'white' }} onClick={() => {
                                            if (quantidadePedido === 0) return;

                                            setQuantidadePedido(quantidadePedido - 1)
                                        }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Add sx={{ width: 15, marginRight: '-7px', cursor: 'pointer', background: 'white' }} onClick={() => {
                                            if ((quantidadePedido + 1) > produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0]?.quantity) return;

                                            setQuantidadePedido(quantidadePedido + 1)
                                        }} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>

                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{ marginTop: 1, width: 500, background: 'black', color: 'white', fontWeight: 'bold', fontSize: 13, borderRadius: 0, height: 45, marginRight: 1, padding: 3 }}
                            onClick={() => {
                                handleAddProdutoCarrinho();
                            }}
                        >
                            Adicionar ao carrinho
                        </Button>
                    </Box>

                    <Typography style={{ marginTop: 30, fontSize: 16 }}>Vendido e entregue por <span style={{ fontWeight: 'bold' }}>{produto.loja.nome}</span></Typography>

                    {/* <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'start', alignItems: 'flex-end' }}>
                        <Box>
                            <Typography style={{ color: 'gray', fontSize: 13 }}>Digite seu CEP*</Typography>
                            <TextField
                                sx={{ marginLeft: 0, marginTop: 2 }}
                                InputProps={{
                                    style: { width: '180px', fontSize: 16, height: 40 }
                                }}
                                inputProps={{
                                    maxLength: 8,
                                    style: {
                                        background: 'white'
                                    }
                                }}
                                id="cep"
                                placeholder='CEP'
                                variant="outlined"
                                type='text'
                                value={cep}
                                onChange={(e) => {
                                    if (e.target.value === null || e.target.value === undefined || e.target.value === '') return;
                                    setCep(e.target.value.replace('-', '').replace(/[^0-9]/g, ''));
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 16 }
                                }}
                            />
                        </Box>
                        <Button
                            sx={{ marginTop: 1.5, marginLeft: 2, height: 40, minWidth: '170px', maxWidth: 200, backgroundColor: 'black', color: 'white' }}
                            variant='contained'
                        >Pesquisar</Button>
                    </Box> */}
                </Stack>
            </Grid>

            <Box style={{ width: '100%', padding: 30 }}>
                <Tabs value={tabSelecionada} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="DETALHES DO PRODUTO" {...a11yProps(0)} />
                    {/* <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="INFORMAÇÕES" {...a11yProps(1)} /> */}
                </Tabs>
                {/* Pegar as infos da primeira etiqueta */}
                <CustomTabPanel value={tabSelecionada} index={0}>
                    <Typography style={{ marginTop: 5, textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: produto?.descricao }} />
                </CustomTabPanel>

                <Tabs style={{ marginTop: 30, color: 'black' }} value={0} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="VENDEDOR" {...a11yProps(0)} />
                </Tabs>
                <CustomTabPanel value={0} index={0}>
                    <Box style={{ marginTop: 10, display: 'flex', flexWrap: 'nowrap', justifyContent: 'start', alignItems: 'center' }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar style={{ width: 70, height: 70 }} src={produto.loja.fotoLogo} />
                            <Box style={{ width: 'auto', marginLeft: 15 }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{`${produto.loja.nome}`}</Typography>
                                <Typography style={{ color: 'gray', fontSize: 14 }}>{`São Paulo, SP`}</Typography>
                            </Box>
                        </Box>
                        <Button variant='contained' style={{ marginLeft: 40, height: 45, background: 'rgb(238, 237, 234)', fontWeight: 'bold', color: 'black', fontSize: 14 }} onClick={() => navigate(`/atacado`, { state: { loja: produto.loja } })}>Ver Loja</Button>
                    </Box>
                </CustomTabPanel>
            </Box>

        </Grid>
    </ContainerDetalheProduto>
}

export default DetalhesProduto;