import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import "./styles.css";

//Screens
import Video from "./components/video";
import Modules from "./components/module";

//Api
import { GetAulas } from "../../../services/API";

//Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronLeft";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";

import { useNavigate, useLocation } from "react-router-dom";

function AulaScreen() {
   const navigate = useNavigate();
   const location = useLocation();

   const [moduleSelect, setModuleSelect] = useState(true);
   const [cursoDropdown, setCursoDropdown] = useState(false);
   const [aulas, setAulas] = useState([]);
   const [aulaSelect, setAulaSelect] = useState();
   const [tempo, setTempo] = useState();

   const getConteudo = async (id) => {
      const response = await GetAulas(id);

      if (response.status === 200) {
         const _aulas = response.data.filter((res) =>
            res.FlgAtivo ? res : null
         );

         setAulas(_aulas);
      }
   };

   const setHours = (hour) => {
      const hours = Math.floor(hour / 60);
      const minutes = hour % 60;

      let formattedTime = "";
      if (hours > 0) {
         formattedTime = `${hours} hora${
            hours > 1 ? "s" : ""
         } e ${minutes} minuto${minutes > 1 ? "s" : ""}`;
      } else {
         formattedTime = `${minutes} minuto${minutes > 1 ? "s" : ""}`;
      }

      setTempo(formattedTime);
   };

   useEffect(() => {
      if (location?.state?.modulo?.IdModulo) {
         getConteudo(location.state.modulo.IdModulo);
      }

      if (location?.state?.modulo?.Tempo) {
         setHours(location?.state?.modulo?.Tempo);
      }
   }, [location]);

   return (
      <div style={{ backgroundColor: "#f9f9f9", paddingBottom: 20 }}>
         <div className="aula-header">
            <text>LEAP-Academy</text>
            <div
               className="aula-back-buttom"
               onClick={() => navigate(-1)}
            >
               {/* <ChevronLeftIcon htmlColor='#f0416a' fontSize='medium' /> */}
               <ChevronLeftIcon htmlColor="#ff8e00" fontSize="medium" />
               <text>Voltar</text>
            </div>
         </div>

         <div className="aula-container-body">
            <div className="aula-sidebar1" style={{ marginRight: 20 }}>
               <text id="aula-sidebar-title">Titulo #1</text>
               <div id="aula-card-container">
                  <div id="aula-card-content">
                     <text>Duração</text>
                     <div id="aula-card-button">
                        <text>{tempo}</text>
                     </div>
                  </div>

                  <div id="aula-card-content">
                     <text>A quem se destina</text>
                     <div id="aula-card-button">
                        <text>{location?.state?.modulo?.Destino}</text>
                     </div>
                  </div>

                  <div id="aula-card-content">
                     <text>Estágio de maturidade</text>
                     <div id="aula-card-button">
                        <text>{location?.state?.modulo?.Maturidade}</text>
                     </div>
                  </div>
               </div>

               <div id="aula-card-container">
                  <div id="aula-card-content">
                     <div className="aula-card-search">
                        <text id="aula-card-title1">
                           Procurando algo especifico?
                        </text>
                        <text id="aula-card-title2">
                           Use o sistema de busca
                        </text>
                        <text id="aula-card-description">
                           Clique no botão pesquisar abaixo e informe a
                           palavra-chave
                        </text>
                     </div>
                  </div>

                  <div className="aula-card-search-button">
                     <ChevronRightIcon htmlColor="#ff8e00" fontSize="medium" />
                     <text>Pesquisar</text>
                  </div>
               </div>
            </div>

            <div className="aula-content" style={{ minWidth: 600 }}>
               {/* <text>Titulo</text> */}

               {moduleSelect && <Video content={aulaSelect} />}
               {!moduleSelect && <Modules />}

               {/* 
                        <div className='aula-discussion-container'>
                            <text id='aula-description-title'>DISCUSSÕES</text>
                            <div className='aula-discussion-content'>
                                <text id='aula-description-empty'>Nenhum tópico criado!</text>

                                <div className='aula-description-buttons'>
                                    <text id='aula-description-view-more'>Ver mais perguntas e respostas</text>
                                    <text id='aula-description-question'>Fazer uma pergunta à comunidade</text>
                                </div>
                            </div>
                        </div>
               */}
            </div>

            <div className="aula-sidebar2" style={{ marginLeft: 20 }}>
               <div className="aula-sidebar2-container">
                  <text id="aula-sidebar2-title">MEUS CURSOS NA TRILHA</text>

                  <div className="aula-sidebar2-content">
                     {Array.isArray(aulas) &&
                        aulas.map((res) => (
                           <div
                              style={{ marginBottom: 20, cursor: "pointer" }}
                              onClick={() => setAulaSelect(res)}
                           >
                              <div id="aula-sidebar2-aula">
                                 <VisibilityIcon
                                    htmlColor="#ff8e00"
                                    fontSize="medium"
                                 />
                                 <div style={{display:"flex", flexDirection: "column"}}>
                                    <text>
                                       <strong>{res.Titulo}</strong>
                                    </text>
                                    <text>{res.Descricao}</text>
                                 </div>
                                 <div
                                    onClick={() =>
                                       setCursoDropdown(!cursoDropdown)
                                    }
                                 >
                                    {/* {cursoDropdown ? (
                                       <KeyboardArrowUpOutlinedIcon
                                          htmlColor="#ff8e00"
                                          fontSize="small"
                                       />
                                    ) : (
                                       <KeyboardArrowDownOutlinedIcon
                                          htmlColor="#ff8e00"
                                          fontSize="small"
                                       />
                                    )} */}
                                 </div>
                              </div>
                              <ProgressBar
                                 now={100}
                                 label="100%"
                                 bgColor="#ff8e00"
                                 labelSize="small"
                                 labelAlignment="center"
                                 style={{ marginTop: 5 }}
                              />
                           </div>
                        ))}

                     {/* {cursoDropdown && (
                        <div className="aula-sidebar2-modules">
                           <div onClick={() => setModuleSelect(true)}>
                              <AutoStoriesOutlinedIcon
                                 htmlColor="#ff8e00"
                                 fontSize="small"
                              />
                              <text>Modulo 1 - Video aula</text>
                           </div>
                           <div onClick={() => setModuleSelect(false)}>
                              <AutoStoriesOutlinedIcon
                                 htmlColor="#ff8e00"
                                 fontSize="small"
                              />
                              <text>Modulo 2 - Perguntas</text>
                           </div>
                           <div>
                              <AutoStoriesOutlinedIcon
                                 htmlColor="#ff8e00"
                                 fontSize="small"
                              />
                              <text>
                                 Modulo 3 - Minim in velit laboris ullamco et.
                              </text>
                           </div>
                           <div>
                              <AutoStoriesOutlinedIcon
                                 htmlColor="#ff8e00"
                                 fontSize="small"
                              />
                              <text>
                                 Modulo 4 - Minim in velit laboris ullamco et.
                              </text>
                           </div>
                           <div>
                              <AutoStoriesOutlinedIcon
                                 htmlColor="#ff8e00"
                                 fontSize="small"
                              />
                              <text>
                                 Modulo 5 - Minim in velit laboris ullamco et.
                              </text>
                           </div>
                           <div>
                              <AutoStoriesOutlinedIcon
                                 htmlColor="#ff8e00"
                                 fontSize="small"
                              />
                              <text>
                                 Modulo 6 - Minim in velit laboris ullamco et.
                              </text>
                           </div>
                        </div>
                     )} */}
                  </div>
               </div>

               <div className="aula-sidebar2-container">
                  <text id="aula-sidebar2-title">MATERIAIS COMPLEMENTARES</text>
                  <div id="aula-sidebar2-content">
                     {/* <CollectionsBookmarkOutlinedIcon htmlColor='#f0416a' fontSize='large' /> */}
                     <CollectionsBookmarkOutlinedIcon
                        htmlColor="#ff8e00"
                        fontSize="large"
                     />
                     <text>E-book</text>
                  </div>
               </div>

               {/* 
                        <div className='aula-sidebar2-container'>
                            <text id='aula-sidebar2-title'>INDICAÇÕES DA COMUNIDADE</text>
                            <div>
                                <div id='aula-sidebar2-buttons'>
                                    <CreateOutlinedIcon htmlColor='#fff' fontSize='small' />
                                    <text>Indique um material complementar</text>
                                </div>

                                <div id='aula-sidebar2-buttons'>
                                    <CollectionsBookmarkOutlinedIcon htmlColor='#fff' fontSize='small' />
                                    <text>Veja mais indicações da comunidade</text>
                                </div>
                            </div>
                        </div>
                        */}

               <div className="aula-sidebar2-container">
                  <text id="aula-sidebar2-title">INSCRIÇÃO</text>
                  <div id="aula-sidebar2-button-remove">
                     <text>Abandonar a Trilha</text>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default AulaScreen;
