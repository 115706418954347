import { Navigate, useLocation } from 'react-router';
import { getStorage, removeStorage } from '../hooks/useStorage';
import MinimalLayout from '../layout/MinimalLayout';
import ValidarCodigoAtivacao from '../pages/Configuracao/ValidarCodigoAtivacao';
import * as moment from 'moment';
import { useSelector } from 'react-redux';


const Private = ({ children }) => {  
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const onBoard = useSelector((state) => state.onBoard);    
    const usuarioLogado = getStorage('usrin');

    if (!usuarioLogado) return <Navigate to={'/login'} />;
  
    const objUsuarioLogado = JSON.parse(usuarioLogado);
    const dataLogin = moment(objUsuarioLogado.Time);
    const agora = moment().add(0, 'minutos');
  
    if(dataLogin < agora){
      removeStorage('usrin');
      alert('Sessão expirada!\n Favor realizar o login novamente');
      window.location.href = "/login";
      return null;
    }

    if(userLoggedIn?.FlgCodigoAcesso && !onBoard.FlagCompletado) return <Navigate to={'/on-board'} />
    if(userLoggedIn?.FlgCodigoAcesso && onBoard.FlagCompletado) return <Navigate to={'/'} />
  
    return children;
  };

const ValidationCodeRoute = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/validar-codigo-ativacao',
            element: <Private>
                <ValidarCodigoAtivacao />
            </Private>
        }
    ],
};

export default ValidationCodeRoute;
