import { Navigate } from 'react-router';
import { getStorage, removeStorage } from '../hooks/useStorage';
import * as moment from 'moment';
import MainLayout from '../layout/MainLayout';
import Help from '../pages/LeapLovers/Help';
import LeapVestiPedidos from '../pages/LeapVesti/Pedidos';


const Private = ({ children }) => { 
    const ehLeapVesti = process.env.REACT_APP_LEAP_VESTI === 'true';
    const leapVesti = getStorage('usrLeapVesti');
    if (!leapVesti || !ehLeapVesti) return <Navigate to={'/login'} />;
  
    const objUsuarioLogado = JSON.parse(leapVesti);
    const dataLogin = moment(objUsuarioLogado.Time);
    const agora = moment().add(0, 'minutos');
  
    if(dataLogin < agora){
      removeStorage('usrin');
      alert('Sessão expirada!\n Favor realizar o login novamente');
      window.location.href = "/login";
      return null;
    }
  
    return children;
  };

const leapVestiRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/pedidos-vesti',
            element: <Private>
                <LeapVestiPedidos />
            </Private>
        },
        {
            path: '/help',
            element: <Private>
                <Help />
            </Private>
        }
    ],
};

export default leapVestiRoutes;
