export const getStyleDestaqueSemaforo = (background = 'green', color = 'white', width = 70) => {
    return {
        padding: 10,
        paddingTop: 2.5,
        paddingBottom: 2.5,
        minWidth: width,
        textAlign: 'center',
        background: background,
        color: color,
        fontWeight: 'bold',
        borderRadius: 40
    }
}

const getStyleDestaque = (background = 'green', color = 'white', width = 70) => {
    return {
        padding: 10,
        paddingTop: 2.5,
        paddingBottom: 2.5,
        minWidth: width,
        maxWidth: 200,
        textAlign: 'center',
        background: background,
        color: color,
        fontWeight: 'bold',
        borderRadius: 40
    }
}

export const getCorByStatusPedido = (descricao) => {
    switch (descricao) {
        case 'Todos':
            return 'white';
        case 'Em analise':
            return '#fff2a7';
        case 'Em análise':
            return '#fff2a7';
        case 'Reprovado':
            return '#ff9999';
        case 'Erro de Processamento':
            return '#ff9999';
        case 'Aprovado':
            return '#ccff99';
        case 'Entregue':
            return '#2c8f1d';
        case 'Cancelado Vendedor':
            return '#ff9999';
        case 'Cancelado Comprador':
            return '#f598c5';
        case 'Cancelado Inatividade':
            return '#df8aeb';
        case 'Cancelado Robô':
            return '#df8aeb';
        default:
            return '#c4f9ff';
    }
}

export const getCorByStatusIncludesDescricao = (descricao) => {
    if (descricao.includes('analise') || descricao.includes('análise'))
        return '#fff2a7';
    else if (descricao.includes('reprovado') || descricao.includes('cancelado'))
        return '#bd2e20';
    else if (descricao.includes('processamento') || descricao.includes('preparação') || descricao.includes('Curtiu'))
        return '#1a69b8';
    else if (descricao.includes('aprovado'))
        return 'green';
    else if (descricao.includes('entregue'))
        return '#2c8f1d';
    else if (descricao.includes('inatividade') || descricao.includes('Robô'))
        return '#df8aeb';
    else 
        return 'black';    
}

export const getDescricaoStatusPedido = (descricao) => {
    const cor = getCorByStatusPedido(descricao);
    switch (descricao) {
        case 'Reprovado':
            return (<div style={getStyleDestaque(cor, 'white', 80)}>{descricao}</div>);
        case 'Aprovado':
            return <div style={getStyleDestaque(cor, 'black', 80)}>{descricao}</div>
        case 'Cancelado Vendedor':
            return <div style={getStyleDestaque(cor, 'white', 80)}>{descricao}</div>
        case 'Cancelado Comprador':
            return <div style={getStyleDestaque(cor, 'white', 80)}>{descricao}</div>
        case 'Em analise':
            return <div style={getStyleDestaque(cor, 'black', 80)}>{descricao}</div>
        case 'Cancelado Robô':
            return <div style={getStyleDestaque(cor, 'white', 80)}>Cancelado Inatividade</div>
        default:
            return <div style={getStyleDestaque(cor, 'black', 80)}>{descricao}</div>
    }
}