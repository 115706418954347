import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SET_MENU } from "../../../store/Customization/types";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { POST_DATA, SalvarLogSentry } from "../../../services/API";
import LoadingAllPage from "../../../components/LoadingAllPage";

const Colecoes = () => {
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const navigete = useNavigate();
    const dispatch = useDispatch();

    const [colecoes, setColecoes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: false });

        (async () => {
            try {
                setLoading(true);

                const result = await POST_DATA(`UsuarioEmpresa/ColecaoContadores?IdUsuario=${userLoggedIn.IdUsuario}`);

                setColecoes([{
                    title: 'Todos os produtos',
                    link: 'produtos',
                    quantidade: result.iTotal
                },
                {
                    title: 'Promoções',
                    link: 'ecommerce-promocoes',
                    quantidade: result.iTotalPromocao
                },
                {
                    title: 'Liquidação',
                    link: 'ecommerce-liquidacao',
                    quantidade: result.iTotalLiquidacao
                },
                {
                    title: 'Novidades',
                    link: 'ecommerce-novidades',
                    quantidade: result.iTotalNovidade
                },
                {
                    title: 'Lançamentos',
                    link: 'ecommerce-lancamento',
                    quantidade: result.iTotalLancamento
                },
                {
                    title: 'Mais vendidos',
                    link: 'ecommerce-mais-vendidos',
                    quantidade: result.iTotalMaisVendido
                }]);

                setLoading(false);
            }
            catch (err) {
                setLoading(false);
                SalvarLogSentry(err);
            }
        })();

    }, []);


    return loading ? <LoadingAllPage text='Buscando dados...' /> : <>
        <Typography style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10, cursor: 'pointer' }}> <KeyboardBackspaceIcon onClick={() => dispatch({ type: SET_MENU, opened: true })} /> Coleções</Typography>
        <Typography style={{ fontSize: 16, marginBottom: 10 }}>insira os produtos nos grupos de produtos que você usa para criar sua loja. Você pode adicionar esse produto a uma ou mais categorias.</Typography>
        <Paper style={{ padding: 20 }}>
            <Grid container spacing={2}>
                {
                    colecoes.map((colecao) => {
                        return <Grid item xs={12} md={2}>
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 120, background: '#fde4ea', cursor: 'pointer', borderRadius: 10, padding: 20 }} onClick={() => navigete(`/${colecao.link}`, { replace: true })}>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Typography style={{ fontSize: 18, position: 'absolute', marginTop: 10 }}>{colecao.title}</Typography>
                                    <Button style={{ position: 'relative', left: '50%', bottom: '50%' }}><RemoveRedEyeIcon style={{ color: 'black' }} /></Button>
                                </Box>

                                <Typography style={{ fontSize: 18, marginTop: 15 }}>{colecao.quantidade}</Typography>
                            </Box>
                        </Grid>
                    })
                }

            </Grid>
        </Paper>
    </>
}

export default Colecoes;