import HomeIcon from '@mui/icons-material/Home';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

// ==============================|| MENU ITEMS ||============================== //

const dashboard = {
    id: 'initial',
    type: 'group',
    children: [
        {
            id: 'pedidos-vesti',
            title: 'Início',
            type: 'item',
            url: '/pedidos-vesti',
            icon: HomeIcon,
            breadcrumbs: false
        }
    ]
};

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'pedidos',
            title: 'Pedidos',
            type: 'item',
            url: '/pedidos-vesti',
            icon: PointOfSaleIcon,
            breadcrumbs: false
        }
    ]
};


const menuLeapVesti = {
    items: [dashboard, pages]
};

export default menuLeapVesti;
