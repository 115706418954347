import { Box, Paper, css, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Height } from "@mui/icons-material";

export const Container = styled(Paper)`
  padding: 20px;
`;

export const NotificacaoModalContainer = styled(Paper)`
    ${({ theme }) => css`
        background: #f0f3f6;
    `}

    border-radius: 1rem;
    cursor: pointer;
    height: 100vh;

    background-image: url(${({ imgSrc }) => `${imgSrc}`});
    background-size: cover;
`
export const ModalContainer = styled(Box)`
  ${({ theme }) => css`
    background: rgb(240, 243, 246);
    color: #000000;

    .MuiFormControlLabel-label {
      color: #000000
    }

    path {
      color: #000000
    }

    right: 0;
    width: 430px;

    @media (max-width:600px) {
      left: 0;
      width: 100%;
    }
  `}

  padding: 0.8rem;
  position: absolute;  
  height: 100vh;
  min-height: 100%;
  overflow-y: auto;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.51);

  padding-top: 20px;
  padding-bottom: 30px;

  p, h1, h2, h3, h4, input {
    ${({ theme }) => css`
      color: #000000;
    `}
  }

  //animation
  animation: fade-in 300ms ease-out forwards;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const CloseBtn = styled(CloseIcon)`

  ${({ theme }) => css`
    path {
      color: black
    }
  `}

  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 5;
  right: 5;
`;
