import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POST_DATA, PUT_DATA, SalvarLogSentry } from '../../../services/API';
import { Box, Button, Card, CardHeader, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, CircularProgress, Stack, TextField, InputAdornment, Modal } from '@mui/material';
import { Divider } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import { useLocation, useNavigate } from 'react-router';

const Genero = () => {
    const dispatch = useDispatch();
    const locaion = useLocation();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [nomeGenero, setNomeGenero] = useState('');
    const [descricaoMarca, setDescricaoMarca] = useState('');

    const [search, setSearch] = useState('');
    const [generosSelecionadas, setGenerosSelecionadas] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [allGeneros, setAllGeneros] = useState([]);
    const [generosLoja, setGenerosLoja] = useState([]);

    const leftChecked = intersection(generosSelecionadas, generos);
    const rightChecked = intersection(generosSelecionadas, generosLoja);

    useEffect(() => {
        (async () => {
            await fetchGeneros();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchGeneros = async () => {
        setIsLoadingData(true);

        const resultSexo = await POST_DATA(`Sexo/GetAllSexo?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=SEX_Id&Log=`);

        // const arrGeneros = [
        //     {
        //         SEX_Id: 1,
        //         SEX_Nome: 'Masculino',
        //         SEX_Sigla: 'M',
        //         SEX_Ativo: true
        //     },
        //     {
        //         SEX_Id: 2,
        //         SEX_Nome: 'Feminino',
        //         SEX_Sigla: 'F',
        //         SEX_Ativo: true
        //     }
        // ]

        const resultUsuarioEmpresaSexo = await POST_DATA(`UsuarioEmpresaXSexo/GetAllUsuarioEmpresaXSexoByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
        const arrGenerosLojaFiltrados = [];
        let generosFiltrados = resultSexo;
        let sexo = null;

        resultUsuarioEmpresaSexo.forEach(item => {
            sexo = generosFiltrados.filter(a => a.SEX_Id === item.IdSexo)[0];
            generosFiltrados = generosFiltrados.filter(a => a.SEX_Id !== item.IdSexo);
            arrGenerosLojaFiltrados.push(sexo);
        });

        

        setAllGeneros(generosFiltrados);
        setGeneros(generosFiltrados);
        setGenerosLoja(arrGenerosLojaFiltrados);
        setIsLoadingData(false);
    }

    const handleFiltrarGeneros = async (filtro = null) => {
        try {
            setIsLoadingData(true);
            filtro = filtro !== null ? filtro : search;

            let arrAllGeneros = allGeneros;
            let generosFiltradas = allGeneros;

            if(filtro.length > 0){
                generosFiltradas = generos.filter(genero => genero.SEX_Nome.toUpperCase().includes(filtro.toUpperCase()));
                arrAllGeneros = generos.filter(genero => genero.SEX_Nome.toUpperCase().includes(filtro.toUpperCase()));
            }
            else {
                generosFiltradas = generos;
                arrAllGeneros = generos;
            }

            setGeneros(generosFiltradas);
            setAllGeneros(arrAllGeneros);
            setIsLoadingData(false);
        } catch (err) {
            SalvarLogSentry(err);
        }
    }

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const handleToggle = (genero) => () => {
        const currentIndex = generosSelecionadas.indexOf(genero);
        const newChecked = [...generosSelecionadas];

        if (currentIndex === -1) {
            newChecked.push(genero);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setGenerosSelecionadas(newChecked);
    };

    const numberOfChecked = (items) => intersection(generosSelecionadas, items).length;

    const handleCheckedRight = () => {
        setSearch('');
        setGenerosLoja(generosLoja.concat(leftChecked));
        setGeneros(not(allGeneros, leftChecked));
        setGenerosSelecionadas(not(generosSelecionadas, leftChecked));
    };

    const handleCheckedLeft = () => {
        setSearch('');
        setGeneros(generos.concat(rightChecked));
        setGenerosLoja(not(generosLoja, rightChecked));
        setGenerosSelecionadas(not(generosSelecionadas, rightChecked));
    };

    const handleAllLeft = () => {
        setGeneros(allGeneros.concat(generosLoja));
        setGenerosLoja([]);
    };

    const handleSalvar = async () => {
        try{
            const arrGenerosLoja = generosLoja.map(item => {
                return {
                    IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                    IdSexo: item.SEX_Id,
                    FlagAtivo: true
                }
            });

            const result = await PUT_DATA(`UsuarioEmpresaXSexo/InsertListUsuarioEmpresaXSexo`, arrGenerosLoja);
    
            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Gêneros salvos com sucesso',
                });
            }
        }
        catch(err){
            SalvarLogSentry(err);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: 'Erro ao salvar os dados',
            });
        }
    }

    const customList = (title, items, opcoesList = true) => (
        <Card>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardHeader
                    sx={{ py: 1 }}
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
                />
            </Box>
            {
                opcoesList &&
                <Box style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'start', padding: 10, paddingLeft: 15 }}>
                    <TextField
                        label="Pesquisar"
                        placeholder='Nome'
                        size="small"
                        value={search}
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                handleFiltrarGeneros();
                            }
                        }}
                        InputProps={{
                            endAdornment: search.length > 0 && <InputAdornment
                                style={{ cursor: 'pointer' }}
                                position="end"
                                onClick={() => {
                                    setSearch(() => '');
                                    setTimeout(() => {
                                        handleFiltrarGeneros('');
                                    }, 450)
                                }}
                            >
                                <ClearIcon />
                            </InputAdornment>
                        }}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ height: 43 }}
                        onClick={() => handleFiltrarGeneros()}
                    >
                        Pesquisar
                    </Button>
                </Box>
            }
            <Divider />
            <List
                sx={{
                    width: '100%',
                    minHeight: 350,
                    maxHeight: 350,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((item) => {
                    const labelId = `transfer-list-all-item-${item.SEX_Id}-label`;
                    return (
                        <ListItem
                            key={item.SEX_Id}
                            role="listitem"
                            button
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={generosSelecionadas.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.SEX_Nome} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    if (isLoadingData)
        return <Stack
            sx={{ height: "550px" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>Carregando marcas</Typography>
        </Stack>

    const componente = allGeneros && allGeneros.length > 0 &&
        <div>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Gêneros</Typography>
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography></Typography>
                    <Button variant='contained' onClick={() => handleSalvar()}>Salvar</Button>
                </Box>
                <Grid container justifyContent="center" alignItems="top" style={{ marginTop: 10 }}>
                    <Grid item xs={5}>{customList('Opções de gêneros', generos)}</Grid>
                    <Grid item xs={2}>
                        <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                /** 
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                */
                            }
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="Mover para a direita"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="Mover para a equerda"
                            >
                                &lt;
                            </Button>
                            {
                                /**
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                                */
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={5}>{customList('Gêneros selecionadas', generosLoja, false)}</Grid>
                </Grid>
            </Box>
        </div>

    try {
        return componente;
    } catch (err) {
        SalvarLogSentry(err);

        dispatch({
            type: CONTROLEMENSAGEM_ERRO,
            tipoComponente: 'alert',
            titulo: 'Erro',
            message: err?.message,
        });
    }
}

export default Genero;