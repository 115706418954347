import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { Container } from "./styles";
import { Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from '../../services/utils/functions';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from '../../store/ControleMensagemReducer/types';
import { POST_DATA, PUT_DATA, SalvarLogSentry } from '../../services/API';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ManageSearch } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlined from '@mui/icons-material/EditOutlined';

const columns = [
    { id: "imagem", label: "FotoProfile", minWidth: 100 },
    {
        id: "nome",
        label: "Nome",
        maxWidth: 120,
        align: "left",
    },
    {
        id: "cpf",
        label: "CPF",
        maxWidth: 120,
        align: "left",
    },
    { id: "email", label: "E-mail", minWidth: 90, maxWidth: 100 },
    {
        id: "celular",
        label: "Celular",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "dtInclusao",
        label: "Data Cadastro",
        minWidth: 140,
        align: "left",
        format: (value) => value.toFixed(2),
    },
    {
        id: "flgAtivo",
        label: "Ativo",
        minWidth: 70,
        align: "left",
    },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 100,
        align: "left",
    },
];

const Afiliados = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    const [afiliados, setAfiliados] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const [pesquisar, setPesquisar] = useState(0);

    useEffect(() => {
        if (userLoggedIn != null) {
            (async () => {
                await fetchAfiliados();
            })();
        }
    }, [userLoggedIn, pesquisar]);

    const fetchAfiliados = async () => {
        try {
            if(search.length > 0){

                let users = [];
                const cpf = search.replace(/([^\d])+/gim, '').trim();

                if(cpf.length >= 11 && cpf.length <= 14){
                    users = afiliados.filter(a => a.Cpf.toUpperCase().includes(search.trim().toUpperCase()));
                }
                else {
                    users = afiliados.filter(a => a.Nome.toUpperCase().includes(search.trim().toUpperCase()));
                }

                setAfiliados(users);
                setRows(users);
            }
            else {
                const resultAfiliados = await POST_DATA(`UsuarioLoginInfluencer/GetUsuarioLoginInfluencerByIdUsuarioLoja?IdUsuarioLoja=${userLoggedIn.IdUsuario}&join=true&Log=`);
                setAfiliados(resultAfiliados);
                setRows(resultAfiliados);
            }
        }
        catch (err) {
            SalvarLogSentry(err);
        }
    }

    const handleAtivaInativa = async (idUsuario, flgAtivo) => {
        let usuario = await POST_DATA(`UsuarioLoginInfluencer/GetUsuarioLoginInfluencerById?IdUsuario=${idUsuario}&join=true&Log=`);
        usuario = { ...usuario, Senha: '', FlgAtivo: flgAtivo }

        await PUT_DATA(`UsuarioLoginInfluencer/CadastroUsuarioLoginInfluencer`, usuario);

        if (!flgAtivo) {
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao inativar o usuário',
            });
        }
        else {
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao ativar o usuário',
            });
        }

        await fetchAfiliados();
    }

    const fetchExcel = async () => {
        if (afiliados === null || afiliados.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Nome; CPF; E-mail; Celular; Data de Cadastro; Ativo`;
        afiliados?.forEach(cliente => {
            dados += `\n${cliente.Nome}; ${cliente.Cpf}; ${cliente.Email}; ${cliente.Celular}; ${formatDateSimples(cliente.DtInclusao)}; ${(cliente.FlgAtivo ? 'Sim' : 'Não')} `;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Afiliados.csv', dados);
        window.open(url, '_blank');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return <Container>
        <Box style={{ width: "100%" }}>
            <Typography style={{ fontWeight: 'bold' }}>Afiliados</Typography>
        </Box>
        <Stack
            direction="row"
            sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
            justifyContent="space-between"
        >
            <TextField
                label="Pesquisar por nome/cpf"
                placeholder='Pesquisar por nome/cpf'
                size="small"
                sx={{ width: 250 }}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                onKeyDownCapture={async(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                        await fetchAfiliados();
                    }
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <ManageSearch style={{ cursor: 'pointer' }} onClick={async () => await fetchAfiliados()} />
                    </InputAdornment>,
                    endAdornment: search.length > 0 && (
                        <InputAdornment
                            style={{ cursor: 'pointer', color: 'black' }}
                            position="end"
                            onClick={async () => {
                                setSearch(() => '');
                                setPesquisar(Math.random());
                            }}
                        >
                            <ClearIcon />
                        </InputAdornment>
                    )
                }}
            />
            <Button variant='contained' onClick={() => navigate('/cadastro-afiliado')}>Novo</Button>
        </Stack>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading ? (
                <Stack
                    sx={{ height: "150px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                </Stack>
            ) : (
                <>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.imagem}
                                        >
                                            <TableCell key={`img-${row.IdUsuarioLoginInfluencer}`} align='left'>
                                                {
                                                    row.FotoProfile &&
                                                    <img style={{ borderRadius: "8px", width: "80px", height: "100px" }} src={row.FotoProfile} alt='Usuário' />
                                                }
                                                {
                                                    !row.FotoProfile &&
                                                    <img style={{ borderRadius: "8px", width: "80px", width: "70px" }} src={'https://timelineleap-evhgawa4gxdcecak.z01.azurefd.net/img/avatar.png'} alt='Usuário' />
                                                }
                                            </TableCell>
                                            <TableCell key={`nome-${row.IdUsuarioLoginInfluencer}`} align='left'>
                                                {row.Nome}
                                            </TableCell>
                                            <TableCell key={`cpf-${row.IdUsuarioLoginInfluencer}`} align='left'>
                                                {row.Cpf}
                                            </TableCell>
                                            <TableCell key={`email-${row.IdUsuarioLoginInfluencer}`} align='left'>
                                                {row.Email}
                                            </TableCell>
                                            <TableCell key={`celular-${row.IdUsuarioLoginInfluencer}`} align='left' style={{ minWidth: 140 }}>
                                                {row.Celular}
                                            </TableCell>
                                            <TableCell key={`data-inclusao-${row.IdUsuarioLoginInfluencer}`} align='left'>
                                                <Typography sx={{ fontSize: 15 }}> {formatDateSimples(row.DtInclusao)} </Typography>
                                            </TableCell>
                                            <TableCell key={`flgAtivo-${row.IdUsuarioLoginInfluencer}`} align='left' style={{ minWidth: 140 }}>
                                                <Switch
                                                    checked={row.FlgAtivo}
                                                    onChange={() => handleAtivaInativa(row.IdUsuarioLoginInfluencer, !row.FlgAtivo)} />
                                            </TableCell>
                                            <TableCell key={`cliente-${row.IdUsuarioLoginInfluencer}`} align='left'>
                                                <Stack direction="row">
                                                    <Tooltip title="Lista de compras">
                                                        <IconButton
                                                            onClick={() => {
                                                                navigate('/cadastro-afiliado', { state: { IdUsuarioLoginInfluencer: row.IdUsuarioLoginInfluencer } })
                                                            }}
                                                        >
                                                            <EditOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={
                            search
                                ? `${rows.length}/${afiliados.length} resultados encontrados para pesquisa "${search}" - afiliados por página: `
                                : "Afiliados por página"
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
        <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                size="small"
                variant="contained"
                style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                onClick={() => fetchExcel()}
            >
                <FileDownloadIcon />
                Excel
            </Button>
        </Box>
    </Container>
}

export default Afiliados;