import { Box, Button } from "@mui/material";
import { TEMA_LOJA_SET } from "../../../store/TemaLojaReducer/types";
import { useDispatch } from "react-redux";

const CardFinalizar = ({ themeStorage, proximo = null, voltar = null, onClickProximo = null, onClickFinalizar = null }) => {
    const dispatch = useDispatch();

    return <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: voltar !== null ? 'space-between' : 'flex-end' }}>
        {
            voltar !== null &&
            <Button variant="contained"
                style={{ background: 'black' }}
                onClick={() => {
                    window.scroll(0, 0);
                    dispatch({
                        type: TEMA_LOJA_SET,
                        data: { ...themeStorage, etapaExecutada: voltar.etapaExecutada, etapaAtual: voltar.etapaAtual }
                    });
                }}>
                Voltar
            </Button>
        }
        {
            proximo === null && onClickFinalizar !== null &&
            <Button variant="contained" onClick={() => {
                const result = onClickFinalizar(false);
                if(result){
                    window.scroll(0, 0);
                    dispatch({
                        type: TEMA_LOJA_SET,
                        data: { ...themeStorage, etapaExecutada: 5, etapaAtual: 4 }
                    });
                }                
            }}>
                Finalizar e publicar
            </Button>
        }
        {
            proximo !== null && onClickFinalizar === null &&
            <Button variant="contained" onClick={() => {
                if(onClickProximo !== null){
                    const result = onClickProximo();
                    if (result) {
                        window.scroll(0, 0);
                        dispatch({
                            type: TEMA_LOJA_SET,
                            data: { ...themeStorage, etapaExecutada: proximo.etapaExecutada, etapaAtual: proximo.etapaAtual }
                        });
                    }                    
                }
                else {
                    window.scroll(0, 0);
                    dispatch({
                        type: TEMA_LOJA_SET,
                        data: { ...themeStorage, etapaExecutada: proximo.etapaExecutada, etapaAtual: proximo.etapaAtual }
                    });
                }
                
            }}>
                Próximo
            </Button>
        }

    </Box>
}

export default CardFinalizar;