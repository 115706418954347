import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SettingsTwoTone } from "@mui/icons-material";
import { POST_DATA, POST_DATA_SERVICOS_ROBO, PUT_DATA, SalvarLogSentry } from '../../../services/API';
import { useDispatch, useSelector } from 'react-redux';
import sendblue from '../../../assets/sendblue.png';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useNavigate } from "react-router";
import { atualizarOnboardStore, isEmail } from "../../../services/utils/functions";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

import LoadingGif from '../../../assets/loading.gif';
import Loading from "../../../layout/Loader";

export default function OpcoesEnvioEmailsAccordion() {
  const userLoggedIn = useSelector((state) => state.usuarioLogado);
  const onBoard = useSelector((store) => store.onBoard);
  const [getConfirmation, Confirmation] = useDialogConfirmationAsync();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dominio, setDominio] = useState(null);
  const [dadosConfigBrevo, setDadosConfigBrevo] = useState(null);
  const [habilitadoProsseguir, setHabilitadoProsseguir] = useState(null);

  const [possuiDominioProprio, setPossuiDominioProprio] = useState('nao');

  const [verificado, setVerificado] = useState(false);
  const [autorizado, setAutorizado] = useState(false);

  const [dadosSenderBrevo, setDadosSenderBrevo] = useState({
    IdUsuarioEmpresa: 0,
    IdSenderBrevo: 0,
    NomeRemetente: '',
    EmailRemetente: ''
  });

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getDominios = async () => {
    const result = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
    if (result !== null && result.length > 0) {
      setDominio(result[0]);
      await getDominiosBrevo(result[0].NomeSiteDominioGeral);
      //await getDominiosBrevo("diadiashopee.com.br");
    }
    else
      setHabilitadoProsseguir(false);
  }

  const getDominiosBrevo = async (dominio) => {
    try {
      const result = await POST_DATA_SERVICOS_ROBO(`BrevoMails?route=domain&method=get`, {
        "domain": dominio
      });

      if (result.success) {
        const obj = result.objetoRetorno.data;
        setDadosConfigBrevo(obj);
        setVerificado(obj.verified);
        setAutorizado(obj.authenticated);
        setHabilitadoProsseguir(obj.verified && obj.authenticated);
      }
    }
    catch (err) {
      setHabilitadoProsseguir(false);
    }
  }

  useEffect(() => {
    if (userLoggedIn === null) return;

    (async () => {
      setLoading(true);

      await getDominios();
      await fetchUsuarioEmpresa();

      setLoading(false);
    })();

  }, [userLoggedIn]);

  const fetchUsuarioEmpresa = async () => {
    const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);

    const obj = {
      IdUsuarioEmpresa: userEmpresa.IdUsuarioEmpresa,
      IdSenderBrevo: userEmpresa?.IdSenderBrevo || 0,
      NomeRemetente: userEmpresa?.NomeRemetente || '',
      EmailRemetente: userEmpresa?.EmailRemetente || '',
      FlgBrevo: userEmpresa?.FlgBrevo || false
    };

    setDadosSenderBrevo(obj);
  }

  const handleSave = async () => {
    try {
      if (dadosSenderBrevo.NomeRemetente.length < 3) {
        dispatch({
          type: CONTROLEMENSAGEM_AVISO,
          tipoComponente: 'alert',
          titulo: 'Aviso',
          message: 'Favor informar um nome de remetente válido',
        });
        return;
      }

      if (!isEmail(dadosSenderBrevo.EmailRemetente)) {
        dispatch({
          type: CONTROLEMENSAGEM_AVISO,
          tipoComponente: 'alert',
          titulo: 'Aviso',
          message: 'Favor informar um e-mail de remetente válido',
        });
        return;
      }

      setLoading(true);

      if (dadosSenderBrevo.FlgBrevo) {
        if (!habilitadoProsseguir) {
          dispatch({
            type: CONTROLEMENSAGEM_AVISO,
            tipoComponente: 'alert',
            titulo: 'Aviso',
            message: 'Para salvar as informações é necessário ter configurado o domínio da loja.',
          });

          return;
        }

        let result = null;
        if (dadosSenderBrevo?.IdSenderBrevo == 0) {
          result = await POST_DATA_SERVICOS_ROBO(`BrevoMails?route=sender&method=create`, {
            "name": dadosSenderBrevo.NomeRemetente,
            "email": dadosSenderBrevo.EmailRemetente
          });
        }
        else {
          result = await POST_DATA_SERVICOS_ROBO(`BrevoMails?route=sender&method=update`, {
            "id": dadosSenderBrevo.IdSenderBrevo,
            "name": dadosSenderBrevo.NomeRemetente,
            "email": dadosSenderBrevo.EmailRemetente
          });
        }

        if (!result.success) {
          dispatch({
            type: CONTROLEMENSAGEM_ERRO,
            tipoComponente: 'alert',
            titulo: 'Aviso',
            message: 'Ocorreu um erro ao enviar os dados para a Brevo',
          });
          return;
        }

        const id = dadosSenderBrevo.IdSenderBrevo > 0 ? dadosSenderBrevo.IdSenderBrevo : result?.objetoRetorno.data?.id;
        await PUT_DATA(`UsuarioEmpresa/UpdateIdSenderBrevo?IdSenderBrevo=${id}&NomeRemetente=${dadosSenderBrevo.NomeRemetente}&EmailRemetente=${dadosSenderBrevo.EmailRemetente}&IdUsuarioEmpresa=${dadosSenderBrevo.IdUsuarioEmpresa}`);
        dispatch({
          type: CONTROLEMENSAGEM_SUCESSO,
          tipoComponente: 'alert',
          titulo: 'Sucesso',
          message: 'Informações salvas!',
        });

        await fetchUsuarioEmpresa();

        setLoading(false);

        if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 6)[0].FlagStatus) { //Configure a comunicação da loja
          const objOnboard = { ...onBoard };
          objOnboard.IdUltimoOnboardingStoreItem = 6;

          const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 6)[0];
          itemOnboard.FlagStatus = true;

          atualizarOnboardStore(objOnboard, itemOnboard);

          dispatch({
            type: SET_DADOS_ON_BOARD,
            data: objOnboard
          });

          navigate('/on-board');
        }
      }
      else {
        await PUT_DATA(`UsuarioEmpresa/UpdateIdSenderBrevo?IdSenderBrevo=0&NomeRemetente=${dadosSenderBrevo.NomeRemetente}&EmailRemetente=${dadosSenderBrevo.EmailRemetente}&IdUsuarioEmpresa=${dadosSenderBrevo.IdUsuarioEmpresa}`);
        dispatch({
          type: CONTROLEMENSAGEM_SUCESSO,
          tipoComponente: 'alert',
          titulo: 'Sucesso',
          message: 'Informações salvas!',
        });

        await fetchUsuarioEmpresa();

        setLoading(false);

        if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 6)[0].FlagStatus) { //Configure a comunicação da loja
          const objOnboard = { ...onBoard };
          objOnboard.IdUltimoOnboardingStoreItem = 6;

          const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 6)[0];
          itemOnboard.FlagStatus = true;

          atualizarOnboardStore(objOnboard, itemOnboard);

          dispatch({
            type: SET_DADOS_ON_BOARD,
            data: objOnboard
          });

          navigate('/on-board');
        }
      }
    }
    catch (err) {
      SalvarLogSentry(err);
      dispatch({
        type: CONTROLEMENSAGEM_ERRO,
        tipoComponente: 'alert',
        titulo: 'Erro',
        message: 'Erro ao tentar salvar integração Brevo',
      });

      setLoading(false);
    }
  };

  const handleCadastrarBrevo = async () => {
    try {
      setLoading(true);

      const result = await POST_DATA_SERVICOS_ROBO(`BrevoMails?route=domain&method=create`, {
        "domain": dominio.NomeSiteDominioGeral
      });

      if (result.success) {
        await getDominios();

        dispatch({
          type: CONTROLEMENSAGEM_SUCESSO,
          tipoComponente: 'alert',
          titulo: 'Sucesso',
          message: 'Sucesso ao conectar com a brevo',
        });
      }
      else {
        dispatch({
          type: CONTROLEMENSAGEM_ERRO,
          tipoComponente: 'alert',
          titulo: 'Erro',
          message: result.message,
        });
      }

      setLoading(false);
    }
    catch (err) {
      setLoading(false);
    }
  }

  return (
    <Stack sx={{ mt: 2 }}>
      <Confirmation
        title="Conectar Brevo?"
        btn1={{ title: "Cancelar", onClick: null }}
        btn2={{
          title: "Conectar",
          onClick: async () => {
            await handleCadastrarBrevo();
          }
        }}
      >
        <Box style={{ width: '100', minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img src={LoadingGif} alt='Loading' width={90} />

          <Typography style={{ fontSize: 18, color: 'gray', marginTop: 20 }}>Será necessário executar os seguintes passos: </Typography>
          <Box style={{ width: '80%', display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
            <ol style={{ fontSize: 13 }}>
              <li>Verificar e-mail master do seu domínio</li>
              <li style={{ marginTop: 10 }}>Autenticar conexão com a Brevo</li>
              <li style={{ marginTop: 10 }}>Após executar os passos acima, informar o Nome do Remetente e E-mail para envio nesta tela</li>
            </ol>
          </Box>
          <Typography style={{ fontSize: 12, color: 'gray', marginTop: 30 }}>Ao prosseguir será realizado o cadastro junto a Brevo! Deseja continuar?</Typography>
        </Box>
      </Confirmation>

      <Accordion
        expanded={true}
        onChange={handleChange(`panel`)}
      >
        <AccordionSummary
          expandIcon={<SettingsTwoTone />}
          aria-controls={`panel-content`}
          id={`panel-header`}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            width={"100%"}
            sx={{ padding: "0 1rem" }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <img
                alt='Imagem'
                src={sendblue}
                style={{ width: "70px", height: "70px" }}
              />
              <Typography sx={{ color: "text.secondary", marginLeft: 10, fontWeight: 'bold' }}>
                SendInBlue / Brevo
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {loading && <Loading texto="Buscando dados..." marginTop="0%" />}

          {
            !loading && habilitadoProsseguir &&
            <Stack direction="column" gap={1}>
              <Alert severity="success">
                <AlertTitle>Conexão Brevo</AlertTitle>
                <strong color="green">SendInBlue / Brevo conectado com sucesso</strong>.
              </Alert>
              <Stack direction="row" gap={1} width={"100%"} style={{ marginTop: 10 }}>
                <TextField
                  disabled
                  label="Id Sender Brevo"
                  variant="outlined"
                  type="number"
                  value={dadosSenderBrevo?.IdSenderBrevo}
                  sx={{ flex: 1 }}
                />

                <Tooltip
                  enterDelay={1000}
                  title="Nome do remetente que irá nos e-mails"
                >
                  <TextField
                    label="Nome do Remetente"
                    disabled={loading || !habilitadoProsseguir}
                    variant="outlined"
                    type="text"
                    value={dadosSenderBrevo?.NomeRemetente}
                    sx={{ flex: 1 }}
                    onChange={(e) => {
                      setDadosSenderBrevo({ ...dadosSenderBrevo, NomeRemetente: e.target.value });
                    }}
                  ></TextField>
                </Tooltip>

                <Tooltip
                  enterDelay={1000}
                  title="O e-mail que será utilizado para enviar os e-mails informativos (templates) aos clientes da sua loja"
                >
                  <TextField
                    label="E-mail de disparo"
                    variant="outlined"
                    placeholder={`@${dominio.NomeSiteDominioGeral}`}
                    disabled={loading || !habilitadoProsseguir}
                    value={dadosSenderBrevo?.EmailRemetente}
                    type="email"
                    sx={{ flex: 1 }}
                    onChange={(e) => {
                      setDadosSenderBrevo({ ...dadosSenderBrevo, EmailRemetente: e.target.value });
                    }}
                  ></TextField>
                </Tooltip>

                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => handleSave()}
                >
                  {loading ? (
                    <CircularProgress size="small" />
                  ) : (
                    "Salvar"
                  )}
                </Button>
              </Stack>
            </Stack>
          }
          {
            !loading && dadosConfigBrevo !== null && !autorizado &&
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: 30 }}>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', color: '#a84032' }}>*Foi enviado um e-mail de verificação para o e-mail que foi registrado seu domínio</Typography>
            </Box>
          }
          {
            !loading && dominio === null &&
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBotton: 30 }}>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', color: '#a84032' }}>*Favor configurar o domínio da loja para prosseguir com a configuração.</Typography>
            </Box>
          }
          {
            !loading && !habilitadoProsseguir && dominio !== null &&
            <Box style={{ width: '100%' }}>

              <Box>
                {
                  ((dadosSenderBrevo?.EmailRemetente === null || dadosSenderBrevo?.EmailRemetente.length === 0) && dadosSenderBrevo.IdSenderBrevo === 0) &&
                  <Box style={{ marginBottom: 15 }}>
                    <Typography style={{ fontWeight: 'bold' }}>Você possui um domínio de e-mail próprio? </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={possuiDominioProprio}
                      name="radio-buttons-group"
                      direction="row"
                      style={{ display: 'flex', flexDirection: 'row' }}
                      onChange={(e) => {
                        setPossuiDominioProprio(e.target.value === 'sim');
                        setDadosSenderBrevo({ ...dadosSenderBrevo, FlgBrevo: e.target.value === 'sim' });
                      }}
                    >
                      <FormControlLabel value={'sim'} control={<Radio />} label="Sim" />
                      <FormControlLabel value={'nao'} control={<Radio />} label="Não" />
                    </RadioGroup>
                  </Box>
                }

              </Box>

              <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: dadosSenderBrevo.FlgBrevo ? 'flex-end' : 'center' }}>
                {
                  (dadosSenderBrevo?.EmailRemetente?.length > 0 || dadosSenderBrevo.IdSenderBrevo !== 0) &&
                  <FormControlLabel
                    style={{ width: 150 }}
                    labelPlacement="top"
                    control={
                      <Switch
                        checked={dadosSenderBrevo.FlgBrevo}
                        onChange={() => {
                          setDadosSenderBrevo({ ...dadosSenderBrevo, FlgBrevo: !dadosSenderBrevo.FlgBrevo });
                        }} />}
                    label="Integrar Brevo?"
                  />
                }

                {
                  dadosSenderBrevo.FlgBrevo &&
                  <Button variant="contained" style={{ background: 'black' }} onClick={() => {
                    if (dadosConfigBrevo === null) {
                      getConfirmation()
                    }
                    else if (!autorizado) {
                      dispatch({
                        type: CONTROLEMENSAGEM_AVISO,
                        tipoComponente: 'alert',
                        titulo: 'Aviso',
                        message: 'Foi enviado um e-mail de verificação para o e-mail que foi registrado seu domínio',
                      });
                    }
                  }}>Conectar Brevo</Button>
                }

                {
                  !dadosSenderBrevo.FlgBrevo &&
                  <Stack direction="row" gap={1} width={"100%"} style={{ marginTop: 10 }}>
                    <Tooltip
                      enterDelay={1000}
                      title="Nome do remetente que irá nos e-mails"
                    >
                      <TextField
                        label="Nome do Remetente"
                        variant="outlined"
                        type="text"
                        value={dadosSenderBrevo?.NomeRemetente}
                        sx={{ flex: 1 }}
                        onChange={(e) => {
                          setDadosSenderBrevo({ ...dadosSenderBrevo, NomeRemetente: e.target.value });
                        }}
                      ></TextField>
                    </Tooltip>

                    <Tooltip
                      enterDelay={1000}
                      title="O e-mail que será utilizado para enviar os e-mails informativos (templates) aos clientes da sua loja"
                    >
                      <TextField
                        label="E-mail de disparo"
                        variant="outlined"
                        placeholder={`@${dominio.NomeSiteDominioGeral}`}
                        value={dadosSenderBrevo?.EmailRemetente}
                        type="email"
                        sx={{ flex: 1 }}
                        onChange={(e) => {
                          setDadosSenderBrevo({ ...dadosSenderBrevo, EmailRemetente: e.target.value });
                        }}
                      ></TextField>
                    </Tooltip>

                    <Button
                      variant="contained"
                      disabled={loading}
                      onClick={() => handleSave()}
                    >
                      {loading ? (
                        <CircularProgress size="small" />
                      ) : (
                        "Salvar"
                      )}
                    </Button>
                  </Stack>
                }
              </Box>
            </Box>

          }
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
