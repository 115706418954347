import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './mainRouter';
import AuthenticationRoutes from './authenticationRoutes';
import ValidationCodeRoute from './validationCodeRoute';
import LeapLoversRoute from './leapLoversRoutes';
import leapVestiRoutes from './leapVestiRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  let rotas = [MainRoutes, AuthenticationRoutes, ValidationCodeRoute, LeapLoversRoute, leapVestiRoutes];
  return useRoutes(rotas);
}
