import { Box, Button, Card, Grid, TextField } from "@mui/material";
import { AlertError, CardRecovery, Container } from "./styles";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';

import logoLeap from '../../assets/logoBranco.jpg';

import packageJson from '../../../package.json';
import { POST_DATA } from "../../services/API";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../store/ControleMensagemReducer/types";
import { useDispatch } from "react-redux";

const Recovery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [token, setToken] = useState();

    const validationSchema = Yup.object().shape({
        senha: Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres')
            .max(20, 'A senha deve ter no máximo 20 caracteres')
            .required('A senha é obrigatória'),
        senhaConfirmacao: Yup.string().test(
            'validateSenha',
            '*As senhas são diferentes',
            (senha, context) => {
                if (!senha && !context.parent?.senha) return true;
                if (senha !== context.parent?.senha) return false;

                return true;
            }
        )
    });

    const onSubmit = async (values) => {
        try {
            const result = await POST_DATA("Usuario/UpdateRecoveryPassword?sToken=" + token + "&sSenha=" + values.senha);
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Senha alterada',
            });

            setTimeout(() => {
                navigate('/login', { replace: true });
            }, 2000);
        }
        catch (err) {
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: "Não foi possivel realizar a alteração de sua senha. Entre em contato com o administrador do sistema!\nMotivo: " + err.message,
            });
        }
    }

    const formik = useFormik({
        onSubmit,
        initialValues: {
            senha: '',
            senhaConfirmacao: ''
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    useEffect(() => {
        const arrToken = location.search.split('=');
        
        if(arrToken.length == 0 || !arrToken[1]){
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Avio',
                message: "Token inválido",
            });
            return;
        }

        setToken(arrToken[1]);
        formik.resetForm();
    }, [location]);

    return <Container>
        <form onSubmit={formik.handleSubmit}>
            <CardRecovery>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={logoLeap} width={200} style={{ filter: 'invert(1)' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type='password'
                            inputProps={{ maxLength: 20 }}
                            label={'Senha'}
                            value={formik.values?.senha}
                            onChange={(e) => {
                                formik.setFieldValue(`senha`, e.target.value);
                            }}
                        />
                        <AlertError className="msg-error">{formik.errors.senha}</AlertError>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            type='password'
                            label={'Senha Confirmação'}
                            value={formik.values?.senhaConfirmacao}
                            onChange={(e) => {
                                formik.setFieldValue(`senhaConfirmacao`, e.target.value);
                            }}
                        />
                        <AlertError className="msg-error">{formik.errors.senhaConfirmacao}</AlertError>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type='submit' variant="contained" style={{ background: 'black', width: '100%' }}>Salvar</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: '100%', marginTop: 5, marginBottom: 20, display: 'flex', justifyContent: 'center', color: 'gray' }}>
                            Versão: {packageJson.version} | © 2024 | <span style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => window.open('https://leapstyle.me/', '_blank')}>@LeapStyle.me</span>
                        </Box>
                    </Grid>

                </Grid>
            </CardRecovery>
        </form>
    </Container>
}

export default Recovery;