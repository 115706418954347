import React, { useState } from "react"

import './styles.css'

function Modules() {
    const data = [
        {
            title: "1. Laboris exercitation ipsum amet sit sunt ullamco dolor. Commodo ea esse reprehenderit quis excepteur esse.",
            questions: [
                'Laboris aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Laboris aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Laboris aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Laboris aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Laboris aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.'
            ]
        },
        {
            title: "2. Dolore ut ad nulla ea Lorem. Irure aliquip ut magna nostrud laboris proident et.",
            questions: [
                'Dolore aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Dolore aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Dolore aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Dolore aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Dolore aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.'
            ]
        },
        {
            title: "3. Nulla do non officia deserunt non amet fugiat nisi minim laborum aute ut. Id labore aliqua dolor sunt sint cupidatat sint exercitation enim elit fugiat officia aliquip.",
            questions: [
                'Nulla aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Nulla aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Nulla aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Nulla aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Nulla aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.'
            ]
        },
        {
            title: "4. Commodo non esse ipsum esse ea irure ut culpa et veniam. Officia laboris ex adipisicing dolore eiusmod consequat fugiat.",
            questions: [
                'Commodo aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Commodo aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Commodo aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Commodo aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.',
                'Commodo aute tempor sit duis nisi occaecat est incididunt culpa adipisicing occaecat.'
            ]
        }
    ]

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const handleNextQuestion = () => {
        if (currentQuestionIndex < data.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setCurrentQuestionIndex(currentQuestionIndex)
        }
    };

    const handleBackQuestion = () => {
        if (currentQuestionIndex > 0 && currentQuestionIndex <= data.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        } else {
            setCurrentQuestionIndex(currentQuestionIndex)
        }
    };

    return (
        <div className="aula-container-question">
            <h2>{data[currentQuestionIndex].title}</h2>

            <ul>
                {data[currentQuestionIndex].questions.map((question, index) => (
                    <li key={index}>
                        <label>
                            <input
                                type="radio"
                                name="selectedQuestion"
                                value={index}
                                id="aula-input-select"
                            // checked={}
                            // onChange={() => null}
                            />
                            {question}
                        </label>
                    </li>
                ))}
            </ul>

            <div className="aula-button-question">
                {currentQuestionIndex !== 0 &&
                    <div onClick={handleBackQuestion}>
                        <text>Voltar</text>
                    </div>
                }

                {data.length !== currentQuestionIndex + 1 &&
                    <div onClick={handleNextQuestion}>
                        <text>Proximo</text>
                    </div>
                }

                {currentQuestionIndex !== 0 && data.length === currentQuestionIndex + 1 &&
                    <div onClick={handleNextQuestion}>
                        <text>Enviar tudo e terminar</text>
                    </div>
                }
            </div>
        </div>
    )
}

export default Modules