import React from "react";
import formats from './ToolbarOptions.js'

import varinhaMagica from '../../assets/varinha-magica.png';
import { Button } from "@mui/material";

const renderOptions = (formatData) => {
    const { className, options } = formatData;
    return (
        <select className={className}>
            <option selected="selected"></option>
            {
                options.map(value => {
                    return (
                        <option value={value}></option>
                    )
                })
            }
        </select>
    )
}
const renderSingle = (formatData) => {
    const { className, value } = formatData;
    return (
        <button className={className} value={value}></button>
    )
}
const CustomToolbar = ({ id = 'customTooolbar', showWand = false, onClickCustom = null }) => (
    <div key={id} id={id}>
        {
            formats.map((classes, i) => {
                return (
                    <span key={`option-${i}`} className="ql-formats">
                        {
                            classes.map((formatData, j) => {
                                return formatData.options ? renderOptions(formatData) : renderSingle(formatData);
                            })
                        }
                    </span>
                )
            })
        }
        {
            showWand &&
            <span key={'custom'} className="ql-formats">
                <Button 
                    variant="contained"
                    id='varinhaOpenIA'
                    onClick={(e) => { 
                        e.preventDefault()
                        onClickCustom();
                    }}
                    style={{ width: '150px', background: '#f1416c', height: 33, minWidth: 160, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                    className="ql-insertCustomTags" value='rtl'>
                    <img src={varinhaMagica} width='24px' style={{ marginLeft: 7 }} /> <span style={{ fontWeight: 'bold', marginRight: 7 }}>Texto mágico</span>
                </Button>
            </span>
        }
    </div>
)
export default CustomToolbar;
