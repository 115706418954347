import { TextField } from '@mui/material'

const InputText = ({
    id, 
    type='text', 
    label, 
    onChange, 
    value, 
    estilo = { width: '100%', marginTop: 10 }, 
    error = null, 
    disabled = false, 
    multline = false, 
    minRows=0,
    maxText=null
}) => <TextField
    multiline={multline}
    minRows={minRows}
    error={error}
    disabled={disabled}
    readOnly={disabled}
    type={type}
    style={estilo}
    InputLabelProps={{style: {fontSize: 15 }}}
    InputProps={{style: {fontSize: 15 }}}
    variant='outlined'
    id={id}
    label={label}
    onChange={onChange}
    value={value}
    inputProps={{ maxLength: maxText }}
    onFocus={event => {
        event.target.select();
    }}
/>

export default InputText;
