import { Fragment, useEffect, useRef, useState } from "react";
import { Box, Stack, Typography, TableContainer, Table, TableHead, TableRow, TablePagination, TableCell, TableBody, Switch, CircularProgress, InputAdornment, Button, TextField, Paper, FormControlLabel, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA } from "../../../services/API";
import { Container } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import ModalWhats from "../../../components/ModalWhats";
import { POPOVER_OPEN } from "../../../store/PopoverReducer/types";
import ModalEmail from "../../../components/ModalEmail";

const columns = [
    { id: "nome", label: "Nome", minWidth: 120, maxWidth: 200 },
    { id: "dataCadastro", label: "Data Cadastro", minWidth: 120, maxWidth: 170 },
    { id: "email", label: "E-mail", minWidth: 120, maxWidth: 170 },
    { id: "celular", label: "Celular", minWidth: 120, maxWidth: 170 },
    {
        id: "ativo",
        label: "Ativo",
        maxWidth: 100,
        align: "right",
    },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 200,
        align: "right",
    },
];

const RelatoriosClientes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [anchorEl, setaAchorEl] = useState(null);

    const [ativos, setAtivos] = useState(true);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [usuarios, setUsuarios] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            await fetchUsuarios();
        })();
    }, []);

    useEffect(() => {
        const tmpUsuarios = usuarios?.filter(a => a.FlgAtivo === ativos)?.map(usuario => {
            return <Row usuario={usuario} />
        });

        setRows(tmpUsuarios || []);
    }, [usuarios, ativos]);

    const fetchUsuarios = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoading(true);

        let filtroStr = search || null;
        if (filtro !== null) filtroStr = filtro;

        let endpoint = `UsuarioEmpresa/GetAllUsuarioByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuario}`;
        if(filtroStr !== null && filtroStr.length > 0)
            endpoint = `UsuarioEmpresa/GetAllUsuarioByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuario}&strFiltro=${filtroStr}`;
        
        const result = await POST_DATA(endpoint);

        setUsuarios(result || []);
        setLoading(false);
    }

    const fetchExcel = async () => {
        if (usuarios === null || usuarios.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Data de Cadastro; Nome; Cpf; Email; Celular; Ativo`;
        let cpf = '-';
        let celular = '-';
        usuarios?.forEach(usuario => {
            cpf = usuario.Cpf !== null ? usuario.Cpf : '-';
            celular = usuario.Celular !== null ? usuario.Celular : '-';

            dados += `\n${formatDateSimples(usuario.DtInclusao)}; ${usuario.Nome}; ${cpf}; ${usuario.Email}; ${celular}; ${usuario.FlgAtivo ? 'Sim' : 'Não'}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Clientes.csv', dados);
        window.open(url, '_blank');
    }

    const Row = ({ usuario }) => {
        return <Fragment key={usuario.IdUsuario}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {usuario.Nome}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {formatDateSimples(usuario.DtInclusao)}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {usuario.Email}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {usuario.Celular}
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                    {usuario.FlgAtivo ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                    <Button
                        title="Enviar cupom"
                        size="small"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14 }} 
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmailComAnexo',
                                    idItem: 'poperModalEmailComAnexo',
                                    open: true,
                                    data: {
                                        nomePessoa: usuario.Nome,
                                        emailCliente: usuario.Email
                                    }
                                }); 
                            }, 200);
                        }}                      
                    >
                        <LocalOfferIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        title="Enviar mala direta"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmail',
                                    idItem: 'poperModalEmail',
                                    open: true,
                                    data: {
                                        nomePessoa: usuario.Nome,
                                        emailCliente: usuario.Email
                                    }
                                }); 
                            }, 200);
                        }}
                    >
                        <EmailIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        title="Enviar mensagem"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalWhats',
                                    idItem: 'poperModalWhats',
                                    open: true,
                                    data: {
                                        nomePessoa: usuario.Nome,
                                        numeroWhatsapp: usuario.Celular
                                    }
                                }); 
                            }, 200);
                        }}
                    >
                        <WhatsAppIcon style={{ fontSize: 16 }} />
                    </Button>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Relatórios / Clientes</Typography>
                </Box>
            </Box>

            <ModalWhats anchorEl={anchorEl} />
            <ModalEmail idPopover='poperModalEmail' anchorEl={anchorEl} />
            <ModalEmail idPopover='poperModalEmailComAnexo' anchorEl={anchorEl} possuiAnexo={true} />

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'nowrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <TextField
                            label="Pesquisar"
                            placeholder='Nome/CPF'
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchUsuarios('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ minHeight: 43, marginLeft: 10 }}
                            onClick={() => fetchUsuarios()}
                        >
                            Pesquisar
                        </Button>
                    </Box>

                    <FormControlLabel labelPlacement="top" control={<Switch checked={ativos} onChange={() => setAtivos(!ativos)} />} label="Ativos" />
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando clientes</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${rows?.length} resultados encontrados para pesquisa "${search}" - Produtos por página: `
                                    : "Produtos por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default RelatoriosClientes;