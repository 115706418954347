import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { Save, Undo } from "@mui/icons-material";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from '../../../services/API';
import { useDispatch, useSelector } from 'react-redux';
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';

const TemplateList = () => {
  const userLoggedIn = useSelector((state) => state.usuarioLogado);
  const superUser = useSelector((state) => state.superUser);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [Imagem, setImagem] = useState(null);

  const [objCadastroTemplates, setObjCadastroTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [modifiedItems, setModifiedItems] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showUndoConfirmation, setShowUndoConfirmation] = useState(false);

  const container = {
    position: 'absolute',
    top: '0%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const getListOfIntegracoes = async () => {
    try {
      const resultLogista = await POST_DATA(`IntegracaoEmailTemplatexUsuario/GetAllIntegracaoEmailTemplatexUsuarioByIdUsuarioAndIdIntegracao?IdUsuario=${userLoggedIn.IdUsuario}&IdIntegracao=1&IdIntegracaoEmailTemplate=0`);
      if (resultLogista === null || resultLogista === undefined || resultLogista.length === 0) {
        const result = await POST_DATA(`IntegracaoEmailTemplatexUsuario/GetAllIntegracaoEmailTemplatexUsuarioByIdUsuarioAndIdIntegracao?IdUsuario=167&IdIntegracao=1&IdIntegracaoEmailTemplate=0`);
        result.forEach((item) => { item.IdIntegracaoEmailTemplatexUsuario = 0 });

        setTemplateList(result);
        setObjCadastroTemplates(result);
      }
      else {
        setTemplateList(resultLogista);
        setObjCadastroTemplates(resultLogista);
      }

      setIsLoadingTemplates(false);
      setModifiedItems([]);
    }
    catch (err) {
      toast.error("Erro ao carregar lista de templates de e-mails");
      setIsLoadingTemplates(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getListOfIntegracoes();
    })();
  }, []);

  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleSaveChanges = async () => {
    setIsLoadingSave(true);
    try {
      objCadastroTemplates.forEach((item) => { item.IdUsuario = userLoggedIn.IdUsuario });

      await PUT_DATA(`IntegracaoEmailTemplatexUsuario/UpdateIntegracaoEmailTemplatexUsuario`, objCadastroTemplates);
      dispatch({
        type: CONTROLEMENSAGEM_SUCESSO,
        tipoComponente: 'alert',
        titulo: 'Sucesso',
        message: 'Informações salvas!',
      });
      getListOfIntegracoes();
      setIsLoadingSave(false);
      setShowConfirmation(false);
    }
    catch (err) {
      SalvarLogSentry(err);
      dispatch({
        type: CONTROLEMENSAGEM_ERRO,
        tipoComponente: 'alert',
        titulo: 'Sucesso',
        message: 'Ocorreu um erro ao tentar salvar as informações.',
      });
      setIsLoadingSave(false);
      setShowConfirmation(false);
    }
  };

  const undoChanges = () => {
    const originalTemplates = templateList.map((template) => ({ ...template }));
    setObjCadastroTemplates(originalTemplates);
    setModifiedItems([]);

    setShowUndoConfirmation(false);
  };

  const handleConfirmUndo = () => {
    setShowUndoConfirmation(true);
  };

  const handleCloseUndoConfirmation = () => {
    setShowUndoConfirmation(false);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 35,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Card sx={{ padding: 4 }}>
      {
        openModal &&
        <Modal
          key="modal"
          open={openModal}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '0%'
          }}
        >
          <Box
            sx={[
              container,
              {
                width: '50%',
                height: 'auto',
                maxHeight: 650,
                overflowY: 'auto',
                borderRadius: 2,
                backgroundColor: 'white',
              },
            ]}
          >
            <BootstrapDialogTitle id="componenteMensagem" onClose={() => setOpenModal(false)}>
              <Box sx={{ fontSize: 20, marginLeft: -2 }}>Exemplo e-mail</Box>
            </BootstrapDialogTitle>
            <br />

            <img src={Imagem} style={{ width: '100%' }} />
          </Box>
        </Modal>
      }

      <Stack direction="column" gap={2}>
        {!isLoadingTemplates && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography fontWeight={700}>
              {templateList.length} templates encontrados -{" "}
              {modifiedItems.length} itens modificados
            </Typography>
            {isLoadingSave && (
              <Stack direction="row" alignItems="center" gap={2}>
                <CircularProgress />
                <Typography>Salvando integrações...</Typography>
              </Stack>
            )}
            {/* {
              superUser !== null &&
              <Stack direction="row" gap={1}>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  onClick={() => setShowConfirmation(true)}
                  disabled={modifiedItems.length === 0}
                >
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Undo />}
                  onClick={handleConfirmUndo}
                  disabled={modifiedItems.length === 0}
                >
                  Desfazer
                </Button>
              </Stack>
            } */}
          </Stack>
        )}
        {templateList.length > 0 ? (
          objCadastroTemplates?.map((template, i) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                key={i}
                id="template-item"
              >
                <Stack
                  sx={{ width: "470px" }}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={1}
                >
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: 12,
                      color: 'white',
                      backgroundColor: modifiedItems.includes(i)
                        ? "#e23d65"
                        : "gray",
                    }}
                  >
                    {i + 1}
                  </Avatar>
                  <Typography>
                    {template.IntegracaoEmailTemplate?.Descricao ? template.IntegracaoEmailTemplate?.Descricao : template.IntegracaoEmailTemplate?.Nome}
                  </Typography>
                </Stack>

                {/* {
                  superUser !== null &&
                  <TextField
                    id={`campo-id-${i}`}
                    value={objCadastroTemplates[i]?.IdTemplate || ""}
                    size="small"
                    label="ID"
                    type="number"
                    inputProps={{ pattern: "[0-9]*" }}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const isNumeric = /^[0-9]*$/.test(inputValue);
                      if (isNumeric) {
                        const updatedTemplates = objCadastroTemplates.map(
                          (temp, index) => {
                            if (index === i) {
                              return {
                                ...temp,
                                IdTemplate: parseInt(inputValue, 10),
                              };
                            }
                            return temp;
                          }
                        );
                        setObjCadastroTemplates(updatedTemplates);
                        //adc item nos itens modificados
                        if (!modifiedItems.includes(i)) {
                          setModifiedItems([...modifiedItems, i]);
                        }
                      }
                    }}
                  />
                } */}

                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpenModal(true);
                    setImagem(template.IntegracaoEmailTemplate.Url ? template.IntegracaoEmailTemplate.Url : 'https://leapstyle.blob.core.windows.net/view-email-loja-global/01_confirma_pedido.jpeg');
                  }}
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Stack>
            );
          })
        ) : isLoadingTemplates ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
            gap={2}
          >
            <CircularProgress />
            <Typography>Carregando...</Typography>
          </Stack>
        ) : (
          <Alert severity="info">Nenhum template encontrado.</Alert>
        )}
      </Stack>
      <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirmar Alterações</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja salvar as alterações em{" "}
            {modifiedItems.length} itens?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Cancelar</Button>
          <Button onClick={handleSaveChanges} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showUndoConfirmation} onClose={handleCloseUndoConfirmation}>
        <DialogTitle>Confirmar Desfazer Alterações</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja desfazer as alterações em{" "}
            {modifiedItems.length} itens?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUndoConfirmation}>Cancelar</Button>
          <Button onClick={undoChanges} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default TemplateList;
