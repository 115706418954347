import { SHOPING_CART_SET } from './types';

const shoppingCartReducer = (state = null, action) => {
  switch (action.type) {
    case SHOPING_CART_SET:
      return action.value;
    default:
      return state;
  }
};

export default shoppingCartReducer;