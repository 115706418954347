import { MODAL_ABRIR, MODAL_CLOSE } from './types';

const INITIAL_STATE = {
  open: false,
  idModal: '',
  idItem: 0,
  data: {},
};

const modalDialogRecuder = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODAL_ABRIR:
      return { ...state, open: true, idModal: action.idModal, idItem: action.idItem, data: action.data };
    case MODAL_CLOSE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default modalDialogRecuder;
