import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .m_title {
    display: inline-block; /* Thanks to Fanky (https://stackoverflow.com/users/2095642/fanky) */
    
    text-transform: lowercase
    }

    .m_title::first-letter {
    text-transform: uppercase
    }
`;
