import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POST_DATA, PUT_DATA, SalvarLogSentry } from '../../../services/API';
import { Box, Button, Card, CardHeader, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, CircularProgress, Stack, TextField, InputAdornment, Modal } from '@mui/material';
import { Divider } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import { useLocation, useNavigate } from 'react-router';
import { CloseBtn, ModalContainer } from './styles';

const Marcas = () => {
    const dispatch = useDispatch();
    const locaion = useLocation();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [nomeMarca, setNomeMarca] = useState('');
    const [descricaoMarca, setDescricaoMarca] = useState('');

    const [search, setSearch] = useState('');
    const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [allMarcas, setAllMarcas] = useState([]);
    const [marcasLoja, setMarcasLoja] = useState([]);

    const leftChecked = intersection(marcasSelecionadas, marcas);
    const rightChecked = intersection(marcasSelecionadas, marcasLoja);

    useEffect(() => {
        if(userLoggedIn === null) return;
        (async () => {
            await fetchMarcas();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn]);

    const fetchMarcas = async () => {
        setIsLoadingData(true);

        const responseMarcas = await POST_DATA(`Marca/GetAllMarca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=100&order_by=IdMarca&Log=`);
        setMarcas(responseMarcas);

        const responseMarcasLoja = await POST_DATA(`UsuarioEmpresaXMarca/GetAllUsuarioEmpresaXMarcaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=UXM.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);        

        const arrMarcasLojaFiltrada = [];
        let marcasFiltradas = responseMarcas;
        
        responseMarcasLoja.forEach(item => {
            marcasFiltradas = marcasFiltradas.filter(marca => marca.IdMarca !== item.IdMarca);
            arrMarcasLojaFiltrada.push({
                IdMarca: item.IdMarca,
                IdUsuarioEmpresa: item.IdUsuarioEmpresa,
                Nome: item?.Marca?.Nome,
                FlagAtivo: item.FlagAtivo
            });
        });

        setAllMarcas(marcasFiltradas);
        setMarcas(marcasFiltradas);
        setMarcasLoja(arrMarcasLojaFiltrada);
        setIsLoadingData(false);
    }

    const handleFiltrarMarcas = async (filtro = null) => {
        try {
            setIsLoadingData(true);
            filtro = filtro !== null ? filtro : search;

            let arrAllMarcas = allMarcas;
            let marcasFiltradas = allMarcas;

            marcasFiltradas = await POST_DATA(`Marca/GetAllMarcaByPartial?strPartial=${filtro}&ColunaParaPartial=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMarca&Log=`);
            marcasLoja.forEach(item => {
                marcasFiltradas = marcasFiltradas.filter(marca => marca.IdMarca !== item.IdMarca);
                arrAllMarcas = arrAllMarcas.filter(marca => marca.IdMarca !== item.IdMarca);
            });

            setMarcas(marcasFiltradas);
            setAllMarcas(arrAllMarcas);
            setIsLoadingData(false);
        } catch (err) {
            SalvarLogSentry(err);
        }
    }

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const handleToggle = (marca) => () => {
        const currentIndex = marcasSelecionadas.indexOf(marca);
        const newChecked = [...marcasSelecionadas];

        if (currentIndex === -1) {
            newChecked.push(marca);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setMarcasSelecionadas(newChecked);
    };

    const numberOfChecked = (items) => intersection(marcasSelecionadas, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setMarcasSelecionadas(not(marcasSelecionadas, items));
        } else {
            setMarcasSelecionadas(union(marcasSelecionadas, items));
        }
    };

    const handleAllRight = () => {
        setMarcasLoja(marcasLoja.concat(allMarcas));
        setMarcas([]);
    };

    const handleCheckedRight = () => {
        setSearch('');
        setMarcasLoja(marcasLoja.concat(leftChecked));
        setMarcas(not(allMarcas, leftChecked));
        setMarcasSelecionadas(not(marcasSelecionadas, leftChecked));
    };

    const handleCheckedLeft = () => {
        setSearch('');
        setMarcas(allMarcas.concat(rightChecked));
        setMarcasLoja(not(marcasLoja, rightChecked));
        setMarcasSelecionadas(not(marcasSelecionadas, rightChecked));
    };

    const handleAllLeft = () => {
        setMarcas(allMarcas.concat(marcasLoja));
        setMarcasLoja([]);
    };

    const handleSave = async () => {
        setIsLoadingData(true);

        const arrEnvio = [];
        if (marcasLoja.length > 0) {
            marcasLoja.forEach(marca => {
                arrEnvio.push({
                    IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                    IdMarca: marca.IdMarca,
                    FlagAtivo: true
                });
            });
            await PUT_DATA(`UsuarioEmpresaXMarca/InsertListUsuarioEmpresaXMarca`, arrEnvio);
        }
        else {
            await POST_DATA(`UsuarioEmpresaXMarca/DeletarUsuarioEmpresaXMarca?Id=${userLoggedIn.IdUsuarioEmpresa}&Log=`);
        }

        dispatch({
            type: CONTROLEMENSAGEM_SUCESSO,
            tipoComponente: 'alert',
            titulo: 'Sucesso',
            message: 'Marcas salvas',
        });

        setIsLoadingData(false);

        if (locaion.state?.veioTelaProduto) {
            navigate('/produtos');
        }
    }

    const handleSalvar = async () => {
        let result = await POST_DATA(`Marca/GetAllMarcaByValorExato?strValorExato=${nomeMarca}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMarca&Log=`);

        if(nomeMarca.length < 3){
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Favor informar o nome de uma marca válida',
            });

            return;
        }

        if(result.length > 0){
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Já existe a marca informada',
            });

            return;
        }

        const obj = {
            "Nome": nomeMarca,
            "Descricao": descricaoMarca,
            "FlgAtivo": true,
            "IdIncluidoPor": userLoggedIn.IdUsuario
        }

        result = await PUT_DATA(`Marca/CadastroMarca`, obj);
        if(result){
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Marca salva com sucesso',
            });
        }

    }

    const customList = (title, items, opcoesList = true) => (
        <Card>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardHeader
                    sx={{ py: 1 }}
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
                />
                {
                    opcoesList &&
                    <Button style={{ height: 40, marginRight: 10, marginTop: 10 }} onClick={() => setModalIsOpen(true)} variant='contained'>Novo</Button>
                }
            </Box>
            {
                opcoesList &&
                <Box style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'start', padding: 10, paddingLeft: 15 }}>
                    <TextField
                        label="Pesquisar"
                        placeholder='Nome'
                        size="small"
                        value={search}
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                handleFiltrarMarcas();
                            }
                        }}
                        InputProps={{
                            endAdornment: search.length > 0 && <InputAdornment
                                style={{ cursor: 'pointer' }}
                                position="end"
                                onClick={() => {
                                    setSearch(() => '');
                                    setTimeout(() => {
                                        handleFiltrarMarcas('');
                                    }, 450)
                                }}
                            >
                                <ClearIcon />
                            </InputAdornment>
                        }}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ height: 43 }}
                        onClick={() => handleFiltrarMarcas()}
                    >
                        Pesquisar
                    </Button>
                </Box>
            }
            <Divider />
            <List
                sx={{
                    width: '100%',
                    minHeight: 350,
                    maxHeight: 350,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((item) => {
                    const labelId = `transfer-list-all-item-${item.IdMarca}-label`;
                    return (
                        <ListItem
                            key={item.IdMarca}
                            role="listitem"
                            button
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={marcasSelecionadas.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.Nome} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    if (isLoadingData)
        return <Stack
            sx={{ height: "550px" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>Carregando marcas</Typography>
        </Stack>

    const componente = allMarcas && allMarcas.length > 0 &&
        <div>
            {
                modalIsOpen &&
                <Modal
                    open={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    keepMounted
                    disableEscapeKeyDown
                    disableAutoFocus
                >
                    <ModalContainer style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Cadastrar Marca</Typography>
                            <span style={{ marginRight: 10 }}>
                                <CloseBtn style={{ fontSize: 25 }} onClick={() => setModalIsOpen(false)} />
                            </span>
                        </Box>
                        <Box style={{ width: '100%', marginTop: 30 }}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label='Nome Marca'
                                name='login'
                                value={nomeMarca}
                                onChange={(e) => setNomeMarca(e.target.value)}
                                style={{ width: '100%', marginTop: 10 }}
                                InputLabelProps={{
                                    style: {
                                        color: 'black',
                                        fontSize: '14px'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: 'black',
                                        fontSize: '14px'
                                    }
                                }}
                            />
                        </Box>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ marginTop: 10 }} variant='contained' onClick={() => handleSalvar()}>Salvar</Button>
                        </Box>
                    </ModalContainer>
                </Modal>
            }

            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Marcas</Typography>
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography></Typography>
                    <Button variant='contained' onClick={() => handleSave()}>Salvar</Button>
                </Box>
                <Grid container justifyContent="center" alignItems="top" style={{ marginTop: 10 }}>
                    <Grid item xs={5}>{customList('Opções de marcas', marcas)}</Grid>
                    <Grid item xs={2}>
                        <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                /** 
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                */
                            }
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="Mover para a direita"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="Mover para a equerda"
                            >
                                &lt;
                            </Button>
                            {
                                /**
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                                */
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={5}>{customList('Marcas selecionadas', marcasLoja, false)}</Grid>
                </Grid>
            </Box>
        </div>

    try {
        return componente;
    } catch (err) {
        SalvarLogSentry(err);

        dispatch({
            type: CONTROLEMENSAGEM_ERRO,
            tipoComponente: 'alert',
            titulo: 'Erro',
            message: err?.message,
        });
    }
}

export default Marcas;
