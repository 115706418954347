import { Box, Button, Card, CircularProgress, Divider, Fade, Grid, InputAdornment, Menu, MenuItem, Skeleton, Stack, TablePagination, TextField, Typography } from "@mui/material";
import { Container, StyleValueCart } from "./styles";
import { memo, useEffect, useRef, useState } from "react";
import CardLoja from "../../components/CardLoja";


import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import lojaAtacado1 from '../../assets/logos/lojaAtacado1.png';
import lojaAtacado2 from '../../assets/logos/lojaAtacado2.avif';
import lojaAtacado3 from '../../assets/logos/lojaAtacado3.jpg';
import lojaAtacado4 from '../../assets/logos/lojaAtacado4.avif';

import fotoCapa1 from '../../assets/fotosCapa/fotoCapa1.jpg';
import fotoCapa2 from '../../assets/fotosCapa/fotoCapa2.jpg';
import fotoCapa3 from '../../assets/fotosCapa/fotoCapa3.jpg';
import fotoCapa4 from '../../assets/fotosCapa/fotoCapa4.jpg';

import ProductList from "../../components/ProductList";
import { useBeforeRender } from "../../hooks/useBeforeRender";

import './styles.css';
import { useLocation, useNavigate } from "react-router";
import CarrosselImagens from "../../components/CarrosselImagem";
import { MODAL_SHOPPING_CART_ABRIR } from "../../store/ModalShoppingCartReducer/types";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartModal from "../../components/ShoppingCartModal";
import { GET_DATA_SERVICOS_ROBO, POST_DATA, SalvarLogSentry } from "../../services/API";
import { VESTI_SET_DADOS } from "../../store/Vesti/types";

const Atacado = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const carrinhoVesti = useSelector((store) => store.carrinhoVesti);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const dadosVesti = useSelector((state) => state.dadosVesti);
    const [navegueLojas, setNavegueLojas] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [loading, setLoading] = useState(false);
    const [loadingOthersProducts, setLoadingOthersProducts] = useState(false);

    const [banners, setBanners] = useState([]);
    const [lojas, setLojas] = useState([]);
    const [lojasFiltradas, setLojasFiltradas] = useState([]);

    const [produtos, setProdutos] = useState([]);
    const [produtosFiltrados, setProdutosFiltrados] = useState([]);

    const [tipoFiltro, setTipoFiltro] = useState(1);
    const [search, setSearch] = useState('');

    const [lojaSelecionada, setLojaSelecionada] = useState(null);

    const [categorias, setCategorias] = useState([]);
    const [categoriasFornecedores, setCategoriasFornecedores] = useState([]);
    const [categoriasFiltradas, setCategoriasFiltradas] = useState([]);
    const [filtroCategoria, setFiltroCategoria] = useState(0);

    //Filtros
    const [filtroPreco, setFiltroPreco] = useState('Preço');
    const [clicouOpcoesFiltro, setClicouOpcoesFiltro] = useState(false);
    const [clearFiltros, setClearFiltros] = useState(null);
    const [filtroRelevancia, setFiltroRelevancia] = useState('todos');
    const [filtroDepartamento, setFiltroDepartamento] = useState();
    const [filtroTamanho, setFiltroTamanho] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [opcaoMenuSelecionado, setOpcaoMenuSelecionado] = useState('Relevância');
    const openMenu = Boolean(anchorEl);

    const carregouProduto = useRef(null);

    useEffect(() => {
        if (userLoggedIn === null) return;

        if (userLoggedIn.PlanoEmpresa.IdUsuarioEmpresaPlano < 4) {
            navigate('/pagina-em-dev');
            return;
        }

        const objBanners = [
            {
                id: 'banner1',
                url: 'https://leapstyle.blob.core.windows.net/cms/92317928000112/images/banner2.gif',
                url_mobile: 'https://leapstyle.blob.core.windows.net/cms/92317928000112/images/banner_mobile2.gif',
                alternative_text: 'Banner 1'
            },
            {
                id: 'banner2',
                url: 'https://leapstyle.blob.core.windows.net/cms/92317928000112/images/banner1.gif',
                url_mobile: 'https://leapstyle.blob.core.windows.net/cms/92317928000112/images/banner_mobile2.gif',
                alternative_text: 'Banner 1'
            }
        ]

        setBanners(objBanners);

        (async () => {
            await fetchDadosVesti();
        })();

    }, [userLoggedIn]);

    useEffect(() => {
        if (lojaSelecionada !== null) {
            const categorias = categoriasFornecedores.filter(a => a.IdFornecedor === lojaSelecionada.id).map((item) => {
                return {
                    id: item.id,
                    nome: item.name
                }
            });
            setCategoriasFiltradas(categorias);
        }

    }, [lojaSelecionada]);

    useEffect(() => {
        const ljSelecionada = location.state?.loja || null;
        const cat = location.state?.categoria || null;
        setLojaSelecionada(ljSelecionada);
        setTipoFiltro(1);

        if (ljSelecionada !== null) {
            let pFiltrado = produtos.filter(a => a.lojaId == ljSelecionada.id);

            if (cat !== null) {
                pFiltrado = pFiltrado.filter(a => a.categoriaId == cat.id);
            }
            else {
                setFiltroCategoria(0);
            }

            setProdutosFiltrados(pFiltrado);
        }
        else {
            let pFiltrado = produtos;
            if (cat !== null) {
                pFiltrado = pFiltrado.filter(a => a.categoriaId == cat.id);
            }
            else {
                setFiltroCategoria(0);
            }

            setProdutosFiltrados(pFiltrado);
        }

        if (cat === null) {
            setCategoriasFiltradas(categorias);
        }

    }, [produtos, location])

    useBeforeRender(() => {
        window.addEventListener("error", (e) => {
            if (e) {
                if (!e.message?.includes('ResizeObserver loop completed with undelivered notifications')) return;

                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div",
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay",
                );

                if (resizeObserverErr)
                    resizeObserverErr.className = "hide-resize-observer";
                if (resizeObserverErrDiv)
                    resizeObserverErrDiv.className = "hide-resize-observer";
            }
        });
    }, []);

    const fetchDadosVesti = async () => {
        try {
            setLoading(true);

            if (dadosVesti) {

                setCategoriasFiltradas(dadosVesti.categorias);
                setCategorias(dadosVesti.categorias);
                setCategoriasFornecedores(dadosVesti.categoriasLojas);

                setLojas(dadosVesti.fornecedores);
                setLojasFiltradas(dadosVesti.fornecedores);

                setProdutos(() => dadosVesti.produtos);
                setProdutosFiltrados(() => dadosVesti.produtos);

                setLoading(false);

                return;
            }

            const fornecedores = await POST_DATA(`FornecedoresVesti/GetListFornecedoresVesti`);

            const arrFornecedores = [];
            const arrCategorias = [];
            const arrProdutos = [];
            const arrCategoriasLojas = [];

            for (const fornecedorItem of fornecedores) {

                const fornecedor = {
                    id: fornecedorItem.IdFornecedor,
                    nome: fornecedorItem.Nome,
                    cnpj: fornecedorItem.cnpj,
                    fotoLogo: fornecedorItem.Logo,
                    fotoCapaLoja: fotoCapa1,
                    apiKey: fornecedorItem.ApiKey,
                    company: fornecedorItem.Company,
                    quantidadeProdutosVendidos: 0,
                    flgAtivo: 1,

                    ...fornecedorItem?.RootFornecedor
                };

                const categorias = await GET_DATA_SERVICOS_ROBO(`BuscaPedidoVesti_?CompanyId=${fornecedor.id}&collection=categories`);

                let qtdPordutos = 0;
                const produtos = await GET_DATA_SERVICOS_ROBO(`BuscaPedidoVesti_?CompanyId=${fornecedor.id}&collection=Products`);

                if (produtos) {
                    produtos.forEach((produto, index) => {
                        qtdPordutos++;

                        let imagens = [];
                        if (produto.main_media && produto.media) {
                            const itens = produto.media.filter(a => a.id !== produto.main_media.id);
                            itens.map((media) => {
                                imagens.push({ fotoProduto: media.normal.url });
                            });
                        }
                        else {
                            imagens = [produto.media.normal.url];
                        }

                        for (const cat of produto.categories) {

                            if (arrCategorias.filter(a => a.id === cat.id).length) continue;

                            if (categorias.filter(a => a.id === cat.id).length > 0) {
                                arrCategorias.push({
                                    id: categorias.filter(a => a.id === cat.id)[0].id,
                                    nome: categorias.filter(a => a.id === cat.id)[0].name
                                });
                            }
                        }

                        const prod = {
                            id: produto.id,
                            lojaId: fornecedorItem.IdFornecedor,
                            marcaId: produto.brand.name,
                            loja: fornecedor,
                            marca: produto.brand,
                            quantidade: produto.length,
                            categoriaId: produto.categories[0].id,
                            categoria: produto.categories[0],
                            nome: produto.name,
                            descricao: produto.description,
                            fotoProduto: produto?.main_media?.normal?.url,
                            imagensCompolementares: imagens,
                            flgCuradoriaVesti: true,
                            valor: produto.price,
                            valorPromocional: produto.price_promotional,
                            colors: produto.colors,
                            tamanhos: produto.sizes,
                            estoque: produto.stocks
                        };

                        arrProdutos.push(prod);

                        if (index >= 10 && produtosFiltrados.length === 0) {
                            setProdutos(() => arrProdutos);
                            setProdutosFiltrados(() => arrProdutos);

                            fornecedor.qtdProdutos = qtdPordutos;
                            const arr = [fornecedor];

                            setLojas(() => arr);
                            setLojasFiltradas(() => arr);

                            setCategorias(() => arrCategorias);
                            setCategoriasFiltradas(() => arrCategorias);
                            setCategoriasFornecedores(() => arrCategorias);
                        }
                    });
                }

                if (qtdPordutos > 0) {
                    arrFornecedores.push(fornecedor);
                    fornecedor.qtdProdutos = qtdPordutos;

                    for (const categoria of categorias) {
                        if (arrProdutos.filter(a => a.categoriaId === categoria.id).length > 0) {
                            arrCategoriasLojas.push({ ...categoria, IdFornecedor: fornecedor.id });
                        }
                    }
                }

                if (arrProdutos.length > 0) {
                    setProdutos(() => arrProdutos);
                    setLoadingOthersProducts(true);
                    setLoading(false);
                }
            }

            const dados = {
                fornecedores: arrFornecedores,
                categorias: arrCategorias,
                categoriasLojas: arrCategoriasLojas,
                produtos: arrProdutos
            }

            dispatch({
                type: VESTI_SET_DADOS,
                data: dados
            });

            setCategoriasFiltradas(arrCategorias);
            setCategorias(arrCategorias);
            setCategoriasFornecedores(arrCategoriasLojas);

            setLojas(arrFornecedores);
            setLojasFiltradas(arrFornecedores);

            setProdutos(() => arrProdutos);
            setProdutosFiltrados(() => arrProdutos);

            setLoading(false);
            setLoadingOthersProducts(false);
        }
        catch (err) {
            setLoading(false);
            SalvarLogSentry(err);
        }
    }

    const handleCarregarLojas = () => {
        setNavegueLojas(true);
        setTipoFiltro(2); //Lojas
    }

    const handleCarregarProdutos = () => {
        setNavegueLojas(false);
        setTipoFiltro(1); //Produtos
    }

    const fetchDados = async (busca = null) => {
        busca = busca != null ? busca : search;

        const ljSelecionada = location.state?.loja || null;

        if (busca.trim().length === 0) {
            if (ljSelecionada) {
                const pFiltrado = produtos.filter(a => a.lojaId === ljSelecionada.id && a.nome.toUpperCase().includes(busca.trim().toUpperCase()));
                setProdutosFiltrados(pFiltrado);
            }
            else {
                setProdutosFiltrados(produtos);
            }

            setLojasFiltradas(lojas);
        } else {
            if (tipoFiltro === 1 && busca.length > 0) { //Produtos

                if (ljSelecionada) {
                    const pFiltrado = produtos.filter(a => a.lojaId === ljSelecionada.id && a.nome.toUpperCase().includes(busca.trim().toUpperCase()));
                    setProdutosFiltrados(pFiltrado);
                }
                else {
                    const pFiltrado = produtos.filter(a => a.nome.toUpperCase().includes(busca.trim().toUpperCase()));
                    setProdutosFiltrados(pFiltrado);
                }
            }
            else { //Lojas
                setLojasFiltradas(lojas.filter(a => a.nome.toUpperCase().includes(busca.trim().toUpperCase())))
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenShppingCart = () => {
        dispatch({
            type: MODAL_SHOPPING_CART_ABRIR
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Container>
            <ShoppingCartModal />
            <Box style={{ width: "100%", marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Atacado</Typography>

                <Box style={{ marginRight: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleOpenShppingCart}>
                    <span className="material-symbols-outlined" style={{ fontSize: 30 }}>
                        shopping_cart
                    </span>
                    <StyleValueCart>
                        {carrinhoVesti.length}
                    </StyleValueCart>
                </Box>
            </Box>

            <Card style={{ width: '100%', padding: 15 }}>
                <Box style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box style={{ display: 'flex' }}>
                        <Button
                            onClick={() => {
                                handleCarregarProdutos();
                                navigate('/atacado');
                            }}
                            variant='contained'
                            style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: !navegueLojas ? 'white' : 'black',
                                cursor: 'pointer',
                                width: '160px',
                                height: 45,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: !navegueLojas ? '#F1416C' : 'white',
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                marginRight: 10
                            }}
                        >
                            Navegue por produtos
                        </Button>
                        <Button
                            onClick={() => {
                                handleCarregarLojas()
                            }}
                            variant='contained'
                            style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                color: navegueLojas ? 'white' : 'black',
                                background: navegueLojas ? '#F1416C' : 'white',
                                width: '160px',
                                height: 45,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5
                            }}>
                            Navegue por lojas
                        </Button>
                    </Box>

                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="Pesquisar"
                            placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                            size="small"
                            sx={{ width: "200px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="start"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchDados('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>,
                                endAdornment: <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        await fetchDados();
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            }}
                        />
                    </Box>
                </Box>
                <Divider style={{ marginTop: 10 }} />

                {
                    tipoFiltro === 1 &&
                    <>
                        <Box style={{ width: '100%', padding: 10, paddingTop: 15, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                            {
                                lojaSelecionada &&
                                <Box style={{ width: '100%' }}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>{lojaSelecionada.nome}</Typography>
                                    <Typography style={{ fontSize: 12, color: '#919090' }}>
                                        {
                                            lojaSelecionada.catalogue.cart_requirements?.tipo === 'pecas' &&
                                            <span>Qtd. mínima de compra: <span style={{ fontWeight: 'bold' }}>{lojaSelecionada.catalogue.cart_requirements.qtdevalor} peças </span></span>
                                        }
                                        {
                                            lojaSelecionada.catalogue.cart_requirements?.tipo !== 'pecas' &&
                                            <span>Valor mínimo de compra: <span style={{ fontWeight: 'bold' }}>{Number(lojaSelecionada.catalogue.cart_requirements.qtdevalor).toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })} </span></span>
                                        }
                                    </Typography>
                                </Box>
                            }
                            <CarrosselImagens carrossel_images={banners} marginTop={10} />
                        </Box>

                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 20, marginBottom: 15 }}>
                            <Box style={{ overflowX: 'auto', display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', paddingTop: 15, paddingBottom: 15, width: '100%' }}>
                                <Button
                                    style={{
                                        background: filtroCategoria === 0 ? '#F1416C' : null,
                                        color: filtroCategoria === 0 ? 'white' : 'black',
                                        fontWeight: 600,
                                        fontSize: 13,
                                        marginLeft: 10,
                                        marginRight: 10,
                                        maxHeight: 40
                                    }}
                                    variant={filtroCategoria === 0 ? 'contained' : 'text'}
                                    key={-1}
                                    onClick={() => {
                                        setFiltroTamanho([]);
                                        setFiltroCategoria(0);
                                        setClicouOpcoesFiltro(true);
                                        setPage(0);

                                        if (location.state?.loja) {
                                            navigate('/atacado', { state: { categoria: null, loja: location.state.loja } });
                                        }
                                        else {
                                            navigate('/atacado', { state: { categoria: null } });
                                        }
                                    }}
                                >Todos</Button>
                                {
                                    categoriasFiltradas.map((item, index) => {

                                        return <Button
                                            style={{
                                                background: filtroCategoria === item.id ? '#f1416c' : null,
                                                color: filtroCategoria === item.id ? 'white' : 'black',
                                                fontWeight: 600,
                                                fontSize: 10,
                                                minWidth: 90,
                                                marginLeft: 2,
                                                marginRight: 2,
                                                maxHeight: 40,
                                                minWidth: 170
                                            }}
                                            variant={filtroCategoria === item.id ? 'contained' : 'text'}
                                            key={index + 1}
                                            onClick={() => {
                                                setFiltroTamanho([]);
                                                setFiltroCategoria(item.id);
                                                setClicouOpcoesFiltro(true);
                                                setPage(0);

                                                if (location.state?.loja) {
                                                    navigate('/atacado', { state: { categoria: item, loja: location.state.loja } });
                                                }
                                                else {
                                                    navigate('/atacado', { state: { categoria: item } })
                                                }
                                            }}
                                        >{item.nome}</Button>
                                    })
                                }
                            </Box>
                            <Divider style={{ background: 'gray' }} />
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ minWidth: 25, width: 'auto', height: 25, padding: 5, paddingTop: 3, paddingLeft: 9, paddingRight: 10, borderRadius: 10, marginRight: 10, color: '#f1416c', fontWeight: 'bold', background: '#f2f2f2' }}>{produtosFiltrados.length}</span><span style={{ fontSize: 20, fontWeight: 'bold' }}>Itens:</span>
                                </Box>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ width: 25, height: 25, padding: 5, paddingTop: 0, paddingLeft: 4, paddingRight: 10, borderRadius: 10, marginRight: 10, color: '#f1416c', fontWeight: 'bold', background: '#f2f2f2' }}><FilterAltIcon style={{ fontSize: 18 }} /></span>
                                    <Button
                                        id="basic-button"
                                        variant="text"
                                        aria-controls={openMenu ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? "true" : undefined}
                                        onClick={handleClick}
                                        style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}
                                    >
                                        {opcaoMenuSelecionado}
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={openMenu}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                    >
                                        <MenuItem key={1}
                                            style={{ fontSize: 14 }}
                                            onClick={() => {
                                                handleClose();
                                                setFiltroRelevancia('todos');
                                                setOpcaoMenuSelecionado('Todos');
                                            }}>
                                            Todos
                                        </MenuItem>
                                        <MenuItem key={2}
                                            style={{ fontSize: 14 }}
                                            onClick={() => {
                                                handleClose();
                                                setFiltroRelevancia('IdImagem desc');
                                                setOpcaoMenuSelecionado('Mais recentes');
                                            }}>
                                            Mais recentes
                                        </MenuItem>
                                        <MenuItem key={3}
                                            style={{ fontSize: 14 }}
                                            onClick={() => {
                                                handleClose();
                                                setFiltroRelevancia('IdImagem asc');
                                                setOpcaoMenuSelecionado('Mais antigos');
                                            }}>
                                            Mais antigos
                                        </MenuItem>
                                        <MenuItem key={4} onClick={() => {
                                            setOpen(true);
                                            setAnchorEl(null);
                                            setOpcaoMenuSelecionado(filtroPreco);
                                        }}>
                                            <Typography style={{ fontSize: 14 }}>{filtroPreco}</Typography>
                                        </MenuItem>
                                    </Menu>
                                    {openMenu ? <ArrowDropUpIcon style={{ fontSize: 35, color: 'black', fontWeight: 'bold', color: '#f1416c' }} /> : <ArrowDropDownIcon style={{ fontSize: 35, color: 'black', fontWeight: 'bold', color: '#f1416c' }} />}
                                </Box>
                            </Box>
                        </Box>
                    </>
                }

                {
                    loading && <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Buscando dados</Typography>
                    </Stack>
                }

                {
                    !loading && tipoFiltro === 1 && produtosFiltrados &&
                    <>
                        <ProductList rowsPerPage={rowsPerPage} page={page} ehAtacado={true} productList={produtosFiltrados} />
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={produtosFiltrados.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${produtosFiltrados.length}/${produtos.length} resultados encontrados para pesquisa "${search}" - afiliados por página: `
                                    : "Afiliados por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                }

                {/* {
                    loadingOthersProducts && tipoFiltro === 1 && produtosFiltrados &&
                    <Box style={{ width: '1100', display: 'flex', justifyContent: 'center' }}>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {
                                produtosFiltrados.map((item, index) => {
                                    return <Skeleton key={index} variant="rect" width={220} height={370} style={{ marginRight: 10, marginBottom: 10 }} />
                                })
                            }
                        </Box>
                        <Box style={{ width: '1100', display: 'flex', justifyContent: 'center' }}>
                            <Fade in={loadingOthersProducts}>
                                <CircularProgress />
                            </Fade>
                        </Box>
                    </Box>
                } */}

                {
                    !loading && tipoFiltro === 2 &&
                    <Box style={{ display: 'flex', width: '100%', marginTop: 10 }}>
                        <Grid container spacing={4} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {
                                lojasFiltradas.map((loja, idx) => {
                                    return (
                                        <Grid key={`loja-${idx}`} item xs={3} style={{ marginBottom: 25 }}>
                                            <CardLoja loja={loja} />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Box>
                }
            </Card>
        </Container>
    );
}

export default memo(Atacado);