import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
  `}
`;

export const Item = styled(Box)`
    ${({ theme }) => css`
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    `}
`;
