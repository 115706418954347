import {styled, Box, Typography} from '@mui/material';

export const Container = styled(Box)`

`;

export const Title = styled(Typography)`
    color: #757575;
    font-size: 16px !important;
    font-weight: bold;
`;

export const Descricao = styled(Typography)`
    color: rgb(247, 147, 30);
    font-size: 24px !important;
    font-weight: 700 !important;
    padding-top: 10px;
`;
