import { Stack, Typography, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { getStorage } from "../../../hooks/useStorage";
import { useNavigate } from "react-router";

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const usuarioLogado = JSON.parse(getStorage("usrin"));
  
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
      sx={{ m: 1 }}
    >
      <Typography variant="body2">Plano</Typography>
      <Chip style={{ cursor: 'pointer' }} onClick={() => navigate('/assinatura')} label={usuarioLogado?.PlanoEmpresa ? usuarioLogado.PlanoEmpresa.Descricao : "Sem Plano"} variant="outlined" color="secondary" />
    </Stack>
  );
};

export default SubscriptionPlan;
