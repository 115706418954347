import { Box, Typography } from '@mui/material';
import { Container } from './styles';
import { useNavigate } from 'react-router';
import OpcoesFormasEntrega from './opcoesFormasEntrega';

const FormasEntrega = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Configuração / Formas de entrega</Typography>
                </Box>
            </Box>
            <OpcoesFormasEntrega />
        </Container>
    )
}

export default FormasEntrega;
