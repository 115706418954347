import { Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA_DESING, UploadFileAzure } from "../../../services/API";
import { TEMA_LOJA_SET, TEMA_LOJA_SET_MARKETING_SEO } from "../../../store/TemaLojaReducer/types";
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import UploadImagemDesign from "../../../components/UploadImagemDesign";

const MarketingSEO = () => {
    const dispatch = useDispatch();

    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const themeStorage = useSelector((store) => store.temaLoja);

    const [construtorSite, setConstrutorSite] = useState(null);
    const [themeStorageSelected, setThemeStorageSelected] = useState(null);
    const [tokenDesign, setTokenDesign] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };

            const login = await POST_AUTH(obj);
            setTokenDesign(login.token);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (tokenDesign === null) return;
            await GetDadosTema();
        })();
    }, [tokenDesign]);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store) {
            const construtorSiteSelected = themeStorage.themes_store[0];
            setThemeStorageSelected(construtorSiteSelected);            
        }
    }, [themeStorage]);

    const GetDadosTema = async () => {
        try {
            setLoading(true);

            const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
            setConstrutorSite(construtorSite.data);

            dispatch({
                type: TEMA_LOJA_SET,
                data: construtorSite.data.arquivo
            });

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const handleChangeMarketingSEO = (property, value) => {
        const data = { ...themeStorageSelected?.marketingSEO };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_MARKETING_SEO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            marketingSEO: data
        });
    }

    const enviarImagensToAzure = async (themeStorage) => {
        const theme = themeStorage.themes_store[0];
        const pasta = `${userLoggedIn.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}`
        
        if (theme.marketingSEO?.favicon?.url !== null && theme.marketingSEO?.favicon.img !== undefined) {
            const nommeArquivo = `${theme.marketingSEO.favicon.id}.${theme.marketingSEO.favicon.extensao}`;
            theme.marketingSEO.favicon.url = await UploadFileAzure(theme.marketingSEO.favicon.img, `${pasta}/images`, nommeArquivo, theme.marketingSEO.favicon.extensao);
        }

        return themeStorage;
    }

    const handlePublish = async () => {
        try {
            setLoading(true);

            const construtorSiteObj = { ...construtorSite };
            const themeToSave = await enviarImagensToAzure(themeStorage);

            if (construtorSiteObj.id > 0) {
                construtorSiteObj.ArquivoStr = JSON.stringify(themeToSave);
                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, construtorSiteObj);
            }

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso ao salvar os dados'
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: 'Ocorreu um erro ao salvar os dados da loja',
            });
        }
    };

    return <>
        {
            loading && <Stack
                sx={{ height: "450px", marginTop: '10%' }}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress />
                <Typography sx={{ mt: 1 }}>Carregando dados do eCommerce</Typography>
            </Stack>
        }

        {
            !loading && <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                        <Typography style={{ fontWeight: 'bold' }}>Marketing / SEO</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        key="nome-seo"
                        title={'Nome da loja'}
                        style={{ margin: 20, marginLeft: -2 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        value={themeStorageSelected?.marketingSEO?.name || ''}
                        onChange={(e) => {
                            handleChangeMarketingSEO('name', e.target.value)
                            setTimeout(() => {
                                document.getElementById("nome-seo").focus();
                            }, 100);
                        }}
                        inputProps={{ maxLength: 75 }}
                        id="nome-seo"
                        label='Digite o nome da loja'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        key="url-seo"
                        title={'URL da loja'}
                        style={{ margin: 20, marginLeft: -2 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        value={themeStorageSelected?.marketingSEO?.url}
                        onChange={(e) => {
                            handleChangeMarketingSEO('url', e.target.value)
                            setTimeout(() => {
                                document.getElementById("url-seo").focus();
                            }, 100);
                        }}
                        inputProps={{ maxLength: 75 }}
                        id="url-seo"
                        label='Digite a URL da loja'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        key="titulo-seo"
                        title={'Título'}
                        style={{ margin: 20, marginLeft: -2 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        value={themeStorageSelected?.marketingSEO?.title}
                        onChange={(e) => {
                            handleChangeMarketingSEO('title', e.target.value);
                            setTimeout(() => {
                                document.getElementById("titulo-seo").focus();
                            }, 100);
                        }}
                        inputProps={{ maxLength: 75 }}
                        id="titulo-seo"
                        label='Digite o Título'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth
                        key="descricao-seo"
                        title={'Descrição'}
                        placeholder="Insira a descrição da sua loja"
                        style={{ margin: 20, marginLeft: -2 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        inputProps={{ maxLength: 175 }}
                        value={themeStorageSelected?.marketingSEO?.description}
                        onChange={(e) => {
                            handleChangeMarketingSEO('description', e.target.value);
                            setTimeout(() => {
                                document.getElementById("descricao-seo").focus();
                            }, 100);
                        }}
                        id="descricao-seo"
                        label='Digite a Descrição'
                    />
                </Grid>

                <Grid item xs={6}>
                    <UploadImagemDesign
                        dados={themeStorageSelected?.marketingSEO?.favicon}
                        isEditing={true}
                        key={'favicon'}
                        id={'favicon'}
                        idComponent={'favicon'}
                        imgData={themeStorageSelected?.marketingSEO?.favicon?.url}
                        onChange={(valor) => handleChangeMarketingSEO('favicon', valor)}
                        borderRadiusImg={0}
                        paddingTopImg={0}
                        widthImg='100%'
                        heightImg={140}
                        style={{ width: 150, height: 220 }}
                        descricao="200x200"
                        dimensaoHeightImg={200}
                        dimensaoWidthImg={200}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" onClick={() => handlePublish()}>Salvar</Button>
                    </Box>
                </Grid>
            </Grid>
        }
    </>
}

export default MarketingSEO;