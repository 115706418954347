import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { POST_DATA, SalvarLogSentry } from '../../../services/API';
import { Box, Button, Card, CircularProgress, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from '../../../services/utils/functions';
import ClearIcon from '@mui/icons-material/Clear';
import * as moment from 'moment';
import { ManageSearch } from '@mui/icons-material';
import './styles.css';
import PeriodPicker from '../../../components/PeriodPicker';
import { CONTROLEMENSAGEM_AVISO } from '../../../store/ControleMensagemReducer/types';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WalletIcon from '@mui/icons-material/Wallet';
import SavingsIcon from '@mui/icons-material/Savings';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

const columns = [
    <TableCell>Nº do Pedido</TableCell>,
    <TableCell align="left">Cupom</TableCell>,
    <TableCell align="left">Data Evento</TableCell>,
    <TableCell align="left">Data de Solicitação</TableCell>,
    <TableCell align="left">Qtd itens</TableCell>,
    <TableCell align="left">Valor Total</TableCell>,
    <TableCell align="left">Data de Pagamento</TableCell>,
    <TableCell align="left">Valor Pago</TableCell>,
    <TableCell align="left">Status</TableCell>,
    <TableCell align="center">Comprovante</TableCell>,
];

const ListagemPedidosAfiliado = ({ showPeriodPicker = true }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const leapLovers = useSelector((state) => state.leapLovers);
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);

    const [totalRecebido, setTotalRecebido] = useState(0);
    const [totalAReceber, setTotalAReceber] = useState(0);
    const [itensVendidos, setItensVendidos] = useState(0);

    const [flgPagos, setflgPagos] = useState(false);

    const [loadingPedidos, setLoadingPedidos] = useState(false);
    const [cupomPesqusiado, setCupomPesquisado] = useState('Todos');
    const [search, setSearch] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [cupons, setCupons] = useState([]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            if (leapLovers !== null) {
                await fetchPedidos();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, leapLovers, cupomPesqusiado, flgPagos, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchPedidos = async () => {
        try{
            setLoadingPedidos(true);

            const cupom = cupomPesqusiado === 'Todos' ? '' : cupomPesqusiado;
            let result = await POST_DATA(`UsuarioLoginInfluencer/GetListMinhasVendasFilter?idUsuarioLoja=${leapLovers.afiliado.IdLoja}&idUsuarioLoginiInfluencer=${leapLovers.afiliado.IdUsuarioLoginInfluencer}&dataInicio=${ordenacaoDashboard.period.startDate}&dataFim=${ordenacaoDashboard.period.endDate}&cupom=${cupom}`);
            if (flgPagos) {
                result = result.filter(a => a.DataPagamento !== null);
            }
    
            setTotalAReceber(() => result.reduce((total, item) => total += item.ValorTotal, 0));
            setTotalRecebido(() => result.reduce((total, item) => total += item.ValorPago, 0));
            setItensVendidos(() => result.reduce((total, item) => total += (item._pedido ? item._pedido?._PedidoItens.reduce((total2, item2) => total2 += item2.iQuantidade, 0) : 0), 0));
    
            setPedidos(result);
            setRows(result);
    
            const resultCupons = await POST_DATA(`Cupom/GetAllCupomByIdUsuarioInfluencer?IdUsuarioInfluencer=${leapLovers.afiliado.IdUsuarioLoginInfluencer}&flagAtivo=true`);
            setCupons(resultCupons);
    
            setLoadingPedidos(false);
        }
        catch(err){
            setLoadingPedidos(false);
            SalvarLogSentry(err);
        }
    }

    const fetchExcel = async () => {
        if (pedidos === null || pedidos.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Número do pedido; Cupom; Data evento; Data de solicitação; Valor total; Data de pagamento; Valor pago; Status`;
        pedidos?.forEach(venda => {
            dados += `\n${venda.IdPedido > 0 ? venda.IdPedido : '-'}; ${venda.Cupom !== null ? venda.Cupom : '-'}; ${formatDateSimples(venda.DataCadastro)}; ${formatDateSimples(venda.DataSolicitacao)}; ${venda.ValorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${venda.DataPagamento !== null ? formatDateSimples(venda.DataPagamento) : '-'}; ${venda.ValorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${venda.DataPagamento !== null ? 'Pago' : 'Não pago'}; `;
        });

        const url = await salvarCSV(leapLovers.afiliado.IdLoja, `RelatorioVendasAfiliado${leapLovers.afiliado.IdUsuarioLoginInfluencer}.csv`, dados);
        window.open(url, '_blank');
    }


    function Row(props) {
        const { row: item } = props;

        return (
            <Fragment>
                <TableRow>
                    <TableCell component="th" scope="row"> {(item.IdPedido === null || item.IdPedido === 0) ? '-' : item.IdPedido} </TableCell>
                    <TableCell component="th" scope="row"> {item.Cupom === null ? '-' : item.Cupom} </TableCell>
                    <TableCell align="left">{moment(item.DataCadastro).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="left">{moment(item.DataSolicitacao).format('DD/MM/YYYY')}</TableCell>
                    <TableCell component="th" scope="row"> {item._pedido !== null ? item._pedido?._PedidoItens.reduce((total, item) => total += item.iQuantidade, 0) : '-'} </TableCell>
                    <TableCell align="left">{item.ValorTotal.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}</TableCell>
                    <TableCell align="left">{item.DataPagamento === null ? '-' : moment(item.DataPagamento).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="left">{item.ValorPago === null ? '-' : item.ValorPago.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}</TableCell>
                    <TableCell align="left">{item.DataPagamento === null ? 'Não pago' : 'Pago'}</TableCell>
                    <TableCell align="center">
                        {item.UrlComprovante === null || item.UrlComprovante.length === 0 && <span>-</span>}
                        {item.UrlComprovante !== null && item.UrlComprovante.length > 0 && <a href='#' onClick={() => window.open(`${item.UrlComprovante}`, '_blank')}>Baixar</a>}
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    return (
        <>
            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4.5}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>Suas vendas</Typography>
                    </Grid>
                    {
                        showPeriodPicker && <>
                            <Grid item xs={2.5}>
                                <Card style={{ width: '100%', padding: 20, background: '#f7931e', color: 'white', height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Typography style={{ fontSize: 18 }}>
                                        <WalletIcon style={{ marginRight: 10 }} />
                                        Você recebeu
                                    </Typography>
                                    <Box style={{ width: '100%', marginTop: 30, height: 40, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', color: 'black' }}>
                                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{totalRecebido.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}</Typography>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Card style={{ width: '100%', padding: 20, border: '1px solid #842f66', height: 120, color: '#842f66', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Typography style={{ fontSize: 18 }}>
                                        <SavingsIcon style={{ marginRight: 10 }} />
                                        Falta receber
                                    </Typography>
                                    <Box style={{ width: '100%', border: '1px solid #842f66', marginTop: 30, height: 40, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{(totalAReceber - totalRecebido).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}</Typography>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Card style={{ width: '100%', padding: 20, border: '1px solid #842f66', height: 120, color: '#842f66', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Typography style={{ fontSize: 18 }}>
                                        <TurnedInIcon style={{ marginRight: 10 }} />
                                        Itens vendidos
                                    </Typography>
                                    <Box style={{ width: '100%', border: '1px solid #842f66', marginTop: 30, height: 40, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{itensVendidos}</Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2}>
                    <TextField
                        type='number'
                        label="Pesquisar por código pedido"
                        placeholder='Pesquisar por código pedido'
                        size="small"
                        sx={{ width: "350px" }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value.trim());
                            if (e.target.value !== '') {
                                setRows(pedidos.filter(a => a.IdPedido?.toString()?.includes(Number(e.target.value))));
                            } else {
                                setRows(pedidos);
                            }
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <ManageSearch />
                            </InputAdornment>,
                            endAdornment: search.length > 0 && (
                                <InputAdornment
                                    style={{ cursor: 'pointer', color: 'black' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        setRows(pedidos);
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="tamanho-selector">Cupons</InputLabel>
                        <Select
                            labelId="cupons-selector"
                            id="cupons-selector-id"
                            value={cupomPesqusiado}
                            label="Cupons"
                            style={{ width: '150px', height: 43, marginLeft: 5 }}
                            onChange={(e) => {
                                setCupomPesquisado(e.target.value)
                            }}
                        >
                            <MenuItem value='Todos'>Todos</MenuItem>
                            {
                                cupons.map((cupom, index) => {
                                    return <MenuItem key={index} value={cupom.Descricao}>{cupom.Descricao}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <Box style={{ display: 'flex' }}>
                    <FormControlLabel labelPlacement="top" control={
                        <Switch
                            checked={flgPagos}
                            onChange={() => {
                                setflgPagos(!flgPagos)
                            }} />}
                        label="Somente Pagos"
                    />
                    {showPeriodPicker && <PeriodPicker />}
                </Box>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loadingPedidos ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => column)}
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ width: "100%" }}>
                                    {rows && rows.length > 0 &&
                                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item) => {
                                            return <Row key={item.IdPedidoPagamentoInfluencer} row={item} idPedido={item.IdPedido} />
                                        }
                                        )}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={pedidos.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${rows?.length} resultados encontrados para pesquisa "${search}" - Produtos por página: `
                                    : "Produtos por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </>
    );
}

export default ListagemPedidosAfiliado;
