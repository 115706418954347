export default function tratarErro(error) {
  let erro = {};

  if (error?.response?.data) {
    if (error?.response?.status === 400) {
      //Validação da model
      const { errors } = error.response.data !== null ? error.response.data : error.response.errors;
      const errorArr = [];
      const isObject = typeof errors === 'object';

      if (isObject && Array.isArray(Object.entries(errors))) {
        for (let [values] of Object.entries(errors)) {
          if (Array.isArray(values)) {
            values.forEach((value) => {
              errorArr.push(value)
            });
          } else {
            errorArr.push(values);
          }
        }
      } else {
        if (error.response.data?.obj)
          errorArr.push(error.response.data.mensagem)
        else if (error.response.data?.mensagem)
          errorArr.push(error.response.data.mensagem)
        else
          errorArr.push('Ocorreu um erro inesperado!')
      }

      erro = {
        tipoComponente: 'modal',
        titulo: 'Validação de dados',
        message: errorArr,
      };
    } else if (error?.response?.status === 500 || error?.response?.status === 406 || error?.response?.status === 409) {
      const errorArr = [];

      if (error.response.data?.obj)
        errorArr.push(error.response.data.mensagem)
      else if (error.response.data?.mensagem)
        errorArr.push(error.response.data.mensagem)
      else if (error.response.data?.message)
        errorArr.push(error.response.data.message)
      else
        errorArr.push('Ocorreu um erro inesperado!')

      erro = {
        tipoComponente: 'modal',
        titulo: 'Erro API',
        message: errorArr,
      };
    } else {
      erro = {
        tipoComponente: 'alert',
        titulo: 'Erro API',
        message: [error.message],
      };
    }
  } else if (error?.response?.status === 401) {
    erro = {
      tipoComponente: 'alert',
      titulo: 'Erro',
      message: 'Favor fazer novamente o login',
    };
  } else {
    erro = {
      tipoComponente: 'alert',
      titulo: 'Erro',
      message: [error.message],
    };
  }

  return erro;
}
