import Cookie from 'js-cookie';
import CryptoJS from 'crypto-js';

export const setStorage = (itemName, value) => {
    const encryptedData = CryptoJS.AES.encrypt(value, process.env.REACT_APP_TOKEN_CRYPTO_JS).toString();
    localStorage.setItem(itemName, encryptedData);

    /*Cookie.set(itemName, value, {
        expires: 1,
        secure: true,
        sameSite: 'strict',
        path: '/'
    });*/
}

export const getStorage = (itemName) => {
    const encryptedData = localStorage.getItem(itemName);
    try{
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_TOKEN_CRYPTO_JS);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return null;
    }
    catch{
        return null;
    }

    //return Cookie.get(itemName);
}

export const removeStorage = (itemName) => {
    localStorage.removeItem(itemName);
    //Cookie.remove(itemName);
}
