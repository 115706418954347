import { Box, Typography } from '@mui/material';
import { Container } from './styles';
import { useNavigate } from 'react-router';
import OpcoesFormasPagamento from './opcoesFormasPagamento';

const FormasPagamento = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Configuração / Formas de pagamento</Typography>
                    <Typography style={{ fontSize: 14, color: 'gray' }}>Observação: Você só pode ter uma integração finaceira por vez</Typography>
                </Box>
            </Box>
            <OpcoesFormasPagamento />
        </Container>
    )
}

export default FormasPagamento;
