import {
    DIALOGCONFIRMATION_SET_CLOSE,
    DIALOGCONFIRMATION_SET_OPEN
} from './types';

const dialogConfirmationRecuder = (state = { open: false }, action) => {
    switch (action.type) {
        case DIALOGCONFIRMATION_SET_OPEN:
            return { ...state, open: true };
        case DIALOGCONFIRMATION_SET_CLOSE:
            return { ...state, open: false };
        default:
            return state;
    }
};

export default dialogConfirmationRecuder;
