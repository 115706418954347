import {
    CONTROLEMENSAGEM_SUCESSO,
    CONTROLEMENSAGEM_INFORMACAO,
    CONTROLEMENSAGEM_AVISO,
    CONTROLEMENSAGEM_ERRO,
    CONTROLEMENSAGEM_CLOSE,
} from './types';

export const INITIAL_STATE = {
    titulo: '',
    message: '',
    tipoComponente: 'alert',
    tipo: 'success',
    open: false,
};

const controleMensagemReducer = (state = INITIAL_STATE, action) => {
    const obj = {
        open: true,
        tipoComponente: action.tipoComponente === undefined ? 'alert' : action.tipoComponente,
        titulo: action.titulo === undefined ? 'Erro' : action.titulo,
        message: action.message,
    };

    switch (action.type) {
        case CONTROLEMENSAGEM_SUCESSO:
        return { ...state, tipo: 'success', ...obj };
        case CONTROLEMENSAGEM_INFORMACAO:
        return { ...state, tipo: 'info', ...obj };
        case CONTROLEMENSAGEM_AVISO:
        return { ...state, tipo: 'warning', ...obj };
        case CONTROLEMENSAGEM_ERRO:
        return { ...state, tipo: 'error', ...obj };
        case CONTROLEMENSAGEM_CLOSE:
        return INITIAL_STATE;
        default:
        return state;
    }
};

export default controleMensagemReducer;
  