import { Box, styled } from '@mui/material';


export const Container = styled(Box)`
    width: 100%;
    min-width: 150px;
    min-height: 120px;
    height: 100%;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
`;