import {styled, Box, Card, Typography} from '@mui/material';

export const Container = styled(Box)`
    width: 100%;
    height: 100vh;
    background: linear-gradient(80deg, rgba(255,78,157,1) 3%, rgba(254,110,125,1) 34%, rgba(255,139,96,1) 66%, rgba(255,158,77,1) 100%);
    
`;

export const CardRecovery = styled(Card)`
    width: 450px;
    height: 420px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
`;

export const AlertError = styled(Typography)`
    padding-top: 10px;
    color: #e15126;
    font-weight: bold;
`;